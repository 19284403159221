import React from 'react'
import styled from 'styled-components'
import Button from 'components/atoms/Button'
import { Modal as MuiModal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { omit } from 'ramda'

const Modal = props => {
  const {
    title,
    children,
    withSubmit,
    withCancel,
    submitButtonText,
    cancelButtonText,
    onCancel,
    onSubmit,
    disabled,
    onClose,
    hideClose
  } = props

  return (
    <MuiModal
      {...omit(['hideClose', 'submitButtonText', 'cancelButtonText'], props)}
    >
      <StyledModal onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          {!hideClose && <StyledCloseIcon onClick={onClose} />}
        </ModalHeader>
        {children}
        {
          (withSubmit || withCancel) && (
            <ButtonsWrapper>
              {withCancel && (
                <Button onClick={onCancel}>
                  {cancelButtonText}
                </Button>)}
              {withSubmit && (
                <SubmitButton color='primary' onClick={onSubmit} disabled={disabled}>
                  {submitButtonText}
                </SubmitButton>)}
            </ButtonsWrapper>
          )
        }
      </StyledModal>
    </MuiModal>
  )
}

Modal.defaultProps = {
  submitButtonText: 'Submit',
  cancelButtonText: 'Cancel',
  onCancel: () => {},
  onSubmit: () => {},
  onClose: () => {}
}

export default Modal

const StyledModal = styled.div`
  box-sizing: border-box;
  padding: 20px;
  min-width: 435px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
  max-height: 95vh;
  overflow: auto;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  ${({ theme }) => theme.colors.haiti};
  opacity: .5;
`

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  justify-content: flex-end;
`

const SubmitButton = styled(Button)`
  margin-left: 15px !important;
`
