import { validateField, validateValues } from 'utils/form'
import { boolean, object, string } from 'yup'
import { isNotNilOrEmpty } from 'utils/ramda'

export const filtersSchema = object().shape({
  total_quantity_min: string()
    .matches(/^\d+$/, 'Musi być wartością liczbową')
    .nullable()
    .transform(value => (isNotNilOrEmpty(value) ? value : null)),
  total_quantity_max: string()
    .matches(/^\d+$/, 'Musi być wartością liczbową')
    .nullable()
    .transform(value => (isNotNilOrEmpty(value) ? value : null)),
  contains_delivery_profile: string().required('Pole wymagane'),
  packaging_type: string().required('Pole wymagane')
})

export const createSchema = object().shape({
  name: string().required('Pole wymagane'),
  ean: string()
    .required('Pole wymagane')
    .matches(/^[0-9][0-9]*$/, 'Musi być wartością liczbową')
    .max(13, 'Maksymalnie 13 znaków'),
  height: string().matches(/^\d+$/, 'Musi być wartością liczbową'),
  width: string().matches(/^\d+$/, 'Musi być wartością liczbową'),
  depth: string().matches(/^\d+$/, 'Musi być wartością liczbową'),
  dimensionsUnit: string(),
  isNew: boolean(),
})

export const updateSchema = object().shape({
  weight: string()
    .required('Pole wymagane')
    .nullable()
    .transform(value => (isNotNilOrEmpty(value) ? value : null))
    .matches(
      /^[0-9][0-9]*$/,
      'Musi być wartością liczbową nie mniejszą niż zero'
    ),
  height: string()
    .required('Pole wymagane')
    .matches(
      /^[0-9][0-9]*$/,
      'Musi być wartością liczbową nie mniejszą niż zero'
    ),
  width: string()
    .required('Pole wymagane')
    .matches(
      /^[0-9][0-9]*$/,
      'Musi być wartością liczbową nie mniejszą niż zero'
    ),
  depth: string()
    .required('Pole wymagane')
    .matches(
      /^[0-9][0-9]*$/,
      'Musi być wartością liczbową nie mniejszą niż zero'
    )
})

export const shiftHistoryFiltersSchema = object().shape({
  quantity: string()
    .matches(/^\d+$/, 'Musi być wartością liczbową')
    .nullable()
    .transform(value => (isNotNilOrEmpty(value) ? value : null))
})

export const changesHistoryFiltersSchema = object().shape({})

export const validateShiftHistoryFiltersField = values =>
  validateField(shiftHistoryFiltersSchema, values)
export const validateShiftHistoryFiltersValues = validateValues(
  shiftHistoryFiltersSchema
)
export const validateChnagesHistoryFiltersField = values =>
  validateField(changesHistoryFiltersSchema, values)
export const validateChnagesHistoryFiltersValues = validateValues(
  changesHistoryFiltersSchema
)
export const validateFiltersField = values =>
  validateField(filtersSchema, values)
export const validateFiltersValues = validateValues(filtersSchema)

export const validateUpdateField = values => validateField(updateSchema, values)
export const validateUpdateValues = validateValues(updateSchema)

export const validateCreateField = values => validateField(createSchema, values)
export const validateCreateValues = validateValues(createSchema)
