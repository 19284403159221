import React from 'react'
import Table from 'components/Table'
import { propOr } from 'ramda'

const MultipickListOrdersTable = ({ orders }) => {
  const headers = [{ children: 'Numer' }, { children: 'Referencja' }]

  const rows = orders.map(row => ({
    cells: [
      { children: propOr('', 'iaiOrderSerialNumber', row) },
      { children: propOr('', 'iaiOrderId', row) }
    ]
  }))

  return <Table rows={rows} headers={headers} />
}

export default MultipickListOrdersTable
