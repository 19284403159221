import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as stocktakingOrdersService from 'services/StocktakingOrdersService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchStocktakingOrdersRoutine = createRoutine('FETCH_STOCKTAKING_ORDERS')
export const createStocktakingOrderForLocationRoutine = createRoutine('CREATE_STOCKTAKING_ORDER_FOR_LOCATION')
export const createStocktakingOrderForProductRoutine = createRoutine('CREATE_STOCKTAKING_ORDER_FOR_PRODUCT')

// ACTIONS
function * fetchStocktakingOrders ({ payload }) {
  yield put(fetchStocktakingOrdersRoutine.request())
  try {
    const { data } = yield call(stocktakingOrdersService.fetchStocktakingOrders, payload)
    yield put(fetchStocktakingOrdersRoutine.success(data))
  } catch (e) {
    yield put(fetchStocktakingOrdersRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * createStocktakingOrderForLocation ({ payload }) {
  const { values, callback } = payload
  yield put(createStocktakingOrderForLocationRoutine.request())
  try {
    yield call(stocktakingOrdersService.createStocktakingOrderForLocation, values)
    yield put(createStocktakingOrderForLocationRoutine.success())
    typeof callback === 'function' && callback()
    toast.success('Inwentaryzacja została zlecona')
  } catch (e) {
    yield put(createStocktakingOrderForLocationRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * createStocktakingOrderForProduct ({ payload }) {
  const { values, callback } = payload
  yield put(createStocktakingOrderForProductRoutine.request())
  try {
    yield call(stocktakingOrdersService.createStocktakingOrderForProduct, values)
    yield put(createStocktakingOrderForProductRoutine.success())
    typeof callback === 'function' && callback()
    toast.success('Inwentaryzacja została zlecona')
  } catch (e) {
    yield put(createStocktakingOrderForProductRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * fetchStocktakingOrdersWatcher () {
  yield takeLatest(fetchStocktakingOrdersRoutine.TRIGGER, fetchStocktakingOrders)
}

export function * createStocktakingOrderForLocationWatcher () {
  yield takeLatest(createStocktakingOrderForLocationRoutine.TRIGGER, createStocktakingOrderForLocation)
}

export function * createStocktakingOrderForProductWatcher () {
  yield takeLatest(createStocktakingOrderForProductRoutine.TRIGGER, createStocktakingOrderForProduct)
}

// SAGAS
export const stocktakingOrderSagas = [
  fork(fetchStocktakingOrdersWatcher),
  fork(createStocktakingOrderForLocationWatcher),
  fork(createStocktakingOrderForProductWatcher)
]
