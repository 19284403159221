import APIService from './APIService'
import { pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

const api = APIService()

export const fetchProducts = payload => api.get(`/products${payload.query}`)
export const fetchSingleProduct = payload => api.get(`/products/${payload.id}`)

export const updateProduct = payload =>
  api.patch(`/products/${payload.id}`, payload)
export const importProductsFromSGT = payload => {
  return api.patch(`/products/import/subiekt`, payload)
}
export const importProductsFromIAI = payload => {
  return api.patch(`/products/import/iai/`, payload)
}
export const importSingleProductFromIAI = payload => {
  return api.patch(`/products/import/iai/${payload.id}`, {
    update_all_records: false
  })
}
export const fetchShiftHistory = payload => {
  return api.get(`/products/${payload.id}/inventory-logs${payload.query}`)
}
export const fetchChangesHistory = payload => {
  return api.get(`/products/${payload.id}/logs${payload.query}`)
}
export const uploadProductFile = ({ file, productId, prefix = 'products' }) =>
  new Promise(resolve => {
    return api
      .post('/files/generate-signature-private', { contentType: file.type })
      .then(resp => {
        const signature = pathOr({}, ['data', 'data', 'signature'], resp)
        const postUrl = pathOr(null, ['data', 'data', 'postUrl'], resp)
        const pathname = `${prefix}/${productId}/${Math.round(
          Math.random() * 23423923234239
        )}-${file.name}`

        if (isNotNilOrEmpty(signature)) {
          const fd = new FormData()
          fd.append('key', pathname)
          fd.append('acl', signature.acl)
          fd.append('X-Amz-Credential', signature.xAmzCredential)
          fd.append('X-Amz-Algorithm', signature.xAmzAlgorithm)
          fd.append('Content-Type', signature.contentType)
          fd.append('success_action_status', signature.successActionStatus)
          fd.append('X-Amz-Date', signature.xAmzDate)
          fd.append('Policy', signature.policy)
          fd.append('X-Amz-Signature', signature.xAmzSignature)
          fd.append('file', file)

          fetch(postUrl, {
            method: 'POST',
            body: fd
          })
            .then(() => {
              api
                .post(`products/${productId}/file`, {
                  name: file.name,
                  path: pathname,
                  type: 'photo'
                })
                .then(resp => resolve(resp.data))
            })
            .catch(e => {
              toast.error(getApiErrors(e))
            })
        }
      })
      .catch(e => {
        toast.error(getApiErrors(e))
      })
  })

export const removeProduct = payload => api.delete(`/products/${payload.id}`)

export const createProduct = payload => api.post(`/products`, payload)
