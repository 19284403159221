import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import Modal from 'components/atoms/Modal'
import EditIcon from 'components/icons/EditIcon'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  fetchCouriersGroupsListRoutine,
  fetchCouriersListRoutine,
  fetchUngroupedCouriersListRoutine,
  updateCourierRoutine
} from 'modules/couriers/ducks/actions'
import { selectCouriersGroupsList } from 'modules/couriers/ducks/selectors'
import { pathOr } from 'ramda'

const initialValues = {
  id: '',
  courierGroupId: '--'
}

const EditCourierModal = ({ courier }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(initialValues)
  const groups = useSelector(selectCouriersGroupsList)

  useEffect(() => {
    open &&
      dispatch(
        fetchCouriersGroupsListRoutine({
          query: stringifyQuery({
            page: 1,
            limit: 1000
          })
        })
      )
  }, [open])

  useEffect(() => {
    if (isNotNilOrEmpty(courier)) {
      setValues({
        id: courier.id,
        courierGroupId: pathOr('--', ['group', 'id'], courier)
      })
    }
  }, [courier])

  const groupsOptions = useMemo(() => {
    if (isNotNilOrEmpty(groups)) {
      return [
        { label: '--', value: '--' },
        ...groups.map(group => ({
          label: group.name,
          value: group.id
        }))
      ]
    } else {
      return [{ label: '--', value: '--' }]
    }
  }, [groups])

  const modalOpenHandler = () => {
    setOpen(true)
  }

  const modalCloseHandler = () => {
    setOpen(false)
  }

  const changeValuesHandler = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const submitHandler = e => {
    e.preventDefault()

    const handleSuccess = () => {
      const currentQuery = getCurrentParsedQuery()
      currentQuery.activeTab === 'ungrouped-couriers'
        ? dispatch(
            fetchUngroupedCouriersListRoutine({
              query: stringifyQuery(currentQuery)
            })
          )
        : dispatch(
            fetchCouriersListRoutine({
              query: stringifyQuery(currentQuery)
            })
          )

      toast.success('Pomyślnie zapisano zmiany')
      modalCloseHandler()
    }

    dispatch(
      updateCourierRoutine({
        values: {
          ...values,
          courierGroupId:
            values.courierGroupId !== '--' ? values.courierGroupId : ''
        },
        callback: handleSuccess
      })
    )
  }

  return (
    <>
      <EditIcon onClick={modalOpenHandler} />
      <Modal
        open={open}
        title='Dodaj grupę do kuriera'
        onClose={modalCloseHandler}
      >
        <form onSubmit={submitHandler}>
          <Input
            select
            options={groupsOptions}
            name='courierGroupId'
            label='Wybierz grupę'
            value={values.courierGroupId}
            onChange={changeValuesHandler}
          />
          <Button type='submit' color='primary'>
            Zapisz
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default EditCourierModal
