import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { fetchMultipickRulesetsListRoutine } from 'modules/multipick/ducks/actions'
import { createMultipickRuleset } from 'services/MultipickService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { selectMultipickRulesetsList } from 'modules/multipick/ducks/selectors'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import { dissoc, omit, pathOr, propOr } from 'ramda'
import Modal from 'components/atoms/Modal'
import SearchIcon from '@mui/icons-material/Search'
import RemoveRulesetModal from 'modules/multipick/components/filters/RemoveRulesetModal'
import ButtonWrapper from 'theme/wrappers'

const MultipickSavedFilters = ({ values, listName, handleRulesetSet }) => {
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [name, setName] = useState('')
  const list = useSelector(selectMultipickRulesetsList)
  const { location: { search } } = useHistory()
  const [searchQuery, setSearchQuery] = useState('')

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const filteredResults = useMemo(() => {
    return list.filter(item =>
      pathOr('', ['ruleset', 'name'], item)
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
  }, [searchQuery, list])

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const fetchList = () => {
    const query = getCurrentParsedQuery()
    dispatch(fetchMultipickRulesetsListRoutine({ query: stringifyQuery(dissoc('activeTab', query)) }))
  }

  const handleSaveRuleset = () => {
    const handleSuccess = () => {
      handleModalClose()
      setName('')
      fetchList()
      toast.success('Zestaw filtrów został zapsiany')
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    const payload = {
      ...omit(['courierGroupId'], values),
      courierGroups: [values.courierGroupId],
      name,
      listName
    }

    createMultipickRuleset(payload)
      .then(handleSuccess)
      .catch(handleError)
  }

  useEffect(() => {
    const query = getCurrentParsedQuery()
    if (isNotNilOrEmpty(pathOr(null, ['filter', 'ordered_before'], query))) {
      fetchList()
    }
  }, [search])

  return (
    <>
      <Wrapper>
        <Input
          name='search'
          placeholder='Szukaj filtrów'
          onChange={(_, value) => setSearchQuery(value)}
          value={searchQuery}
          variant='standard'
          startAdornment={<SearchIcon />}
        />
        <ListWrapper>
          {filteredResults.map(ruleset => (
            <ListItemWrapper key={pathOr('', ['ruleset', 'name'], ruleset)}>
              <NameWrapper onClick={handleRulesetSet(ruleset.ruleset)}>
                  {pathOr('', ['ruleset', 'name'], ruleset)}&nbsp;
                <Counter>
                  {propOr(0, 'ordersCount', ruleset)}
                </Counter>
              </NameWrapper>
              <RemoveRulesetModal ruleset={ruleset.ruleset} handleFetchList={fetchList} />
            </ListItemWrapper>
          ))}
        </ListWrapper>
        <Button small onClick={handleModalOpen}>Zapisz</Button>
      </Wrapper>
      <Modal
        onClose={handleModalClose}
        open={modalOpen}
        title='Zapisz zestaw filtrów'
      >
        <Input
          name='name'
          value={name}
          label='Nazwa'
          onChange={(_, value) => setName(value)}
        />
        <ButtonWrapper>
          <Button color='primary-outline' onClick={handleModalClose}>
            Anuluj
          </Button>
          <Button
            type='submit'
            color='primary'
            onClick={handleSaveRuleset}
            disabled={isNilOrEmpty(name)}
          >
            Zapisz
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default MultipickSavedFilters

const Wrapper = styled.div`
  min-width: 250px;
  display: flex;
  flex-direction: column;
`

const ListWrapper = styled.div`
  max-height: 200px;
  flex: 1;
  overflow-y: auto;
`

const ListItemWrapper = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all .3s;
  
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`

const Counter = styled.div`
  padding: 3px;
  min-width: 20px;
  min-height: 20px;
  max-height: 20px;
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary.main};
  color: #fff;
  font-size: 12px;
`

const NameWrapper = styled.div`
  width: 100%;
`
