import React from 'react'
import styled, { css } from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import { CircularProgress, Button as MaterialButton } from '@mui/material'
import { dissoc } from 'ramda'

const Button = ({ children, isLoading, loadingText, ...props }) => (
  <StyledButton
    {...dissoc('color', props)}
    variant={props.variant || 'contained'}
    fullWidth
    buttoncolor={props.color}
  >
    <ButtonContent>
      {isLoading && (
        <CircularProgress
          size={25}
          color='inherit'
          style={{ marginRight: 15 }}
        />
      )}
      {isLoading && isNotNilOrEmpty(loadingText) ? loadingText : children}
    </ButtonContent>
  </StyledButton>
)

export default Button

const StyledButton = styled(MaterialButton)`
  height: ${({ small, medium }) => (small ? '30px' : medium ? '40px' : '45px')};
  box-shadow: none !important;

  ${({ buttoncolor, theme }) => {
    switch (buttoncolor) {
      case 'primary':
        return css`
          background-color: ${theme.colors.primary.main} !important;
        `
      case 'primary-outline':
        return css`
          background-color: transparent !important;
          color: ${theme.colors.primary.main} !important;
          border: 1px solid ${theme.colors.primary.main} !important;
        `
      case 'secondary':
        return css`
          background-color: ${theme.colors.secondary.main} !important;
        `
      case 'secondary-outline':
        return css`
          background-color: transparent !important;
          color: ${theme.colors.secondary.main} !important;
          border: 1px solid ${theme.colors.secondary.main} !important;
        `
      case 'white':
        return css`
          background-color: #fff !important;
          color: ${theme.colors.text} !important;
        `
      case 'error':
        return css`
          background-color: ${theme.colors.error} !important;
          color: #fff !important;
        `
      case 'success':
        return css`
          background-color: ${theme.colors.success} !important;
          color: #fff !important;
        `
      default:
        return css`
          background-color: ${theme.colors.darkGrey} !important;
        `
    }
  }};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.disabled} !important;
      color: ${({ theme }) => theme.colors.darkGrey} !important;
      border: 1px solid ${({ theme }) => theme.colors.disabled} !important;
    `}
`
const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
`
