import React, { useEffect } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'
import { selectSuppliersList, selectSuppliersListLoading, selectSuppliersListPagination } from 'modules/suppliers/ducks/selectors'
import { fetchSuppliersRoutine } from 'modules/suppliers/ducks/actions'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'name'
}

const SuppliersTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectSuppliersList)
  const pagination = useSelector(selectSuppliersListPagination)
  const isLoading = useSelector(selectSuppliersListLoading)
  const { location: { search } } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchSuppliersRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Nazwa', sort: 'name' },
    { children: 'Symbol', sort: 'symbol' },
    { children: 'Subiekt ID', sort: 'subiekt_id' },
    { children: 'Tax ID', sort: 'tax_id' }
  ]

  const rows = list.map(row => ({
    cells: [
      { children: propOr('---', 'name', row) },
      { children: propOr('---', 'symbol', row) },
      { children: propOr('---', 'subiektId', row) },
      { children: propOr('---', 'taxId', row) }
    ]
  }))

  return (
    <Table
      pagination={pagination}
      onPageChange={handlePageChange}
      headers={headers}
      rows={rows}
      emptyState='Brak dostawców do wyświetlenia'
      isLoading={isLoading}
    />
  )
}

export default SuppliersTable
