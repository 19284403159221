import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import ImportButton from 'components/atoms/ImportButton'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import {
  importSaleOrdersFromIAI,
  importSingleSaleOrderFromIAI
} from 'services/SaleOrdersService'
import ButtonWrapper from 'theme/wrappers'
import Input from 'components/atoms/Input'
import {
  validateImportSingleSaleOrderDetailsViewField,
  validateImportSingleSaleOrderDetailsViewValues,
  validateImportSingleSaleOrderField,
  validateImportSingleSaleOrderValues
} from '../ducks/schema'
import { isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'
import { isAuthorized } from 'utils/navigation'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { propOr } from 'ramda'

const emptyValues = { iaiOrderSerialNumber: '' }

const ImportSaleOrdersFromIAIModal = ({ isDetailsView }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [saleOrder, setSaleOrder] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRoles = ['admin']

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setLoading(false)
  }

  const handleSuccess = () => {
    handleModalClose()
    setSaleOrder(emptyValues)
    toast.success('Zlecono import. Sprawdź wynik w module Zadania Importu')
  }

  const handleError = e => {
    setLoading(false)
    toast.error(getApiErrors(e))
  }

  const handleValueChange = (name, value) => {
    setSaleOrder(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    isDetailsView
      ? validateImportSingleSaleOrderDetailsViewValues(saleOrder, setValid)
      : validateImportSingleSaleOrderValues(saleOrder, setValid)
  }, [saleOrder])

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    isNotNilOrEmpty(saleOrder.iaiOrderSerialNumber)
      ? importSingleSaleOrderFromIAI({
          iaiOrderSerialNumber: parseInt(saleOrder.iaiOrderSerialNumber)
        })
          .then(handleSuccess)
          .catch(handleError)
      : importSaleOrdersFromIAI().then(handleSuccess).catch(handleError)
  }

  return (
    <>
      <ImportButton onClick={handleModalOpen}>Importuj dane z IAI</ImportButton>
      <Modal
        open={open}
        title='Aktualizuj dane na podstawie IAI'
        onClose={handleModalClose}
      >
        {isAuthorized(priviledgedRoles, userRoles) ? <Tooltip
          title={
            'Aby zaimportować pojedyncze zamówienie wpisz jego numer seryjny'
          }
        >
          <InputWrapper>
            <Input
              name='iaiOrderSerialNumber'
              label='Numer IAI zamówienia'
              value={saleOrder.iaiOrderSerialNumber}
              onChange={handleValueChange}
              validate={
                isDetailsView
                  ? validateImportSingleSaleOrderDetailsViewField(saleOrder)
                  : validateImportSingleSaleOrderField(saleOrder)
              }
              clearable
            />
          </InputWrapper>
        </Tooltip> : null}
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          {isDetailsView ? (
            <Button
              type='submit'
              color='primary'
              onClick={handleSubmit}
              disabled={loading || !valid}
              isLoading={loading}
            >
              Aktualizuj zamówienie
            </Button>
          ) : (
            <Button
              type='submit'
              color='primary'
              onClick={handleSubmit}
              disabled={loading || !valid}
              isLoading={loading}
            >
              {!isDetailsView && isNotNilOrEmpty(saleOrder.iaiOrderSerialNumber)
                ? 'Aktualizuj zamówienie'
                : 'Aktualizuj wszystko'}
            </Button>
          )}
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default ImportSaleOrdersFromIAIModal

const InputWrapper = styled.div`
  margin-bottom: 30px;
`
