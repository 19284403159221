import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'components/atoms/Modal'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import Button from 'components/atoms/Button'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import StocktakingOrderIcon from 'components/icons/StocktakingOrderIcon'
import { dissoc, pathOr, propOr } from 'ramda'
import {
  createStocktakingOrderForLocationRoutine,
  createStocktakingOrderForProductRoutine
} from 'modules/stocktakingOrders/ducks/actions'
import ButtonWrapper from 'theme/wrappers'

const emptyValues = {
  productIds: [],
  locationIds: [],
  comment: ''
}

const CreateStocktakingOrderModal = ({ location, product }) => {
  const isLocation = isNotNilOrEmpty(location)
  const children = isLocation
    ? propOr([], 'inventory', location)
    : propOr([], 'inventory', product)

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const inputOptions = useMemo(() => {
    return children.map(entity => ({
      label: isLocation
        ? `${pathOr('', ['product', 'name'], entity)} (${propOr(0, 'quantity', entity)})`
        : isNotNilOrEmpty(pathOr(null, ['inventorable', 'prefix'], entity))
          ? `${entity.inventorable.prefix}-${entity.inventorable.code} (${propOr(0, 'quantity', entity)})`
          : `${pathOr('', ['inventorable', 'name'], entity)} (${propOr(0, 'quantity', entity)})`,
      value: isLocation
        ? pathOr('', ['product', 'id'], entity)
        : pathOr('', ['inventorable', 'id'], entity)
    }))
  }, [children])

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(emptyValues)
    setActiveTabIndex(0)
  }

  const handleSubmit = () => {
    const isGlobal = activeTabIndex === 0
    if (isLocation) {
      const payload = {
        id: location.id,
        productIds: values.productIds,
        comment: values.comment
      }
      dispatch(createStocktakingOrderForLocationRoutine({
        values: isGlobal ? dissoc('productIds', payload) : payload,
        callback: handleModalClose
      }))
    } else {
      const payload = {
        id: product.id,
        locationIds: values.locationIds,
        comment: values.comment
      }
      dispatch(createStocktakingOrderForProductRoutine({
        values: isGlobal ? dissoc('locationIds', payload) : payload,
        callback: handleModalClose
      }))
    }
  }

  return (
    <>
      <StocktakingOrderIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        onClose={handleModalClose}
        title='Zlecenie inwentaryzacji'
      >
        <ModalContentWrapper>
          {
            isLocation
              ? <Title>Lokalizacja <span>{location.name}</span></Title>
              : <Title>{product.name}</Title>
          }
          <Tabs>
            <Tab
              active={activeTabIndex === 0}
              onClick={() => {
                setActiveTabIndex(0)
                setValues(prev => ({
                  ...prev,
                  productIds: [],
                  locationIds: []
                }))
              }}
            >
              {
                isLocation
                  ? 'Cała lokalizacja'
                  : 'Wszystkie lokalizacje'
              }
            </Tab>
            <Tab
              active={activeTabIndex === 1}
              onClick={() => setActiveTabIndex(1)}
            >
              {
                isLocation
                  ? 'Wybrane produkty'
                  : 'Wybrane lokalizacje'
              }
            </Tab>
          </Tabs>
          <InputWrapper>
            {
              activeTabIndex === 1
                ? isNotNilOrEmpty(inputOptions)
                  ? isLocation
                    ? (
                      <Input
                        select
                        multiple
                        options={inputOptions}
                        name='productIds'
                        label='Produkty'
                        value={values.productIds}
                        onChange={handleValueChange}
                      />
                    )
                    : (
                      <Input
                        select
                        multiple
                        options={inputOptions}
                        name='locationIds'
                        label='Lokalizacje'
                        value={values.locationIds}
                        onChange={handleValueChange}
                      />
                    )
                  : isLocation
                    ? <EmptyState>Brak produktów w wybranej lokalizacji</EmptyState>
                    : <EmptyState>Ten produkt nie jest przypisany do żadnej lokalizacji</EmptyState>
                : null
            }
            <Input
              name='comment'
              label='Komentarz'
              value={values.comment}
              onChange={handleValueChange}
            />
          </InputWrapper>
          <ButtonWrapper>
            <Button
              onClick={handleModalClose}
              color='primary-outline'
            >
              Anuluj
            </Button>
            <Button
              onClick={handleSubmit}
              color='primary'
              disabled={isLocation
                ? activeTabIndex === 1 && isNilOrEmpty(values.productIds)
                : activeTabIndex === 1 && isNilOrEmpty(values.locationIds)}
            >
              Zleć inwentaryzację
            </Button>
          </ButtonWrapper>
        </ModalContentWrapper>
      </Modal>
    </>
  )
}

export default CreateStocktakingOrderModal

const ModalContentWrapper = styled.div`
  width: 500px;
`

const InputWrapper = styled.div`
  margin-top: 20px;
`

const Tabs = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 60px;
  transition: all .3s;
  border: 1px solid ${({ theme, active }) => active ? theme.colors.primary.main : theme.colors.border};
  border-radius: 4px;
  cursor: pointer;
`

const EmptyState = styled.div`
  margin: 10px 0;
`

const Title = styled.div`
  font-size: 22px;
  margin-bottom: 20px;

  span {
    font-weight: bold;
  }
`
