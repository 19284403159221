import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { flatten, head, pathOr, propOr } from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import Table from 'components/Table'
import { useSelector } from 'react-redux'
import {
  selectCurrentGeneratedOrders,
  selectSimulatedLists
} from 'modules/multipick/ducks/selectors'
import CreateListManuallyModal from 'modules/multipick/components/CreateListManuallyModal'

const MultipickSimulatedOrders = ({ handleSimulateFilters }) => {
  const simulatedList = useSelector(selectSimulatedLists)
  const orders = propOr([], 'orders', simulatedList)
  const allItems = flatten(orders.map(order => order.items))
  const [selectedOrders, setSelectedOrders] = useState([])
  const generatedOrders = useSelector(selectCurrentGeneratedOrders)

  useEffect(() => {
    setSelectedOrders([])
  }, [simulatedList])

  useEffect(() => {
    isNotNilOrEmpty(generatedOrders) && setSelectedOrders([])
  }, [generatedOrders])

  const products = useMemo(() => {
    let productsById = {}
    allItems.forEach(item => {
      const productId = pathOr('', ['product', 'id'], item)
      productsById[productId] = [
        ...pathOr([], [productId], productsById),
        { ...item.product, orderedQuantity: item.orderedQuantity }
      ]
    })
    let result = []

    for (let key in productsById) {
      const products = productsById[key]
      const firstProduct = head(products)
      const orderedQuantity = products.reduce(
        (acc, { orderedQuantity }) => acc + orderedQuantity,
        0
      )

      result = [
        ...result,
        {
          ...firstProduct,
          orderedQuantity,
          ordersCount: products.length
        }
      ]
    }

    return result
  }, [allItems])

  const tasks = useMemo(() => {
    let productsById = {}
    const items = flatten(selectedOrders.map(order => order.items))
    items.forEach(item => {
      const productId = pathOr('', ['product', 'id'], item)
      productsById[productId] = [
        ...pathOr([], [productId], productsById),
        { ...item.product, orderedQuantity: item.orderedQuantity }
      ]
    })
    let result = []

    for (let key in productsById) {
      const products = productsById[key]
      const firstProduct = head(products)
      const orderedQuantity = products.reduce(
        (acc, { orderedQuantity }) => acc + orderedQuantity,
        0
      )

      result = [
        ...result,
        {
          ...firstProduct,
          orderedQuantity,
          ordersCount: products.length
        }
      ]
    }

    return result
  }, [selectedOrders])

  const ordersHeaders = [
    { children: 'Numer' },
    { children: 'Referencja' },
    { children: 'Ilość linii' }
  ]

  const ordersRows = orders.map(row => {
    return {
      original: row,
      isInactive: generatedOrders.some(order => order.id === row.id),
      cells: [
        { children: row.iaiOrderSerialNumber },
        { children: row.iaiOrderId },
        { children: propOr([], 'items', row).length }
      ]
    }
  })

  const productHeaders = [
    { children: 'Nazwa' },
    { children: 'EAN' },
    { children: 'Ilość' },
    { children: 'Ilosć zam.' }
  ]
  const productsRows = products.map(row => ({
    cells: [
      { children: row.name },
      { children: row.ean },
      { children: row.orderedQuantity },
      { children: row.ordersCount }
    ]
  }))

  const tasksHeaders = [
    { children: 'EAN' },
    { children: 'Nazwa' },
    { children: 'Ilość' }
  ]

  const tasksRows = tasks.map(row => ({
    cells: [
      { children: row.ean },
      { children: row.name },
      { children: row.orderedQuantity }
    ]
  }))

  const toggleCheckbox = (row, value) => {
    value
      ? setSelectedOrders(prev => [...prev, row])
      : setSelectedOrders(prev => prev.filter(o => o.id !== row.id))
  }

  return (
    <ListsWrapper>
      <List>
        <ListTitle>
          Produkty{' '}
          {isNotNilOrEmpty(products) &&
            `(${products.reduce(
              (acc, { orderedQuantity }) => acc + orderedQuantity,
              0
            )})`}
        </ListTitle>
        {isNotNilOrEmpty(products) && (
          <ListContent>
            <Table headers={productHeaders} rows={productsRows} />
          </ListContent>
        )}
      </List>
      <List>
        <ListTitle>
          Zamówienia {isNotNilOrEmpty(orders) && `(${orders.length})`}
        </ListTitle>
        {isNotNilOrEmpty(orders) && (
          <ListContent>
            <Table
              withCheckboxes
              headers={ordersHeaders}
              rows={ordersRows}
              selected={selectedOrders}
              onCheck={toggleCheckbox}
            />
          </ListContent>
        )}
      </List>
      <TasksList>
        <ListTitle>
          Zadania {isNotNilOrEmpty(tasks) && `(${tasks.length})`}
        </ListTitle>
        {isNotNilOrEmpty(tasks) && (
          <>
            <TaskListContent>
              <Table headers={tasksHeaders} rows={tasksRows} />
            </TaskListContent>
            {isNilOrEmpty(generatedOrders) && <CreateListManuallyModal
              handleSimulateFilters={handleSimulateFilters}
              selectedOrders={selectedOrders}
            />}
          </>
        )}
      </TasksList>
    </ListsWrapper>
  )
}

export default MultipickSimulatedOrders

const ListsWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
`

const List = styled.div`
  min-height: 300px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.border};
  flex: 1;
`

const TasksList = styled.div`
  min-height: 300px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.border};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TaskListContent = styled.div`
  padding: 5px;
  max-height: 500px;
  overflow-y: auto;
  flex: 1;
`

const ListTitle = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: #fff;
`

const ListContent = styled.div`
  padding: 5px;
  max-height: 500px;
  overflow-y: auto;
`
