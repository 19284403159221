import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectPacking = state => state.packing

export const selectCurrentUnitOrders = createSelector(
  selectPacking,
  propOr([], 'currentUnitOrders')
)

export const selectCurrentUnitCompletionList = createSelector(
  selectPacking,
  propOr({}, 'currentUnitCompletionList')
)

export const selectCurrentPackingOrder = createSelector(
  selectPacking,
  propOr(null, 'currentPackingOrder')
)

export const selectPackedItemQuantity = createSelector(
  selectPacking,
  propOr(1, 'packedItemQuantity')
)

export const selectPackedItemEan = createSelector(
  selectPacking,
  propOr('', 'packedItemEan')
)

export const selectCurrentUnitNumber = createSelector(
  selectPacking,
  propOr('', 'currentUnitNumber')
)

export const selectCurrentUnitParentNumber = createSelector(
  selectPacking,
  propOr('', 'currentUnitParentNumber')
)
