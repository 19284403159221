import React, { useEffect, useMemo, useState } from 'react'
import Input from 'components/atoms/Input'
import Modal from 'components/atoms/Modal'
import EditIcon from 'components/icons/EditIcon'
import { useDispatch, useSelector } from 'react-redux'
import { selectPackingTypesList } from 'modules/packingTypes/ducks/selectors'
import { pathOr, pick, propOr } from 'ramda'
import Button from 'components/atoms/Button'
import { updateProductRoutine } from 'modules/products/ducks/actions'
import {
  validateUpdateField,
  validateUpdateValues
} from 'modules/products/ducks/schema'
import styled from 'styled-components'
import { selectStockUnitKindList } from 'modules/stockUnits/ducks/selectors'
import { INPOST_SIZE } from 'utils/product'
import { fetchStockUnitKindsRoutine } from 'modules/stockUnits/ducks/actions'
import { isNilOrEmpty } from 'utils/ramda'

const initialValues = {
  packagingTypeId: '',
  weight: '',
  weightUnit: 'g',
  width: '',
  height: '',
  depth: '',
  dimensionsUnit: 'cm',
  inpostSize: 'none',
  isCumbersome: false,
  isPrepacked: false,
  stockUnitKindId: '',
  comment: ''
}

const EditProductModal = ({ product, isProductDetailsView }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const packagingTypes = useSelector(selectPackingTypesList)
  const stockUnitKindsList = useSelector(selectStockUnitKindList)

  useEffect(() => {
    modalOpen && dispatch(fetchStockUnitKindsRoutine())
  }, [modalOpen])

  const stockUnitKindsOptions = useMemo(() => {
    return stockUnitKindsList.map(kind => ({
      label: kind.name,
      value: kind.id
    }))
  }, [stockUnitKindsList])

  const packagingTypeOptions = useMemo(() => {
    return packagingTypes.map(packagingType => ({
      label: packagingType.name,
      value: packagingType.id
    }))
  }, [packagingTypes])

  const inpostSizeOptions = useMemo(() => {
    let items = []
    for (let key in INPOST_SIZE) {
      items = [...items, { label: INPOST_SIZE[key], value: key }]
    }
    return items
  }, [INPOST_SIZE])

  useEffect(() => {
    modalOpen &&
      setValues({
        ...pick(
          [
            'name',
            'weight',
            'weightUnit',
            'height',
            'width',
            'depth',
            'dimensionsUnit',
            'isCumbersome',
            'isPrepacked',
            'inpostSize',
            'comment'
          ],
          product
        ),
        packagingTypeId: pathOr('', ['packagingType', 'id'], product),
        stockUnitKindId: propOr('', 'stockUnitKindId', product)
      })
  }, [product, modalOpen])

  const modalOpenHandler = e => {
    e.stopPropagation()
    setModalOpen(true)
  }

  const modalCloseHandler = e => {
    e && e.stopPropagation()
    setModalOpen(false)
    setValues(initialValues)
  }

  const valuesChangeHandler = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateUpdateValues(values, setValid)
  }, [values])

  const submitHandler = e => {
    e.preventDefault()
    dispatch(
      updateProductRoutine({
        values: {
          ...values,
          id: product.id,
          inpostSize: values.inpostSize === 'none' ? '' : values.inpostSize
        },
        isProductDetailsView,
        callback: modalCloseHandler
      })
    )
  }

  return (
    <>
      {isProductDetailsView ? (
        <Button medium onClick={modalOpenHandler} color='primary'>
          Edytuj produkt
        </Button>
      ) : (
        <EditIcon onClick={modalOpenHandler} />
      )}
      <Modal
        open={modalOpen}
        title='Edytuj produkt'
        onClose={modalCloseHandler}
      >
        <ProductName>{values.name}</ProductName>
        <form onSubmit={submitHandler}>
          <Input
            select
            name='packagingTypeId'
            label='Rodzaj opakowania'
            options={packagingTypeOptions}
            value={values.packagingTypeId}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            name='weight'
            label='Waga'
            value={values.weight}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            select
            name='weightUnit'
            label='Jednostka wagi'
            options={[
              { label: 'g', value: 'g' },
              { label: 'kg', value: 'kg' }
            ]}
            value={values.weightUnit}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            name='height'
            label='Wysokość'
            value={values.height}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            name='width'
            label='Szerokość'
            value={values.width}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            name='depth'
            label='Głębokość'
            value={values.depth}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            select
            name='dimensionsUnit'
            label='Jednostka wymiarów'
            options={[
              { label: 'mm', value: 'mm' },
              { label: 'cm', value: 'cm' },
              { label: 'm', value: 'm' }
            ]}
            value={values.dimensionsUnit}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            select
            name='inpostSize'
            label='Rozmiar paczkomatu'
            options={inpostSizeOptions}
            value={isNilOrEmpty(values.inpostSize) ? 'none' : values.inpostSize}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            select
            name='isCumbersome'
            label='Nieporęczny?'
            options={[
              { label: 'Nie', value: false },
              { label: 'Tak', value: true }
            ]}
            value={values.isCumbersome}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            select
            name='isPrepacked'
            label='Wstępnie spakowany?'
            options={[
              { label: 'Nie', value: false },
              { label: 'Tak', value: true }
            ]}
            value={values.isPrepacked}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            select
            options={stockUnitKindsOptions}
            value={values.stockUnitKindId}
            name='stockUnitKindId'
            label='Rodzaj jednostki'
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Input
            clearable
            name='comment'
            label='Komentarz'
            value={values.comment}
            multiline
            rows={3}
            onChange={valuesChangeHandler}
            validate={validateUpdateField(values)}
          />
          <Button type='submit' color='primary' disabled={!valid}>
            Zapisz
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default EditProductModal

const ProductName = styled.div`
  font-size: 16px;
  padding: 8px;
`
