import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as suppliersService from 'services/SuppliersService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchSuppliersRoutine = createRoutine('FETCH_SUPPLIERS')

// ACTIONS
function * fetchSuppliers ({ payload }) {
  yield put(fetchSuppliersRoutine.request())
  try {
    const { data } = yield call(suppliersService.getSuppliers, payload)
    yield put(fetchSuppliersRoutine.success(data))
  } catch (e) {
    yield put(fetchSuppliersRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

export function * fetchSuppliersWatcher () {
  yield takeLatest(fetchSuppliersRoutine.TRIGGER, fetchSuppliers)
}

// SAGAS
export const suppliersSagas = [
  fork(fetchSuppliersWatcher)
]
