import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import { validateZoneField, validateZoneValues } from 'modules/zones/ducks/schema'
import { selectZonesDictionary } from 'ducks/dictionaries/selectors'
import { keys, pick, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { toSnakeCase } from 'js-convert-case'
import { updateZoneRoutine } from 'modules/zones/ducks/actions'
import EditIcon from 'components/icons/EditIcon'

const emptyValues = {
  name: '',
  zoneType: '',
  storageType: ''
}

const EditZoneModal = ({ zone }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const zonesDictionary = useSelector(selectZonesDictionary)
  const storageTypes = propOr({}, 'storageTypes', zonesDictionary)
  const zoneTypes = propOr({}, 'zoneTypes', zonesDictionary)
  const dispatch = useDispatch()

  useEffect(() => {
    open && setValues(pick(['name', 'storageType', 'zoneType'], zone))
  }, [zone, open])

  const storageTypeOptions = useMemo(() => {
    if (isNotNilOrEmpty(storageTypes)) {
      const objectKeys = keys(storageTypes)
      return objectKeys.map(key => ({
        value: toSnakeCase(key),
        label: storageTypes[key]
      }))
    } else {
      return []
    }
  }, [storageTypes])

  const zoneTypeOptions = useMemo(() => {
    if (isNotNilOrEmpty(zoneTypes)) {
      const objectKeys = keys(zoneTypes)
      return objectKeys.map(key => ({
        value: toSnakeCase(key),
        label: zoneTypes[key]
      }))
    } else {
      return []
    }
  }, [zoneTypes])

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    open && validateZoneValues(values, setValid)
  }, [values, open])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(updateZoneRoutine({
      values: {
        ...values,
        id: zone.id
      },
      callback: handleModalClose
    }))
  }

  return (
    <>
      <EditIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Edycja strefy'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa strefy'
            value={values.name}
            onChange={handleValueChange}
            validate={validateZoneField(values)}
          />
          <Input
            select
            options={zoneTypeOptions}
            name='zoneType'
            label='Typ strefy'
            value={values.zoneType}
            onChange={handleValueChange}
            validate={validateZoneField(values)}
          />
          <Input
            select
            options={storageTypeOptions}
            name='storageType'
            label='Typ składowania'
            value={values.storageType}
            onChange={handleValueChange}
            validate={validateZoneField(values)}
          />
          <Button
            type='submit'
            color='primary'
            disabled={!valid}
          >
            Zapisz
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default EditZoneModal
