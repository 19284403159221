import React, { useEffect, useMemo, useState } from 'react'
import AddButton from 'components/atoms/AddButton'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import { validateUserField, validateUserValues } from 'modules/users/ducks/schema'
import { createUserRoutine } from 'modules/users/ducks/actions'
import { selectUserRolesDictionary } from 'ducks/dictionaries/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { keys } from 'ramda'
import styled from 'styled-components'
import { toSnakeCase } from 'js-convert-case'

const emptyValues = {
  username: '',
  roles: []
}

const AddUserModal = () => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const userRolesDictionary = useSelector(selectUserRolesDictionary)

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const userRolesOptions = useMemo(() => {
    if (isNotNilOrEmpty(userRolesDictionary)) {
      const objectKeys = keys(userRolesDictionary)
      return objectKeys.map(key => ({
        value: toSnakeCase(key),
        label: userRolesDictionary[key]
      }))
    } else {
      return []
    }
  }, [userRolesDictionary])

  useEffect(() => {
    validateUserValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(createUserRoutine({
      values: {
        username: values.username.toLowerCase(),
        roles: values.roles
      },
      callback: handleModalClose
    }))
  }

  return (
    <>
      <AddButton onClick={handleModalOpen}>Dodaj użytkownika</AddButton>
      <Modal
        open={open}
        title='Dodaj nowego użytkownika'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='username'
            label='Login'
            value={values.username}
            onChange={handleValueChange}
            validate={validateUserField(values)}
          />
          <Input
            multiple
            select
            name='roles'
            label='Rola użytkownika'
            value={values.roles}
            onChange={handleValueChange}
            options={userRolesOptions}
            validate={validateUserField(values)}
          />
          <ButtonWrapper>
            <Button
              type='submit'
              color='primary'
              disabled={!valid}
            >
              Dodaj
            </Button>
          </ButtonWrapper>
        </form>
      </Modal>
    </>
  )
}

export default AddUserModal

const ButtonWrapper = styled.div`
  margin-top: 30px;
`
