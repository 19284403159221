import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Collapse, IconButton, InputAdornment, MenuItem, TextField } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import PasswordHint from 'components/PasswordHint'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { propOr } from 'ramda'

export const Input = props => {
  const {
    name,
    type,
    onChange,
    label,
    value: initialValue,
    required,
    variant,
    disabled,
    multiline,
    validate,
    rows,
    startAdornment,
    endAdornment,
    hiddenLabel,
    noMarginBottom,
    inputProps,
    placeholder,
    onBlur,
    onFocus,
    withPasswordHint,
    validatePasswordCallback,
    select,
    multiple,
    options,
    size,
    clearable,
    autoComplete
  } = props
  const [passwordVisible, _setPasswordVisibility] = useState(false)
  const [passwordHintVisible, setPasswordHintVisible] = useState(false)
  const [value, _setValue] = useState(initialValue || '')
  const [touched, _setTouched] = useState(false)
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: ''
  })

  useEffect(() => {
    if (touched) {
      validate(name, v => {
        _validate({ valid: v.valid, error: v.errors })
      })
    }
  }, [value, touched])

  useEffect(() => {
    _setValue(initialValue === null ? '' : initialValue)
  }, [initialValue])

  const inputType = isNilOrEmpty(type) || passwordVisible ? 'text' : type

  const handleVisibilityChange = () =>
      _setPasswordVisibility(prevVisibility => !prevVisibility)

  const passwordAdornment =  (
      <InputAdornment position='end'>
        <IconButton onClick={handleVisibilityChange}>
          {passwordVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
        </IconButton>
      </InputAdornment>
  )

  const clearAdornment = (
    <InputAdornment position='end'>
      <IconButton onClick={() => {
        _setValue('')
        onChange(name, '')
      }}>
        <ClearOutlinedIcon />
      </IconButton>
    </InputAdornment>
  )

  const getEndAdornment = useMemo(() => {
    switch (true) {
      case isNotNilOrEmpty(endAdornment):
        return endAdornment
      case type === 'password':
        return passwordAdornment
      case clearable && isNotNilOrEmpty(value):
        return clearAdornment
      default:
        return null
    }
  }, [value])

  const handleBlur = () => {
    _setTouched(true)
    typeof onBlur === 'function' && onBlur()
    type === 'password' && withPasswordHint && setPasswordHintVisible(false)
  }
  const handleFocus = () => {
    typeof onFocus === 'function' && onFocus()
    type === 'password' && withPasswordHint && setPasswordHintVisible(true)
  }
  const handleChange = e => {
    _setValue(e.target.value)
    onChange(name, e.target.value)
  }

  const dateInputOptions = (type === 'date' || type === 'datetime-local')
    ? {
        InputLabelProps: {
         shrink: true
        }
      }
    : {}

  return (
    <InputWrapper noMarginBottom={noMarginBottom}>
      <TextField
        error={!valid && touched && !disabled}
        helperText={error}
        label={label}
        size={size}
        disabled={disabled}
        hiddenLabel={hiddenLabel}
        placeholder={placeholder}
        variant={variant}
        required={required}
        name={name}
        multiline={multiline}
        rows={rows}
        type={inputType || 'text'}
        fullWidth
        value={value}
        select={select}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        autoComplete={autoComplete}
        SelectProps={{
          multiple: multiple,
          renderValue: multiple ? value => {
            return (
              <MultiSelectSelectedItems>
                {
                  value.map(item => {
                    const option = options.find(option => option.value === item)
                    return isNotNilOrEmpty(option) ? (
                      <MultiSelectSelectedItemLabel key={propOr('', 'value', option)}>
                        {propOr('', 'label', option)}
                      </MultiSelectSelectedItemLabel>
                    ) : null
                  })
                }
              </MultiSelectSelectedItems>
            )
          } : value => {
            const option = options.find(option => option.value === value)
            return propOr('', 'label', option)
          }
        }}
        InputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start">
              {startAdornment}
            </InputAdornment>
          ) : null,
          endAdornment: getEndAdornment
        }}
        inputProps={inputProps}
        {...dateInputOptions}
      >
        {select && isNotNilOrEmpty(options)
          ? options.map(option => (
              <MenuItem
                disabled={option.disabled}
                key={option.value}
                value={option.value}
              >
                {option.label}{option.count ? ` (${option.count})` : ''}
              </MenuItem>
            ))
          : <MenuItem disabled>Brak opcji</MenuItem>
        }
      </TextField>
      <Collapse in={passwordHintVisible}>
        <PasswordHint
          validatePasswordCallback={validatePasswordCallback}
          password={value}
        />
      </Collapse>
    </InputWrapper>
  )
}

Input.defaultProps = {
  name: '',
  type: 'text',
  size: 'big',
  label: '',
  value: '',
  required: false,
  variant: 'filled',
  disabled: false,
  multiline: false,
  rows: 1,
  noMarginBottom: false,
  inputProps: {},
  placeholder: '',
  onChange: () => {},
  onBlur: () => {},
  validate: () => {},
  onFocus: () => {}
}

export default Input

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: ${({ noMarginBottom }) => noMarginBottom ? 0 : '20px' };
  background-color: #fff;
  width: 100%;

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary.main};
  }

  .MuiSelect-select {
    &:focus {
      background-color: #fff !important;
    }
  }
  
  .MuiFilledInput-root {
    background-color: #fff !important;
    
    &:hover, &:active, &:focus {
      background-color: #fff !important;
    }
  }
  
  .Mui-error {
    color: ${({ theme }) => theme.colors.error} !important;
  }
  
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    margin: 0;
  }
`

const MultiSelectSelectedItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 5px;
`

const MultiSelectSelectedItemLabel = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  width: fit-content;
  border-radius: 8px;
  padding: 3px 10px;
`
