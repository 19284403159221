import { Collapse, Tooltip } from '@mui/material'
import { propOr } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { redirectWithQuery } from 'utils/navigation'
import PATHS from 'utils/paths'

const SublistNumbersList = ({ completionList }) => {
  const [open, setOpen] = useState(false)
  const sublists = propOr([], 'subLists', completionList)
  const hasSubLists = isNotNilOrEmpty(sublists)

  const handleShowSublists = () => {
    setOpen(true)
  }

  const handleHideSublists = () => {
    setOpen(false)
  }

  const handleCompletionListDetails = completionList => e => {
    e && e.stopPropagation()
    const query = {
      page: 1,
      limit: 100,
      sort: 'created_at',
      activeTab: 'created'
    }
    redirectWithQuery(PATHS.lists, query, {
      currentMultipick: completionList,
      isOpen: hasSubLists
    })
  }

  return (
    <>
      <CompletionList>
        <Tooltip title='Szczegóły listy kompletacji'>
          <MultipickNumber
            onClick={handleCompletionListDetails(completionList)}
          >
            {propOr('---', 'listNumber', completionList)}
          </MultipickNumber>
        </Tooltip>
        {hasSubLists && (
          <>
            {open ? (
              <ArrowDropUpIcon onClick={handleHideSublists} />
            ) : (
              <ArrowDropDownIcon onClick={handleShowSublists} />
            )}
          </>
        )}
      </CompletionList>
      <Collapse in={open}>
        {sublists.map(sublist => {
          return (
            <Tooltip key={sublist.id} title='Szczegóły listy kompletacji'>
              <MultipickNumberSublist
                onClick={handleCompletionListDetails(completionList)}
              >
                {propOr('---', 'listNumber', sublist)}
              </MultipickNumberSublist>
            </Tooltip>
          )
        })}
      </Collapse>
    </>
  )
}

export default SublistNumbersList

const MultipickNumber = styled.div`
  font-weight: bolder;
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`
const MultipickNumberSublist = styled.div`
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`
const CompletionList = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`
