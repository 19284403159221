import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import FileInput from 'components/atoms/FileInput'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProduct } from 'modules/products/ducks/selectors'
import { fetchSingleProductRoutine } from 'modules/products/ducks/actions'
import { uploadProductFile } from 'services/ProductsService'
import AddButton from 'components/atoms/AddButton'
import styled from 'styled-components'

const AddProductPhotoModal = () => {
  const product = useSelector(selectCurrentProduct)
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState({})
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
    setFile({})
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    const handleSuccess = () => {
      handleModalClose()
      setLoading(false)
      dispatch(fetchSingleProductRoutine({ id: product.id }))
    }

    const handleError = e => {
      setLoading(false)
      toast.error(getApiErrors(e))
    }

    uploadProductFile({ file, productId: product.id })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <ButtonWrapper>
        <AddButton onClick={handleModalOpen}>
          Dodaj zdjęcie
        </AddButton>
      </ButtonWrapper>
      <Modal
        open={open}
        title='Dodaj zdjęcie produktu'
        onClose={handleModalClose}
      >
        <FileInput
          onChange={file => setFile(file)}
          accept='.jpg,.jpeg,.gif,.png,.webp'
          isUploaded={isNotNilOrEmpty(file)}
          isLoading={loading}
        />
        <Button
          type='submit'
          color='primary'
          onClick={handleSubmit}
          disabled={isNilOrEmpty(file) || loading}
        >
          Dodaj
        </Button>
      </Modal>
    </>
  )
}

export default AddProductPhotoModal

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`
