import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectReplenishments = state => state.replenishments

export const selectReplenishmentsList = createSelector(
  selectReplenishments,
  propOr([], 'list')
)

export const selectReplenishmentsListPagination = createSelector(
  selectReplenishments,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'pagination'
  )
)

export const selectReplenishmentsListLoading = createSelector(
  selectReplenishments,
  propOr(false, 'isLoading')
)
