import React, { useEffect, useMemo, useState } from 'react'
import ContentPanel from 'components/atoms/ContentPanel'
import { selectSettingsList } from 'modules/settings/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSettingsRoutine } from 'modules/settings/ducks/actions'
import Input from 'components/atoms/Input'
import { propOr } from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import Button from 'components/atoms/Button'
import { updateSettings } from 'services/SettingsService'
import styled from 'styled-components'
import PageHeader from 'components/atoms/PageHeader'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'

const Integrations = () => {
  const [values, setValues] = useState({})
  const settings = useSelector(selectSettingsList)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSettingsRoutine())
  }, [])

  useEffect(() => {
    setValues(() => {
      let newValues = {}
      settings.forEach(setting => {
        newValues = {
          ...newValues,
          [setting.name]: setting.value
        }
      })
      return newValues
    })
  }, [settings])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const iaiImportIntervalSettings = useMemo(() => {
    return settings.find(setting => setting.name === 'import_orders_from_iai_interval')
  }, [settings])

  const handleSettingUpdate = (id, value) => () => {
    updateSettings({ id, value })
      .then(() => {
        toast.success('Pomyślnie zapisano zmiany')
      })
      .catch(err => {
        toast.error(getApiErrors(err))
      })
  }

  return (
    <>
      <PageHeader withMargin>Ustawienia integracji</PageHeader>
      <ContentPanel>
        {
          isNotNilOrEmpty(iaiImportIntervalSettings) && (
            <SettingsRow>
              <Input
                type='number'
                inputProps={{
                  min: 1
                }}
                name={iaiImportIntervalSettings.name}
                label={iaiImportIntervalSettings.label}
                onChange={handleValueChange}
                value={Number(propOr(0, iaiImportIntervalSettings.name, values))}
              />
              <ButtonWrapper>
                <Button
                  onClick={handleSettingUpdate(iaiImportIntervalSettings.id, values[iaiImportIntervalSettings.name])}
                  disabled={isNilOrEmpty(values[iaiImportIntervalSettings.name])}
                >
                  Zapisz
                </Button>
              </ButtonWrapper>
            </SettingsRow>
          )
        }
      </ContentPanel>
    </>
  )
}

export default Integrations

const SettingsRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 500px;
`

const ButtonWrapper = styled.div`
  width: 150px;
`
