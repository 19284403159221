import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectTabs = state => state.tabs

export const selectTabsList = createSelector(selectTabs, propOr([], 'list'))

export const selectActiveTab = createSelector(
  selectTabs,
  propOr('', 'activeTab')
)
