import React, { useState } from 'react'
import styled from 'styled-components'
import ImageViewer from 'react-simple-image-viewer'
import { isNotNilOrEmpty } from 'utils/ramda'
import imagePlaceholder from 'assets/image-placeholder.png'
import { propOr } from 'ramda'
import { selectCurrentProductIconSmallUrl } from 'modules/products/ducks/selectors'
import { useSelector } from 'react-redux'

const ProductBasicDetailsThumbnail = ({ file }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const smallUrl = useSelector(selectCurrentProductIconSmallUrl)
  const iaiImage = { id: '23erweq', url: smallUrl }

  const openImageViewer = () => {
    setIsViewerOpen(true)
  }

  const closeImageViewer = () => {
    setIsViewerOpen(false)
  }

  return (
    <>
      {isNotNilOrEmpty(file) ? (
        <Thumbnail
          url={file.url}
          onClick={openImageViewer}
          key={`product-thumbnail-${file.id}`}
        />
      ) : isNotNilOrEmpty(smallUrl) ? (
        <Thumbnail
          url={iaiImage.url}
          onClick={openImageViewer}
          key={`product-thumbnail-${iaiImage.id}`}
        />
      ) : (
        <ProductImage src={imagePlaceholder} alt='product thumbnail' />
      )}

      {isViewerOpen && (
        <ViewerWrapper>
          <ImageViewer
            src={[propOr(smallUrl, 'url', file)]}
            backgroundStyle={{
              background: 'rgba(0, 0, 0, .7)'
            }}
            currentIndex={0}
            disableScroll
            closeOnClickOutside
            onClose={closeImageViewer}
          />
        </ViewerWrapper>
      )}
    </>
  )
}

export default ProductBasicDetailsThumbnail

const Thumbnail = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  background: ${({ url, theme }) =>
    `${theme.colors.lightGrey} url(${url}) no-repeat center/cover`};
  cursor: pointer;
`

const ViewerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
`

const ProductImage = styled.img`
  width: 150px;
`
