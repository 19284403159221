import APIService from './APIService'

const api = APIService()

export const fetchSaleOrders = payload =>
  api.get(`/sale-orders${payload.query}`)
export const fetchSuspendedSaleOrders = payload =>
  api.get(`/sale-orders/suspended${payload.query}`)
export const fetchSingleSaleOrder = payload =>
  api.get(`/sale-orders/${payload.id}`)
export const getSaleOrdersStatistics = () => api.get(`/sale-orders/statistics`)
export const importSaleOrdersFromIAI = () => api.post(`/sale-orders/import/iai`)
export const importSingleSaleOrderFromIAI = payload => {
  return api.post(`/sale-orders/import/iai`, payload)
}
export const fetchStatusLogs = payload => {
  return api.get(`/sale-orders/${payload.id}/status-logs${payload.query}`)
}
export const unsuspendOrder = payload =>
  api.patch(`/sale-orders/unsuspend`, payload)
export const cancelSaleOrder = payload =>
  api.delete(`/sale-orders/${payload.id}`)
export const updateSaleOrder = payload =>
  api.patch(`/sale-orders/${payload.id}`, {
    allowReimportFromIai: payload.allowReimport
  })
export const changeStatusPackedToSent = payload =>
  api.patch(`/sale-orders/packed-change-sent`, payload)
