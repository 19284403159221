import React from 'react'
import PageHeader from 'components/atoms/PageHeader'
import SearchInput from 'components/atoms/SearchInput'
import styled from 'styled-components'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNilOrEmpty } from 'utils/ramda'
import { dissoc, propOr } from 'ramda'
import SuppliersTable from 'modules/suppliers/components/SuppliersTable'
import ContentPanel from 'components/atoms/ContentPanel'
import SyncSuppliersModal from 'modules/suppliers/components/SyncSuppliersModal'
import { FiltersWrapper, HeaderWrapper } from 'theme/wrappers'

const Suppliers = () => {
  const handleSubmit = searchQuery => {
    const parsedQuery = getCurrentParsedQuery()
    const isEmpty = isNilOrEmpty(searchQuery)

    changeQuery({
      ...parsedQuery,
      page: 1,
      filter: isEmpty
        ? dissoc('search', propOr({}, 'filter', parsedQuery))
        : {
            ...propOr({}, 'filter', parsedQuery),
            search: searchQuery
          }
    })
  }

  const handleClear = () => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      filter: dissoc('search', propOr({}, 'filter', parsedQuery))
    })
  }

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Lista dostawców</PageHeader>
        <FiltersWrapper>
          <SyncSuppliersModal />
          <InputWrapper>
            <SearchInput
              onSubmit={handleSubmit}
              onClear={handleClear}
              placeholder='Znajdź dostawcę...'
            />
          </InputWrapper>
        </FiltersWrapper>
      </HeaderWrapper>
      <ContentPanel>
        <SuppliersTable />
      </ContentPanel>
    </>
  )
}

export default Suppliers

const InputWrapper = styled.div`
  max-width: 250px;
`
