import { validateField, validateValues } from 'utils/form'
import { object, string } from 'yup'

export const importSingleSaleOrderSchema = object().shape({
  iaiOrderSerialNumber: string().matches(/^\d*$/, 'Musi być wartością liczbową')
    .nullable()
})

export const importSingleSaleOrderDetailsViewSchema = object().shape({
  iaiOrderSerialNumber: string().required('Numer zamówienia jest wymagany').matches(/^\d*$/, 'Musi być wartością liczbową')
})

export const validateImportSingleSaleOrderField = values => validateField(importSingleSaleOrderSchema, values)
export const validateImportSingleSaleOrderValues = validateValues(importSingleSaleOrderSchema)
export const validateImportSingleSaleOrderDetailsViewField = values => validateField(importSingleSaleOrderDetailsViewSchema, values)
export const validateImportSingleSaleOrderDetailsViewValues = validateValues(importSingleSaleOrderDetailsViewSchema)
