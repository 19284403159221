import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as zonesService from 'services/ZonesService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'

export const fetchZonesRoutine = createRoutine('FETCH_ZONES')
export const fetchSingleZoneRoutine = createRoutine('FETCH_SINGLE_ZONE')
export const createZoneRoutine = createRoutine('CREATE_ZONE')
export const updateZoneRoutine = createRoutine('UPDATE_ZONE')
export const removeZoneRoutine = createRoutine('REMOVE_ZONE')

// ACTIONS
function * fetchZones ({ payload }) {
  yield put(fetchZonesRoutine.request())
  try {
    const { data } = yield call(zonesService.fetchZones, payload)
    yield put(fetchZonesRoutine.success(data))
  } catch (e) {
    yield put(fetchZonesRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchSingleZone ({ payload }) {
  yield put(fetchSingleZoneRoutine.request())
  try {
    const { data } = yield call(zonesService.fetchSingleZone, payload)
    yield put(fetchSingleZoneRoutine.success(data.data))
  } catch (e) {
    yield put(fetchSingleZoneRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * createZone ({ payload }) {
  const { callback, values } = payload
  yield put(createZoneRoutine.request())
  try {
    yield call(zonesService.createZone, values)
    yield put(createZoneRoutine.success())
    yield put(fetchZonesRoutine({
      query: stringifyQuery(getCurrentParsedQuery())
    }))
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie utworzono nową strefę')
  } catch (e) {
    yield put(createZoneRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * updateZone ({ payload }) {
  const { callback, values } = payload
  yield put(updateZoneRoutine.request())
  try {
    yield call(zonesService.updateZone, values)
    yield put(updateZoneRoutine.success())
    yield put(fetchZonesRoutine({
      query: stringifyQuery(getCurrentParsedQuery())
    }))
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie edytowano strefę')
  } catch (e) {
    yield put(updateZoneRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * removeZone ({ payload }) {
  const { callback, id } = payload
  yield put(removeZoneRoutine.request())
  try {
    yield call(zonesService.removeZone, { id })
    yield put(removeZoneRoutine.success())
    yield put(fetchZonesRoutine({
      query: stringifyQuery(getCurrentParsedQuery())
    }))
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie usunięto strefę')
  } catch (e) {
    yield put(removeZoneRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * fetchZonesWatcher () {
  yield takeLatest(fetchZonesRoutine.TRIGGER, fetchZones)
}

export function * fetchSingleZoneWatcher () {
  yield takeLatest(fetchSingleZoneRoutine.TRIGGER, fetchSingleZone)
}

export function * createZoneWatcher () {
  yield takeLatest(createZoneRoutine.TRIGGER, createZone)
}

export function * updateZoneWatcher () {
  yield takeLatest(updateZoneRoutine.TRIGGER, updateZone)
}

export function * removeZoneWatcher () {
  yield takeLatest(removeZoneRoutine.TRIGGER, removeZone)
}

// SAGAS
export const zonesSagas = [
  fork(fetchZonesWatcher),
  fork(fetchSingleZoneWatcher),
  fork(createZoneWatcher),
  fork(updateZoneWatcher),
  fork(removeZoneWatcher)
]
