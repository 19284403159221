import {
  fetchCouriersGroupsListRoutine,
  fetchCouriersListRoutine,
  fetchSingleCourierGroupRoutine,
  fetchUngroupedCouriersListRoutine
} from 'modules/couriers/ducks/actions'

const defaultState = {
  list: [],
  pagination: null,
  groupsList: [],
  groupsPagination: null,
  ungroupedList: [],
  currentGroup: {},
  isLoading: false,
  isLoadingGroups: false,
  isLoadingUngrouped: false
}

const couriersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchCouriersListRoutine.TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case fetchCouriersListRoutine.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case fetchCouriersListRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoading: false
      }
    case fetchUngroupedCouriersListRoutine.TRIGGER:
      return {
        ...state,
        isLoadingUngrouped: true
      }
    case fetchUngroupedCouriersListRoutine.FAILURE:
      return {
        ...state,
        isLoadingUngrouped: false
      }
    case fetchUngroupedCouriersListRoutine.SUCCESS:
      return {
        ...state,
        ungroupedList: action.payload.data,
        isLoadingUngrouped: false
      }
    case fetchCouriersGroupsListRoutine.TRIGGER:
      return {
        ...state,
        isLoadingGroups: true
      }
    case fetchCouriersGroupsListRoutine.FAILURE:
      return {
        ...state,
        isLoadingGroups: false
      }
    case fetchCouriersGroupsListRoutine.SUCCESS:
      return {
        ...state,
        groupsList: action.payload.data,
        groupsPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoadingGroups: false
      }
    case fetchSingleCourierGroupRoutine.SUCCESS:
      return {
        ...state,
        currentGroup: action.payload
      }
    default:
      return state
  }
}

export default couriersReducer
