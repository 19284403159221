import {
  fetchWarehousesRoutine,
  fetchWarehouseStateRoutine
} from 'modules/warehouses/ducks/actions'

const defaultState = {
  list: [],
  warehouseState: [],
  warehouseStatePagination: null,
  isLoading: false,
  isLoadingWarehouseState: false
}

const warehousesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchWarehousesRoutine.TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case fetchWarehousesRoutine.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case fetchWarehousesRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload,
        isLoading: false
      }
    case fetchWarehouseStateRoutine.TRIGGER:
      return {
        ...state,
        isLoadingWarehouseState: true
      }
    case fetchWarehouseStateRoutine.FAILURE:
      return {
        ...state,
        isLoadingWarehouseState: false
      }
    case fetchWarehouseStateRoutine.SUCCESS:
      return {
        ...state,
        warehouseState: action.payload.data,
        warehouseStatePagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoadingWarehouseState: false
      }
    default:
      return state
  }
}

export default warehousesReducer
