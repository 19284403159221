import React from 'react'
import ContentPanel from 'components/atoms/ContentPanel'
import { useSelector } from 'react-redux'
import {
  selectCurrentLocation,
  selectCurrentLocationLoading
} from 'modules/locations/ducks/selectors'
import styled from 'styled-components'
import { pathOr, propOr } from 'ramda'
import LoadingSpinner from 'components/atoms/LoadingSpinner'

const BasicLocationDetails = () => {
  const location = useSelector(selectCurrentLocation)
  const isLoading = useSelector(selectCurrentLocationLoading)

  return (
    <ContentPanel withMargin>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <LocationName>
            Lokalizacja <span>{location.name}</span>
          </LocationName>
          <DetailedInfo>
            <DetailItem>
              Liczba produktów:{' '}
              <span>{propOr(0, 'productsQuantity', location)}</span>
            </DetailItem>
            <DetailItem>
              Waga produktów:{' '}
              <span>
                {propOr('---', 'productsWeight', location)}
                {pathOr('', ['productsWeightUnit'], location)}
              </span>
            </DetailItem>
            <DetailItem>
              Limit wagi:{' '}
              <span>
                {pathOr('---', ['weightLimit'], location)}
                {pathOr('', ['weightLimitUnit'], location)}
              </span>
            </DetailItem>
            <DetailItem>
              Magazyn:{' '}
              <span>{pathOr('---', ['warehouse', 'name'], location)}</span>
            </DetailItem>
            <DetailItem>
              Strefa: <span>{pathOr('---', ['zone', 'name'], location)}</span>
            </DetailItem>
            <DetailItem>
              Kolejność: <span>{pathOr('---', ['sortOrder'], location)}</span>
            </DetailItem>
          </DetailedInfo>
        </>
      )}
    </ContentPanel>
  )
}

export default BasicLocationDetails

const LocationName = styled.div`
  font-size: 24px;
  span {
    font-weight: bold;
  }
`

const DetailItem = styled.div`
  font-size: 12px;
  text-transform: uppercase;

  span {
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
  }
`

const DetailedInfo = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 20px;
`
