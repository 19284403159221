import {
  fetchSingleZoneRoutine,
  fetchZonesRoutine
} from 'modules/zones/ducks/actions'

const defaultState = {
  list: [],
  currentZone: {},
  pagination: null,
  isLoading: false
}

const zonesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchZonesRoutine.TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case fetchSingleZoneRoutine.TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case fetchZonesRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoading: false
      }
    case fetchSingleZoneRoutine.SUCCESS:
      return {
        ...state,
        currentZone: action.payload
      }
    default:
      return state
  }
}

export default zonesReducer
