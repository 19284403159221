import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import {
  selectDeliveryItems,
  selectDeliveryItemsLoading,
  selectDeliveryItemsPagination
} from 'modules/delivery/ducks/selectors'
import { useHistory, useParams } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { fetchDeliveryItemsRoutine } from 'modules/delivery/ducks/actions'
import { pathOr, propOr } from 'ramda'
import styled from 'styled-components'
import RemoveDeliveryItemModal from 'modules/deliveryDetails/components/RemoveDeliveryItemModal'
import EditDeliveryItemModal from './EditDeliveryItemModal'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'

const DeliveryDetailsProductsTab = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectDeliveryItems)
  const pagination = useSelector(selectDeliveryItemsPagination)
  const isLoading = useSelector(selectDeliveryItemsLoading)
  const { id } = useParams()

  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    isNotNilOrEmpty(search) &&
      dispatch(fetchDeliveryItemsRoutine({ id, query: search }))
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Np.' },
    { children: 'Mix' },
    { children: 'Nowość' },
    { children: 'Kod zeskanowany' },
    { children: 'Nazwa' },
    { children: 'Nazwa wariantu' },
    { children: 'EAN' },
    { children: 'Ilość przyjęta' },
    { children: 'Ilość oczekiwana' },
    { children: 'Numer JM' },
    { children: 'Ilość na JM' }
  ]

  const rows = list.map(row => {
    const productId = pathOr([], ['product', 'id'], row)
    const inventories = propOr([], 'inventories', row)
    const isMix = pathOr(false, ['product', 'isMix'], row)
    const isNew = pathOr(false, ['product', 'isNew'], row)
    const parents = pathOr([], ['product', 'parents'], row)

    return {
      redirectPath: `/products/${productId}`,
      cells: [
        { children: propOr('---', 'ordinalNumber', row) },
        { children: isMix ? '✓' : '✗' },
        { children: isNew ? '✓' : '✗' },
        {
          children: isMix
            ? parents.map(parent => (
                <div key={parent.id}>{propOr('---', 'ean', parent)}</div>
              ))
            : pathOr('---', ['product', 'ean'], row)
        },
        {
          children: isMix
            ? parents.map(parent => (
                <div key={parent.id}>{propOr('---', 'name', parent)}</div>
              ))
            : pathOr('---', ['product', 'name'], row)
        },
        {
          children: isMix
            ? isNotNilOrEmpty(row.product.name)
              ? pathOr('---', ['product', 'name'], row)
              : 'Brak'
            : '---'
        },
        {
          children: pathOr('---', ['product', 'ean'], row)
        },
        { children: propOr('---', 'confirmedQuantity', row) },
        { children: propOr('---', 'expectedQuantity', row) },
        {
          children: isNotNilOrEmpty(inventories) ? (
            inventories.map(inventory => (
              <div key={inventory.id}>
                {pathOr('---', ['inventorable', 'code'], inventory)}
              </div>
            ))
          ) : (
            <div>---</div>
          )
        },
        {
          children: isNotNilOrEmpty(inventories) ? (
            inventories.map(inventory => (
              <div key={inventory.id}>
                {pathOr('---', ['pivot', 'quantity'], inventory)}
              </div>
            ))
          ) : (
            <div>---</div>
          )
        },
        {
          children: (
            <ActionsContainer>
              <EditDeliveryItemModal item={row} />
              <RemoveDeliveryItemModal item={row} />
            </ActionsContainer>
          ),
          align: 'right',
          preventRedirect: true
        }
      ]
    }
  })

  return (
    <div>
      <Table
        headers={headers}
        rows={rows}
        pagination={pagination}
        onPageChange={handlePageChange}
        emptyState='Brak produktów w dostawie'
        isLoading={isLoading}
      />
    </div>
  )
}

export default DeliveryDetailsProductsTab

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`
