import { fetchLatestApkVersionRoutine } from 'modules/apkVersion/ducks/actions'

const defaultState = {
  version: {}
}

const apkVersionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchLatestApkVersionRoutine.SUCCESS:
      return {
        version: action.payload.version
      }
    default:
      return state
  }
}

export default apkVersionReducer
