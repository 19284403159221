import { pathOr } from 'ramda'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import { isNotNilOrEmpty } from 'utils/ramda'
import APIService from './APIService'

const api = APIService()

export const fetchSignature = () => api.post('/apk-versions/signature')
export const fetchLatestApkVersion = () => api.get('/apk-versions')
export const createApkVersion = payload => api.post(`/apk-versions`, payload)
export const deleteApkVersion = payload => {
  return api.delete(`/apk-versions/${payload}`)
}

export const uploadFile = ({ file, version }) =>
  new Promise(resolve => {
    return api
      .post('/apk-versions/signature')
      .then(response => {
        const signature = pathOr({}, ['data', 'data', 'signature'], response)
        const postUrl = pathOr({}, ['data', 'data', 'postUrl'], response)
        const prefix = 'apk-file'
        const pathname = `${prefix}/${file.name}`

        if (isNotNilOrEmpty(signature)) {
          const fd = new FormData()
          fd.append('key', pathname)
          fd.append('acl', signature.acl)
          fd.append('X-Amz-Credential', signature.xAmzCredential)
          fd.append('X-Amz-Algorithm', signature.xAmzAlgorithm)
          fd.append('Content-Type', signature.contentType)
          fd.append('success_action_status', signature.successActionStatus)
          fd.append('X-Amz-Date', signature.xAmzDate)
          fd.append('Policy', signature.policy)
          fd.append('X-Amz-Signature', signature.xAmzSignature)
          fd.append('file', file)

          fetch(postUrl, {
            method: 'POST',
            body: fd
          })
            .then(() => {
              api
                .post(`/apk-versions`, { version })
                .then(resolve())
                .catch(e => {
                  toast.error(getApiErrors(e))
                })
            })
            .catch(e => {
              toast.error(getApiErrors(e))
            })
        }
      })
      .catch(e => {
        toast.error(getApiErrors(e))
      })
  })
