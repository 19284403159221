import React from 'react'
import ContentPanel from 'components/atoms/ContentPanel'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { has, pathOr, propOr } from 'ramda'
import {
  selectCurrentSaleOrder,
  selectCurrentSaleOrderLoading
} from 'modules/saleOrders/ducks/selectors'
import { DATE_FORMATS, formatDate } from 'utils/date'
import ForwardButton from 'components/atoms/ForwardButton'
import { getStatusColor } from 'utils/saleOrders'
import LoadingSpinner from 'components/atoms/LoadingSpinner'

const SaleOrderBasicDetails = () => {
  const order = useSelector(selectCurrentSaleOrder)
  const isLoading = useSelector(selectCurrentSaleOrderLoading)
  const stockUnits = propOr([], 'stockUnits', order).map(item => item.code)
  const uniqueStockUnits = Array.from(new Set(stockUnits)).join(', ')

  return (
    <ContentPanel withMargin>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Header>
            <LocationName>
              Zamówienie{' '}
              <span>{propOr('---', 'iaiOrderSerialNumber', order)}</span>
            </LocationName>
            <StatusTag
              color={getStatusColor(pathOr('---', ['status', 'value'], order))}
            >
              {pathOr('---', ['status', 'label'], order)}
            </StatusTag>
            <IAILink>
              <ForwardButton
                disabled={
                  !has('iaiUrl', order) || !has('iaiOrderSerialNumber', order)
                }
                isDirectLink
                tooltip={'Idź do IdoSell'}
                path={propOr('undefined', 'iaiUrl', order)}
                label={
                  'Numer w IAI: ' + propOr('---', 'iaiOrderSerialNumber', order)
                }
              />
            </IAILink>
          </Header>
          <DetailedInfo>
            <DetailItem>
              Data zamówienia:{' '}
              <span>
                {formatDate(
                  propOr('---', 'orderedAt', order),
                  DATE_FORMATS.dashedWithTime
                )}
              </span>
            </DetailItem>
            <DetailItem>
              Nabywca: <span>{propOr('---', 'iaiClientLogin', order)}</span>
            </DetailItem>
            <DetailItem>
              Kurier: <span>{pathOr('---', ['courier', 'name'], order)}</span>
            </DetailItem>
            <DetailItem>
              Zródło: <span>{pathOr('---', ['source', 'name'], order)}</span>
            </DetailItem>
          </DetailedInfo>
          <DetailedInfo>
            <DetailItem>
              Z platformy B2B?:{' '}
              <span>
                {propOr(false, 'isFromB2bPlatform', order) ? 'TAK' : 'NIE'}
              </span>
            </DetailItem>
            <DetailItem>
              Lista kompletacji:{' '}
              <span>
                {pathOr('---', ['completionList', 'listNumber'], order)}
              </span>
            </DetailItem>
            <DetailItem>
              Jednostki magazynowe: <span>{uniqueStockUnits || '---'}</span>
            </DetailItem>
            <DetailItem>
              Notatka klienta: <span>{propOr('---', 'clientNote', order)}</span>
            </DetailItem>
          </DetailedInfo>
          <DetailedInfo>
            <DetailItem>
              Komentarz: <span>{propOr('---', 'comment', order)}</span>
            </DetailItem>
          </DetailedInfo>
        </>
      )}
    </ContentPanel>
  )
}

export default SaleOrderBasicDetails

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`

const LocationName = styled.div`
  font-size: 24px;
  span {
    font-weight: bold;
  }
`

const DetailItem = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  flex: 1;

  span {
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
  }
`

const DetailedInfo = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 20px;
`

const StatusTag = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  color: #fff;
`

const IAILink = styled.div`
  margin-left: auto;
`
