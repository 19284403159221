import React, { useEffect } from 'react'
import ContentPanel from 'components/atoms/ContentPanel'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentZone } from 'modules/zones/ducks/selectors'
import { selectZonesDictionary } from 'ducks/dictionaries/selectors'
import { propOr } from 'ramda'
import { fetchZonesDictionaryRoutine } from 'ducks/dictionaries/actions'
import { toCamelCase } from 'js-convert-case'

const ZoneBasicDetails = () => {
  const zone = useSelector(selectCurrentZone)
  const zonesDictionary = useSelector(selectZonesDictionary)
  const storageTypes = propOr({}, 'storageTypes', zonesDictionary)
  const zoneTypes = propOr({}, 'zoneTypes', zonesDictionary)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchZonesDictionaryRoutine())
  }, [])

  return (
    <ContentPanel withMargin>
      <div>
        Nazwa: {zone.name}
      </div>
      <div>
        Typ składowania: {storageTypes[toCamelCase(zone.storageType)]}
      </div>
      <div>
        Typ strefy: {zoneTypes[toCamelCase(zone.zoneType)]}
      </div>

    </ContentPanel>
  )
}

export default ZoneBasicDetails
