import React from 'react'
import Tabs from 'components/atoms/Tabs'
import ContentPanel from 'components/atoms/ContentPanel'
import ActiveStocktakingOrdersTable from 'modules/stocktakingOrders/components/ActiveStocktakingOrdersTable'
import FinishedStocktakingOrdersTable from 'modules/stocktakingOrders/components/FinishedStocktakingOrdersTable'

const StocktakingOrdersTabs = () => {
  const tabs = [
    {
      key: 'assigned',
      title: 'Zlecone',
      children: <ActiveStocktakingOrdersTable />,
      isList: true
    },
    {
      key: 'finished',
      title: 'Zakończone',
      children: <FinishedStocktakingOrdersTable />,
      isList: true
    }
  ]

  return (
    <ContentPanel>
      <Tabs tabs={tabs} />
    </ContentPanel>
  )
}

export default StocktakingOrdersTabs
