import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import styled from 'styled-components'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

const PackingOrderSuccessModal = ({ open, currentOrder }) => {
  const [orderNr, setOrderNr] = useState('')

  useEffect(() => {
    isNotNilOrEmpty(currentOrder) &&
      setOrderNr(propOr('----', 'iaiOrderSerialNumber', currentOrder))
  }, [currentOrder])

  return (
    <Modal open={open} onClose={() => {}} title='Zamówienie spakowane'>
      <IconWrapper>
        <StyledIcon />
      </IconWrapper>
      <Content>
        Zamówienie <span>{orderNr}</span> zostało spakowane.
      </Content>
    </Modal>
  )
}

export default PackingOrderSuccessModal

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const StyledIcon = styled(CheckCircleIcon)`
  font-size: 70px !important;
  color: ${({ theme }) => theme.colors.success};
`

const Content = styled.div`
  text-align: center;
  font-size: 24px;

  span {
    font-weight: bold;
  }
`
