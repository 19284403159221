import React from 'react'
import styled, { css } from 'styled-components'
import { redirect } from 'utils/navigation'
import { ArrowForward } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

const ForwardButton = ({
  path,
  label,
  withMargin,
  isDirectLink,
  tooltip,
  disabled
}) => {
  return isDirectLink ? (
    <Wrapper withMargin={withMargin} disabled={disabled}>
      {label}
      {!disabled && (
        <Tooltip title={tooltip}>
          {
            <a href={path} target='_blank' rel='noreferrer'>
              <ArrowForward />
            </a>
          }
        </Tooltip>
      )}
    </Wrapper>
  ) : (
    <Wrapper
      onClick={() => redirect(path)}
      withMargin={withMargin}
      disabled={disabled}
    >
      {label}
      <ArrowForward />
    </Wrapper>
  )
}

ForwardButton.defaultProps = {
  label: 'Idź do'
}

export default ForwardButton

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-bottom: ${({ withMargin }) => (withMargin ? '20px' : 0)};
  cursor: default;
  transition: all 0.3s;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.colors.disabled}; ;
    `}

  a {
    padding-top: 5px;
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }
`
