import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { createMultipickListManually } from 'services/MultipickService'
import { dissoc, pathOr } from 'ramda'
import { validateCreateListField, validateCreateListValues } from 'modules/multipick/ducks/schema'
import Input from 'components/atoms/Input'
import ListIcon from 'components/icons/ListIcon'
import { useHistory } from 'react-router-dom'
import { fetchSaleOrdersRoutine, fetchSuspendedSaleOrdersRoutine } from 'modules/saleOrders/ducks/actions'
import { useDispatch } from 'react-redux'
import ButtonWrapper from 'theme/wrappers'

const initialValues = {
  name: '',
  priority: 5,
  comment: '',
  saleOrdersIds: []
}

const CreateListForSingleOrderModal = ({ orderId, suspended }) => {
  const [values, setValues] = useState(initialValues)
  const [valid, setValid] = useState(false)
  const [open, setOpen] = useState(false)
  const { location: { search } } = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    values && validateCreateListValues(dissoc('saleOrdersIds', values), setValid)
  }, [values])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(initialValues)
  }

  const handleSubmit = e => {
    e.preventDefault()
    createMultipickListManually({
      ...values,
      saleOrdersIds: [orderId]
    })
      .then(resp => {
        const created = pathOr(0, ['data', 'meta', 'ordersCount'], resp) === 1
        created
          ? toast.success('Utworzono nową listę kompletacji')
          : toast.error('Brak towarów w alokacji, lista nie została utworzona')
        suspended
          ? dispatch(fetchSuspendedSaleOrdersRoutine({ query: search }))
          : dispatch(fetchSaleOrdersRoutine({ query: search }))
        handleModalClose()
      })
      .catch((e) => {
        toast.error(getApiErrors(e))
      })
  }

  return (
    <>
      <ListIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Tworzenie list'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa'
            value={values.name}
            onChange={handleValueChange}
            validate={validateCreateListField(values)}
          />
          <Input
            name='priority'
            label='Priorytet'
            type='number'
            value={values.priority}
            onChange={handleValueChange}
            validate={validateCreateListField(values)}
          />
          <Input
            multiline
            rows={3}
            name='comment'
            label='Komentarz'
            value={values.comment}
            onChange={handleValueChange}
            validate={validateCreateListField(values)}
          />
          <ButtonWrapper>
            <Button
              onClick={handleModalClose}
              color='primary-outline'
            >
              Anuluj
            </Button>
            <Button
              disabled={!valid}
              type='submit'
              color='primary'
            >
              Stwórz listę
            </Button>
          </ButtonWrapper>
        </form>
      </Modal>
    </>
  )
}

export default CreateListForSingleOrderModal
