import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import { useDispatch } from 'react-redux'
import { fetchStocktakingOrdersRoutine } from '../ducks/actions'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { cancelStocktakingOrder } from 'services/StocktakingOrdersService'
import RemoveIcon from 'components/icons/RemoveIcon'
import ButtonWrapper from 'theme/wrappers'

const CancelStocktakingOrder = ({ stocktakingOrder }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = () => {
      dispatch(
        fetchStocktakingOrdersRoutine({
          query: stringifyQuery(getCurrentParsedQuery())
        })
      )
      toast.success('Zlecone zadanie zostało anulowane')
      handleModalClose()
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    cancelStocktakingOrder({ id: stocktakingOrder.id })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <RemoveIcon onClick={handleModalOpen} title='Anuluj zlecenie' />
      <Modal open={open} title='Anulowanie zadania inwentaryzacji' onClose={handleModalClose}>
        Czy na pewno chcesz anulować to zlecenie inwentaryzacji?
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Potwierdź
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default CancelStocktakingOrder
