import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import styled from 'styled-components'
import ResetButton from 'components/atoms/ResetButton'
import {
  prepareCompletionListWithRemains,
  prepareMultiSkuListWithoutOrders,
  prepareTestMultiSku,
  prepareTestMultiSkuInpostSize,
  prepareTestSingleSku
} from 'services/PackingService'
import { pathOr, values } from 'ramda'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { isNotNilOrEmpty } from 'utils/ramda'

const defaultData = { code: '', unitCodes: []}

const AddTestPackingUnit = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(defaultData)
  const [created, setCreated] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setCreated(false)
    setData(defaultData)
  }

  const handleAdd = type => () => {
    if (type === 'single') {
      prepareTestSingleSku()
        .then(resp => {
          const code = pathOr('', ['data', 'data', 'code'], resp)

          const complLists = pathOr(
            [],
            ['data', 'data', 'completionLists'],
            resp
          )
          const unitCodes = isNotNilOrEmpty(complLists)
            ? values(complLists[0]?.stockUnits)?.map(u => u.code)
            : []

          setData({ code, unitCodes })
          setCreated(true)
        })
        .catch(err => {
          toast.error(getApiErrors(err))
        })
    } else if (type === 'multi') {
      prepareTestMultiSku()
        .then(resp => {
          const code = pathOr('', ['data', 'data', 'code'], resp)

          const complLists = pathOr(
            [],
            ['data', 'data', 'completionLists'],
            resp
          )
          const unitCodes = isNotNilOrEmpty(complLists)
            ? values(complLists[0]?.stockUnits)?.map(u => u.code)
            : []

          setData({ code, unitCodes })
          setCreated(true)
        })
        .catch(err => {
          toast.error(getApiErrors(err))
        })
    }
  }

  const handleWithInpostSize = () => {
    prepareTestMultiSkuInpostSize()
      .then(resp => {
        const code = pathOr('', ['data', 'data', 'code'], resp)

        const complLists = pathOr([], ['data', 'data', 'completionLists'], resp)
        const unitCodes = isNotNilOrEmpty(complLists)
          ? values(complLists[0]?.stockUnits)?.map(u => u.code)
          : []

        setData({ code, unitCodes })
        setCreated(true)
      })
      .catch(err => {
        toast.error(getApiErrors(err))
      })
  }

  const handleWithRemains = () => {
    prepareCompletionListWithRemains()
      .then(resp => {
        const code = pathOr('', ['data', 'data', 'code'], resp)

        const complLists = pathOr([], ['data', 'data', 'completionLists'], resp)
        const unitCodes = isNotNilOrEmpty(complLists)
          ? values(complLists[0]?.stockUnits)?.map(u => u.code)
          : []

        setData({ code, unitCodes })
        setCreated(true)
      })
      .catch(err => toast.error(getApiErrors(err)))
  }

  const handleWithoutOrders = () => {
    prepareMultiSkuListWithoutOrders()
      .then(resp => {
        const code = pathOr('', ['data', 'data', 'code'], resp)

        const complLists = pathOr([], ['data', 'data', 'completionLists'], resp)
        const unitCodes = isNotNilOrEmpty(complLists)
          ? values(complLists[0]?.stockUnits)?.map(u => u.code)
          : []

        setData({ code, unitCodes })
        setCreated(true)
      })
      .catch(err => toast.error(getApiErrors(err)))
  }
  return (
    <>
      <ResetButton onClick={handleOpen} />
      <Modal open={open} onClose={handleClose} title='Stwórz testową JM'>
        {created ? (
          <Wrapper>
            <div>Wózek: {Number(data.code)}</div>
            <div>Pojemniki: {data.unitCodes.join(', ')}</div>
          </Wrapper>
        ) : (
          <Wrapper>
            <Button onClick={handleAdd('single')}>Single SKU</Button>
            <Button onClick={handleAdd('multi')}>Multi SKU</Button>
            <Button onClick={handleWithRemains}>Z pozostałościami</Button>
            <Button onClick={handleWithoutOrders}>Multi bez zamówień</Button>
            <Button onClick={handleWithInpostSize}>Multi wybór gabarytu</Button>
          </Wrapper>
        )}
      </Modal>
    </>
  )
}

export default AddTestPackingUnit

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`

const Button = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: #fff;
  }
`
