import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCouriersGroup, selectUngroupedCouriersList } from 'modules/couriers/ducks/selectors'
import { validateCreateCourierGroupField, validateCreateCourierGroupValues } from 'modules/couriers/ducks/schema'
import {
  clearSingleCourierGroupRoutine,
  fetchCouriersGroupsListRoutine,
  fetchSingleCourierGroupRoutine,
  fetchUngroupedCouriersListRoutine
} from 'modules/couriers/ducks/actions'
import { updateCouriersGroup } from 'services/CouriersService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import EditIcon from 'components/icons/EditIcon'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { Tooltip } from '@mui/material'
import { CompactPicker } from 'react-color'
import styled from 'styled-components'

const emptyValues = {
  name: '',
  courierIds: [],
  color: '#CCCCCC'
}

const EditCouriersGroupModal = ({ groupId }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const ungroupedCouriers = useSelector(selectUngroupedCouriersList)
  const currentGroup = useSelector(selectCurrentCouriersGroup)
  const couriers = propOr([], 'couriers', currentGroup)

  useEffect(() => {
    if (open) {
      dispatch(fetchUngroupedCouriersListRoutine())
      dispatch(fetchSingleCourierGroupRoutine({ id: groupId }))
    } else {
      dispatch(clearSingleCourierGroupRoutine())
    }
  }, [open])

  useEffect(() => {
    if (isNotNilOrEmpty(currentGroup)) {
      setValues({
        name: currentGroup.name,
        courierIds: propOr([], 'couriers', currentGroup).map(courier => courier.id),
        color: currentGroup.color || '#CCCCCC'
      })
    }
  }, [currentGroup])

  const couriersOptions = useMemo(() => {
    return [...ungroupedCouriers, ...couriers].map(courier => ({
      label: courier.name,
      value: courier.id
    }))
  }, [ungroupedCouriers, couriers])

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateCreateCourierGroupValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = () => {
      dispatch(fetchCouriersGroupsListRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      }))
      handleModalClose()
      toast.success('Zapisano zmiany w grupie')
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    updateCouriersGroup({
      ...values,
      id: groupId
    })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <EditIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Dodaj nową grupę kurierów'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa grupy'
            value={values.name}
            onChange={handleValueChange}
            validate={validateCreateCourierGroupField(values)}
          />
          <Input
            select
            multiple
            options={couriersOptions}
            name='courierIds'
            label='Kurierzy'
            value={values.courierIds}
            onChange={handleValueChange}
            validate={validateCreateCourierGroupField(values)}
          />
          <ColorPickerWrapper>
            <ColorPickerLabel>Kolor grupy:</ColorPickerLabel>
            <Tooltip title={(
              <CompactPicker
                color={values.color}
                onChange={(color) => handleValueChange('color', color.hex)}
              />
            )}>
              <Picker color={values.color} />
            </Tooltip>
          </ColorPickerWrapper>
          <Button
            type='submit'
            color='primary'
            disabled={!valid}
          >
            Zapisz
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default EditCouriersGroupModal

const ColorPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`

const ColorPickerLabel = styled.div`
  font-weight: bold;
`

const Picker = styled.div`
  height: 20px;
  width: 50px;
  background-color: ${({ color }) => color};
`
