import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import {
  changeQuery,
  getCurrentParsedQuery,
  stringifyQuery
} from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { dissoc, propOr } from 'ramda'
import {
  validateFiltersField,
  validateFiltersValues
} from 'modules/products/ducks/schema'
import { useHistory } from 'react-router-dom'
import { fetchPackingTypesRoutine } from 'modules/packingTypes/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectPackingTypesList } from 'modules/packingTypes/ducks/selectors'
import { ButtonsWrapper } from 'theme/wrappers'

const emptyValues = {
  total_quantity_min: '',
  total_quantity_max: '',
  contains_delivery_profile: 'all',
  packaging_type: 'all'
}

const ProductsFilters = ({ open }) => {
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const {
    location: { search }
  } = useHistory()
  const dispatch = useDispatch()
  const packingTypesList = useSelector(selectPackingTypesList)

  const resetFilters = () => {
    setValues(emptyValues)
    changeQuery({
      page: 1,
      sort: 'name'
    })
  }

  useEffect(() => {
    dispatch(
      fetchPackingTypesRoutine({
        query: stringifyQuery({
          page: 1,
          limit: 1000
        })
      })
    )
  }, [])

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    setValues({
      ...emptyValues,
      ...propOr({}, 'filter', currentQuery)
    })
  }, [search])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const containsDeliveryProfileOptions = [
    { label: 'Wszystkie', value: 'all' },
    { label: 'Tak', value: 'true' },
    { label: 'Nie', value: 'false' }
  ]

  const packagingTypesOptions = useMemo(() => {
    if (isNotNilOrEmpty(packingTypesList)) {
      return [
        { label: 'Wszystkie', value: 'all' },
        ...packingTypesList.map(type => ({
          label: type.name,
          value: type.id
        }))
      ]
    } else {
      return [{ label: 'Wszystkie', value: 'all' }]
    }
  }, [packingTypesList])

  const handleSubmit = () => {
    const currentQuery = getCurrentParsedQuery()
    let payload = {}

    for (let key in values) {
      if (isNotNilOrEmpty(values[key]) && values[key] !== 'all') {
        payload[key] = values[key]
      } else {
        payload = dissoc(key, payload)
      }
    }

    changeQuery({
      ...currentQuery,
      page: 1,
      filter: payload
    })
  }

  useEffect(() => {
    validateFiltersValues(values, setValid)
  }, [values])

  return (
    <Wrapper open={open}>
      <Input
        clearable
        value={values.total_quantity_min}
        name='total_quantity_min'
        label='Ilość od'
        onChange={handleValueChange}
        validate={validateFiltersField(values)}
        noMarginBottom
      />
      <Input
        clearable
        value={values.total_quantity_max}
        name='total_quantity_max'
        label='Ilość do'
        onChange={handleValueChange}
        validate={validateFiltersField(values)}
        noMarginBottom
      />
      <Input
        select
        options={containsDeliveryProfileOptions}
        value={values.contains_delivery_profile}
        name='contains_delivery_profile'
        label='Zawiera profil dostawy'
        onChange={handleValueChange}
        validate={validateFiltersField(values)}
        noMarginBottom
      />
      <Input
        select
        options={packagingTypesOptions}
        value={values.packaging_type}
        name='packaging_type'
        label='Rodzaj opakowania'
        onChange={handleValueChange}
        validate={validateFiltersField(values)}
        noMarginBottom
      />
      <ButtonsWrapper>
        <Button small onClick={handleSubmit} color='primary' disabled={!valid}>
          Zastosuj
        </Button>
        <Button small onClick={resetFilters} color='primary-outline'>
          Resetuj
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default ProductsFilters

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  width: 100%;
  overflow: hidden;
  height: 0;
  padding: 0 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 15px;

  ${({ open }) =>
    open &&
    css`
      padding: 20px;
      margin-bottom: 20px;
      height: 110px;
    `}
`
