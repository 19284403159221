import { combineReducers } from 'redux'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { all, fork } from '@redux-saga/core/effects'

// reducers
import authReducer from 'modules/auth/ducks/reducer'
import warehousesReducer from 'modules/warehouses/ducks/reducer'
import usersReducer from 'modules/users/ducks/reducer'
import packingTypesReducer from 'modules/packingTypes/ducks/reducer'
import productsReducer from 'modules/products/ducks/reducer'
import dictionariesReducer from 'ducks/dictionaries/reducer'
import zonesReducer from 'modules/zones/ducks/reducer'
import locationsReducer from 'modules/locations/ducks/reducer'
import stocktakingOrdersReducer from 'modules/stocktakingOrders/ducks/reducer'
import stockUnitsReducer from 'modules/stockUnits/ducks/reducer'
import saleOrdersReducer from 'modules/saleOrders/ducks/reducer'
import couriersReducer from 'modules/couriers/ducks/reducer'
import apkVersionReducer from 'modules/apkVersion/ducks/reducer'
import multipickReducer from 'modules/multipick/ducks/reducer'
import importJobsReducer from 'modules/importJobsReport/ducks/reducer'
import settingsReducer from 'modules/settings/ducks/reducer'
import suppliersReducer from 'modules/suppliers/ducks/reducer'
import deliveriesReducer from 'modules/delivery/ducks/reducer'
import tabsReducer from './tabs/reducer'
import replenishmentsReducer from 'modules/replenishments/ducks/reducer'
import packingReducer from 'modules/packing/ducks/reducer'
import flightControlReducer from 'modules/flightControl/ducks/reducer'
import inventoryMoveLogsReducer from 'modules/inventoryMoveLogs/ducks/reducers'

// sagas
import { authSagas } from 'modules/auth/ducks/actions'
import { warehousesSagas } from 'modules/warehouses/ducks/actions'
import { usersSagas } from 'modules/users/ducks/actions'
import { packingTypesSagas } from 'modules/packingTypes/ducks/actions'
import { productsSagas } from 'modules/products/ducks/actions'
import { dictionariesSagas } from 'ducks/dictionaries/actions'
import { zonesSagas } from 'modules/zones/ducks/actions'
import { locationsSagas } from 'modules/locations/ducks/actions'
import { stocktakingOrderSagas } from 'modules/stocktakingOrders/ducks/actions'
import { stockUnitsSagas } from 'modules/stockUnits/ducks/actions'
import { saleOrdersSagas } from 'modules/saleOrders/ducks/actions'
import { couriersSagas } from 'modules/couriers/ducks/actions'
import { apkVersionSagas } from 'modules/apkVersion/ducks/actions'
import { multipickSagas } from 'modules/multipick/ducks/actions'
import { importJobsSagas } from 'modules/importJobsReport/ducks/actions'
import { settingsSagas } from 'modules/settings/ducks/actions'
import { suppliersSagas } from 'modules/suppliers/ducks/actions'
import { deliveriesSagas } from 'modules/delivery/ducks/actions'
import { replenishmentSagas } from 'modules/replenishments/ducks/actions'
import { packingSagas } from 'modules/packing/ducks/actions'
import { tabsSagas } from 'ducks/tabs/actions'
import { flightControlSagas } from 'modules/flightControl/ducks/actions'
import { moveLogsSagas } from 'modules/inventoryMoveLogs/ducks/actions'

const appReducer = combineReducers({
  auth: authReducer,
  warehouses: warehousesReducer,
  users: usersReducer,
  packingTypes: packingTypesReducer,
  products: productsReducer,
  dictionaries: dictionariesReducer,
  zones: zonesReducer,
  locations: locationsReducer,
  stocktakingOrders: stocktakingOrdersReducer,
  stockUnits: stockUnitsReducer,
  saleOrders: saleOrdersReducer,
  couriers: couriersReducer,
  apkVersion: apkVersionReducer,
  multipick: multipickReducer,
  importJobs: importJobsReducer,
  settings: settingsReducer,
  suppliers: suppliersReducer,
  deliveries: deliveriesReducer,
  replenishments: replenishmentsReducer,
  packing: packingReducer,
  tabs: tabsReducer,
  flightControl: flightControlReducer,
  moveLogs: inventoryMoveLogsReducer
})

export default appReducer

export function * rootSaga () {
  yield all([
    ...authSagas,
    ...warehousesSagas,
    ...usersSagas,
    ...packingTypesSagas,
    ...productsSagas,
    ...dictionariesSagas,
    ...zonesSagas,
    ...locationsSagas,
    ...stocktakingOrderSagas,
    ...stockUnitsSagas,
    ...saleOrdersSagas,
    ...couriersSagas,
    ...apkVersionSagas,
    ...multipickSagas,
    ...importJobsSagas,
    ...settingsSagas,
    ...suppliersSagas,
    ...deliveriesSagas,
    ...replenishmentSagas,
    ...packingSagas,
    ...tabsSagas,
    ...flightControlSagas,
    ...moveLogsSagas,
    fork(routinePromiseWatcherSaga)
  ])
}
