import React from 'react'
import styled, { css } from 'styled-components'
import CheckIcon from '@mui/icons-material/Check'

const CheckboxButton = ({ onClick, onChange, active, checked, isInput }) => {
  return isInput ? (
    <>
      <Wrapper active={active}>{active && <CheckIcon />}</Wrapper>
      <Input type='checkbox' onChange={onChange} checked={checked} />
    </>
  ) : (
    <Wrapper onClick={onClick} active={active}>
      {active && <CheckIcon />}
    </Wrapper>
  )
}

export default CheckboxButton

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;

  ${({ theme, active }) =>
    active &&
    css`
      background-color: ${theme.colors.primary.main};
      color: #fff;
    `}
`
const Input = styled.input`
  opacity: 0;
  width: 40px;
  height: 40px;
  margin-left: -40px;
`
