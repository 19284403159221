import React from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import styled, { css } from 'styled-components'
import { Tooltip } from '@mui/material'

const RemoveIcon = ({ onClick, title, disabled }) => {
  return (
    <Tooltip title={!disabled ? title || 'Usuń' : ''}>
      <Content disabled={disabled}>
        <StyledIcon onClick={onClick} disabled={disabled} />
      </Content>
    </Tooltip>
  )
}

export default RemoveIcon

const StyledIcon = styled(DeleteOutlineOutlinedIcon)`
  font-size: 18px !important;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.disabled};
      pointer-events: none;
    `}
`

const Content = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.disabled};
      cursor: initial;
    `}
`
