import React, { useEffect, useMemo } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import ContentPanel from 'components/atoms/ContentPanel'
import UsersTable from 'modules/users/components/UsersTable'
import styled from 'styled-components'
import AddUserModal from 'modules/users/components/AddUserModal'
import { fetchUsersByRoleListRoutine } from 'modules/users/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectUsersListByRoles } from 'modules/users/ducks/selectors'
import { keys } from 'ramda'
import { selectUserRolesDictionary } from 'ducks/dictionaries/selectors'
import { HeaderWrapper } from 'theme/wrappers'

const Users = () => {
  const listByRoles = useSelector(selectUsersListByRoles)
  const dispatch = useDispatch()
  const userRolesDictionary = useSelector(selectUserRolesDictionary)

  useEffect(() => {
    dispatch(fetchUsersByRoleListRoutine())
  }, [])

  const activeUsers = useMemo(() => {
    const roleKeys = keys(listByRoles)
    const allRoles = {
      ...userRolesDictionary,
      noRole: 'Brak roli'
    }
    return roleKeys.map(key => (
      <ActiveUser key={key}>
        <ActiveUserTitle>{allRoles[key]}</ActiveUserTitle>
        <ActiveUserContent>{listByRoles[key].length}</ActiveUserContent>
      </ActiveUser>
    ))
  }, [listByRoles, userRolesDictionary])

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Lista użytkowników</PageHeader>
        <AddUserModal />
      </HeaderWrapper>
      <ActiveUsersPanel>
        Zalogowani użytkownicy:
        <ActiveUsersWrapper>{activeUsers}</ActiveUsersWrapper>
      </ActiveUsersPanel>
      <ContentPanel>
        <UsersTable />
      </ContentPanel>
    </>
  )
}

export default Users

const ActiveUsersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
`

const ActiveUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
`

const ActiveUserTitle = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.main};
  width: 100%;
  text-align: center;
  color: #fff;
`

const ActiveUserContent = styled.div`
  background-color: #fff;
  width: 100%;
  text-align: center;
`

const ActiveUsersPanel = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
`
