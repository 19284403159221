const PATHS = {
  home: '/',
  products: '/products',
  couriers: '/couriers',
  productDetails: '/products/:id',
  locations: '/locations',
  locationDetails: '/locations/:id',
  warehouses: '/warehouses',
  zones: '/zones',
  zoneDetails: '/zones/:id',
  packingTypes: '/packing-types',
  delivery: '/delivery',
  deliveryDetails: '/delivery/:id',
  users: '/users',
  settings: '/settings',
  unauthorized: '/unauthorized',
  packing: '/packing',
  lists: '/lists',
  control: '/control',
  saleOrders: '/sale-orders',
  saleOrderDetails: '/sale-orders/:id',
  stockUnits: '/stock-units',
  stockUnitDetails: '/stock-units/:id',
  stockUnitKinds: '/stock-unit-kinds',
  stocktakingOrders: '/stocktaking-orders',
  additions: '/additions',
  skipTaskReasons: '/skip-task-reasons',
  warehouseState: '/warehouse-state',
  warehouseDealings: '/warehouse-dealings',
  efficiencyStats: '/efficiency-stats',
  integrations: '/integrations',
  apkVersions: '/apk-versions',
  importJobsReport: '/import-jobs',
  suppliers: '/suppliers',
  replenishments: '/replenishments',
  packerStatistics: '/packer-statistics',
  moveLogs: '/move-logs',
  deliveryOrdersLogs: '/delivery-orders-logs'
}

export default PATHS
