import React, { useEffect, useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import StocktakingOrdersTabs from 'modules/stocktakingOrders/components/StocktakingOrdersTabs'
import StocktakingOrdersFilters from 'modules/stocktakingOrders/components/StocktakingOrdersFilters'
import { getCurrentParsedQuery } from 'utils/navigation'
import { omit, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import FiltersButton from 'components/atoms/FiltersButton'
import ExportStocktakingOrdersToFileModal from 'modules/stocktakingOrders/components/ExportStocktakingOrdersToFileModal'
import { FiltersWrapper, HeaderWrapper } from 'theme/wrappers'
import { fetchSettingsRoutine } from 'modules/settings/ducks/actions'
import { useDispatch } from 'react-redux'

const StocktakingOrders = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const { activeTab } = getCurrentParsedQuery()
  const dispatch = useDispatch()

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const filters = omit(['status'], propOr({}, 'filter', query))
    setFiltersOpen(isNotNilOrEmpty(filters))
    dispatch(fetchSettingsRoutine())
  }, [])

  const toggleFilters = () => setFiltersOpen(prev => !prev)

  return (
    <>
      <HeaderWrapper>
        <PageHeader withMargin>Lista inwentaryzacji</PageHeader>
        <FiltersWrapper>
          {activeTab === 'finished' && <ExportStocktakingOrdersToFileModal />}
          <FiltersButton active={filtersOpen} onClick={toggleFilters} />
        </FiltersWrapper>
      </HeaderWrapper>
      <StocktakingOrdersFilters open={filtersOpen} />
      <StocktakingOrdersTabs />
    </>
  )
}

export default StocktakingOrders
