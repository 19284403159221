import React from 'react'
import Tabs from 'components/atoms/Tabs'
import ContentPanel from 'components/atoms/ContentPanel'
import CouriersTable from 'modules/couriers/components/CouriersTable'
import CouriersGroupsTable from 'modules/couriers/components/CouriersGroupsTable'
import UngroupedCouriersTable from 'modules/couriers/components/UngroupedCouriersTable'

const CouriersTabs = () => {
  const tabs = [
    {
      key: 'couriers',
      title: 'Wszyscy kurierzy',
      children: <CouriersTable />,
      isList: true
    },
    {
      key: 'ungrouped-couriers',
      title: 'Kurierzy nieprzypisani',
      children: <UngroupedCouriersTable />,
      isList: true
    },
    {
      key: 'groups',
      title: 'Grupy kurierów',
      children: <CouriersGroupsTable />,
      isList: true
    }
  ]

  return (
    <ContentPanel>
      <Tabs tabs={tabs} />
    </ContentPanel>
  )
}

export default CouriersTabs
