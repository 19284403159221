import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { bulkDeleteCompletionLists } from 'services/MultipickService'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { filter, omit } from 'ramda'
import {
  currentGeneratedOrdersRoutine,
  fetchGeneratedMultipickListsRoutine
} from 'modules/multipick/ducks/actions'
import ButtonWrapper from 'theme/wrappers'
import { fetchSaleOrdersStatisticsRoutine } from 'modules/saleOrders/ducks/actions'
import styled from 'styled-components'
import { isNilOrEmpty } from 'utils/ramda'
import { canRemoveList } from 'utils/multipick'

const BulkRemoveListsModal = ({ lists, clearSelected }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const pendingLists = filter(item => canRemoveList(item), lists)
  const blockedLists = filter(item => !canRemoveList(item), lists)

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    const handleSuccess = () => {
      const query = getCurrentParsedQuery()
      const payloadQuery = omit(
        ['activeTab', 'ordered_after', 'ordered_before'],
        query
      )
      dispatch(
        fetchGeneratedMultipickListsRoutine({
          query: stringifyQuery(payloadQuery)
        })
      )
      dispatch(fetchSaleOrdersStatisticsRoutine())
      handleModalClose()
      clearSelected()
      dispatch(currentGeneratedOrdersRoutine([]))
      toast.success('Rozgrupowano listy')
      setLoading(false)
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
      setLoading(false)
    }

    const listIds = pendingLists.map(list => list.id)

    bulkDeleteCompletionLists({ listIds })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <Button onClick={handleModalOpen} color='primary-outline'>
        Rozgrupuj zaznaczone
      </Button>
      <Modal open={open} title='Rozgrupuj listy' onClose={handleModalClose}>
        {blockedLists.length > 0 ? (
          <div>
            <BlockedInfo>
              {
                'Poniższe listy nie zostaną rozgrupowane. Można rozgrupować tylko oczekujące listy.'
              }
            </BlockedInfo>
            {blockedLists.map(list => (
              <p key={list.id}>
                {' '}
                {list.name}
                {' nr:'}
                <ListNumber>{list.listNumber}</ListNumber>
              </p>
            ))}
          </div>
        ) : (
          'Czy na pewno chcesz rozgrupować wybrane listy?'
        )}
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            disabled={loading || isNilOrEmpty(pendingLists)}
            isLoading={loading}
          >
            {blockedLists.length > 0 ? 'Rozgrupuj pozostałe' : 'Rozgrupuj'}
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default BulkRemoveListsModal

const BlockedInfo = styled.div`
  font-weight: bolder;
  margin-bottom: 10px;
`
const ListNumber = styled.span`
  font-weight: bolder;
  margin-left: 4px;
`
