import React, { useEffect, useState } from 'react'
import CheckboxButton from 'components/atoms/CheckboxButton'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { updateSettingsRoutine } from '../ducks/actions'

const StocktakingOrdersCountSetting = ({ id, value }) => {
  const [inventoryCount, setInventoryCount] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setInventoryCount(value === '1')
  }, [value])

  const toggleInventoryCount = e => {
    e.preventDefault()
    dispatch(
      updateSettingsRoutine({
        id,
        value: e.target.checked ? '1': '0'
      })
    )
    setInventoryCount(prev => !prev)
  }

  return (
    <CheckboxWrapper>
      <CheckboxLabel>
        Pokazuj ilość towaru do zliczenia:
      </CheckboxLabel>
      <CheckboxButton
        isInput
        active={inventoryCount}
        checked={inventoryCount}
        onChange={e => toggleInventoryCount(e)}
      />
    </CheckboxWrapper>
  )
}

export default StocktakingOrdersCountSetting

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 280px;
  background-color: #fff;
  border-color: ${({ theme }) => theme.colors.secondary.main};
`
const CheckboxLabel = styled.div`
  margin-right: 10px;
`
