import React, { useEffect, useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import { getCurrentParsedQuery } from 'utils/navigation'
import { dissoc, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import FiltersButton from 'components/atoms/FiltersButton'
import PackerStatisticsTable from 'modules/efficiecyStats/components/PackerStatisticsTable'
import PackerStatisticsFilters from 'modules/efficiecyStats/components/PackerStatisticsFilters'
import styled from 'styled-components'

const PackerStatistics = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const filters = dissoc('search', propOr({}, 'filter', query))
    setFiltersOpen(isNotNilOrEmpty(filters))
  }, [])

  const toggleFilters = () => setFiltersOpen(prev => !prev)

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Statystyki pakowaczy</PageHeader>
        <FiltersWrapper>
          <FiltersButton active={filtersOpen} onClick={toggleFilters} />
        </FiltersWrapper>
      </HeaderWrapper>
      <PackerStatisticsFilters open={filtersOpen} />
      <PackerStatisticsTable />
    </>
  )
}

export default PackerStatistics

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
