import React from 'react'
import { useSelector } from 'react-redux'
import { pathOr, propOr } from 'ramda'
import Table from 'components/Table'
import { selectCurrentStockUnit } from 'modules/stockUnits/ducks/selectors'

const StockUnitsProductsTab = () => {
  const stockUnit = useSelector(selectCurrentStockUnit)
  const products = propOr([], 'inventory', stockUnit)

  const headers = [
    { children: 'EAN' },
    { children: 'Nazwa' },
    { children: 'Ilość' }
  ]

  const rows = products.map(row => ({
    redirectPath: `/products/${pathOr('', ['product', 'id'], row)}`,
    cells: [
      { children: pathOr('---', ['product', 'ean'], row) },
      { children: pathOr('---', ['product', 'name'], row) },
      { children: pathOr('---', ['quantity'], row) }
    ]
  }))

  return (
    <div>
      <Table
        headers={headers}
        rows={rows}
        emptyState='Brak produktów w JM'
      />
    </div>
  )
}

export default StockUnitsProductsTab
