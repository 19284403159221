import React from 'react'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'

const StocktakingOrderIcon = ({ onClick }) => {
  return (
    <Tooltip title='Zleć inwentaryzację'>
      <Content>
        <StyledIcon onClick={onClick} />
      </Content>
    </Tooltip>
  )
}

export default StocktakingOrderIcon

const StyledIcon = styled(InventoryOutlinedIcon)`
  cursor: pointer;
  font-size: 18px !important;
`

const Content = styled.div`
  display: flex;
  align-items: center;
`
