import React from 'react'
import SaleOrderStatusHistory from 'modules/saleOrderDetails/components/SaleOrderStatusHistory'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectCurrentSaleOrder } from 'modules/saleOrders/ducks/selectors'
import { pathOr, propOr } from 'ramda'
import SaleOrderCompletionList from './SaleOrderCompletionList'
import SaleOrderPickerHistory from './SaleOrderPickerHistory'
import { Tooltip } from '@mui/material'

const SaleOrderHistory = () => {
  const saleOrder = useSelector(selectCurrentSaleOrder)
  const completionListId = pathOr({}, ['completionList', 'id'], saleOrder)

  const truncate = (str, n) => {
    return str.length > n ? str.slice(0, n - 1) + '...' : str
  }

  return (
    <>
      <ListsWrapper>
        <List>
          <ListTitle>
            <div>Zamówienie nr: </div>
            <ListTitleContent>
              {propOr('---', 'iaiOrderSerialNumber', saleOrder)}
            </ListTitleContent>
          </ListTitle>
          <ListContent>
            <SaleOrderStatusHistory />
          </ListContent>
        </List>
        <List>
          <ListTitle>
            <div>Zamówienie jest zgrupowane do listy: </div>
            <ListTitleContent>
              {pathOr('', ['completionList', 'listNumber'], saleOrder)}
              {' - '}
              <Tooltip
                title={pathOr('', ['completionList', 'name'], saleOrder)}
              >
                <span>
                  {truncate(pathOr('', ['completionList', 'name'], saleOrder),50)}
                </span>
              </Tooltip>
            </ListTitleContent>
          </ListTitle>
          <ListContent>
            <SaleOrderCompletionList id={completionListId} />
          </ListContent>
        </List>
      </ListsWrapper>
      <ListsWrapper>
        <List>
          <ListTitle>
            <div>Ścieżka pickera</div>
          </ListTitle>
          <ListContent>
            <SaleOrderPickerHistory />
          </ListContent>
        </List>
      </ListsWrapper>
    </>
  )
}

export default SaleOrderHistory

const ListsWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
  margin-bottom: 40px;
`

const List = styled.div`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.border};
  flex: 1;
`

const ListTitle = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.primary.main};
`
const ListTitleContent = styled.div`
  font-size: 18px;
  font-weight: bolder;
  margin-left: 5px;
`

const ListContent = styled.div`
  max-height: 310px;
  overflow-y: auto;
  padding: 0 5px;
`
