import React, { useEffect, useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import ContentPanel from 'components/atoms/ContentPanel'
import { FiltersWrapper, HeaderWrapper } from 'theme/wrappers'
import InventoryMoveLogsTable from 'modules/inventoryMoveLogs/components/InventoryMoveLogsTable'
import { getCurrentParsedQuery } from 'utils/navigation'
import { dissoc, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import FiltersButton from 'components/atoms/FiltersButton'
import InventoryMoveLogsFilters from 'modules/inventoryMoveLogs/components/InventoryMoveLogsFilters'

const InventoryMoveLogs = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const filters = dissoc('search', propOr({}, 'filter', query))
    setFiltersOpen(isNotNilOrEmpty(filters))
  }, [])

  const toggleFilters = () => setFiltersOpen(prev => !prev)

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Raport przesunięć</PageHeader>
        <FiltersWrapper>
          <FiltersButton active={filtersOpen} onClick={toggleFilters} />
        </FiltersWrapper>
      </HeaderWrapper>
      <InventoryMoveLogsFilters open={filtersOpen} />
      <ContentPanel>
        <InventoryMoveLogsTable />
      </ContentPanel>
    </>
  )
}

export default InventoryMoveLogs
