import React from 'react'
import Button from 'components/atoms/Button'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import styled from 'styled-components'

const AddButton = ({ children, onClick }) => {
  return (
    <ButtonWrapper>
      <Button color='primary' onClick={onClick}>
        <AddOutlinedIcon /> {children}
      </Button>
    </ButtonWrapper>
  )
}

export default AddButton

const ButtonWrapper = styled.div`
  width: fit-content;
`
