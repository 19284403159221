import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDefaultFilteredList,
  selectDefaultFilteredListLoading,
  selectDefaultFilteredListPagination
} from 'modules/delivery/ducks/selectors'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { omit, pathOr, propOr, values } from 'ramda'
import Table from 'components/Table'
import {
  changeQuery,
  getCurrentParsedQuery,
  stringifyQuery
} from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'
import { fetchDefaultFilteredDeliveriesListRoutine } from 'modules/delivery/ducks/actions'
import {
  DELIVERY_PRIORITIES,
  DELIVERY_STATUSES,
  REMOVABLE_DELIVERY_STATUSES
} from 'utils/delivery'
import RemoveDeliveryModal from 'modules/delivery/components/RemoveDeliveryModal'
import { ActionsContainer } from 'theme/wrappers'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'scheduled_for'
}

const DefaultFilteredDeliveryTable = () => {
  const deliveries = useSelector(selectDefaultFilteredList)
  const pagination = useSelector(selectDefaultFilteredListPagination)
  const isLoading = useSelector(selectDefaultFilteredListLoading)
  const removableStatuses = values(REMOVABLE_DELIVERY_STATUSES)

  const {
    location: { search }
  } = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const filters = omit(['differences'], propOr({}, 'filter', query))

    if (isNotNilOrEmpty(search)) {
      dispatch(
        fetchDefaultFilteredDeliveriesListRoutine({
          query: stringifyQuery({ ...query, filter: filters })
        })
      )
      changeQuery({ ...query, filter: filters })
    } else changeQuery(defaultFilters)
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'ID', sort: 'identifier' },
    { children: 'Nr dokumentu', sort: 'document_no' },
    { children: 'Planowana data dostawy', sort: 'scheduled_for' },
    { children: 'Rozpoczęcie rozpakowania' },
    { children: 'Zakończenie rozpakowania' },
    { children: 'Data wprowadzenia dostawy' },
    { children: 'Dostawca', sort: 'supplier_id' },
    { children: 'Komentarz', sort: 'comment' },
    { children: 'Magazyn', sort: 'warehouse_id' },
    { children: 'Priorytet', sort: 'priority' },
    { children: 'Dodający', sort: 'created_by' },
    { children: 'Przyjmujący' },
    { children: 'Status', sort: 'status' },
    { children: 'Czy zgodna?' }
  ]

  const rows = deliveries.map(row => {
    const status = DELIVERY_STATUSES[propOr('undefined', 'status', row)]
    const unloaders = propOr([], 'unloaders', row)
    return {
      redirectPath: `/delivery/${row.id}`,
      cells: [
        { children: propOr('---', 'identifier', row) },
        { children: propOr('---', 'documentNo', row) },
        { children: formatDate(row.scheduledFor, DATE_FORMATS.dashed) },
        {
          children: formatDate(
            row.unloadingStartedAt,
            DATE_FORMATS.dashedWithTime
          )
        },
        { children: formatDate(row.unloadedAt, DATE_FORMATS.dashedWithTime) },
        { children: formatDate(row.receivedAt, DATE_FORMATS.dashed) },
        { children: pathOr('---', ['supplier', 'name'], row) },
        { children: row.comment },
        { children: pathOr('---', ['warehouse', 'name'], row) },
        { children: DELIVERY_PRIORITIES[row.priority] },
        { children: pathOr('---', ['createdBy', 'username'], row) },
        {
          children: (
            <>
              {isNotNilOrEmpty(unloaders) ? (
                unloaders.map(item => (
                  <div key={item.id}>{propOr('---', 'username', item)}</div>
                ))
              ) : (
                <div>---</div>
              )}
            </>
          )
        },
        { children: status },
        {
          children: ['confirmed', 'unloaded', 'moved_to_warehouse'].includes(
            row.status
          ) ? (
            propOr(false, 'differences', row) ? (
              '✗'
            ) : (
              '✔'
            )
          ) : (
            <div>---</div>
          )
        },
        {
          children: (
            <ActionsContainer>
              {removableStatuses.includes(status) && (
                <RemoveDeliveryModal delivery={row} />
              )}
            </ActionsContainer>
          ),
          align: 'right',
          preventRedirect: true
        }
      ]
    }
  })

  return (
    <Table
      rows={rows}
      headers={headers}
      pagination={pagination}
      onPageChange={handlePageChange}
      isLoading={isLoading}
    />
  )
}

export default DefaultFilteredDeliveryTable
