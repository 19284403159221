import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import ImportButton from 'components/atoms/ImportButton'
import { toast } from 'react-hot-toast'
import ButtonWrapper from 'theme/wrappers'
import { isNotNilOrEmpty } from 'utils/ramda'
import { changeStatusPackedToSent } from 'services/SaleOrdersService'
import { getApiErrors } from 'utils/errors'
import { useHistory } from 'react-router-dom'
import { selectSaleOrdersList } from 'modules/saleOrders/ducks/selectors'
import { fetchSaleOrdersRoutine } from 'modules/saleOrders/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { changeQuery } from 'utils/navigation'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'status',
  activeTab: 'active'
}

const ChangePackedToSentModal = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const list = useSelector(selectSaleOrdersList)
  const ids = list.map(item => item.id)
  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    dispatch(
      fetchSaleOrdersRoutine({
        query: search
      })
    )
  }, [search])

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    changeQuery(defaultFilters)
    setOpen(false)
    setLoading(false)
  }

  const handleSuccess = () => {
    handleModalClose()
    toast.success('Zlecono zmianę statusu ze spakowanych na wysłane')
  }

  const handleError = e => {
    setLoading(false)
    handleModalClose()
    toast.error(getApiErrors(e))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    isNotNilOrEmpty(ids)
      ? changeStatusPackedToSent({ sale_orders_ids: ids })
          .then(handleSuccess)
          .catch(handleError)
      : changeStatusPackedToSent().then(handleSuccess).catch(handleError)
  }

  return (
    <>
      <ImportButton onClick={handleModalOpen}>
        Zmień status na wysłane
      </ImportButton>
      <Modal
        open={open}
        title='Zmień status spakowanych zamówień'
        onClose={handleModalClose}
      >
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button
            type='submit'
            color='primary'
            onClick={handleSubmit}
            disabled={loading}
            isLoading={loading}
          >
            Zmień na wysłane
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default ChangePackedToSentModal
