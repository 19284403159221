import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { navItems } from 'utils/navigation'
import SideNavigationItem from 'components/navigation/SideNavigationItem'
import LogoutButton from 'components/navigation/LogoutButton'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { intersection,propOr } from 'ramda'
import { fetchUserRolesDictionaryRoutine } from 'ducks/dictionaries/actions'

const SideNavigation = () => {
  const [isOpen, setIsOpen] = useState(false)
  const user = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', user)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserRolesDictionaryRoutine())
  }, [])

  const openNavigation = () => {
    setIsOpen(true)
  }

  const closeNavigation = () => {
    setIsOpen(false)
  }

  return (
    <ContentWrapper
      onMouseEnter={openNavigation}
      onMouseLeave={closeNavigation}
      open={isOpen}
    >
      <SideNavigationWrapper>
        {navItems.map(item => (
          <SideNavigationItem
            key={item.label}
            icon={item.icon}
            label={item.label}
            path={item.path}
            sub={item.sub}
            navigationOpen={isOpen}
            isVisible={intersection(userRoles, propOr([], 'roles', item)).length > 0}
            userRoles={userRoles}
          />
        ))}
      </SideNavigationWrapper>
      <LogoutButton />
    </ContentWrapper>
  )
}

export default SideNavigation

const SideNavigationWrapper = styled.ul`
  list-style: none;
`

const ContentWrapper = styled.div`
  position: fixed;
  padding: 10px 0;
  top: 0;
  left: 0;
  height: calc(100vh - ${({ theme }) => theme.dimensions.topBarHeight});
  margin-top: ${({ theme }) => theme.dimensions.topBarHeight};
  width: ${({ theme, open }) =>
    open ? '220px' : theme.dimensions.sideBarWidth};
  background-color: ${({ theme }) => theme.colors.primary.main};
  box-shadow: ${({ theme }) => theme.shadows.main};
  transition: all 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;

  &::-webkit-scrollbar {
    width: 0;
  }
`
