import {
  authCheckRoutine,
  fetchAuthUserRoutine,
  loginUserRoutine,
  logoutUserRoutine, switchUserRoleRoutine
} from 'modules/auth/ducks/actions'

const defaultState = {
  user: {},
  isLoading: false
}

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case loginUserRoutine.TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case loginUserRoutine.FAILURE:
      return {
        ...state,
        isLoading: false,
        user: defaultState.user
      }
    case loginUserRoutine.SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoading: false
      }
    case fetchAuthUserRoutine.SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    case logoutUserRoutine.SUCCESS:
    case authCheckRoutine.FAILURE:
      return {
        ...state,
        user: defaultState.user
      }
    case switchUserRoleRoutine.SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    default:
      return state
  }
}

export default authReducer
