import React from 'react'
import ListAltIcon from '@mui/icons-material/ListAlt'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'

const ListIcon = ({ onClick }) => {
  return (
    <Tooltip title='Stwórz listę kompletacji'>
      <Content>
        <StyledIcon onClick={onClick} />
      </Content>
    </Tooltip>
  )
}

export default ListIcon

const StyledIcon = styled(ListAltIcon)`
  cursor: pointer;
  font-size: 18px !important;
`

const Content = styled.div`
  display: flex;
  align-items: center;
`
