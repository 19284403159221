import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { dissoc, pathOr, propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import { ButtonsWrapper } from 'theme/wrappers'

const emptyValues = {
  created_after: '',
  created_before: '',
  difference: 'all'
}

const StocktakingOrdersFilters = ({ open }) => {
  const [values, setValues] = useState(emptyValues)
  const [showDifferenceFilter, setShowDifferenceFilter] = useState(false)
  const {
    location: { search }
  } = useHistory()
  const currentQuery = getCurrentParsedQuery()

  useEffect(() => {
    setShowDifferenceFilter(currentQuery.activeTab === 'finished')
  }, [currentQuery.activeTab])

  const differnceOptions = [
    { label: 'Zgodne i niezgodne', value: 'all' },
    { label: 'Tylko niezgodne', value: '1' },
    { label: 'Tylko zgodne', value: '0' }
  ]

  const resetFilters = () => {
    setValues(emptyValues)
    const currentQuery = getCurrentParsedQuery()
    changeQuery({
      page: 1,
      limit: 20,
      sort: '-created_at',
      filter: {
        status: pathOr('', ['filter', 'status'], currentQuery)
      },
      activeTab: pathOr('', ['activeTab'], currentQuery)
    })
  }

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    setValues({
      ...emptyValues,
      ...propOr({}, 'filter', currentQuery)
    })
  }, [search])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = () => {
    const currentQuery = getCurrentParsedQuery()
    let payload = {}

    for (let key in values) {
      if (isNotNilOrEmpty(values[key]) && values[key] !== 'all') {
        payload[key] = values[key]
      } else {
        payload = dissoc(key, payload)
      }
    }

    changeQuery({
      ...currentQuery,
      page: 1,
      filter: payload
    })
  }

  return (
    <Wrapper open={open}>
      <Input
        type='date'
        value={values.created_after}
        name='created_after'
        label='Data zlecenia od'
        onChange={handleValueChange}
        noMarginBottom
      />
      <Input
        type='date'
        value={values.created_before}
        name='created_before'
        label='Data zlecenia do'
        onChange={handleValueChange}
        noMarginBottom
      />
      {showDifferenceFilter && (
        <Input
          select
          options={differnceOptions}
          value={values.difference}
          name='difference'
          label='Różnice'
          onChange={handleValueChange}
          noMarginBottom
        />
      )}
      <ButtonsWrapper>
        <Button small onClick={handleSubmit} color='primary'>
          Zastosuj
        </Button>
        <Button small onClick={resetFilters} color='primary-outline'>
          Resetuj
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default StocktakingOrdersFilters

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  width: 100%;
  overflow: hidden;
  height: 0;
  padding: 0 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 15px;

  ${({ open }) =>
    open &&
    css`
      padding: 20px;
      margin-bottom: 20px;
      height: 110px;
    `}
`
