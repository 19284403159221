import { validateField, validateValues } from 'utils/form'
import { object, string } from 'yup'

// login schema
export const loginSchema = object().shape({
  username: string().required('Login jest wymagany'),
  password: string().required('Hasło jest wymagane')
})

export const validateLoginField = values => validateField(loginSchema, values)
export const validateLoginValues = validateValues(loginSchema)
