import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as settingsService from 'services/SettingsService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchSettingsRoutine = createRoutine('FETCH_SETTINGS')
export const updateSettingsRoutine = createRoutine('UPDATE_SETTINGS')

// ACTIONS
function * fetchSettings () {
  yield put(fetchSettingsRoutine.request())
  try {
    const { data } = yield call(settingsService.getSettings)
    yield put(fetchSettingsRoutine.success(data.data))
  } catch (e) {
    yield put(fetchSettingsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * updateSettings ({ payload }) {
  const { id, value } = payload
  yield put(updateSettingsRoutine.request())
  try {
    yield call(settingsService.updateSettings, { id, value })
    yield put(updateSettingsRoutine.success())
    toast.success('Pomyślnie zmieniono ustawienie')
    yield put(fetchSettingsRoutine())
  } catch (e) {
    yield put(updateSettingsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

//WATCHERS
export function * fetchSettingsWatcher () {
  yield takeLatest(fetchSettingsRoutine.TRIGGER, fetchSettings)
}

export function * updateSettingsWatcher () {
  yield takeLatest(updateSettingsRoutine.TRIGGER, updateSettings)
}

// SAGAS
export const settingsSagas = [
  fork(fetchSettingsWatcher),
  fork(updateSettingsWatcher)
]
