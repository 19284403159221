import React, { useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import ContentPanel from 'components/atoms/ContentPanel'
import AddDeliveryModal from 'modules/delivery/components/AddDeliveryModal'
import DeliveryTable from 'modules/delivery/components/DeliveryTable'
import FiltersButton from 'components/atoms/FiltersButton'
import DeliveryFilters from 'modules/delivery/components/DeliveryFilters'
import DefaultFilteredDeliveryTable from 'modules/delivery/components/DefaultFilteredDeliveryTable'
import { FiltersWrapper, HeaderWrapper } from 'theme/wrappers'

const Delivery = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [allDeliveries, setAllDeliveries] = useState(false)

  const toggleFilters = () => setFiltersOpen(prev => !prev)
  const toggleAllDeliveries = () => setAllDeliveries(prev => !prev)

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Lista dostaw</PageHeader>
        <FiltersWrapper>
          <FiltersButton active={filtersOpen} onClick={toggleFilters} />
          <AddDeliveryModal />
        </FiltersWrapper>
      </HeaderWrapper>
      <DeliveryFilters
        open={filtersOpen}
        allDeliveries={allDeliveries}
        toggleAllDeliveries={toggleAllDeliveries}
      />
      <ContentPanel>
        {allDeliveries ? <DeliveryTable /> : <DefaultFilteredDeliveryTable />}
      </ContentPanel>
    </>
  )
}

export default Delivery
