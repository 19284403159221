import { propOr } from 'ramda'
import { createSelector } from 'reselect'
import moment from 'moment'

export const selectMultipick = state => state.multipick

export const selectMultipickRulesetsList = createSelector(
  selectMultipick,
  propOr([], 'list')
)

export const selectSavedMultipicksValues = createSelector(
  selectMultipick,
  propOr({
    name: '',
    orderType: '',
    allowStandard: false,
    allowCumbersome: false,
    allowHeavy: false,
    minItems: 1,
    maxItems: 50,
    minUniqueProducts: 1,
    maxUniqueProducts: 50,
    priority: 5,
    courierGroupId: '',
    packagingTypes: [],
    sources: [],
    stockUnitKinds: [],
    limitBy: 'items_count',
    minStockUnitFilling: 0,
    maxStockUnitFilling: 80
  }, 'selectedValues')
)

export const selectSavedMultipicksDates = createSelector(
  selectMultipick,
  propOr({
      ordered_after: moment().subtract(1, 'days').startOf('day').toDate(),
      ordered_before: moment().toDate()
  }, 'selectedDates')
)

export const selectIsDictionaryFetching = createSelector(
  selectMultipick,
  propOr([], 'isDictionaryFetching')
)

export const selectMultipickDictionary = createSelector(
  selectMultipick,
  propOr({}, 'dictionary')
)

export const selectSimulatedLists = createSelector(
  selectMultipick,
  propOr([], 'simulatedLists')
)

export const selectGeneratedMultipicks = createSelector(
  selectMultipick,
  propOr([], 'generatedLists')
)

export const selectCurrentGeneratedOrders = createSelector(
  selectMultipick,
  propOr([], 'currentGeneratedOrders')
)

export const selectIsLoadingGeneratedOrders = createSelector(
  selectMultipick,
  propOr(false, 'isLoadingGeneratedLists')
)
