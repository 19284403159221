import React from 'react'
import App from './App'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from 'theme/globalStyles'
import theme from 'theme/theme'
import ReduxProvider from 'providers/ReduxProvider'
import { Toaster } from 'react-hot-toast'
import 'react-datepicker/dist/react-datepicker.css'
import { setDefaultLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'
import { Router } from 'react-router-dom'
import history from 'utils/history'

setDefaultLocale(pl)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ThemeProvider theme={theme}>
    <Toaster />
    <GlobalStyles />
    <ReduxProvider>
      <Router history={history}>
        <App />
      </Router>
    </ReduxProvider>
  </ThemeProvider>
)
