import React, { useState } from 'react'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'

const ChangePasswordForm = ({ onSave }) => {
  const [password, setPassword] = useState('')
  const [valid, setValid] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    onSave(password)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input
        value={password}
        label='Hasło'
        placeholder='Wpisz hasło'
        onChange={((_, value) => setPassword(value))}
        type='password'
        withPasswordHint
        validatePasswordCallback={setValid}
      />
      <Button
        type='submit'
        disabled={!valid}
        color='primary'
      >
        Zapisz
      </Button>
    </form>
  )
}

export default ChangePasswordForm
