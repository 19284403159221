import APIService from './APIService'
import { dissoc } from 'ramda'

const api = APIService()

export const fetchZones = payload => api.get(`/zones${payload.query}`)
export const fetchSingleZone = payload => api.get(`/zones/${payload.id}`)
export const createZone = payload => api.post(`/zones`, payload)
export const updateZone = payload => api.patch(`/zones/${payload.id}`, dissoc('id', payload))
export const removeZone = payload => api.delete(`/zones/${payload.id}`)
