import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import { validateCreateLocationField, validateCreateLocationValues } from 'modules/locations/ducks/schema'
import { updateLocationRoutine } from 'modules/locations/ducks/actions'
import { selectWarehousesList } from 'modules/warehouses/ducks/selectors'
import { selectZonesList } from 'modules/zones/ducks/selectors'
import EditIcon from 'components/icons/EditIcon'
import { pathOr, pick } from 'ramda'

const emptyValues = {
  name: '',
  weightLimit: 0,
  weightLimitUnit: 'g',
  warehouseId: '',
  zoneId: '',
  sortOrder: ''
}

const EditLocationModal = ({ location }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const warehouses = useSelector(selectWarehousesList)
  const zones = useSelector(selectZonesList)

  useEffect(() => {
    open && setValues({
      ...pick(['name', 'weightLimit', 'weightLimitUnit', 'sortOrder'], location),
      warehouseId: pathOr('', ['warehouse', 'id'], location),
      zoneId: pathOr('', ['zone', 'id'], location),
    })
  }, [location, open])

  const warehousesOptions = useMemo(() => {
    return warehouses.map(warehouse => ({
      label: warehouse.name,
      value: warehouse.id
    }))
  }, [warehouses])

  const zonesOptions = useMemo(() => {
    return zones.map(zone => ({
      label: zone.name,
      value: zone.id
    }))
  }, [zones])

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateCreateLocationValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(updateLocationRoutine({
      values: {
        ...values,
        id: location.id
      },
      callback: handleModalClose
    }))
  }

  return (
    <>
      <EditIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Edytuj lokalizację'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa lokalizacji'
            value={values.name}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Input
            name='weightLimit'
            label='Limit wagi'
            value={values.weightLimit}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Input
            select
            options={[
              { label: 'g', value: 'g' },
              { label: 'kg', value: 'kg' },
            ]}
            name='weightLimitUnit'
            label='Jednostka limitu wagi'
            value={values.weightLimitUnit}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Input
            select
            options={warehousesOptions}
            name='warehouseId'
            label='Magazyn'
            value={values.warehouseId}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Input
            select
            options={zonesOptions}
            name='zoneId'
            label='Strefa'
            value={values.zoneId}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Input
            name='sortOrder'
            label='Kolejność'
            value={values.sortOrder}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Button
            type='submit'
            color='primary'
            disabled={!valid}
          >
            Zapisz
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default EditLocationModal
