import React, { useEffect, useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import styled from 'styled-components'
import AddStockUnitModal from 'modules/stockUnits/components/AddStockUnitModal'
import StockUnitsTable from 'modules/stockUnits/components/StockUnitsTable'
import ContentPanel from 'components/atoms/ContentPanel'
import StockUnitsFilters from 'modules/stockUnits/components/StockUnitsFilters'
import { changeQuery, getCurrentParsedQuery, isAuthorized } from 'utils/navigation'
import { omit, propOr } from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import FiltersButton from 'components/atoms/FiltersButton'
import SearchInput from 'components/atoms/SearchInput'
import { FiltersWrapper, HeaderWrapper } from 'theme/wrappers'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'

const StockUnits = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRoles = ['admin', 'foreman', 'bok']

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const filters = omit(
      ['search', 'with_deactivated'],
      propOr({}, 'filter', query)
    )
    setFiltersOpen(isNotNilOrEmpty(filters))
  }, [])

  const toggleFilters = () => setFiltersOpen(prev => !prev)

  const handleSearch = searchQuery => {
    const parsedQuery = getCurrentParsedQuery()
    const isEmpty = isNilOrEmpty(searchQuery)

    changeQuery({
      ...parsedQuery,
      page: 1,
      filter: isEmpty
        ? omit(
            ['search', 'with_deactivated'],
            propOr({}, 'filter', parsedQuery)
          )
        : {
            ...propOr({}, 'filter', parsedQuery),
            search: searchQuery,
            with_deactivated: 'with'
          }
    })
  }

  const handleClear = () => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      filter: omit(
        ['search', 'with_deactivated'],
        propOr({}, 'filter', parsedQuery)
      )
    })
  }

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Lista jednostek magazynowych</PageHeader>
        <FiltersWrapper>
          <FiltersButton active={filtersOpen} onClick={toggleFilters} />
          <InputWrapper>
            <SearchInput
              onSubmit={handleSearch}
              onClear={handleClear}
              placeholder='Znajdź JM...'
            />
          </InputWrapper>
          {isAuthorized(priviledgedRoles, userRoles) ? <AddStockUnitModal /> : null}
        </FiltersWrapper>
      </HeaderWrapper>
      <StockUnitsFilters open={filtersOpen} />
      <ContentPanel>
        <StockUnitsTable />
      </ContentPanel>
    </>
  )
}

export default StockUnits

const InputWrapper = styled.div`
  max-width: 250px;
`
