import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import BackButton from 'components/atoms/BackButton'
import PATHS from 'utils/paths'
import { fetchSingleSaleOrderRoutine } from 'modules/saleOrders/ducks/actions'
import SaleOrderBasicDetails from 'modules/saleOrderDetails/components/SaleOrderBasicDetails'
import SaleOrderDetailsTabs from 'modules/saleOrderDetails/components/SaleOrderDetailsTabs'
import AllowReimportSaleOrdersModal from 'modules/saleOrders/components/AllowReimportSaleOrderModal'
import styled from 'styled-components'
import { selectCurrentSaleOrder } from 'modules/saleOrders/ducks/selectors'
import { pathOr, propOr } from 'ramda'
import ImportSaleOrdersFromIAIModal from 'modules/saleOrders/components/ImportSaleOrdersFromIAIModal'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { isAuthorized } from 'utils/navigation'

const SaleOrderDetails = () => {
  const dispatch = useDispatch()
  const currentSaleOrder = useSelector(selectCurrentSaleOrder)
  const { id } = useParams()
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRoles = ['admin', 'bok']

  useEffect(() => {
    dispatch(fetchSingleSaleOrderRoutine({ id }))
  }, [id])

  return (
    <>
      <ActionWrapper>
        <BackButton
          withMargin
          path={PATHS.saleOrders}
          label='Lista zamówień sprzedaży'
        />
        {isAuthorized(priviledgedRoles, userRoles) && (
          <Buttons>
            <ImportSaleOrdersFromIAIModal isDetailsView />
            {pathOr('', ['status', 'value'], currentSaleOrder) ===
              'cancelled' && (
              <AllowReimportSaleOrdersModal saleOrder={currentSaleOrder} />
            )}
          </Buttons>
        )}
      </ActionWrapper>
      <SaleOrderBasicDetails />
      <SaleOrderDetailsTabs />
    </>
  )
}

export default SaleOrderDetails

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`
const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  button {
    width: fit-content;
  }
`
