import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as authService from 'services/AuthService'
import { getCurrentPathname, isAuthorizedToPath, redirect } from 'utils/navigation'
import PATHS from 'utils/paths'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { pathOr } from 'ramda'

export const loginUserRoutine = createRoutine('LOGIN_USER')
export const logoutUserRoutine = createRoutine('LOGOUT_USER')
export const fetchAuthUserRoutine = createRoutine('FETCH_AUTH_USER')
export const changeUserPasswordRoutine = createRoutine('CHANGE_USER_PASSWORD')
export const authCheckRoutine = createRoutine('AUTH_CHECK')
export const switchUserRoleRoutine = createRoutine('SWITCH_USER_ROLE')

// ACTIONS
function * loginUser ({ payload }) {
  yield put(loginUserRoutine.request())
  try {
    const { data } = yield call(authService.loginUser, payload)
    yield put(loginUserRoutine.success(data.data))
    const userRoles = pathOr([], ['data', 'roles'], data)
    if (isAuthorizedToPath(PATHS.control, userRoles)) {
      redirect(PATHS.control)
    } else if (userRoles.includes('packer') && !isAuthorizedToPath  (PATHS.control, userRoles)) {
      redirect(PATHS.packing)
    } else if (userRoles.includes('inbound')) {
      redirect(PATHS.delivery)
    } else if (userRoles.includes('stocktaker')) {
      redirect(PATHS.stocktakingOrders)
    } else if (isAuthorizedToPath(PATHS.products, userRoles)) {
      redirect(PATHS.products)
    } else {
      redirect(PATHS.unauthorized)
    }
  } catch (e) {
    toast.error(getApiErrors(e))
    yield put(loginUserRoutine.failure(e))
    console.error(e)
  }
}

function * fetchAuthUser () {
  yield put(fetchAuthUserRoutine.request())
  try {
    const { data } = yield call(authService.fetchAuthUser)
    yield put(fetchAuthUserRoutine.success(data.data))
  } catch (error) {
    if (getCurrentPathname() !== PATHS.home) {
      redirect(PATHS.home)
    }
    console.error(error)
    yield put(fetchAuthUserRoutine.failure(error))
  }
}

function * logoutUser () {
  yield put(logoutUserRoutine.request())
  try {
    yield call(authService.logoutUser)
    yield put(logoutUserRoutine.success())
    redirect(PATHS.home)
  } catch (e) {
    yield put(logoutUserRoutine.failure(e))
    console.error(e)
  }
}

function * changeUserPassword ({ payload }) {
  yield put(changeUserPasswordRoutine.request())
  try {
    yield call(authService.changeUserPassword, payload)
    yield put(changeUserPasswordRoutine.success())
    yield put(fetchAuthUserRoutine())
    toast.success('Hasło zostało pomyślnie zmienione')
  } catch (e) {
    yield put(changeUserPasswordRoutine.failure(e))
    console.error(e)
  }
}

function * authCheck () {
  yield put(authCheckRoutine.request())
  try {
    yield call(authService.fetchAuthUser)
    yield put(authCheckRoutine.success())
  } catch {
    yield put(authCheckRoutine.failure())
    redirect(PATHS.home)
  }
}

function * switchUserRole ({ payload }) {
  yield put(switchUserRoleRoutine.request())
  try {
    const { data } = yield call(authService.switchUserRole, payload)
    yield put(switchUserRoleRoutine.success(data.data))
  } catch (e) {
    yield put(switchUserRoleRoutine.failure())
    toast.error(getApiErrors(e))
  }
}

// WATCHERS
export function * loginUserWatcher () {
  yield takeLatest(loginUserRoutine.TRIGGER, loginUser)
}

export function * fetchAuthUserWatcher () {
  yield takeLatest(fetchAuthUserRoutine.TRIGGER, fetchAuthUser)
}

export function * logoutUserWatcher () {
  yield takeLatest(logoutUserRoutine.TRIGGER, logoutUser)
}

export function * changeUserPasswordWatcher () {
  yield takeLatest(changeUserPasswordRoutine.TRIGGER, changeUserPassword)
}

export function * authCheckWatcher () {
  yield takeLatest(authCheckRoutine.TRIGGER, authCheck)
}

export function * switchUserRoleWatcher () {
  yield takeLatest(switchUserRoleRoutine.TRIGGER, switchUserRole)
}

// SAGAS
export const authSagas = [
  fork(loginUserWatcher),
  fork(fetchAuthUserWatcher),
  fork(logoutUserWatcher),
  fork(changeUserPasswordWatcher),
  fork(authCheckWatcher),
  fork(switchUserRoleWatcher)
]
