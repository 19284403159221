import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import styled from 'styled-components'
import { createMultipickListManually } from 'services/MultipickService'
import { dissoc, pathOr } from 'ramda'
import { validateCreateListField, validateCreateListValues } from 'modules/multipick/ducks/schema'
import Input from 'components/atoms/Input'
import ButtonWrapper from 'theme/wrappers'

const initialValues = {
  name: '',
  priority: 5,
  comment: '',
  saleOrdersIds: []
}

const CreateListManuallyModal = ({ handleSimulateFilters, selectedOrders }) => {
  const [values, setValues] = useState(initialValues)
  const [valid, setValid] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    values && validateCreateListValues(dissoc('saleOrdersIds', values), setValid)
  }, [values])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(initialValues)
  }

  const handleSubmit = e => {
    e.preventDefault()
    createMultipickListManually({
      ...values,
      saleOrdersIds: selectedOrders.map(order => order.id)
    })
      .then(resp => {
        const meta = pathOr({}, ['data', 'meta'], resp)
        toast.success(`Utworzono nową listę
        Zamówienia: ${meta.ordersCount}
        Zamówienia bez alokacji: ${meta.ordersWithoutAllocationCount}
        `, { duration: 4000 })
        handleSimulateFilters()
        handleModalClose()
      })
      .catch((e) => {
        toast.error(getApiErrors(e))
      })
  }

  return (
    <>
      <CreateListButton onClick={handleModalOpen}>
        Stwórz listę kompletacji
      </CreateListButton>
      <Modal
        open={open}
        title='Tworzenie list'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa'
            value={values.name}
            onChange={handleValueChange}
            validate={validateCreateListField(values)}
          />
          <Input
            name='priority'
            label='Priorytet'
            type='number'
            value={values.priority}
            onChange={handleValueChange}
            validate={validateCreateListField(values)}
          />
          <Input
            multiline
            rows={3}
            name='comment'
            label='Komentarz'
            value={values.comment}
            onChange={handleValueChange}
            validate={validateCreateListField(values)}
          />
          <ButtonWrapper>
            <Button
              onClick={handleModalClose}
              color='primary-outline'
            >
              Anuluj
            </Button>
            <Button
              disabled={!valid}
              type='submit'
              color='primary'
            >
              Stwórz listę
            </Button>
          </ButtonWrapper>
        </form>
      </Modal>
    </>
  )
}

export default CreateListManuallyModal

const CreateListButton = styled.div`
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.secondary.main};
`
