import React, { useState } from 'react'
import ExportButton from 'components/atoms/ExportButton'
import { Tooltip } from '@mui/material'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { downloadCSV } from 'utils/files'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { exportWarehouseStateToCsv } from 'services/WarehousesService'
import ButtonWrapper from 'theme/wrappers'

const ExportWarehouseStateToFileModal = () => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setLoading(false)
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    exportWarehouseStateToCsv()
      .then(resp => {
        downloadCSV(
          resp.data.replace(/#/g, ''),
          `Stan_magazynu-${new Date().toLocaleDateString()}`
        )
        handleModalClose()
      })
      .catch(e => {
        toast.error(getApiErrors(e))
        handleModalClose()
      })
  }
  return (
    <>
      <Tooltip title='Export do pliku CSV'>
        <div>
          <ExportButton onClick={handleModalOpen} />
        </div>
      </Tooltip>

      <Modal open={open} title='Eksport do CSV' onClose={handleModalClose}>
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button
            type='submit'
            color='primary'
            onClick={handleSubmit}
            disabled={loading}
            isLoading={loading}
          >
            Zatwierdź
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default ExportWarehouseStateToFileModal
