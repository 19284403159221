import { pathOr, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectFlightControl = state => state.flightControl

export const selectFlightControlSummary = createSelector(
  selectFlightControl,
  propOr({}, 'summary')
)

// hidden because loading state for summary lasts to long
// export const selectFlightControlSummaryLoading = createSelector(
//   selectFlightControl,
//   propOr(false, 'isLoadingSummary')
// )

export const selectFlightControlSaleOrders = createSelector(
  selectFlightControl,
  propOr({}, 'saleOrders')
)

export const selectFlightControlSaleOrdersLoading = createSelector(
  selectFlightControl,
  propOr(false, 'isLoadingSaleOrders')
)

export const selectPackedOrders = createSelector(
  selectFlightControl,
  propOr({}, 'packed')
)

export const selectPackedOrdersLoading = createSelector(
  selectFlightControl,
  pathOr(false, ['isLoadingByStatus', 'packed'])
)

export const selectForPackingOrders = createSelector(
  selectFlightControl,
  propOr({}, 'for_packing')
)

export const selectForPackingOrdersLoading = createSelector(
  selectFlightControl,
  pathOr(false, ['isLoadingByStatus', 'for_packing'])
)

export const selectAllocatedOrders = createSelector(
  selectFlightControl,
  propOr({}, 'allocated')
)

export const selectAllocatedOrdersLoading = createSelector(
  selectFlightControl,
  pathOr(false, ['isLoadingByStatus', 'allocated'])
)

export const selectCollectedOrders = createSelector(
  selectFlightControl,
  propOr({}, 'collected')
)

export const selectCollectedOrdersLoading = createSelector(
  selectFlightControl,
  pathOr(false, ['isLoadingByStatus', 'collected'])
)

export const selectPackerStats = createSelector(
  selectFlightControl,
  propOr({}, 'packer')
)

export const selectPackerStatsLoading = createSelector(
  selectFlightControl,
  pathOr(false, ['isLoadingStatsByRole', 'packer'])
)

export const selectPickerStats = createSelector(
  selectFlightControl,
  propOr({}, 'picker')
)

export const selectPickerStatsLoading = createSelector(
  selectFlightControl,
  pathOr(false, ['isLoadingStatsByRole', 'picker'])
)

export const selectConnectorStats = createSelector(
  selectFlightControl,
  propOr({}, 'connector')
)

export const selectConnectorStatsLoading = createSelector(
  selectFlightControl,
  pathOr(false, ['isLoadingStatsByRole', 'connector'])
)

export const selectFlightControlSettings = createSelector(
  selectFlightControl,
  propOr({}, 'settings')
)

export const selectFlightControlSettingsLoading = createSelector(
  selectFlightControl,
  pathOr(false, ['isLoadingSettings'])
)

export const selectFlightControlCutOffTime = createSelector(
  selectFlightControl,
  pathOr('', ['settings', 'flightControlEndOfPacking'])
)
