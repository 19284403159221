import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { dissoc, propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import { DELIVERY_STATUSES } from 'utils/delivery'
import CheckboxButton from 'components/atoms/CheckboxButton'
import { ButtonsWrapper } from 'theme/wrappers'

const emptyValues = {
  status: [],
  differences: 'all'
}

const DeliveryFilters = ({ open, allDeliveries, toggleAllDeliveries }) => {
  const [values, setValues] = useState(emptyValues)

  const {
    location: { search }
  } = useHistory()

  const resetFilters = () => {
    setValues(emptyValues)
    changeQuery({
      page: 1,
      sort: 'scheduled_for'
    })
  }

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    setValues({
      ...emptyValues,
      ...propOr({}, 'filter', currentQuery)
    })
  }, [search])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const statusOptions = useMemo(() => {
    let options = []
    for (let key in DELIVERY_STATUSES) {
      options = [...options, { label: DELIVERY_STATUSES[key], value: key }]
    }
    return options
  }, [])

  const handleSubmit = () => {
    const currentQuery = getCurrentParsedQuery()
    let payload = {}

    for (let key in values) {
      if (isNotNilOrEmpty(values[key]) && values[key] !== 'all') {
        payload[key] = values[key]
      } else {
        payload = dissoc(key, payload)
      }
    }

    changeQuery({
      ...currentQuery,
      page: 1,
      filter: payload
    })
  }

  return (
    <Wrapper open={open}>
      <Input
        select
        multiple
        options={statusOptions}
        value={values.status}
        name='status'
        label='Status'
        onChange={handleValueChange}
        noMarginBottom
      />
      <ButtonsWrapper>
        <Button small onClick={handleSubmit} color='primary'>
          Zastosuj
        </Button>
        <Button small onClick={resetFilters} color='primary-outline'>
          Resetuj
        </Button>
      </ButtonsWrapper>
      <CheckboxWrapper>
        <CheckboxLabel>Ukryj potwierdzone zgodne:</CheckboxLabel>
        <CheckboxButton active={!allDeliveries} onClick={toggleAllDeliveries} />
      </CheckboxWrapper>
    </Wrapper>
  )
}

export default DeliveryFilters

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  width: 100%;
  overflow: hidden;
  height: 0;
  padding: 0 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 15px;

  ${({ open }) =>
    open &&
    css`
      padding: 20px;
      margin-bottom: 20px;
      height: 110px;
    `}
`

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 280px;
  background-color: #fff;
  border-color: ${({ theme }) => theme.colors.secondary.main};
`
const CheckboxLabel = styled.div`
  margin-right: 20px;
`
