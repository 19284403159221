import { changeTabRoutine, fetchTabsListRoutine } from 'ducks/tabs/actions'

const defaultState = {
  list: [],
  activeTab: ''
}

const tabsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case changeTabRoutine.SUCCESS:
      return {
        ...state,
        activeTab: action.payload
      }
    case fetchTabsListRoutine.SUCCESS:
      return {
        list: action.payload.keys,
        activeTab: action.payload.activeTab
      }

    default:
      return state
  }
}

export default tabsReducer
