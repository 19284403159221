import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import { validateWarehouseField } from 'modules/warehouses/ducks/schema'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import EditIcon from 'components/icons/EditIcon'
import { isEmpty } from 'ramda'
import { editPackingTypeRoutine } from 'modules/packingTypes/ducks/actions'

const emptyValues = {
  name: ''
}

const EditPackingTypeModal = ({ type }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    handleValueChange('name', type.name)
  }, [type])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(editPackingTypeRoutine({
      values: {
        name: values.name,
        id: type.id
      },
      callback: handleModalClose
    }))
  }

  return (
    <>
      <EditIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Edycja opakowania'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa opakowania'
            value={values.name}
            onChange={handleValueChange}
            validate={validateWarehouseField(values)}
          />
          <Button
            type='submit'
            color='primary'
            disabled={isEmpty(values.name)}
          >
            Zapisz
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default EditPackingTypeModal
