import APIService from './APIService'

const api = APIService()

export const fetchLocations = payload => api.get(`/locations${payload.query}`)
export const fetchSingleLocation = payload =>
  api.get(`/locations/${payload.id}`)
export const createLocation = payload => api.post('/locations', payload)
export const updateLocation = payload =>
  api.patch(`/locations/${payload.id}`, payload)
export const removeLocation = payload => api.delete(`/locations/${payload.id}`)
export const exportLocationsToFile = () => api.get('/locations/export/csv')

export const importLocationsFromFile = payload => {
  const fd = new FormData()
  fd.append('file', payload.file)
  return api.post(`/locations/import/csv`, fd)
}

export const fetchLocationHistory = payload => {
  return api.get(`/locations/${payload.id}/inventory-logs${payload.query}`)
}
