import React from 'react'
import Tabs from 'components/atoms/Tabs'
import ContentPanel from 'components/atoms/ContentPanel'
import FinishedJobImportsTab from 'modules/importJobsReport/components/FinishedJobImportsTab'
import PendingJobImportsTab from 'modules/importJobsReport/components/PendingJobImportsTab'

const ImportJobsTabs = () => {
  const tabs = [
    {
      key: 'finished',
      title: 'Zakończone',
      children: <FinishedJobImportsTab />,
      isList: true
    },
    {
      key: 'active',
      title: 'W trakcie',
      children: <PendingJobImportsTab />,
      isList: true
    }
  ]

  return (
    <ContentPanel>
      <Tabs tabs={tabs} />
    </ContentPanel>
  )
}

export default ImportJobsTabs
