import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import { useDispatch } from 'react-redux'
import { removeWarehouseRoutine } from 'modules/warehouses/ducks/actions'
import Button from 'components/atoms/Button'
import RemoveIcon from 'components/icons/RemoveIcon'
import ButtonWrapper from 'theme/wrappers'

const RemoveWarehouseModal = ({ warehouse }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(removeWarehouseRoutine({
      id: warehouse.id,
      callback: handleModalClose
    }))
  }

  return (
    <>
      <RemoveIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Usunięcie magazynu'
        onClose={handleModalClose}
      >
        Czy na pewno chcesz usunąć magazyn &quot;{warehouse.name}&quot;
        <ButtonWrapper>
          <Button
            onClick={handleModalClose}
            color='primary-outline'
          >
            Anuluj
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
          >
            Usuń
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default RemoveWarehouseModal
