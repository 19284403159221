import { createRoutine } from 'redux-saga-routines'
import { call, fork, put, takeLatest } from '@redux-saga/core/effects'
import * as apkVersionService from 'services/ApkVersionService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchLatestApkVersionRoutine = createRoutine('FETCH_LATEST_APK')

//ACTIONS
function * fetchLatestApkVersion ({ payload }) {
  yield put(fetchLatestApkVersionRoutine.request())
  try {
    const { data } = yield call(
      apkVersionService.fetchLatestApkVersion,
      payload
    )
    yield put(fetchLatestApkVersionRoutine.success(data.data))
  } catch (e) {
    yield put(fetchLatestApkVersionRoutine.failure(e))
    toast.error(getApiErrors(e))
  }
}

//WATCHERS
export function * fetchLatestApkVersionWatcher () {
  yield takeLatest(fetchLatestApkVersionRoutine.TRIGGER, fetchLatestApkVersion)
}

//SAGAS
export const apkVersionSagas = [
  fork(fetchLatestApkVersionWatcher)
]
