import React from 'react'
import { useSelector } from 'react-redux'
import { selectFlightControlSummary } from 'modules/flightControl/ducks/selectors'
import { pathOr, values } from 'ramda'
import styled from 'styled-components'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

const PackedOrdersStats = () => {
  const summary = useSelector(selectFlightControlSummary)
  // hidden because loading state for summary lasts to long
  // const isLoading = useSelector(selectFlightControlSummaryLoading)
  const packersSummary = values(pathOr({}, ['packers', 'remaining'], summary))

  const total = packersSummary.find(courier => isNilOrEmpty(courier.label))
  const couriers = packersSummary.filter(courier =>
    isNotNilOrEmpty(courier.label)
  )

  return (
    <SummaryWrapper>
      {/* {isLoading ? (
        <LoadingSpinner />
      ) : ( */}
      <>
        <SummaryBox>
          <SummaryBoxTotal color='#004f74'>
            <Value>{total?.total}</Value>
            <Label>Pozostało łącznie</Label>
          </SummaryBoxTotal>
          <SummaryBoxParts>
            <SummaryBoxPart color='#004f74'>
              <Value>{total?.singleSku}</Value>
              <Label>1 SKU</Label>
            </SummaryBoxPart>
            <SummaryBoxPart color='#004f74'>
              <Value>{total?.multiSku}</Value>
              <Label>Multi SKU</Label>
            </SummaryBoxPart>
          </SummaryBoxParts>
        </SummaryBox>
        {isNotNilOrEmpty(couriers) &&
          couriers.map(courier => (
            <SummaryBox key={courier.label}>
              <SummaryBoxTotal color={courier.color}>
                <Value>{courier.total}</Value>
                <Label>{courier.label}</Label>
              </SummaryBoxTotal>
              <SummaryBoxParts>
                <SummaryBoxPart color='#72bb52'>
                  <Value>{courier.singleSku}</Value>
                  <Label>1 SKU</Label>
                </SummaryBoxPart>
                <SummaryBoxPart color='#ff4db5'>
                  <Value>{courier.multiSku}</Value>
                  <Label>Multi SKU</Label>
                </SummaryBoxPart>
              </SummaryBoxParts>
            </SummaryBox>
          ))}
      </>
      {/* )} */}
    </SummaryWrapper>
  )
}

export default PackedOrdersStats

const SummaryWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 20px;
  width: 100%;
`

const SummaryBox = styled.div`
  min-width: 160px;
`

const SummaryBoxTotal = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ color }) => color || '#666'};
  border-radius: 6px;
  color: #fff;
  height: 80px;
`

const SummaryBoxParts = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 2px;
`

const SummaryBoxPart = styled.div`
  padding: 10px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ color }) => color || '#666'};
  border-radius: 6px;
  color: #fff;
  height: 80px;
`

const Label = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
`

const Value = styled.div`
  font-size: 18px;
  font-weight: bold;
`
