import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call, takeEvery } from '@redux-saga/core/effects'
import * as flightControlService from 'services/FlightControlService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchFlightControlSummaryRoutine = createRoutine('FETCH_FLIGHT_CONTROL_SUMMARY')
export const fetchFlightControlSaleOrdersRoutine = createRoutine('FETCH_FLIGHT_CONTROL_SALE_ORDERS')
export const fetchFlightControlSaleOrdersByStatusRoutine = createRoutine('FETCH_FLIGHT_CONTROL_SALE_ORDERS_BY_STATUS')
export const fetchFlightControlStatsByRoleRoutine = createRoutine('FETCH_FLIGHT_CONTROL_STATS_BY_ROLE')
export const fetchFlightControlSettingsRoutine = createRoutine('FETCH_FLIGHT_CONTROL_SETTINGS')
export const fetchAllRolesAndStatuesRoutine = createRoutine('FETCH_FLIGHT_CONTROL_ALL_ROLES_AND_STATUSES')

// ACTIONS
function * fetchFlightControlSummary () {
  yield put(fetchFlightControlSummaryRoutine.request())
  try {
    const { data } = yield call(flightControlService.fetchFlightControlSummary)
    yield put(fetchFlightControlSummaryRoutine.success(data.data))
  } catch (e) {
    yield put(fetchFlightControlSummaryRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchFlightControlSaleOrders () {
  yield put(fetchFlightControlSaleOrdersRoutine.request())
  try {
    const { data } = yield call(flightControlService.fetchFlightControlSaleOrders)
    yield put(fetchFlightControlSaleOrdersRoutine.success(data.data))
  } catch (e) {
    yield put(fetchFlightControlSaleOrdersRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchFlightControlSaleOrdersByStatus ({ payload }) {
  yield put(fetchFlightControlSaleOrdersByStatusRoutine.request())
  try {
    const { data } = yield call(flightControlService.fetchFlightControlSaleOrdersByStatus, payload)
    yield put(fetchFlightControlSaleOrdersByStatusRoutine.success({ status: payload.status, data: data.data }))
  } catch (e) {
    yield put(fetchFlightControlSaleOrdersByStatusRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchFlightControlStatsByRole ({ payload }) {
  yield put(fetchFlightControlStatsByRoleRoutine.request())
  try {
    const { data } = yield call(flightControlService.fetchFlightControlStatsByRole, payload)
    yield put(fetchFlightControlStatsByRoleRoutine.success({ role: payload.role, data: data.data }))
  } catch (e) {
    yield put(fetchFlightControlStatsByRoleRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchFlightControlSettings () {
  yield put(fetchFlightControlSettingsRoutine.request())
  try {
    const { data } = yield call(flightControlService.fetchFlightControlSettings)
    yield put(fetchFlightControlSettingsRoutine.success(data.data))
  } catch (e) {
    yield put(fetchFlightControlSettingsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchAllRolesAndStatues () {
  yield put(fetchAllRolesAndStatuesRoutine.request())
  try {
    yield put(fetchFlightControlSettingsRoutine())
    yield put(fetchFlightControlSummaryRoutine())
    yield put(fetchFlightControlSaleOrdersRoutine())
    yield put(fetchFlightControlStatsByRoleRoutine({ role: 'picker' }))
    yield put(fetchFlightControlStatsByRoleRoutine({ role: 'packer' }))
    yield put(fetchFlightControlStatsByRoleRoutine({ role: 'connector' }))
    yield put(fetchFlightControlSaleOrdersByStatusRoutine({ status: 'for_packing' }))
    yield put(fetchFlightControlSaleOrdersByStatusRoutine({ status: 'allocated' }))
    yield put(fetchFlightControlSaleOrdersByStatusRoutine({ status: 'collected' }))
    yield put(fetchFlightControlSaleOrdersByStatusRoutine({ status: 'packed' }))
    fetchAllRolesAndStatuesRoutine.success()
  } catch (e) {
    yield put(fetchAllRolesAndStatuesRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * fetchFlightControlSummaryWatcher () {
  yield takeLatest(fetchFlightControlSummaryRoutine.TRIGGER, fetchFlightControlSummary)
}

export function * fetchFlightControlSaleOrdersWatcher () {
  yield takeLatest(fetchFlightControlSaleOrdersRoutine.TRIGGER, fetchFlightControlSaleOrders)
}

export function * fetchFlightControlSaleOrdersByStatusWatcher () {
  yield takeEvery(fetchFlightControlSaleOrdersByStatusRoutine.TRIGGER, fetchFlightControlSaleOrdersByStatus)
}

export function * fetchFlightControlStatsByRoleWatcher () {
  yield takeEvery(fetchFlightControlStatsByRoleRoutine.TRIGGER, fetchFlightControlStatsByRole)
}

export function * fetchFlightControlSettingsWatcher () {
  yield takeLatest(fetchFlightControlSettingsRoutine.TRIGGER, fetchFlightControlSettings)
}

export function * fetchAllRolesAndStatuesWatcher () {
  yield takeLatest(fetchAllRolesAndStatuesRoutine.TRIGGER, fetchAllRolesAndStatues)
}

// SAGAS
export const flightControlSagas = [
  fork(fetchFlightControlSummaryWatcher),
  fork(fetchFlightControlSaleOrdersWatcher),
  fork(fetchFlightControlSaleOrdersByStatusWatcher),
  fork(fetchFlightControlStatsByRoleWatcher),
  fork(fetchFlightControlSettingsWatcher),
  fork(fetchAllRolesAndStatuesWatcher)
]
