import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { MultipickFiltersTitle } from 'modules/multipick/components/filters/MultipickFiltersCommonComponents'
import { useDispatch, useSelector } from 'react-redux'
import { stringifyQuery } from 'utils/navigation'
import Input from 'components/atoms/Input'
import { fetchPackingTypesRoutine } from 'modules/packingTypes/ducks/actions'
import Checkbox from 'components/atoms/Checkbox'
import { selectMultipickDictionary } from 'modules/multipick/ducks/selectors'
import { propOr } from 'ramda'

const MultipickProductFilters = ({ values, handleValueChange }) => {
  const dispatch = useDispatch()
  const multipickDictionary = useSelector(selectMultipickDictionary)
  const packagingTypes = propOr([], 'packagingTypes', multipickDictionary)
  const productTypes = propOr([], 'productTypes', multipickDictionary)
  const stockUnitKinds = propOr([], 'stockUnitKinds', multipickDictionary)

  const stockUnitKindCheckboxes = useMemo(() => {
    return stockUnitKinds.map(kind => {
      const isIncluded = propOr([], 'stockUnitKinds', values).includes(kind.id)
      return (
        <Checkbox
          key={kind.id}
          label={`${kind.name} (${kind.matchingOrdersCount})`}
          name='container'
          value={isIncluded}
          isChecked={isIncluded}
          onChange={() => {
            isIncluded
              ? handleValueChange('stockUnitKinds', propOr([], 'stockUnitKinds', values).filter(kindId => kindId !== kind.id))
              : handleValueChange('stockUnitKinds', [...values.stockUnitKinds, kind.id])
          }}
        />
      )
    })
  }, [stockUnitKinds, values])

  useEffect(() => {
    dispatch(fetchPackingTypesRoutine({
      query: stringifyQuery({
        page: 1,
        limit: 1000
      })
    }))
  }, [])

  const packagingTypesOptions = useMemo(() => {
    return packagingTypes.map(type => ({
      label: `${type.name} (${type.matchingOrdersCount})`,
      value: type.id,
      disabled: type.matchingOrdersCount < 1
    }))
  }, [packagingTypes])

  return (
    <Wrapper>
      <MultipickFiltersTitle>Parametry produktów</MultipickFiltersTitle>
      <CheckboxesTitle>Typy produktów</CheckboxesTitle>
      <CheckboxesRow>
        <Checkbox
          label={`Standardowe (${propOr(0, 'standard', productTypes)})`}
          name='allowStandard'
          value={values.allowStandard}
          onChange={handleValueChange}
        />
        <Checkbox
          label={`Nieporęczne (${propOr(0, 'cumbersome', productTypes)})`}
          name='allowCumbersome'
          value={values.allowCumbersome}
          onChange={handleValueChange}
        />
        <Checkbox
          label={`Ciężkie (${propOr(0, 'heavy', productTypes)})`}
          name='allowHeavy'
          value={values.allowHeavy}
          onChange={handleValueChange}
        />
      </CheckboxesRow>
      <CheckboxesTitle>JM przypisana</CheckboxesTitle>
      <CheckboxesRow>
        {stockUnitKindCheckboxes}
      </CheckboxesRow>
      <Input
        select
        multiple
        options={packagingTypesOptions}
        name='packagingTypes'
        label='W co pakować'
        value={values.packagingTypes}
        onChange={handleValueChange}
      />
    </Wrapper>
  )
}

export default MultipickProductFilters

const Wrapper = styled.div`
  min-width: 380px;
`

const CheckboxesRow = styled.div`
  display: flex;
  gap: 10px;
  margin: 5px 0;
  * {
    font-size: 12px !important;
  }
`

const CheckboxesTitle = styled.div`
  margin: 10px 0;
`
