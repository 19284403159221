import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useDispatch, useSelector } from 'react-redux'
import { selectZonesDictionary } from 'ducks/dictionaries/selectors'
import { dissoc, keys, propOr } from 'ramda'
import { fetchZonesDictionaryRoutine } from 'ducks/dictionaries/actions'
import { toSnakeCase } from 'js-convert-case'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { validateFiltersField, validateFiltersValues } from 'modules/zones/ducks/schema'
import { useHistory } from 'react-router-dom'
import { ButtonsWrapper } from 'theme/wrappers'

const initialValues = {
  zone_type: 'all',
  storage_type: 'all'
}

const ZonesFilters = ({ open }) => {
  const [filterValues, setFilterValues] = useState(initialValues)
  const [isValid, setIsValid] = useState(false)
  const dispatch = useDispatch()
  const zonesDictionary = useSelector(selectZonesDictionary)
  const zoneTypes = propOr({}, 'zoneTypes', zonesDictionary)
  const storageTypes = propOr({}, 'storageTypes', zonesDictionary)
  const { location: { search } } = useHistory()

  useEffect(() => {
    dispatch(fetchZonesDictionaryRoutine())
  }, [])

  const zoneTypeOptions = useMemo(() => {
    if (isNotNilOrEmpty(zoneTypes)) {
      const zoneTypesKeys = keys(zoneTypes)
      return [
        { label: 'Wszystkie', value: 'all' },
        ...zoneTypesKeys.map(key => ({
        label: zoneTypes[key],
        value: toSnakeCase(key)
      }))
    ]
    } else {
      return [{ label: 'Wszystkie', value: 'all' },]
    }
  }, [zoneTypes])

  const storageTypeOptions = useMemo(() => {
    if (isNotNilOrEmpty(storageTypes)) {
      const storageTypesKeys = keys(storageTypes)
      return [
        { label: 'Wszystkie', value: 'all' },
        ...storageTypesKeys.map(key => ({
        label: storageTypes[key],
        value: toSnakeCase(key)
      }))
    ]
    } else {
      return [{ label: 'Wszystkie', value: 'all' },]
    }
  }, [storageTypes])

  const changeValueHandler = (name, value) => {
    setFilterValues(prev => ({ ...prev, [name]: value }))
  }

  const resetFiltersHandler = () => {
    setFilterValues(initialValues)
    changeQuery({
      page: 1,
      sort: 'name'
    })
  }

  const submitHandler = () => {
    const currentQuery = getCurrentParsedQuery()
    let payload = {}

    for (let key in filterValues) {
      if (isNotNilOrEmpty(filterValues[key]) && filterValues[key] !== 'all') {
        payload[key] = filterValues[key]
      } else {
        payload = dissoc(key, payload)
      }
    }

    changeQuery({
      ...currentQuery,
      page: 1,
      filter: payload
    })
  }

  useEffect(() => {
    validateFiltersValues(filterValues, setIsValid)
  }, [filterValues])

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    setFilterValues({
      ...initialValues,
      ...propOr({}, 'filter', currentQuery)
    })
  }, [search])

  return (
    <Wrapper open={open}>
      <Input
        select
        options={zoneTypeOptions}
        name='zone_type'
        value={filterValues.zone_type}
        label='Rodzaj strefy'
        onChange={changeValueHandler}
        validate={validateFiltersField(filterValues)}
        noMarginBottom
      />
      <Input
        select
        options={storageTypeOptions}
        name='storage_type'
        value={filterValues.storage_type}
        label='Typ składowania'
        onChange={changeValueHandler}
        validate={validateFiltersField(filterValues)}
        noMarginBottom
      />
      <ButtonsWrapper>
        <Button
          small
          onClick={submitHandler}
          color='primary'
          disabled={!isValid}
        >
          Zastosuj
        </Button>
        <Button
          small
          onClick={resetFiltersHandler}
          color='primary-outline'
        >
          Resetuj
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )

}

export default ZonesFilters

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  width: 100%;
  overflow: hidden;
  height: 0;
  padding: 0 20px;
  transition: all .3s;
  display: flex;
  align-items: center;
  gap: 15px;

  ${({ open }) => open && css`
    padding: 20px;
    margin-bottom: 20px;
    height: 110px;
  `}
`
