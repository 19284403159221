import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectImportJobs = state => state.importJobs

export const selectImportJobsList = createSelector(
  selectImportJobs,
  propOr([], 'importJobs')
)

export const selectPendingImportJobsList = createSelector(
  selectImportJobs,
  propOr([], 'pendingImportJobs')
)

export const selectImportJobsListPagination = createSelector(
  selectImportJobs,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'importJobsPagination'
  )
)

export const selectImportJobsListLoading = createSelector(
  selectImportJobs,
  propOr(false, 'isLoading')
)
