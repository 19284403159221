import React from 'react'
import styled from 'styled-components'
import Button from './Button'

const ImportButton = ({ children, onClick, disabled }) => {
  return (
    <Wrapper>
      <Button medium color='primary' onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </Wrapper>
  )
}

export default ImportButton

const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
`
