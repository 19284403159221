import React from 'react'
import styled from 'styled-components'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

const ResetButton = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <RestartAltIcon />
    </Wrapper>
  )
}

export default ResetButton

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: #fff;
    border-color: ${({ theme }) => theme.colors.primary.dark};
  }
`
