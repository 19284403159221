import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as stockUnitsService from 'services/StockUnitsService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'

export const fetchStockUnitsRoutine = createRoutine('FETCH_STOCK_UNITS')
export const fetchSingleStockUnitRoutine = createRoutine(
  'FETCH_SINGLE_STOCK_UNIT'
)
export const createStockUnitRoutine = createRoutine('CREATE_STOCK_UNIT')
export const fetchStockUnitHistoryRoutine = createRoutine(
  'FETCH_STOCK_UNIT_HISTORY'
)
export const fetchStockUnitKindsRoutine = createRoutine(
  'FETCH_STOCK_UNIT_KINDS'
)

// ACTIONS
function * fetchStockUnits ({ payload }) {
  yield put(fetchStockUnitsRoutine.request())
  try {
    const { data } = yield call(stockUnitsService.fetchStockUnits, payload)
    yield put(fetchStockUnitsRoutine.success(data))
  } catch (e) {
    yield put(fetchStockUnitsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchSingleStockUnit ({ payload }) {
  yield put(fetchSingleStockUnitRoutine.request())
  try {
    const { data } = yield call(stockUnitsService.fetchSingleStockUnit, payload)
    yield put(fetchSingleStockUnitRoutine.success(data.data))
  } catch (e) {
    yield put(fetchSingleStockUnitRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * createStockUnit ({ payload }) {
  const { values, callback } = payload
  yield put(createStockUnitRoutine.request())
  try {
    const { data } = yield call(stockUnitsService.createStockUnit, values)
    yield put(createStockUnitRoutine.success())
    yield put(
      fetchStockUnitsRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    typeof callback === 'function' && callback(data)
    toast.success('Pomyślnie stworzono jednostkę/ki')
  } catch (e) {
    yield put(createStockUnitRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchStockUnitHistory ({ payload }) {
  yield put(fetchStockUnitHistoryRoutine.request())
  try {
    const { data } = yield call(
      stockUnitsService.fetchStockUnitHistory,
      payload
    )
    yield put(fetchStockUnitHistoryRoutine.success(data))
  } catch (e) {
    yield put(fetchStockUnitHistoryRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchStockUnitKinds () {
  yield put(fetchStockUnitKindsRoutine.request())
  try {
    const { data } = yield call(stockUnitsService.fetchStockUnitKinds)
    yield put(fetchStockUnitKindsRoutine.success(data))
  } catch (e) {
    yield put(fetchStockUnitKindsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * fetchStockUnitsWatcher () {
  yield takeLatest(fetchStockUnitsRoutine.TRIGGER, fetchStockUnits)
}

export function * fetchSingleStockUnitWatcher () {
  yield takeLatest(fetchSingleStockUnitRoutine.TRIGGER, fetchSingleStockUnit)
}

export function * createStockUnitWatcher () {
  yield takeLatest(createStockUnitRoutine.TRIGGER, createStockUnit)
}

export function * fetchStockUnitHistoryWatcher () {
  yield takeLatest(fetchStockUnitHistoryRoutine.TRIGGER, fetchStockUnitHistory)
}
export function * fetchStockUnitKindsWatcher () {
  yield takeLatest(fetchStockUnitKindsRoutine.TRIGGER, fetchStockUnitKinds)
}

// SAGAS
export const stockUnitsSagas = [
  fork(fetchStockUnitsWatcher),
  fork(fetchSingleStockUnitWatcher),
  fork(createStockUnitWatcher),
  fork(fetchStockUnitHistoryWatcher),
  fork(fetchStockUnitKindsWatcher)
]
