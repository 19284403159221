import FiltersButton from 'components/atoms/FiltersButton'
import Table from 'components/Table'
import { fetchChangesHistoryRoutine } from 'modules/products/ducks/actions'
import {
  selectChangesHistoryList,
  selectChangesHistoryListLoading,
  selectChangesHistoryListPagination
} from 'modules/products/ducks/selectors'
import { dissoc, keys, pathOr, propOr, values } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { TabFiltersWrapper } from 'theme/wrappers'
import { DATE_FORMATS, formatDate } from 'utils/date'
import {
  changeQuery,
  getCurrentParsedQuery
} from 'utils/navigation'
import { INPOST_SIZE, PRODUCT_FEATURES } from 'utils/product'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import ChangesHistoryFilters from './ChangesHistoryFilters'

const defaultFilters = {
  page: 1,
  limit: 20
}

const ChangesHistoryTab = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const { id } = useParams()
  const {
    location: { search }
  } = useHistory()
  const dispatch = useDispatch()
  const list = useSelector(selectChangesHistoryList)
  const pagination = useSelector(selectChangesHistoryListPagination)
  const isLoading = useSelector(selectChangesHistoryListLoading)

  useEffect(() => {
    changeQuery(defaultFilters)
  }, [])

  useEffect(() => {
    isNotNilOrEmpty(search)
      ? dispatch(fetchChangesHistoryRoutine({ id, query: search }))
      : changeQuery(defaultFilters)
  }, [search])

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const filters = dissoc('search', propOr({}, 'filter', query))
    setFiltersOpen(isNotNilOrEmpty(filters))
  }, [])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const toggleFilters = () => setFiltersOpen(prev => !prev)

  const headers = [
    { children: 'Data i godzina zmiany' },
    { children: 'Zmienione pole' },
    { children: 'Poprzednia wartość' },
    { children: 'Nowa wartość' },
    { children: 'Zmienione przez' }
  ]

  const rows = list.map(row => {
    const keyValues = keys(pathOr([], ['changes', 'old'], row))
    const displayedValue = value => {
      if (isNilOrEmpty(value)) {
        return '-'
      } else if (value === true) {
        return '✓'
      } else if (value === false) {
        return '✕'
      } else if (keys(INPOST_SIZE).includes(value)) {
        return INPOST_SIZE[value]
      } else return value
    }

    return {
      cells: [
        {
          children: formatDate(
            pathOr('-', ['createdAt'], row),
            DATE_FORMATS.dashedWithTime
          )
        },
        {
          children: keyValues.map(key => (
            <div key={key}>{PRODUCT_FEATURES[key]}</div>
          ))
        },
        {
          children: values(pathOr([], ['changes', 'old'], row)).map(
            oldValue => (
              <div key={oldValue}>{displayedValue(oldValue, 'old')}</div>
            )
          )
        },
        {
          children: values(pathOr([], ['changes', 'new'], row)).map(
            newValue => (
              <div key={newValue}>{displayedValue(newValue, 'new')}</div>
            )
          )
        },
        { children: pathOr('-', ['user', 'username'], row) }
      ]
    }
  })

  return (
    <>
      <TabFiltersWrapper>
        <FiltersButton active={filtersOpen} onClick={toggleFilters} />
      </TabFiltersWrapper>
      <ChangesHistoryFilters open={filtersOpen} />
      <Table
        headers={headers}
        rows={rows}
        pagination={pagination}
        onPageChange={handlePageChange}
        isLoading={isLoading}
      />
    </>
  )
}

export default ChangesHistoryTab
