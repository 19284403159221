import React, { useEffect } from 'react'
import { selectPendingImportJobsList } from 'modules/importJobsReport/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { pathOr, propOr } from 'ramda'
import { DATE_FORMATS, formatDate } from 'utils/date'
import Table from 'components/Table'
import { fetchPendingImportJobsRoutine } from 'modules/importJobsReport/ducks/actions'
import { IMPORT_TYPES } from 'utils/importTypes'

const PendingJobImportsTab = () => {
  const jobs = useSelector(selectPendingImportJobsList)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPendingImportJobsRoutine())
  }, [])

  const headers = [
    { children: 'Data' },
    { children: 'Typ' }
  ]

  const rows = jobs.map(row => ({
    cells: [
      { children: formatDate(propOr('', 'createdAt', row), DATE_FORMATS.dashedWithTime) },
      { children: IMPORT_TYPES[pathOr('undefined', ['payload', 'displayName'], row)] }
    ]
  }))

  return (
    <Table
      rows={rows}
      headers={headers}
    />
  )
}

export default PendingJobImportsTab
