import { validateField, validateValues } from 'utils/form'
import { object, string, array } from 'yup'

export const userSchema = object().shape({
  username: string().required('Login jest wymagany'),
  roles: array().min(1, 'Rola jest wymagana')
})

export const validateUserField = values => validateField(userSchema, values)
export const validateUserValues = validateValues(userSchema)
