import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import { validateUserField, validateUserValues } from 'modules/users/ducks/schema'
import { updateUserRoutine } from 'modules/users/ducks/actions'
import { selectUserRolesDictionary } from 'ducks/dictionaries/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { keys, propOr } from 'ramda'
import styled from 'styled-components'
import EditIcon from 'components/icons/EditIcon'
import Checkbox from 'components/atoms/Checkbox'
import { toSnakeCase } from 'js-convert-case'

const emptyValues = {
  username: '',
  roles: [],
  isActive: false
}

const EditUserModal = ({ user }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const userRolesDictionary = useSelector(selectUserRolesDictionary)

  useEffect(() => {
    open && setValues({
      username: propOr('', 'username', user),
      roles: propOr([], 'roles', user),
      isActive: propOr(false, 'isActive', user),
    })
  }, [user, open])

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const userRolesOptions = useMemo(() => {
    if (isNotNilOrEmpty(userRolesDictionary)) {
      const objectKeys = keys(userRolesDictionary)
      return objectKeys.map(key => ({
        value: toSnakeCase(key),
        label: userRolesDictionary[key]
      }))
    } else {
      return []
    }
  }, [userRolesDictionary])

  useEffect(() => {
    open && validateUserValues(values, setValid)
  }, [values, open])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(updateUserRoutine({
      values: {
        ...values,
        id: user.id,
        username: values.username.toLowerCase(),
      },
      callback: handleModalClose
    }))
  }

  return (
    <>
      <EditIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Edycja użytkownika'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='username'
            label='Login'
            value={values.username}
            onChange={handleValueChange}
            validate={validateUserField(values)}
          />
          <Input
            multiple
            select
            name='roles'
            label='Rola użytkownika'
            value={values.roles}
            onChange={handleValueChange}
            options={userRolesOptions}
            validate={validateUserField(values)}
          />
          <Checkbox
            label='Użytkownik aktywny'
            name='isActive'
            onChange={handleValueChange}
            value={values.isActive}
          />
          <ButtonWrapper>
            <Button
              type='submit'
              color='primary'
              disabled={!valid}
            >
              Zapisz
            </Button>
          </ButtonWrapper>
        </form>
      </Modal>
    </>
  )
}

export default EditUserModal

const ButtonWrapper = styled.div`
  margin-top: 30px;
`
