import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import DateInput from 'components/atoms/DateInput'
import Input from 'components/atoms/Input'
import { equals } from 'ramda'
import {
  validateFlightControlCourierField,
  validateFlightControlCourierValues
} from 'modules/flightControl/ducks/schema'

const CourierSettingsForm = ({ courier, onChange, onValidate, values }) => {
  const [lastValues, setLastValues] = useState(null)

  useEffect(() => {
    if (!equals(lastValues, values)) {
      setLastValues(values)
      onChange(values)
      validateFlightControlCourierValues(values, onValidate)
    }
  }, [values])

  const handleTimeChange = (name, value) => {
    onChange(name, moment(value).format('HH:mm'))
  }

  const timeInputValue = useMemo(() => {
    const dateString = `2000-01-01T${values.cutOffTime || '00:00'}`
    return new Date(dateString)
  }, [values.cutOffTime])

  return (
    <Wrapper>
      <CourierName color={courier?.color}>{courier.name}</CourierName>
      <DateInput
        label='Godzina odcięcia'
        name='cutOffTime'
        value={timeInputValue}
        onChange={handleTimeChange}
        timeOnly
        withPortal
      />
      <Input
        name='couriersPercentage'
        label='% kuriera do ilości zam.'
        value={values.couriersPercentage}
        onChange={onChange}
        validate={validateFlightControlCourierField(values)}
        autoComplete='off'
      />
      <Input
        name='singleSkuPercentage'
        label='% 1 SKU'
        value={values.singleSkuPercentage}
        onChange={onChange}
        validate={validateFlightControlCourierField(values)}
        autoComplete='off'
      />
      <Input
        name='multiSkuPercentage'
        label='% Multi SKU'
        value={values.multiSkuPercentage}
        onChange={onChange}
        validate={validateFlightControlCourierField(values)}
        autoComplete='off'
      />
    </Wrapper>
  )
}

export default CourierSettingsForm

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  
  & > * {
    flex: 1;
  }
`

const CourierName = styled.div`
  font-size: 18px;
  height: 60px;
  margin-bottom: 20px;
  font-weight: bold;
  min-width: 150px;
  color: #fff;
  background: ${({ color }) => color || '#666'};
  padding: 0 5px;
  line-height: 60px;
  border-radius: 4px;
`
