import React, { useEffect, useState } from 'react'
import AddButton from 'components/atoms/AddButton'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import { validatePackingTypeField, validatePackingTypeValues } from 'modules/packingTypes/ducks/schema'
import { createPackingTypeRoutine } from 'modules/packingTypes/ducks/actions'

const emptyValues = {
  name: ''
}

const AddPackingTypeModal = () => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validatePackingTypeValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(createPackingTypeRoutine({
      values,
      callback: handleModalClose
    }))
  }

  return (
    <>
      <AddButton onClick={handleModalOpen}>Dodaj opakowanie</AddButton>
      <Modal
        open={open}
        title='Dodaj nowy typ'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa opakowania'
            value={values.name}
            onChange={handleValueChange}
            validate={validatePackingTypeField(values)}
          />
          <Button
            type='submit'
            color='primary'
            disabled={!valid}
          >
            Dodaj
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default AddPackingTypeModal
