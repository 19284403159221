import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as couriersService from 'services/CouriersService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchCouriersListRoutine = createRoutine('FETCH_COURIERS')
export const fetchUngroupedCouriersListRoutine = createRoutine(
  'FETCH_UNGROUPED_COURIERS'
)
export const fetchCouriersGroupsListRoutine = createRoutine(
  'FETCH_COURIERS_GROUPS'
)
export const fetchSingleCourierGroupRoutine = createRoutine(
  'FETCH_SINGLE_COURIERS_GROUP'
)
export const clearSingleCourierGroupRoutine = createRoutine(
  'CLEAR_SINGLE_COURIERS_GROUP'
)
export const updateCourierRoutine = createRoutine('UPDATE_COURIER')

// ACTIONS
function * fetchCouriersList ({ payload }) {
  yield put(fetchCouriersListRoutine.request())
  try {
    const { data } = yield call(couriersService.fetchCouriers, payload)
    yield put(fetchCouriersListRoutine.success(data))
  } catch (e) {
    yield put(fetchCouriersListRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchUngroupedCouriersList () {
  yield put(fetchUngroupedCouriersListRoutine.request())
  try {
    const { data } = yield call(couriersService.fetchUngroupedCouriers)
    yield put(fetchUngroupedCouriersListRoutine.success(data))
  } catch (e) {
    yield put(fetchUngroupedCouriersListRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchCouriersGroupsList ({ payload }) {
  yield put(fetchCouriersGroupsListRoutine.request())
  try {
    const { data } = yield call(couriersService.fetchCouriersGroups, payload)
    yield put(fetchCouriersGroupsListRoutine.success(data))
  } catch (e) {
    yield put(fetchCouriersGroupsListRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchSingleCourierGroup ({ payload }) {
  yield put(fetchSingleCourierGroupRoutine.request())
  try {
    const { data } = yield call(
      couriersService.fetchSingleCourierGroup,
      payload
    )
    yield put(fetchSingleCourierGroupRoutine.success(data.data))
  } catch (e) {
    yield put(fetchSingleCourierGroupRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * clearSingleCourierGroup () {
  yield put(fetchSingleCourierGroupRoutine.success())
}

function * updateCourier ({ payload }) {
  const { values, callback } = payload
  yield put(updateCourierRoutine.request())
  try {
    const { data } = yield call(couriersService.updateCourier, values)
    yield put(updateCourierRoutine.success(data.data))
    typeof callback === 'function' && callback()
  } catch (e) {
    yield put(updateCourierRoutine.failure(e))
    toast.error(getApiErrors(e))
  }
}

//WATCHERS
export function * fetchCouriersListWatcher () {
  yield takeLatest(fetchCouriersListRoutine.TRIGGER, fetchCouriersList)
}

export function * fetchUngroupedCouriersListWatcher () {
  yield takeLatest(
    fetchUngroupedCouriersListRoutine.TRIGGER,
    fetchUngroupedCouriersList
  )
}

export function * fetchCouriersGroupsListWatcher () {
  yield takeLatest(
    fetchCouriersGroupsListRoutine.TRIGGER,
    fetchCouriersGroupsList
  )
}

export function * fetchSingleCourierGroupWatcher () {
  yield takeLatest(
    fetchSingleCourierGroupRoutine.TRIGGER,
    fetchSingleCourierGroup
  )
}

export function * clearSingleCourierGroupWatcher () {
  yield takeLatest(
    clearSingleCourierGroupRoutine.TRIGGER,
    clearSingleCourierGroup
  )
}

export function * updateCourierWatcher () {
  yield takeLatest(updateCourierRoutine.TRIGGER, updateCourier)
}

// SAGAS
export const couriersSagas = [
  fork(fetchCouriersListWatcher),
  fork(fetchUngroupedCouriersListWatcher),
  fork(fetchCouriersGroupsListWatcher),
  fork(fetchSingleCourierGroupWatcher),
  fork(clearSingleCourierGroupWatcher),
  fork(updateCourierWatcher)
]
