import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import BackButton from 'components/atoms/BackButton'
import PATHS from 'utils/paths'
import { fetchSingleZoneRoutine } from 'modules/zones/ducks/actions'
import ZoneBasicDetails from 'modules/zoneDetails/components/ZoneBasicDetails'

const ZoneDetails = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(fetchSingleZoneRoutine({ id }))
  }, [id])

  return (
    <>
      <BackButton
        withMargin
        path={PATHS.zones}
        label='Lista stref'
      />
      <ZoneBasicDetails />
    </>
  )
}

export default ZoneDetails
