import APIService from './APIService'

const api = APIService()

export const fetchStockUnits = payload => api.get(`/stock-units${payload.query}`)
export const fetchSingleStockUnit = payload => api.get(`/stock-units/${payload.id}`)
export const createStockUnit = payload => api.post('/stock-units', payload)
export const deactivateStockUnit = payload => api.patch(`/stock-units/${payload.id}/deactivate`)
export const getCsvCodes = payload => api.post('/stock-units/export/csv/codes', { codes: payload.codes })
export const fetchStockUnitHistory = payload => {
  return api.get(`/stock-units/${payload.id}/inventory-logs${payload.query}`)
}
export const fetchStockUnitCompletionsLists = payload => {
  return api.get(`/stock-units/${payload.code}/completion-list`)
}

export const createTestPackingData = () => {
  return api.post(`/multipicks/completion-lists/create-test-data`, { count: 10 })
}

export const fetchStockUnitKinds = () => api.get('/stock-unit-kinds')
