const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const convertBytes = x => {
  let l = 0, n = parseInt(x, 10) || 0
  while (n >= 1024 && ++l) {
    n = n/1024
  }

  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l])
}

export const downloadCSV = (csvStr, fileName) => {
  const el = document.createElement('a')
  el.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvStr)
  el.target = '_blank'
  el.download = `${fileName}.csv`
  el.click()
}
