import APIService from './APIService'

const api = APIService()

export const loginUser = payload => {
  const APIBareUrl = process.env.REACT_APP_API_URL.replace(/(\/api)$/, '')
  return api
    .get(`${APIBareUrl}/sanctum/csrf-cookie`, { baseURL: APIBareUrl })
    .then(() => api.post('/login', payload))
}

export const logoutUser = () => api.get('/logout')
export const fetchAuthUser = () => api.get('/me')
export const changeUserPassword = payload => api.patch('/me/password', payload)
export const switchUserRole = payload => api.patch('/me/switch-role', payload)
