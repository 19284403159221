import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as saleOrdersService from 'services/SaleOrdersService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchSaleOrdersRoutine = createRoutine('FETCH_SALE_ORDERS')
export const fetchSuspendedSaleOrdersRoutine = createRoutine('FETCH_SUSPENDED_SALE_ORDERS')
export const fetchSingleSaleOrderRoutine = createRoutine(
  'FETCH_SINGLE_SALE_ORDER'
)
export const fetchSaleOrdersStatisticsRoutine = createRoutine(
  'FETCH_SALE_ORDERS_STATISTICS'
)
export const fetchStatusLogsRoutine = createRoutine('FETCH_STATUS_LOGS')

// ACTIONS
function * fetchSaleOrders ({ payload }) {
  yield put(fetchSaleOrdersRoutine.request())
  try {
    const { data } = yield call(saleOrdersService.fetchSaleOrders, payload)
    yield put(fetchSaleOrdersRoutine.success(data))
  } catch (e) {
    yield put(fetchSaleOrdersRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchSuspendedSaleOrders ({ payload }) {
  yield put(fetchSuspendedSaleOrdersRoutine.request())
  try {
    const { data } = yield call(saleOrdersService.fetchSuspendedSaleOrders, payload)
    yield put(fetchSuspendedSaleOrdersRoutine.success(data))
  } catch (e) {
    yield put(fetchSuspendedSaleOrdersRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchSingleSaleOrder ({ payload }) {
  yield put(fetchSingleSaleOrderRoutine.request())
  try {
    const { data } = yield call(saleOrdersService.fetchSingleSaleOrder, payload)
    yield put(fetchSingleSaleOrderRoutine.success(data.data))
  } catch (e) {
    yield put(fetchSingleSaleOrderRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchSaleOrdersStatistics () {
  yield put(fetchSaleOrdersStatisticsRoutine.request())
  try {
    const { data } = yield call(saleOrdersService.getSaleOrdersStatistics)
    yield put(fetchSaleOrdersStatisticsRoutine.success(data.data))
  } catch (e) {
    yield put(fetchSaleOrdersStatisticsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchStatusLogs ({ payload }) {
  const { id, query } = payload
  yield put(fetchStatusLogsRoutine.request())
  try {
    const { data } = yield call(saleOrdersService.fetchStatusLogs, {
      id,
      query
    })
    yield put(fetchStatusLogsRoutine.success(data))
  } catch (e) {
    toast.error(getApiErrors(e))
    yield put(fetchStatusLogsRoutine.failure(e))
  }
}

// WATCHERS
export function * fetchSaleOrdersWatcher () {
  yield takeLatest(fetchSaleOrdersRoutine.TRIGGER, fetchSaleOrders)
}

export function * fetchSuspendedSaleOrdersWatcher () {
  yield takeLatest(fetchSuspendedSaleOrdersRoutine.TRIGGER, fetchSuspendedSaleOrders)
}

export function * fetchSingleSaleOrderWatcher () {
  yield takeLatest(fetchSingleSaleOrderRoutine.TRIGGER, fetchSingleSaleOrder)
}

export function * fetchSaleOrdersStatisticsWatcher () {
  yield takeLatest(
    fetchSaleOrdersStatisticsRoutine.TRIGGER,
    fetchSaleOrdersStatistics
  )
}
export function * fetchStatusLogsWatcher () {
  yield takeLatest(fetchStatusLogsRoutine.TRIGGER, fetchStatusLogs)
}

// SAGAS
export const saleOrdersSagas = [
  fork(fetchSaleOrdersWatcher),
  fork(fetchSuspendedSaleOrdersWatcher),
  fork(fetchSingleSaleOrderWatcher),
  fork(fetchSaleOrdersStatisticsWatcher),
  fork(fetchStatusLogsWatcher)
]
