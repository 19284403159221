import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as packingTypesService from 'services/PackingTypesService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'

export const fetchPackingTypesRoutine = createRoutine('FETCH_PACKING_TYPES')
export const createPackingTypeRoutine = createRoutine('CREATE_PACKING_TYPE')
export const editPackingTypeRoutine = createRoutine('EDIT_PACKING_TYPE')
export const removePackingTypeRoutine = createRoutine('REMOVE_PACKING_TYPE')

// ACTIONS
function * fetchPackingTypes ({ payload }) {
  yield put(fetchPackingTypesRoutine.request())
  try {
    const { data } = yield call(packingTypesService.fetchPackingTypes, payload)
    yield put(fetchPackingTypesRoutine.success(data))
  } catch (e) {
    yield put(fetchPackingTypesRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * createPackingType ({ payload }) {
  const { values, callback } = payload
  yield put(createPackingTypeRoutine.request())
  try {
    yield call(packingTypesService.createPackingType, values)
    yield put(createPackingTypeRoutine.success())
    yield put(fetchPackingTypesRoutine({
      query: stringifyQuery(getCurrentParsedQuery())
    }))
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie dodano typ opakowania')
  } catch (e) {
    yield put(createPackingTypeRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * editPackingType ({ payload }) {
  const { values, callback } = payload
  yield put(editPackingTypeRoutine.request())
  try {
    yield call(packingTypesService.updatePackingType, values)
    yield put(editPackingTypeRoutine.success())
    yield put(fetchPackingTypesRoutine({
      query: stringifyQuery(getCurrentParsedQuery())
    }))
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie zaktualizowano typ opakowania')
  } catch (e) {
    yield put(editPackingTypeRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * removePackingType ({ payload }) {
  const { id, callback } = payload
  yield put(removePackingTypeRoutine.request())
  try {
    yield call(packingTypesService.deletePackingType, { id })
    yield put(removePackingTypeRoutine.success())
    yield put(fetchPackingTypesRoutine({
      query: stringifyQuery(getCurrentParsedQuery())
    }))
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie usunięto typ opakowania')
  } catch (e) {
    yield put(removePackingTypeRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * fetchPackingTypesWatcher () {
  yield takeLatest(fetchPackingTypesRoutine.TRIGGER, fetchPackingTypes)
}

export function * createPackingTypeWatcher () {
  yield takeLatest(createPackingTypeRoutine.TRIGGER, createPackingType)
}

export function * editPackingTypeWatcher () {
  yield takeLatest(editPackingTypeRoutine.TRIGGER, editPackingType)
}

export function * removePackingTypeWatcher () {
  yield takeLatest(removePackingTypeRoutine.TRIGGER, removePackingType)
}

// SAGAS
export const packingTypesSagas = [
  fork(fetchPackingTypesWatcher),
  fork(createPackingTypeWatcher),
  fork(editPackingTypeWatcher),
  fork(removePackingTypeWatcher)
]
