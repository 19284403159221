import React from 'react'
import Modal from 'components/atoms/Modal'
import styled from 'styled-components'
import Button from 'components/atoms/Button'
import { ActionsContainer } from 'theme/wrappers'
import Table from 'components/Table'
import { propOr } from 'ramda'

const RemainingStockUnitsModal = ({
  open,
  setRemainingModalOpen,
  setRemaining,
  remaining
}) => {

  const handleFinish = () => {
    setRemaining([])
    setRemainingModalOpen(false)
  }

  const headers = [
    { children: 'JM' },
    { children: 'EAN' },
    { children: 'Nazwa' },
    { children: 'Ilość' }
  ]

  const rows = remaining.map(row => ({
    cells: [
      { children: propOr('---', 'stockUnitCode', row) },
      { children: propOr('---', 'productEan', row) },
      { children: propOr('---', 'productName', row) },
      { children: propOr('---', 'quantity', row) }
    ]
  }))

  return (
    <Modal
      open={open}
      title='W pojemnikach powinny znajdować się towary do odłożenia'
      hideClose
    >
      <Content>
        <Table headers={headers} rows={rows} />
        <ContentText>
          Zawołaj przełożonego i odłóż zabawki do rozniesienia
        </ContentText>
        <ActionsContainer>
          <Button onClick={handleFinish}>
            Już odłożyłem zabawki
          </Button>
        </ActionsContainer>
      </Content>
    </Modal>
  )
}

export default RemainingStockUnitsModal

const Content = styled.div`
  text-align: center;
  font-size: 24px;
  margin-top: 20px;

  span {
    font-weight: bold;
  }
`
const ContentText = styled.div`
  margin-bottom: 20px;
`
