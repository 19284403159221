import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as dictionariesService from 'services/DictionariesService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchUserRolesDictionaryRoutine = createRoutine('FETCH_USER_ROLES_DICTIONARY')
export const fetchZonesDictionaryRoutine = createRoutine('FETCH_ZONES_DICTIONARY')

// ACTIONS
function * fetchUserRolesDictionary () {
  yield put(fetchUserRolesDictionaryRoutine.request())
  try {
    const { data } = yield call(dictionariesService.fetchUserRoles)
    yield put(fetchUserRolesDictionaryRoutine.success(data.data))
  } catch (e) {
    yield put(fetchUserRolesDictionaryRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchZonesDictionary () {
  yield put(fetchZonesDictionaryRoutine.request())
  try {
    const { data } = yield call(dictionariesService.fetchZones)
    yield put(fetchZonesDictionaryRoutine.success(data.data))
  } catch (e) {
    yield put(fetchZonesDictionaryRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * fetchUserRolesDictionaryWatcher () {
  yield takeLatest(fetchUserRolesDictionaryRoutine.TRIGGER, fetchUserRolesDictionary)
}

export function * fetchZonesDictionaryWatcher () {
  yield takeLatest(fetchZonesDictionaryRoutine.TRIGGER, fetchZonesDictionary)
}

// SAGAS
export const dictionariesSagas = [
  fork(fetchUserRolesDictionaryWatcher),
  fork(fetchZonesDictionaryWatcher)
]
