import { fetchPackingTypesRoutine } from 'modules/packingTypes/ducks/actions'

const defaultState = {
  list: [],
  pagination: null,
  isLoading: false
}

const packingTypesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchPackingTypesRoutine.TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case fetchPackingTypesRoutine.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case fetchPackingTypesRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoading: false
      }
    default:
      return state
  }
}

export default packingTypesReducer
