import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import Checkbox from 'components/atoms/Checkbox'
import styled from 'styled-components'
import {
  fetchSingleProductRoutine,
  updateProductsFromIAIRoutine,
  updateSingleProductFromIAIRoutine
} from 'modules/products/ducks/actions'
import ImportButton from 'components/atoms/ImportButton'
import ButtonWrapper from 'theme/wrappers'

const ImportProductsFromIAIModal = ({ id }) => {
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [updateAllRecords, setUpdateAllRecords] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setConfirm(false)
    setLoading(false)
  }

  const handleSuccess = () => {
    handleModalClose()
    id && dispatch(fetchSingleProductRoutine({ id }))
  }

  const handleConfirm = () => {
    setConfirm(true)
  }

  const handleValueChange = () => {
    setUpdateAllRecords(prev => !prev)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    id
      ? dispatch(
          updateSingleProductFromIAIRoutine({ id, callback: handleSuccess })
        )
      : dispatch(
          updateProductsFromIAIRoutine({
            updateAllRecords,
            callback: handleSuccess
          })
        )
  }

  return (
    <>
      <ImportButton onClick={handleModalOpen}>Importuj dane z IAI</ImportButton>
      <Modal
        open={open}
        title='Aktualizuj dane na podstawie IAI'
        onClose={handleModalClose}
      >
        {id && (
          <ButtonWrapper>
            <Button onClick={handleModalClose} color='primary-outline'>
              Anuluj
            </Button>
            <Button
              type='submit'
              color='primary'
              onClick={handleSubmit}
              disabled={loading}
              isLoading={loading}
            >
              Aktualizuj
            </Button>
          </ButtonWrapper>
        )}

        {!id &&
          (!confirm ? (
            <>
              <div>Czy na pewno chcesz zaktualizować dane?</div>
              <ButtonWrapper>
                <Button onClick={handleModalClose} color='primary-outline'>
                  Anuluj
                </Button>
                <Button onClick={handleConfirm} color='primary'>
                  Potwierdź
                </Button>
              </ButtonWrapper>
            </>
          ) : (
            <>
              <CheckboxWrapper>
                <Checkbox
                  label='Tylko nowe produkty'
                  name='updateNewOnly'
                  value={!updateAllRecords}
                  onChange={handleValueChange}
                />
                <Checkbox
                  label='Wszystkie produkty'
                  name='updateAllRecords'
                  value={updateAllRecords}
                  onChange={handleValueChange}
                />
              </CheckboxWrapper>
              <Button
                type='submit'
                color='primary'
                onClick={handleSubmit}
                disabled={loading}
                isLoading={loading}
              >
                Aktualizuj
              </Button>
            </>
          ))}
      </Modal>
    </>
  )
}

export default ImportProductsFromIAIModal

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
`
