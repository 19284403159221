import APIService from './APIService'
import { omit } from 'ramda'

const api = APIService()

export const getPossiblePackageSizesForOrder = payload => api.get(`/sale-orders/${payload.id}/possible-package-sizes`)
export const packItem = payload =>
  api.patch(`/stock-units/${payload.unitNumber}/products/${payload.ean}/pack`, omit(['ean', 'unitNumber'], payload) )
export const assignPackageToOrder = payload =>
  api.patch(`/sale-orders/${payload.id}/package-size`, { packageSize: payload.packageSize })
export const resetPackingOrder = payload => api.patch(`/sale-orders/${payload.id}/cancel-packing`)
export const finalizePackingOrder = payload => api.patch(`/stock-units/${payload.code}/finalize-packing`)
// dev-only
export const prepareTestSingleSku = () => api.post('/dev/completion-lists/prepare-single-sku-list-for-packing')
export const prepareTestMultiSku = () =>
  api.post('/dev/completion-lists/prepare-multi-sku-list-for-packing')
export const prepareTestMultiSkuInpostSize = () =>
  api.post('/dev/completion-lists/prepare-multi-sku-list-for-packing', { courier_group: 'Inpost - wybór paczkomatu' })
export const prepareCompletionListWithRemains = () => api.post('/dev/completion-lists/prepare-multi-sku-list-for-packing-with-remains')
export const prepareMultiSkuListWithoutOrders = () => api.post('dev/completion-lists/prepare-multi-sku-list-for-packing-without-orders')
