export const INPOST_SIZE = {
  small: 'Gabaryt A',
  medium: 'Gabaryt B',
  large: 'Gabaryt C',
  none: 'Brak'
}

export const PRODUCT_FEATURES = {
  weight: 'Waga',
  weightUnit: 'Jednostka wagi',
  height: 'Wysokość',
  width: 'Szerokość',
  depth: 'Głębokość',
  dimensionsUnit: 'Jednostka wymiarów',
  inpostSize: 'Rozmiar paczkomatu',
  isCumbersome: 'Nieporęczny',
  isPrepacked: 'Prepak',
  packagingType: 'Rodzaj opakowania',
  stockUnitKind: 'Rodzaj jednostki magazynowej',
  comment: 'Komentarz',
  ean: 'EAN'
}
