import React from 'react'
import styled from 'styled-components'

const PageNotFound = () => {
  return (
    <Wrapper>
      <Header>404</Header>
      <SubHeader>Ta strona nie istnieje</SubHeader>
    </Wrapper>
  )
}

export default PageNotFound

const Wrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Header = styled.div`
  font-size: 80px;
  font-weight: bold;
`

const SubHeader = styled.div`
  font-size: 42px;
`
