import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import { useDispatch } from 'react-redux'
import Button from 'components/atoms/Button'
import RemoveIcon from 'components/icons/RemoveIcon'
import { deleteFile } from 'services/FileService'
import { fetchSingleProductRoutine } from 'modules/products/ducks/actions'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import ButtonWrapper from 'theme/wrappers'

const RemoveProductPhotoModal = ({ file }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    const handleSuccess = () => {
      dispatch(fetchSingleProductRoutine({ id: file.modelId }))
      handleModalClose()
    }

    const handleError = error => {
      toast.error(getApiErrors(error))
    }

    e.preventDefault()
    deleteFile({ id: file.id })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <RemoveIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Usuń zdjęcie'
        onClose={handleModalClose}
      >
        Czy na pewno chcesz usunąć to zdjęcie?
        <ButtonWrapper>
          <Button
            onClick={handleModalClose}
            color='primary-outline'
          >
            Anuluj
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
          >
            Usuń
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default RemoveProductPhotoModal
