import React, { useEffect, useState } from 'react'
import AddButton from 'components/atoms/AddButton'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import {
  validateCreateDeliveryField,
  validateCreateDeliveryValues
} from 'modules/delivery/ducks/schema'
import { createDeliveryRoutine } from 'modules/delivery/ducks/actions'
import { DATE_FORMATS, formatDate } from 'utils/date'
import SuppliersAutocomplete from 'modules/deliveryDetails/components/SuppliersAutocomplete'

const emptyValues = {
  scheduledFor: formatDate(new Date(), DATE_FORMATS.input),
  supplierId: '',
  priority: '',
  comment: '',
  supplier: {}
}

const AddDeliveryModal = () => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateCreateDeliveryValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(
      createDeliveryRoutine({
        ...values,
        callback: handleModalClose
      })
    )
  }

  return (
    <>
      <AddButton onClick={handleModalOpen}>Dodaj dostawę</AddButton>
      <Modal open={open} title='Dodaj nową dostawę' onClose={handleModalClose}>
        <form onSubmit={handleSubmit}>
          <Input
            type='date'
            name='scheduledFor'
            label='Data'
            value={values.scheduledFor}
            onChange={handleValueChange}
            validate={validateCreateDeliveryField(values)}
          />
          <SuppliersAutocomplete
            supplier={values.supplier}
            onHandleValuesChange={handleValueChange}
            onValidate={validateCreateDeliveryField(values)}
          />
          <Input
            select
            options={[
              { label: 'Niski', value: 'low' },
              { label: 'Średni', value: 'medium' },
              { label: 'Wysoki', value: 'high' }
            ]}
            name='priority'
            label='Priorytet'
            value={values.priority}
            onChange={handleValueChange}
            validate={validateCreateDeliveryField(values)}
          />
          <Input
            name='comment'
            multiline
            rows={2}
            label='Komentarz'
            value={values.comment}
            onChange={handleValueChange}
            validate={validateCreateDeliveryField(values)}
          />
          <Button type='submit' color='primary' disabled={!valid}>
            Dodaj
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default AddDeliveryModal
