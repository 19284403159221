import {
  fetchCompletionListsForStockUnitRoutine,
  clearCurrentPackingOrderRoutine,
  setCurrentPackingOrderRoutine,
  clearCurrentStockUnitOrdersRoutine,
  setPackedItemQuantityRoutine,
  setPackedItemEanRoutine
} from 'modules/packing/ducks/actions'
import { pathOr } from 'ramda'

const defaultState = {
  currentUnitOrders: [],
  currentPackingOrder: null,
  currentUnitNumber: null,
  currentUnitParentNumber: null,
  currentUnitCompletionList: null,
  packedItemQuantity: 1,
  packedItemEan: ''
}

const packingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchCompletionListsForStockUnitRoutine.SUCCESS:
      return {
        ...state,
        currentUnitOrders: action.payload.data.orders,
        currentUnitNumber: action.payload.code,
        currentUnitParentNumber: pathOr(null, ['payload', 'data', 'parentStockUnit', 'code'], action),
        currentUnitCompletionList: {
          listNumber: action.payload.data.listNumber,
          name: action.payload.data.name,
          stockUnits: action.payload.data.stockUnits
        }
      }
    case fetchCompletionListsForStockUnitRoutine.FAILURE:
      return {
        ...state,
        currentUnitOrders: [],
        currentUnitNumber: null,
        currentUnitParentNumber: null,
        currentUnitCompletionList: null
      }
    case setCurrentPackingOrderRoutine.SUCCESS:
      return {
        ...state,
        currentPackingOrder: action.payload
      }
    case clearCurrentPackingOrderRoutine.SUCCESS:
      return {
        ...state,
        currentPackingOrder: null
      }
    case setPackedItemQuantityRoutine.SUCCESS:
      return {
        ...state,
        packedItemQuantity: action.payload
      }
    case setPackedItemEanRoutine.SUCCESS:
      return {
        ...state,
        packedItemEan: action.payload
      }
    case clearCurrentStockUnitOrdersRoutine.SUCCESS:
      return {
        ...state,
        currentUnitOrders: [],
        currentUnitNumber: null,
        currentUnitParentNumber: null,
        currentUnitCompletionList: null
      }
    default:
      return state
  }
}

export default packingReducer
