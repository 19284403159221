import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { dissoc, propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import { INVENTORY_STATUSES } from 'utils/inventory'
import {
  validateLocationHistoryFiltersField,
  validateLocationHistoryFiltersValues
} from 'modules/locations/ducks/schema'
import { ButtonsWrapper } from 'theme/wrappers'

const emptyValues = {
  created_before: '',
  created_after: '',
  product_ean: '',
  location_from: '',
  location_to: '',
  quantity: '',
  type: 'all'
}

const LocationHistoryFilters = ({ open }) => {
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const {
    location: { search }
  } = useHistory()

  const resetFilters = () => {
    setValues(emptyValues)
    changeQuery({
      page: 1,
      sort: 'type',
      activeTab: 'history_of_changes'
    })
  }

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    setValues({
      ...emptyValues,
      ...propOr({}, 'filter', currentQuery)
    })
  }, [search])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const typeOptions = useMemo(() => {
    let items = [{ label: 'Wszystkie', value: 'all' }]
    for (let key in INVENTORY_STATUSES) {
      items = [...items, { label: INVENTORY_STATUSES[key], value: key }]
    }
    return items
  }, [])

  const handleSubmit = () => {
    const currentQuery = getCurrentParsedQuery()
    let payload = {}

    for (let key in values) {
      if (isNotNilOrEmpty(values[key]) && values[key] !== 'all') {
        payload[key] = values[key]
      } else {
        payload = dissoc(key, payload)
      }
    }

    changeQuery({
      ...currentQuery,
      page: 1,
      filter: payload
    })
  }

  useEffect(() => {
    validateLocationHistoryFiltersValues(values, setValid)
  }, [values])

  return (
    <Wrapper open={open}>
      <Input
        type='date'
        value={values.created_after}
        name='created_after'
        label='Data od'
        onChange={handleValueChange}
        validate={validateLocationHistoryFiltersField(values)}
        noMarginBottom
      />
      <Input
        type='date'
        value={values.created_before}
        name='created_before'
        label='Data do'
        onChange={handleValueChange}
        validate={validateLocationHistoryFiltersField(values)}
        noMarginBottom
      />
      <Input
        clearable
        value={values.product_ean}
        name='product_ean'
        label='Kod produktu'
        onChange={handleValueChange}
        validate={validateLocationHistoryFiltersField(values)}
        noMarginBottom
      />
      <Input
        clearable
        value={values.location_from}
        name='location_from'
        label='Lokalizacja źródłowa'
        onChange={handleValueChange}
        validate={validateLocationHistoryFiltersField(values)}
        noMarginBottom
      />
      <Input
        clearable
        value={values.location_to}
        name='location_to'
        label='Lokalizacja docelowa'
        onChange={handleValueChange}
        validate={validateLocationHistoryFiltersField(values)}
        noMarginBottom
      />
      <Input
        clearable
        value={values.quantity}
        name='quantity'
        label='Ilość'
        onChange={handleValueChange}
        validate={validateLocationHistoryFiltersField(values)}
        noMarginBottom
      />
      <Input
        select
        options={typeOptions || []}
        value={values.type}
        name='type'
        label='Typ operacji'
        onChange={handleValueChange}
        validate={validateLocationHistoryFiltersField(values)}
        noMarginBottom
      />
      <ButtonsWrapper>
        <Button small onClick={handleSubmit} color='primary' disabled={!valid}>
          Zastosuj
        </Button>
        <Button small onClick={resetFilters} color='primary-outline'>
          Resetuj
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default LocationHistoryFilters

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  width: 100%;
  overflow: hidden;
  height: 0;
  padding: 0 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 15px;

  ${({ open }) =>
    open &&
    css`
      padding: 20px;
      margin-bottom: 20px;
      height: 110px;
    `}
`
