import React, { useEffect, useMemo } from 'react'
import ContentPanel from 'components/atoms/ContentPanel'
import PageHeader from 'components/atoms/PageHeader'
import { HeaderWrapper } from 'theme/wrappers'
import { useDispatch, useSelector } from 'react-redux'
import { selectSettingsList } from 'modules/settings/ducks/selectors'
import { fetchSettingsRoutine } from 'modules/settings/ducks/actions'
import { find, propEq, propOr } from 'ramda'
import StocktakingOrdersCountSetting from 'modules/settings/components/StocktakingOrdersCountSetting'
import styled from 'styled-components'

const Settings = () => {
  const dispatch = useDispatch()
  const settings = useSelector(selectSettingsList)
  const setting = useMemo(() => {
    return find(propEq('name', 'inventory_goods_to_be_counted_amount'))(
      settings
    )
  }, [settings])

  useEffect(() => {
    dispatch(fetchSettingsRoutine())
  }, [])

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Ustawienia</PageHeader>
      </HeaderWrapper>
      <ContentPanel>
        <SettingWrapper>
          <Header>Inwentaryzacja:</Header>
          <StocktakingOrdersCountSetting
            id={propOr('', 'id', setting)}
            value={propOr(0, 'value', setting)}
          />
        </SettingWrapper>
      </ContentPanel>
    </>
  )
}

export default Settings

const SettingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Header = styled.div`
  font-size: 18px;
`
