import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchSingleLocationRoutine } from 'modules/locations/ducks/actions'
import { useParams } from 'react-router-dom'
import BasicLocationDetails from 'modules/locationDetails/components/BasicLocationDetails'
import LocationDetailsTabs from 'modules/locationDetails/components/LocationDetailsTabs'
import BackButton from 'components/atoms/BackButton'
import PATHS from 'utils/paths'
import styled from 'styled-components'

const LocationDetails = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(fetchSingleLocationRoutine({ id }))
  }, [id])

  return (
    <>
      <HeaderWrapper>
        <BackButton
          path={PATHS.locations}
          label='Lista lokalizacji'
          withMargin
        />
      </HeaderWrapper>
      <BasicLocationDetails />
      <LocationDetailsTabs />
    </>
  )
}

export default LocationDetails

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
