import React, { useEffect, useMemo, useState } from 'react'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import styled from 'styled-components'
import Modal from 'components/atoms/Modal'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFlightControlSettings,
  selectFlightControlSettingsLoading
} from 'modules/flightControl/ducks/selectors'
import { fetchFlightControlSettingsRoutine } from 'modules/flightControl/ducks/actions'
import Input from 'components/atoms/Input'
import DateInput from 'components/atoms/DateInput'
import moment from 'moment'
import Button from 'components/atoms/Button'
import { keys, propOr } from 'ramda'
import CourierSettingsForm from 'modules/flightControl/components/CourierSettingsForm'
import { saveFlightControlSettings } from 'services/FlightControlService'
import { getApiErrors } from 'utils/errors'
import toast from 'react-hot-toast'
import {
  validateFlightControlField,
  validateFlightControlValues
} from 'modules/flightControl/ducks/schema'
import LoadingSpinner from 'components/atoms/LoadingSpinner'

const defaultValues = {
  flightControlEstimate: 0,
  flightControlProductsMultiplier: 1,
  flightControlEndOfPacking: '00:00',
  couriers: {}
}

const FlightControlSettings = ({
  onRefetch,
  settingsOpen,
  setSettingsOpen
}) => {
  const currentSettings = useSelector(selectFlightControlSettings)
  const isLoading = useSelector(selectFlightControlSettingsLoading)
  const [values, setValues] = useState(defaultValues)
  const [validForms, setValidForms] = useState({})
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()

  const areCouriersValid = useMemo(() => {
    return Object.values(validForms).every(v => v)
  }, [validForms])

  useEffect(() => {
    validateFlightControlValues(values, setValid)
  }, [values])

  const couriers = useMemo(() => {
    const couriersSettings = propOr({}, 'couriers', currentSettings)
    const couriersIds = keys(couriersSettings)
    return couriersIds.map(id => ({
      id,
      ...couriersSettings[id]
    }))
  }, [currentSettings])

  const handleValidateForm = courierId => isValid => {
    setValidForms(prev => ({
      ...prev,
      [courierId]: isValid
    }))
  }

  const handleCourierValuesChange = courierId => (name, value) => {
    setValues(prev => ({
      ...prev,
      couriers: {
        ...prev.couriers,
        [courierId]: {
          ...prev.couriers[courierId],
          [name]: value
        }
      }
    }))
  }

  useEffect(() => {
    dispatch(fetchFlightControlSettingsRoutine())
  }, [])

  useEffect(() => {
    const tempCouriers = propOr({}, 'couriers', currentSettings)
    const courierKeys = keys(tempCouriers)
    const newCouriers = {}

    courierKeys.forEach(c => {
      newCouriers[c] = {
        ...tempCouriers[c],
        couriersPercentage: propOr(0, 'couriersPercentage', tempCouriers[c]),
        multiSkuPercentage: propOr(0, 'multiSkuPercentage', tempCouriers[c]),
        singleSkuPercentage: propOr(0, 'singleSkuPercentage', tempCouriers[c]),
        cutOffTime: propOr('16:00', 'cutOffTime', tempCouriers[c])
      }
    })

    setValues({
      ...currentSettings,
      couriers: newCouriers
    })
  }, [currentSettings])

  const handleOpen = () => setSettingsOpen(true)
  const handleClose = () => setSettingsOpen(false)

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleTimeChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: moment(value).format('HH:mm') }))
  }

  const timeInputValue = useMemo(() => {
    const dateString = `2000-01-01T${
      values.flightControlEndOfPacking || '00:00'
    }`
    return new Date(dateString)
  }, [values.flightControlEndOfPacking])

  const handleReset = () => {
    setValues(currentSettings)
  }

  const handleSaveChanges = () => {
    saveFlightControlSettings(values)
      .then(() => {
        onRefetch()
        toast.success('Zapisano zmiany')
        handleClose()
      })
      .catch(err => {
        toast.error(getApiErrors(err))
        onRefetch()
      })
  }

  return (
    <>
      <OptionsButton onClick={handleOpen}>
        <SettingsOutlinedIcon />
      </OptionsButton>
      <Modal open={settingsOpen} onClose={handleClose} title='Ustawienia'>
        <ModalContent>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <MainSettingsWrapper>
                <Input
                  name='flightControlEstimate'
                  label='Estymata'
                  onChange={handleValueChange}
                  value={values.flightControlEstimate}
                  validate={validateFlightControlField(values)}
                />
                <Input
                  name='flightControlProductsMultiplier'
                  label='Średnia ilość towarów w 1 zamówieniu (mnożnik)'
                  onChange={handleValueChange}
                  value={values.flightControlProductsMultiplier}
                  validate={validateFlightControlField(values)}
                />
              </MainSettingsWrapper>
              {couriers &&
                values.couriers &&
                couriers.map(courier => (
                  <CourierSettingsForm
                    key={courier.id}
                    courier={courier}
                    values={values?.couriers[courier.id]}
                    onChange={handleCourierValuesChange(courier.id)}
                    onValidate={handleValidateForm(courier.id)}
                  />
                ))}
              <ActionsWrapper>
                <DateInput
                  label='Koniec pakowania'
                  name='flightControlEndOfPacking'
                  value={timeInputValue}
                  onChange={handleTimeChange}
                  timeOnly
                  withPortal
                />
                <Button onClick={handleReset}>Resetuj</Button>
                <Button
                  onClick={handleSaveChanges}
                  disabled={!valid || !areCouriersValid}
                >
                  Zapisz
                </Button>
              </ActionsWrapper>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default FlightControlSettings

const OptionsButton = styled.div`
  background: #dc4b19;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 80px;
  cursor: pointer;
  border-radius: 6px;
`

const MainSettingsWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`

const ActionsWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 30px;
  input {
    width: 200px;
  }
`

const ModalContent = styled.div`
  width: 1000px;
`
