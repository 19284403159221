import axios from 'axios'
import { toast } from 'react-hot-toast'
import { EventEmitter } from 'events'

export const packingErrorEmitter = new EventEmitter()

export const getPackingColor = status => {
  switch (status) {
    case 'cancelled':
    case 'skipped':
      return '#000'
    case 'packed':
      return '#00b300'
    case 'partially_packed':
      return '#e6b800'
    case 'suspended':
    case 'sent':
      return '#FF4747'
    default:
      return '#666666'
  }
}

export const getPackingProductColor = packingItem => {
  const { packedQuantity, orderedQuantity } = packingItem
  switch (true) {
    case packedQuantity === 0:
      return '#666666'
    case packedQuantity >= orderedQuantity:
      return '#00b300'
    case packedQuantity !== orderedQuantity:
      return '#e6b800'
    default:
      return '#666666'
  }
}

// export const printLabel = ({ orderId }) => new Promise(resolve => {
//   return axios.get(`${process.env.REACT_APP_API_URL}/sale-orders/${orderId}/courier-label`, {
//     responseType: 'blob',
//     withCredentials: true
//   })
//     .then(resp => {
//       const file = resp.data
//       const fd = new FormData()
//       fd.append('file', file)
//
//       const res = Array.from(fd.entries(), ([key, prop]) => (
//         {
//           [key]: {
//             "ContentLength":
//               typeof prop === "string"
//                 ? prop.length
//                 : prop.size
//           }
//         }))
//
//       return axios({
//         method: 'POST',
//         data: fd,
//         url: 'http://localhost:3008/print',
//         headers: {
//           "Content-Type": "multipart/form-data",
//           "Content-Length": res[0].file.ContentLength
//         }
//       })
//         .then(() => {
//           resolve()
//         })
//         .catch(() => {
//           toast.error('Nie udało się połączyć z drukarką. Czy na pewno uruchomiłeś program do komunikacji?', {
//             duration: 5000,
//             style: {
//               fontSize: 22
//             }
//           })
//           resolve()
//         })
//     })
// })

export const printLabel = ({ orderId }) =>
  new Promise((resolve, reject) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/sale-orders/${orderId}/courier-label`,
        {
          responseType: 'blob',
          withCredentials: true
        }
      )
      .then(resp => {
        const file = resp.data
        const fd = new FormData()
        fd.append('file', file)

        const res = Array.from(fd.entries(), ([key, prop]) => ({
          [key]: {
            ContentLength: typeof prop === 'string' ? prop.length : prop.size
          }
        }))

        return axios({
          method: 'POST',
          data: fd,
          url: 'http://localhost:3008/print',
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Length': res[0].file.ContentLength
          }
        })
          .then(() => {
            resolve()
          })
          .catch(() => {
            packingErrorEmitter.emit(
              'error',
              'Nie udało się połączyć z drukarką. Czy na pewno uruchomiłeś program do komunikacji?'
            )
            reject(new Error('Błąd połączenia z drukarką'))
          })
      })
      .catch(() => {
        toast.loading(
          'Nie udało się pobrać etykiety, ponowna próba nastąpi za 5 sekund',
          {
            duration: 5000,
            style: {
              fontSize: 22
            }
          }
        )
        setTimeout(() => {
          return axios
            .get(
              `${process.env.REACT_APP_API_URL}/sale-orders/${orderId}/courier-label?force=1`,
              {
                responseType: 'blob',
                withCredentials: true
              }
            )
            .then(resp => {
              const file = resp.data
              const fd = new FormData()
              fd.append('file', file)

              const res = Array.from(fd.entries(), ([key, prop]) => ({
                [key]: {
                  ContentLength:
                    typeof prop === 'string' ? prop.length : prop.size
                }
              }))

              return axios({
                method: 'POST',
                data: fd,
                url: 'http://localhost:3008/print',
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Content-Length': res[0].file.ContentLength
                }
              })
                .then(() => {
                  resolve()
                })
                .catch(() => {
                  packingErrorEmitter.emit(
                    'error',
                    'Nie udało się połączyć z drukarką. Czy na pewno uruchomiłeś program do komunikacji?'
                  )
                  reject(new Error('Błąd połączenia z drukarką'))
                })
            })
        }, 5000)
      })
  })

export const printTechnicalLabel = ({ orderId, count }) =>
  new Promise(resolve => {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/sale-orders/${orderId}/technical-label?pages=${count}`,
        {
          responseType: 'blob',
          withCredentials: true
        }
      )
      .then(resp => {
        const file = resp.data
        const fd = new FormData()
        fd.append('file', file)

        const res = Array.from(fd.entries(), ([key, prop]) => ({
          [key]: {
            ContentLength: typeof prop === 'string' ? prop.length : prop.size
          }
        }))

        return axios({
          method: 'POST',
          data: fd,
          url: 'http://localhost:3008/print',
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Length': res[0].file.ContentLength
          }
        })
          .then(() => {
            resolve()
          })
          .catch(() => {
            packingErrorEmitter.emit(
              'error',
              'Nie udało się połączyć z drukarką. Czy na pewno uruchomiłeś program do komunikacji?'
            )
            resolve()
          })
      })
  })

export const downloadTechnicalLabel = ({ orderId, count }) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/sale-orders/${orderId}/technical-label?pages=${count}`,
      {
        responseType: 'blob',
        withCredentials: true
      }
    )
    .then(resp => {
      const file = resp.data
      const url = window.URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.download = 'etykieta.pdf'
      link.dispatchEvent(new MouseEvent('click'))
    })
}
