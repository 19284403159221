import APIService from './APIService'

const api = APIService()

export const fetchFlightControlSummary = () => api.get('/flight-control/summary')
export const fetchFlightControlSaleOrders = () => api.get('/flight-control/sale-orders-all')
export const fetchFlightControlSaleOrdersByStatus = payload => api.get(`/flight-control/sale-orders/${payload.status}`)
export const fetchFlightControlStatsByRole = payload => api.get(`/flight-control/stats/${payload.role}`)
export const fetchFlightControlSettings = () => api.get(`/flight-control/settings`)
export const saveFlightControlSettings = payload => api.patch(`/flight-control/settings`, payload)
