import React, { useEffect, useMemo, useState } from 'react'
import AddButton from 'components/atoms/AddButton'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import { validateCreateLocationField, validateCreateLocationValues } from 'modules/locations/ducks/schema'
import { createLocationRoutine } from 'modules/locations/ducks/actions'
import { selectWarehousesList } from 'modules/warehouses/ducks/selectors'
import { selectZonesList } from 'modules/zones/ducks/selectors'

const emptyValues = {
  name: '',
  weightLimit: 0,
  weightLimitUnit: 'g',
  warehouseId: '',
  zoneId: '',
  sortOrder: ''
}

const AddLocationModal = () => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const warehouses = useSelector(selectWarehousesList)
  const zones = useSelector(selectZonesList)

  const warehousesOptions = useMemo(() => {
    return warehouses.map(warehouse => ({
      label: warehouse.name,
      value: warehouse.id
    }))
  }, [warehouses])

  const zonesOptions = useMemo(() => {
    return zones.map(zone => ({
      label: zone.name,
      value: zone.id
    }))
  }, [zones])

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateCreateLocationValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(createLocationRoutine({
      values,
      callback: handleModalClose
    }))
  }

  return (
    <>
      <AddButton onClick={handleModalOpen}>Dodaj lokalizację</AddButton>
      <Modal
        open={open}
        title='Dodaj nową lokalizację'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa lokalizacji'
            value={values.name}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Input
            name='weightLimit'
            label='Limit wagi'
            value={values.weightLimit}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Input
            select
            options={[
              { label: 'g', value: 'g' },
              { label: 'kg', value: 'kg' },
            ]}
            name='weightLimitUnit'
            label='Jednostka limitu wagi'
            value={values.weightLimitUnit}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Input
            select
            options={warehousesOptions}
            name='warehouseId'
            label='Magazyn'
            value={values.warehouseId}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Input
            select
            options={zonesOptions}
            name='zoneId'
            label='Strefa'
            value={values.zoneId}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Input
            name='sortOrder'
            label='Kolejność'
            value={values.sortOrder}
            onChange={handleValueChange}
            validate={validateCreateLocationField(values)}
          />
          <Button
            type='submit'
            color='primary'
            disabled={!valid}
          >
            Dodaj
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default AddLocationModal
