export const SALE_ORDERS_STATUSES = {
  new: 'Nowe',
  allocated: 'Zaalokowane',
  unallocated: 'Brak alokacji',
  suspended: 'Wstrzymane',
  enlisted: 'Zgrupowane',
  partially_collected: 'Częściowo zebrane',
  collected: 'Zebrane',
  combining: 'Łączenie',
  for_packing: 'Do pakowania',
  partially_packed: 'Częściowo spakowane',
  packed: 'Spakowane',
  sent: 'Wysłane',
  cancelled: 'Anulowane',
  all: 'Wszystkie'
}

export const SALE_ORDER_ITEM_STATUSES = {
  ordered: 'Zamówiony',
  allocated: 'Zaalokowany',
  unallocated: 'Brak alokacji',
  collected: 'Zebrany',
  packed: 'Spakowany',
  undefined: '---'
}

export const getStatusColor = status => {
  switch (status) {
    case 'allocated':
      return '#cc6599'
    case 'unallocated':
    case 'skipped':
      return '#d15b47'
    case 'partially_collected':
    case 'processing':
      return '#ffb751'
    case 'collected':
      return '#cd833f'
    case 'for_packing':
      return '#65cc99'
    case 'partially_packed':
      return '#4DB086'
    case 'packed':
      return '#349372'
    case 'sent':
    case 'finished':
      return '#34cc33'
    case 'suspended':
      return '#af855d'
    case 'cancelled':
      return '#929292'
    case 'combining':
      return '#46AFB7'
    case 'enlisted':
      return '#e68e75'
    case 'pending':
    default:
      return '#2D97D0'
  }
}
