import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectDeliveries = state => state.deliveries

export const selectDeliveriesList = createSelector(
  selectDeliveries,
  propOr([], 'list')
)

export const selectIsLoading = createSelector(
  selectDeliveries,
  propOr(false, 'isLoading')
)

export const selectDefaultFilteredList = createSelector(
  selectDeliveries,
  propOr([], 'defaultFilteredList')
)

export const selectDefaultFilteredListLoading = createSelector(
  selectDeliveries,
  propOr(false, 'isLoadingFilteredList')
)

export const selectCurrentDelivery = createSelector(
  selectDeliveries,
  propOr([], 'currentDelivery')
)

export const selectCurrentDeliveryLoading = createSelector(
  selectDeliveries,
  propOr(false, 'isLoadingCurrentDelivery')
)

export const selectDeliveriesListPagination = createSelector(
  selectDeliveries,
  propOr([], 'pagination')
)

export const selectDefaultFilteredListPagination= createSelector(
  selectDeliveries,
  propOr([], 'defaultFilteredListPagination')
)

export const selectDeliveryItems = createSelector(
  selectDeliveries,
  propOr([], 'deliveryItems')
)

export const selectDeliveryItemsLoading = createSelector(
  selectDeliveries,
  propOr(false, 'isLoadingItems')
)

export const selectDeliveryItemsPagination = createSelector(
  selectDeliveries,
  propOr([], 'deliveryItemsPagination')
)
