import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as deliveriesService from 'services/DeliveryService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { dissoc } from 'ramda'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'

export const createDeliveryRoutine = createRoutine('CREATE_DELIVERY')
export const fetchDeliveriesListRoutine = createRoutine('FETCH_DELIVERIES_LIST')
export const fetchDefaultFilteredDeliveriesListRoutine = createRoutine('FETCH_DEFAULT_FILTERED_DELIVERIES_LIST')
export const fetchSingleDeliveryRoutine = createRoutine('FETCH_SINGLE_DELIVERY')
export const removeDeliveryRoutine = createRoutine('REMOVE_DELIVERY')
export const fetchDeliveryItemsRoutine = createRoutine('FETCH_DELIVERY_ITEMS')
export const removeDeliveryItemRoutine = createRoutine('REMOVE_DELIVERY_ITEM')

// ACTIONS
function * fetchDeliveriesList ({ payload }) {
  yield put(fetchDeliveriesListRoutine.request())
  try {
    const { data } = yield call(deliveriesService.getDeliveries, payload)
    yield put(fetchDeliveriesListRoutine.success(data))
  } catch (e) {
    yield put(fetchDeliveriesListRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchDefaultFilteredDeliveriesList ({ payload }) {
  yield put(fetchDefaultFilteredDeliveriesListRoutine.request())
  try {
    const { data } = yield call(deliveriesService.getDefaultFilteredDeliveries, payload)
    yield put(fetchDefaultFilteredDeliveriesListRoutine.success(data))
  } catch (e) {
    yield put(fetchDefaultFilteredDeliveriesListRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchSingleDelivery ({ payload }) {
  yield put(fetchSingleDeliveryRoutine.request())
  try {
    const { data } = yield call(deliveriesService.getSingleDelivery, payload)
    yield put(fetchSingleDeliveryRoutine.success(data.data))
  } catch (e) {
    yield put(fetchSingleDeliveryRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * createDelivery ({ payload }) {
  const { callback } = payload
  yield put(createDeliveryRoutine.request())
  try {
    const { data } = yield call(
      deliveriesService.createDelivery,
      dissoc('callback', payload)
    )
    yield put(createDeliveryRoutine.success(data.data))
    yield put(
      fetchDeliveriesListRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    yield put(
      fetchDefaultFilteredDeliveriesListRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    typeof callback === 'function' && callback()
    toast.success('Dodano nową dostawę')
  } catch (e) {
    yield put(createDeliveryRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * removeDelivery ({ payload }) {
  const { id, callback } = payload
  yield put(removeDeliveryRoutine.request())
  try {
    yield call(deliveriesService.deleteDelivery, { id })
    yield put(removeDeliveryRoutine.success())
    yield put(
      fetchDeliveriesListRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    yield put(
      fetchDefaultFilteredDeliveriesListRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie usunięto dostawę')
  } catch (e) {
    yield put(removeDeliveryRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchDeliveryItems ({ payload }) {
  yield put(fetchDeliveryItemsRoutine.request())
  try {
    const { data } = yield call(deliveriesService.getDeliveryItems, payload)
    yield put(fetchDeliveryItemsRoutine.success(data))
  } catch (e) {
    yield put(fetchDeliveryItemsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * removeDeliveryItem ({ payload }) {
  const { id, deliveryId, callback } = payload
  yield put(removeDeliveryItemRoutine.request())
  try {
    yield call(deliveriesService.deleteDeliveryItem, { id, deliveryId })
    yield put(removeDeliveryItemRoutine.success())
    yield put(
      fetchDeliveriesListRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie usunięto produkt z dostawy')
  } catch (e) {
    yield put(removeDeliveryItemRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * createDeliveryWatcher () {
  yield takeLatest(createDeliveryRoutine.TRIGGER, createDelivery)
}

export function * fetchDeliveriesListWatcher () {
  yield takeLatest(fetchDeliveriesListRoutine.TRIGGER, fetchDeliveriesList)
}

export function * fetchDefaultFilteredDeliveriesListWatcher () {
  yield takeLatest(fetchDefaultFilteredDeliveriesListRoutine.TRIGGER, fetchDefaultFilteredDeliveriesList)
}

export function * fetchSingleDeliveryWatcher () {
  yield takeLatest(fetchSingleDeliveryRoutine.TRIGGER, fetchSingleDelivery)
}

export function * removeDeliveryWatcher () {
  yield takeLatest(removeDeliveryRoutine.TRIGGER, removeDelivery)
}

export function * fetchDeliveryItemsWatcher () {
  yield takeLatest(fetchDeliveryItemsRoutine.TRIGGER, fetchDeliveryItems)
}

export function * removeDeliveryItemWatcher () {
  yield takeLatest(removeDeliveryItemRoutine.TRIGGER, removeDeliveryItem)
}

// SAGAS
export const deliveriesSagas = [
  fork(createDeliveryWatcher),
  fork(fetchDeliveriesListWatcher),
  fork(fetchDefaultFilteredDeliveriesListWatcher),
  fork(fetchSingleDeliveryWatcher),
  fork(removeDeliveryWatcher),
  fork(fetchDeliveryItemsWatcher),
  fork(removeDeliveryItemWatcher)
]
