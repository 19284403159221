import React, { useEffect, useState } from 'react'
import { selectSaleOrdersStatistics } from 'modules/saleOrders/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import ContentPanel from 'components/atoms/ContentPanel'
import { fetchSaleOrdersStatisticsRoutine } from 'modules/saleOrders/ducks/actions'
import styled, { css } from 'styled-components'
import { changeQuery, getCurrentParsedQuery, redirect } from 'utils/navigation'
import { selectActiveTab } from 'ducks/tabs/selectors'
import { changeTabRoutine } from 'ducks/tabs/actions'
import { getStatusColor } from 'utils/saleOrders'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'

const SaleOrdersStatistics = () => {
  const stats = useSelector(selectSaleOrdersStatistics)
  const dispatch = useDispatch()
  const activeTab = useSelector(selectActiveTab)
  const [active, setActive] = useState('all')
  const {
    location: { pathname }
  } = useHistory()

  useEffect(() => {
    dispatch(fetchSaleOrdersStatisticsRoutine())
    const interval = setInterval(() => {
      dispatch(fetchSaleOrdersStatisticsRoutine())
    }, 30000)
    return () => clearInterval(interval)
  }, [])

  const statsCount = stats.reduce((n, { orders }) => n + orders, 0)

  const statusFilterHandler = status => () => {
    pathname == PATHS.lists && redirect(PATHS.saleOrders)
    const currentQuery = getCurrentParsedQuery()
    let payload = {}
    payload = { ...payload, status }
    setActive(status)
    if (status === 'suspended') {
      activeTab !== 'suspended' &&
        dispatch(
          changeTabRoutine({ key: 'suspended', filter: payload, isList: true })
        )
    } else {
      activeTab === 'suspended'
        ? dispatch(
            changeTabRoutine({ key: 'active', filter: payload, isList: true })
          )
        : changeQuery({
            ...currentQuery,
            page: 1,
            filter: payload,
            activeTab: 'active'
          })
    }
  }

  const resetFilter = () => {
    pathname == PATHS.lists && redirect(PATHS.saleOrders)
    setActive('all')
    activeTab === 'suspended'
      ? dispatch(changeTabRoutine({ key: 'active', filter: {} }))
      : changeQuery({
          page: 1,
          sort: 'status'
        })
  }

  return (
    <ContentPanel withMargin>
      <StatusesWrapper>
        <SingleStat key='all' active={active === 'all'} onClick={resetFilter}>
          <StatusLabel color={getStatusColor('all')}>Wszystkie</StatusLabel>
          <StatCounter>{statsCount}</StatCounter>
        </SingleStat>
        {stats.map(stat => {
          return (
            <SingleStat
              key={stat.status.value}
              active={active === stat.status.value}
              onClick={statusFilterHandler(stat.status.value)}
            >
              <StatusLabel color={getStatusColor(stat.status.value)}>
                {stat.status.label}
              </StatusLabel>
              <StatCounter>{stat.orders}</StatCounter>
            </SingleStat>
          )
        })}
      </StatusesWrapper>
    </ContentPanel>
  )
}

export default SaleOrdersStatistics

const StatusesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SingleStat = styled.div`
  text-align: center;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary.main};
  }
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.primary.main};
    `}
`

const StatCounter = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  padding: 5px;
  border-radius: 4px;
`

const StatusLabel = styled.div`
  background-color: ${({ color }) => color};
  color: #fff;
  padding: 5px;
  border-radius: 4px;
`
