import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'
import { getCurrentParsedQuery } from 'utils/navigation'
import { pathOr } from 'ramda'

const SearchInput = ({ onSubmit, onClear, placeholder, customFilterName }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const { location: { search } } = useHistory()

  const filterName = isNotNilOrEmpty(customFilterName) ? customFilterName : 'search'

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const searchFilter = pathOr('', ['filter', filterName], query)
    isNotNil(searchFilter) && setSearchQuery(searchFilter)
  }, [search])

  const handleClearInput = () => {
    setSearchQuery('')
    onClear()
  }

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(searchQuery)
  }

  const handleValueChange = (_, value) => setSearchQuery(value)

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        hiddenLabel
        variant='outlined'
        size='small'
        startAdornment={<SearchOutlinedIcon />}
        endAdornment={
          isNotNilOrEmpty(searchQuery)
            ? <StyledCloseIcon onClick={handleClearInput} />
            : null
        }
        noMarginBottom
        placeholder={placeholder}
        name='search'
        value={searchQuery}
        onChange={handleValueChange}
      />
    </Form>
  )
}

SearchInput.defaultState = {
  placeholder: 'Szukaj...'
}

export default SearchInput

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.mediumGrey};
`

const Form = styled.form`
  display: flex;
`
