import React from 'react'
import PageHeader from 'components/atoms/PageHeader'
import ContentPanel from 'components/atoms/ContentPanel'
import DeliveryOrdersLogsTable from 'modules/delivery/components/DeliveryOrdersLogsTable'
import { HeaderWrapper } from 'theme/wrappers'

const DeliveryOrdersLogs = () => {

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Raport dostaw</PageHeader>
      </HeaderWrapper>
      <ContentPanel>
       <DeliveryOrdersLogsTable />
      </ContentPanel>
    </>
  )
}

export default DeliveryOrdersLogs
