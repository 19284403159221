import React from 'react'
import { useSelector } from 'react-redux'
import { pathOr, propOr } from 'ramda'
import Table from 'components/Table'
import { selectCurrentSaleOrder } from 'modules/saleOrders/ducks/selectors'
import { SALE_ORDER_ITEM_STATUSES } from 'utils/saleOrders'

const SaleOrderProductsTable = () => {
  const order = useSelector(selectCurrentSaleOrder)
  const products = propOr([], 'items', order)

  const headers = [
    { children: 'EAN' },
    { children: 'Nazwa' },
    { children: 'Status' },
    { children: 'Zamówiono' },
    { children: 'W alokacji' },
    { children: 'Zebrane' },
    { children: 'Spakowane' }
  ]

  const rows = products.map(row => ({
    redirectPath: `/products/${pathOr('', ['product', 'id'], row)}`,
    cells: [
      { children: pathOr('---', ['product', 'ean'], row) },
      { children: pathOr('---', ['product', 'name'], row) },
      { children: SALE_ORDER_ITEM_STATUSES[pathOr('undefined', ['status'], row)] },
      { children: pathOr('---', ['orderedQuantity'], row) },
      { children: pathOr('---', ['allocatedQuantity'], row) },
      { children: pathOr('---', ['collectedQuantity'], row) },
      { children: pathOr('---', ['packedQuantity'], row) },
    ]
  }))

  return (
    <Table
      headers={headers}
      rows={rows}
      emptyState='Brak produktów w lokalizacji'
    />
  )
}

export default SaleOrderProductsTable
