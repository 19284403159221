import React from 'react'
import { selectCurrentProduct } from 'modules/products/ducks/selectors'
import { useSelector } from 'react-redux'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import { redirect } from 'utils/navigation'

const AdditionalCodesTab = () => {
  const product = useSelector(selectCurrentProduct)
  const barcodes = propOr([], 'barcodes', product)
  const variants = propOr([], 'variants', product)
  const parents = propOr({}, 'parents', product)

  return (
    <>
      <Section>
        <SectionTitle>Kody EAN:</SectionTitle>
        {isNotNilOrEmpty(barcodes) ? (
          barcodes.join(' | ')
        ) : (
          <div>Brak kodów produktu</div>
        )}
      </Section>
      <Section>
        <SectionTitle>Warianty:</SectionTitle>
        {isNotNilOrEmpty(variants) ? (
          variants.map(variant => (
            <Code key={variant.id}>
              <span>{propOr('', 'ean', variant)}&nbsp;</span>
              <CodeName onClick={() => redirect(`${variant.id}`)}>
                {'('}
                {propOr('', 'name', variant)}
                {')'}
              </CodeName>
            </Code>
          ))
        ) : (
          <div>Brak wariantów</div>
        )}
      </Section>
      <Section>
        <SectionTitle>Kody miksu:</SectionTitle>
        {isNotNilOrEmpty(parents) ? (
          parents.map(parent => (
            <Code key={parent.id} onClick={() => redirect(propOr('', 'id', parent))}>
              {propOr('', 'ean', parent)}&nbsp;
            </Code>
          ))
        ) : (
          <div>Brak kodów</div>
        )}
      </Section>
    </>
  )
}

export default AdditionalCodesTab

const SectionTitle = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
`

const Section = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`
const Code = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`

const CodeName = styled.span`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`
