import React from 'react'

const WarehouseDealings = () => {
  return (
    <div>
      obroty magazynowe
    </div>
  )
}

export default WarehouseDealings
