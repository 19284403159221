import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import {
  changeQuery,
  getCurrentParsedQuery,
  stringifyQuery
} from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { dissoc, propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWarehousesRoutine } from 'modules/warehouses/ducks/actions'
import { fetchZonesRoutine } from 'modules/zones/ducks/actions'
import { selectWarehousesList } from 'modules/warehouses/ducks/selectors'
import { selectZonesList } from 'modules/zones/ducks/selectors'
import { selectStockUnitKindList } from '../ducks/selectors'
import { fetchStockUnitKindsRoutine } from '../ducks/actions'
import { ButtonsWrapper } from 'theme/wrappers'

const emptyValues = {
  type: 'all',
  warehouse: 'all',
  zone: 'all',
  created_after: '',
  created_before: '',
  kind: 'all',
  with_deactivated: 'with'
}

const StockUnitsFilters = ({ open }) => {
  const [values, setValues] = useState(emptyValues)
  const {
    location: { search }
  } = useHistory()
  const dispatch = useDispatch()
  const warehousesList = useSelector(selectWarehousesList)
  const zonesList = useSelector(selectZonesList)
  const stockUnitKindsList = useSelector(selectStockUnitKindList)

  const resetFilters = () => {
    setValues(emptyValues)
    changeQuery({
      page: 1,
      sort: 'prefix'
    })
  }

  useEffect(() => {
    dispatch(
      fetchWarehousesRoutine({
        query: stringifyQuery({
          page: 1,
          limit: 1000
        })
      })
    )
    dispatch(
      fetchZonesRoutine({
        query: stringifyQuery({
          page: 1,
          limit: 1000
        })
      })
    )
    dispatch(fetchStockUnitKindsRoutine())
  }, [])

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    setValues({
      ...emptyValues,
      ...propOr({}, 'filter', currentQuery)
    })
  }, [search])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const stockUnitTypeOptions = [
    { label: 'Wszystkie', value: 'all' },
    { label: 'Stałe', value: 'permanent' },
    { label: 'Tymczasowe', value: 'disposable' }
  ]

  const warehouseOptions = useMemo(() => {
    if (isNotNilOrEmpty(warehousesList)) {
      return [
        { label: 'Wszystkie', value: 'all' },
        ...warehousesList.map(row => ({
          label: row.name,
          value: row.id
        }))
      ]
    } else {
      return [{ label: 'Wszystkie', value: 'all' }]
    }
  }, [warehousesList])

  const zonesOptions = useMemo(() => {
    if (isNotNilOrEmpty(zonesList)) {
      return [
        { label: 'Wszystkie', value: 'all' },
        ...zonesList.map(row => ({
          label: row.name,
          value: row.id
        }))
      ]
    } else {
      return [{ label: 'Wszystkie', value: 'all' }]
    }
  }, [zonesList])

  const stockUnitKindsOptions = useMemo(() => {
    if (isNotNilOrEmpty(stockUnitKindsList)) {
      return [
        { label: 'Wszystkie', value: 'all' },
        ...stockUnitKindsList.map(kind => ({
          label: kind.name,
          value: kind.id
        }))
      ]
    } else {
      return [{ label: 'Wszystkie', value: 'all' }]
    }
  }, [stockUnitKindsList])

  const activatedOptions = [
    { label: 'Wszystkie', value: 'with' },
    { label: 'Tylko aktywne', value: 'all' },
    { label: 'Tylko nieaktywne', value: 'only' }
  ]

  const handleSubmit = () => {
    const currentQuery = getCurrentParsedQuery()
    let payload = {}

    for (let key in values) {
      if (isNotNilOrEmpty(values[key]) && values[key] !== 'all') {
        payload[key] = values[key]
      } else {
        payload = dissoc(key, payload)
      }
    }

    changeQuery({
      ...currentQuery,
      page: 1,
      filter: payload
    })
  }

  return (
    <Wrapper open={open}>
      <Input
        type='date'
        value={values.created_after}
        name='created_after'
        label='Data od'
        onChange={handleValueChange}
        noMarginBottom
      />
      <Input
        type='date'
        value={values.created_before}
        name='created_before'
        label='Data do'
        onChange={handleValueChange}
        noMarginBottom
      />
      <Input
        select
        options={stockUnitTypeOptions}
        value={values.type}
        name='type'
        label='Typ jednostki'
        onChange={handleValueChange}
        noMarginBottom
      />
      <Input
        select
        options={warehouseOptions}
        value={values.warehouse}
        name='warehouse'
        label='Magazyn'
        onChange={handleValueChange}
        noMarginBottom
      />
      <Input
        select
        options={zonesOptions}
        value={values.zone}
        name='zone'
        label='Strefa'
        onChange={handleValueChange}
        noMarginBottom
      />
      <Input
        select
        options={stockUnitKindsOptions}
        value={values.kind}
        name='kind'
        label='Rodzaj jednostki'
        onChange={handleValueChange}
        noMarginBottom
      />
      <Input
        select
        options={activatedOptions}
        value={values.with_deactivated}
        name='with_deactivated'
        label='Aktywność jednostki'
        onChange={handleValueChange}
        noMarginBottom
      />
      <ButtonsWrapper>
        <Button small onClick={handleSubmit} color='primary'>
          Zastosuj
        </Button>
        <Button small onClick={resetFilters} color='primary-outline'>
          Resetuj
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default StockUnitsFilters

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  width: 100%;
  overflow: hidden;
  height: 0;
  padding: 0 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 15px;

  ${({ open }) =>
    open &&
    css`
      padding: 20px;
      margin-bottom: 20px;
      height: 110px;
    `}
`
