import React, { useEffect, useState } from 'react'
import AddButton from 'components/atoms/AddButton'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import { validateWarehouseField, validateWarehouseValues } from 'modules/warehouses/ducks/schema'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import { createWarehouseRoutine } from 'modules/warehouses/ducks/actions'

const emptyValues = {
  name: '',
  subiektId: ''
}

const AddWarehouseModal = () => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateWarehouseValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(createWarehouseRoutine({
      values,
      callback: handleModalClose
    }))
  }

  return (
    <>
      <AddButton onClick={handleModalOpen}>Dodaj magazyn</AddButton>
      <Modal
        open={open}
        title='Dodaj nowy magazyn'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa magazynu'
            value={values.name}
            onChange={handleValueChange}
            validate={validateWarehouseField(values)}
          />
          <Input
            name='subiektId'
            label='Subiekt ID'
            value={values.subiektId}
            onChange={handleValueChange}
            validate={validateWarehouseField(values)}
          />
          <Button
            type='submit'
            color='primary'
            disabled={!valid}
          >
            Dodaj
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default AddWarehouseModal
