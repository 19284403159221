import React, { useState } from 'react'
import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import { fetchSuppliersRoutine } from '../ducks/actions'
import { useDispatch } from 'react-redux'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { syncSuppliers } from 'services/SuppliersService'
import ResetButton from 'components/atoms/ResetButton'
import ButtonWrapper from 'theme/wrappers'

const SyncSuppliersModal = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const modalOpenHandler = () => {
    setOpen(true)
  }

  const modalCloseHandler = () => {
    setOpen(false)
  }

  const submitHandler = e => {
    e.preventDefault()
    const handleSuccess = () => {
      modalCloseHandler()
      dispatch(fetchSuppliersRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      }))
      toast.success('Pomyślnie zsynchronizowano')
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    syncSuppliers()
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <ResetButton onClick={modalOpenHandler} />
      <Modal
        open={open}
        title='Synchronizacja dostawców'
        onClose={modalCloseHandler}
      >
        <div>Czy chcesz zsynchronizować dostawców z Subiektem?</div>
        <ButtonWrapper>
          <Button color='primary-outline' onClick={modalCloseHandler}>
            Anuluj
          </Button>
          <Button
            type='submit'
            color='primary'
            onClick={submitHandler}
          >
            Synchronizuj
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default SyncSuppliersModal
