import React from 'react'
import CouriersTabs from 'modules/couriers/components/CouriersTabs'
import PageHeader from 'components/atoms/PageHeader'

const Couriers = () => {
  return (
    <>
      <PageHeader withMargin>
        Kurierzy
      </PageHeader>
      <CouriersTabs />
    </>
  )
}

export default Couriers
