const theme = {
  colors: {
    primary: {
      light: '#5DBCE2',
      main: '#2D97D0',
      dark: '#2076B2',
    },
    secondary: {
      light: '#FDCE61',
      main: '#FCB72E',
      dark: '#D89521',
    },
    text: '#333333',
    lightGrey: '#F2F2F2',
    disabled: '#CCCCCC',
    border: '#CCCCCC',
    darkGrey: '#333333',
    background: '#F9FAFD',
    error: '#FF4747',
    success: '#00cc66',
  },
  dimensions: {
    topBarHeight: '60px',
    sideBarWidth: '55px'
  },
  shadows: {
    main: 'rgb(37 38 94 / 10%) 4px 0 20px 0;'
  }
}

export default theme
