import React from 'react'
import PageHeader from 'components/atoms/PageHeader'
import ContentPanel from 'components/atoms/ContentPanel'
import StockUnitKindsTable from 'modules/stockUnits/components/StockUnitKindsTable'
import { HeaderWrapper } from 'theme/wrappers'

const StockUnitKinds = () => {
  return (
    <>
      <HeaderWrapper>
        <PageHeader>Lista rodzajów jednostek magazynowych</PageHeader>
      </HeaderWrapper>
      <ContentPanel>
        <StockUnitKindsTable />
      </ContentPanel>
    </>
  )
}

export default StockUnitKinds
