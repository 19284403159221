import React from 'react'
import styled from 'styled-components'
import AppLogo from 'components/atoms/AppLogo'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { propOr } from 'ramda'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'

const TopNavigation = () => {
  const user = useSelector(selectCurrentUser)
  const userName = propOr('', 'username', user).replace('.', ' ')

  return (
    <TopNavigationWrapper>
      <AppLogo />
      <UserWrapper>
        <UserIcon>
          <PersonOutlineIcon />
        </UserIcon>
        <UserName>
          {userName}
        </UserName>
      </UserWrapper>

    </TopNavigationWrapper>
  )
}

export default TopNavigation

const TopNavigationWrapper = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.dimensions.topBarHeight};
  position: fixed;
  top: 0;
  box-shadow: ${({ theme }) => theme.shadows.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #fff;
  z-index: 100;
`

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const UserIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`

const UserName = styled.div`
  text-transform: capitalize;
`
