import React, { useEffect, useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import SaleOrdersStatistics from 'modules/saleOrders/components/SaleOrdersStatistics'
import {
  changeQuery,
  getCurrentParsedQuery,
  isAuthorized
} from 'utils/navigation'
import { dissoc, propOr } from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import FiltersButton from 'components/atoms/FiltersButton'
import SearchInput from 'components/atoms/SearchInput'
import styled from 'styled-components'
import SaleOrdersFilters from 'modules/saleOrders/components/SaleOrdersFilters'
import ImportSaleOrdersFromIAIModal from 'modules/saleOrders/components/ImportSaleOrdersFromIAIModal'
import SaleOrdersTabs from 'modules/saleOrders/components/SaleOrdersTabs'
import { FiltersWrapper, HeaderWrapper } from 'theme/wrappers'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import ChangePackedToSentModal from 'modules/saleOrders/components/ChangePackedToSentModal'

const SaleOrders = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRoles = ['admin', 'foreman', 'bok']
  const priviledgedRolesChangeToSent = ['admin']

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const filters = dissoc('search', propOr({}, 'filter', query))
    setFiltersOpen(isNotNilOrEmpty(filters))
  }, [])

  const toggleFilters = () => setFiltersOpen(prev => !prev)

  const handleSubmit = searchQuery => {
    const parsedQuery = getCurrentParsedQuery()
    const isEmpty = isNilOrEmpty(searchQuery)

    changeQuery({
      ...parsedQuery,
      page: 1,
      filter: isEmpty
        ? dissoc('search', propOr({}, 'filter', parsedQuery))
        : {
            ...propOr({}, 'filter', parsedQuery),
            search: searchQuery
          }
    })
  }

  const handleClear = () => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      filter: dissoc('search', propOr({}, 'filter', parsedQuery))
    })
  }

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Zamówienia sprzedaży</PageHeader>
        <FiltersWrapper>
          {isAuthorized(priviledgedRoles, userRoles) ? (
            <ImportSaleOrdersFromIAIModal />
          ) : null}
          {isAuthorized(priviledgedRolesChangeToSent, userRoles) ? (
            <ChangePackedToSentModal />
          ) : null}
          <FiltersButton active={filtersOpen} onClick={toggleFilters} />
          <InputWrapper>
            <SearchInput
              onSubmit={handleSubmit}
              onClear={handleClear}
              placeholder='Znajdź zamówienie...'
            />
          </InputWrapper>
        </FiltersWrapper>
      </HeaderWrapper>
      <SaleOrdersStatistics />
      <SaleOrdersFilters open={filtersOpen} />
      <SaleOrdersTabs />
    </>
  )
}

export default SaleOrders

const InputWrapper = styled.div`
  max-width: 250px;
`
