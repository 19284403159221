import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import BackButton from 'components/atoms/BackButton'
import PATHS from 'utils/paths'
import StockUnitDetailsTabs from 'modules/stockUnitDetails/components/StockUnitDetailsTabs'
import BasicStockUnitDetails from 'modules/stockUnitDetails/components/BasicStockUnitDetails'
import { fetchSingleStockUnitRoutine } from 'modules/stockUnits/ducks/actions'

const StockUnitDetails = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(fetchSingleStockUnitRoutine({ id }))
  }, [id])

  return (
    <>
      <BackButton
        path={PATHS.stockUnits}
        label='Lista jednostek magazynowych'
        withMargin
      />
      <BasicStockUnitDetails />
      <StockUnitDetailsTabs />
    </>
  )
}

export default StockUnitDetails
