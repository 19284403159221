import { validateField, validateValues } from 'utils/form'
import { isNotNilOrEmpty } from 'utils/ramda'
import { object, string } from 'yup'

export const locationsFiltersSchema = object().shape({
  zone: string().required('Pole wymagane'),
  zone_type: string().required('Pole wymagane')
})

export const validateFiltersField = values =>
  validateField(locationsFiltersSchema, values)
export const validateFiltersValues = validateValues(locationsFiltersSchema)

export const createLocationSchema = object().shape({
  name: string().required('Nazwa jest wymagana'),
  weightLimit: string()
    .required('Limit wagi jest wymagany')
    .matches(/^\d+$/, 'Musi być wartością liczbową, większą lub równą 0'),
  weightLimitUnit: string().required('Jednostka jest wymagana'),
  warehouseId: string().required('Magazyn jest wymagany'),
  zoneId: string().required('Strefa jest wymagana'),
  sortOrder: string()
    .required('Kolejność jest wymagana')
    .matches(/^\d+$/, 'Musi być wartością liczbową, większą lub równą 0')
})

export const validateCreateLocationField = values =>
  validateField(createLocationSchema, values)
export const validateCreateLocationValues = validateValues(createLocationSchema)

export const locationHistoryFiltersSchema = object().shape({
  quantity: string()
    .matches(/^\d+$/, 'Musi być wartością liczbową')
    .nullable()
    .transform(value => (isNotNilOrEmpty(value) ? value : null))
})

export const validateLocationHistoryFiltersField = values =>
  validateField(locationHistoryFiltersSchema, values)
export const validateLocationHistoryFiltersValues = validateValues(
  locationHistoryFiltersSchema
)
