import React from 'react'
import ContentPanel from 'components/atoms/ContentPanel'
import PageHeader from 'components/atoms/PageHeader'
import ReplenishmentsTable from 'modules/replenishments/components/ReplenishmentsTable'

const Replenishments = () => {
  return (
    <>
      <PageHeader withMargin>
        Zadania uzupełnień
      </PageHeader>
      <ContentPanel>
        <ReplenishmentsTable />
      </ContentPanel>
    </>
  )
}

export default Replenishments
