import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { getCurrentPathname } from 'utils/navigation'
import history from 'utils/history'
import { isNotNilOrEmpty } from 'utils/ramda'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Collapse } from '@mui/material'
import { Link } from 'react-router-dom'
import { intersection, propOr } from 'ramda'

const SideNavigationItem = ({
  icon,
  label,
  path,
  sub,
  navigationOpen,
  isVisible,
  userRoles
}) => {
  const [pathname, setPathname] = useState(getCurrentPathname())
  const [isOpen, setIsOpen] = useState(false)
  const hasChildren = isNotNilOrEmpty(sub)

  const StyledIcon = styled(icon)`
    color: #fff;
    transition: all 0.3s !important;
  `

  history.listen(({ pathname }) => {
    setPathname(pathname)
  })

  const isMainItemActive = useMemo(() => {
    return sub
      ? pathname === path || sub.some(item => pathname === item.path)
      : pathname === path
  }, [pathname])

  const toggleCollapse = () => {
    setIsOpen(prev => !prev)
  }

  useEffect(() => {
    navigationOpen ? setIsOpen(isMainItemActive) : setIsOpen(false)
  }, [navigationOpen])

  return hasChildren ? (
    isVisible ? <ItemWrapper isActive={isMainItemActive}>
      <LabelWrapper onClick={toggleCollapse}>
        <IconWrapper>
          <StyledIcon />
        </IconWrapper>
        <ItemLabel>
          {label}
          {hasChildren && (isOpen ? <ExpandIcon open /> : <ExpandIcon />)}
        </ItemLabel>
      </LabelWrapper>
      {hasChildren && (
        <Collapse in={isOpen}>
          {sub.map(item => {
            const isVisible =
              intersection(userRoles, propOr([], 'roles', item)).length > 0
            return isVisible ? (
              <SubmenuItem
                isActive={pathname === item.path}
                key={`nav-item-${item.label}`}
                to={item.path}
              >
                {item.label}
              </SubmenuItem>
            ) : null
          })}
        </Collapse>
      )}
    </ItemWrapper> : null
  ) : (
    isVisible ? <ItemWrapperLink
      isActive={isMainItemActive}
      to={path}
    >
      <LabelWrapper>
        <IconWrapper>
          <StyledIcon />
        </IconWrapper>
        <ItemLabel>{label}</ItemLabel>
      </LabelWrapper>
    </ItemWrapperLink> : null
  )
}

export default SideNavigationItem

const ItemWrapper = styled.div`
  cursor: pointer;

  &:hover {
    div,
    svg {
      color: ${({ theme }) => theme.colors.secondary.main};
    }
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      div,
      svg {
        color: ${theme.colors.secondary.main};
      }
    `}
`

const ItemWrapperLink = styled(Link)`
  cursor: pointer;

  &:hover {
    div,
    svg {
      color: ${({ theme }) => theme.colors.secondary.main};
    }
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      div,
      svg {
        color: ${theme.colors.secondary.main};
      }
    `}
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`
const IconWrapper = styled.div`
  min-width: ${({ theme }) => theme.dimensions.sideBarWidth};
  max-width: ${({ theme }) => theme.dimensions.sideBarWidth};
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ItemLabel = styled.div`
  color: #fff;
  transition: all 0.3s;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
`

const SubmenuItem = styled(Link)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary.dark};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.secondary.main : '#fff'} !important;
  padding: 15px 10px 0 55px;

  display: block;

  &:last-child {
    padding-bottom: 15px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.dark} !important;
  }
`
const ExpandIcon = styled(ExpandMoreIcon)`
  ${({ open }) =>
    open &&
    css`
      transform: rotate(-180deg);
    `}
`
