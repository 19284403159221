import React, { useEffect } from 'react'
import {
  selectReplenishmentsList,
  selectReplenishmentsListLoading,
  selectReplenishmentsListPagination
} from 'modules/replenishments/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import { fetchReplenishmentTasksRoutine } from 'modules/replenishments/ducks/actions'
import { pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { useHistory } from 'react-router-dom'

const defaultFilters = {
  page: 1,
  take: 20
}

const ReplenishmentsTable = () => {
  const list = useSelector(selectReplenishmentsList)
  const pagination = useSelector(selectReplenishmentsListPagination)
  const isLoading = useSelector(selectReplenishmentsListLoading)
  const dispatch = useDispatch()
  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchReplenishmentTasksRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'EAN' },
    { children: 'Nazwa produktu' },
    { children: 'Ilość' },
    { children: 'Lokalizacja źródłowa' },
    { children: 'Status' },
    { children: 'Pracownik' }
  ]

  const rows = list.map(row => ({
    cells: [
      { children: pathOr('---', ['product', 'ean'], row) },
      { children: pathOr('---', ['product', 'name'], row) },
      { children: pathOr('---', ['quantity'], row) },
      {
        children: pathOr('', ['inventorableType'], row).includes('Location')
          ? pathOr('---', ['inventorable', 'name'], row)
          : `${pathOr('', ['inventorable', 'prefix'], row)}-${pathOr(
              '',
              ['inventorable', 'code'],
              row
            )}`
      },
      { children: isNotNilOrEmpty(row.assigneeId) ? 'W trakcie' : 'Nowe' },
      { children: pathOr('---', ['assignee', 'username'], row) }
    ]
  }))

  return (
    <Table
      headers={headers}
      rows={rows}
      pagination={pagination}
      emptyState='Brak zleconych uzupełnień'
      onPageChange={handlePageChange}
      isLoading={isLoading}
    />
  )
}

export default ReplenishmentsTable
