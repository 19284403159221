import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as usersService from 'services/UsersService'
import { toast } from 'react-hot-toast'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { getApiErrors } from 'utils/errors'

export const fetchUsersListRoutine = createRoutine('FETCH_USERS_LIST')
export const fetchUsersByRoleListRoutine = createRoutine('FETCH_USERS_BY_ROLE_LIST')
export const createUserRoutine = createRoutine('CREATE_USER_ROUTINE')
export const updateUserRoutine = createRoutine('UPDATE_USER_ROUTINE')
export const resetUserPasswordRoutine = createRoutine('RESET_USER_PASSWORD_ROUTINE')
export const getUserRoleLogsRoutine = createRoutine('GET_USER_ROLE_LOGS')

function * fetchUsersList ({ payload }) {
  yield put(fetchUsersListRoutine.request())
  try {
    const { data } = yield call(usersService.fetchUsersList, payload)
    yield put(fetchUsersListRoutine.success(data))
  } catch (error) {
    console.error(error)
    yield put(fetchUsersListRoutine.failure(error))
  }
}

function * fetchUsersByRoleList () {
  yield put(fetchUsersByRoleListRoutine.request())
  try {
    const { data } = yield call(usersService.fetchUsersByRolesList)
    yield put(fetchUsersByRoleListRoutine.success(data.data))
  } catch (error) {
    console.error(error)
    yield put(fetchUsersByRoleListRoutine.failure(error))
  }
}

function * createUser ({ payload }) {
  const { values, callback } = payload
  yield put(createUserRoutine.request())
  try {
    yield call(usersService.createUser, values)
    yield put(createUserRoutine.success())
    yield put(fetchUsersListRoutine({
      query: stringifyQuery(getCurrentParsedQuery())
    }))
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie stworzono użytkownika')
  } catch (e) {
    console.error(e)
    toast.error(getApiErrors(e))
    yield put(createUserRoutine.failure(e))
  }
}

function * updateUser ({ payload }) {
  const { values, callback } = payload
  yield put(updateUserRoutine.request())
  try {
    yield call(usersService.updateUser, values)
    yield put(updateUserRoutine.success())
    yield put(fetchUsersListRoutine({
      query: stringifyQuery(getCurrentParsedQuery())
    }))
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie zaktualizowano użytkownika')
  } catch (e) {
    console.error(e)
    toast.error(getApiErrors(e))
    yield put(updateUserRoutine.failure(e))
  }
}

function * resetUserPassword ({ payload }) {
  const { values, callback } = payload
  yield put(resetUserPasswordRoutine.request())
  try {
    yield call(usersService.resetUserPassword, values)
    yield put(resetUserPasswordRoutine.success())
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie zresetowano hasło użytkownika')
  } catch (e) {
    console.error(e)
    toast.error(getApiErrors(e))
    yield put(resetUserPasswordRoutine.failure(e))
  }
}

function * getUserRoleLogs ({ payload }) {
  yield put(getUserRoleLogsRoutine.request())
  try {
    const { data } = yield call(usersService.getUserRoleLogs, payload)
    yield put(getUserRoleLogsRoutine.success(data))
  } catch (e) {
    toast.error(getApiErrors(e))
    yield put(getUserRoleLogsRoutine.failure(e))
  }
}

// WATCHERS
export function * fetchUsersListWatcher () {
  yield takeLatest(fetchUsersListRoutine.TRIGGER, fetchUsersList)
}

export function * fetchUsersByRoleListWatcher () {
  yield takeLatest(fetchUsersByRoleListRoutine.TRIGGER, fetchUsersByRoleList)
}

export function * createUserWatcher () {
  yield takeLatest(createUserRoutine.TRIGGER, createUser)
}

export function * updateUserWatcher () {
  yield takeLatest(updateUserRoutine.TRIGGER, updateUser)
}

export function * resetUserPasswordWatcher () {
  yield takeLatest(resetUserPasswordRoutine.TRIGGER, resetUserPassword)
}

export function * getUserRoleLogsWatcher () {
  yield takeLatest(getUserRoleLogsRoutine.TRIGGER, getUserRoleLogs)
}

// SAGAS
export const usersSagas = [
  fork(fetchUsersListWatcher),
  fork(fetchUsersByRoleListWatcher),
  fork(createUserWatcher),
  fork(updateUserWatcher),
  fork(resetUserPasswordWatcher),
  fork(getUserRoleLogsWatcher)
]
