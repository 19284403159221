import React, { useEffect, useMemo, useState } from 'react'
import AddButton from 'components/atoms/AddButton'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import { selectUngroupedCouriersList } from 'modules/couriers/ducks/selectors'
import { validateCreateCourierGroupField, validateCreateCourierGroupValues } from 'modules/couriers/ducks/schema'
import { fetchCouriersGroupsListRoutine, fetchUngroupedCouriersListRoutine } from 'modules/couriers/ducks/actions'
import { addCouriersGroup } from 'services/CouriersService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { CompactPicker } from 'react-color'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'

const emptyValues = {
  name: '',
  courierIds: [],
  color: '#CCCCCC'
}

const AddCouriersGroupModal = () => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const ungroupedCouriers = useSelector(selectUngroupedCouriersList)

  useEffect(() => {
    dispatch(fetchUngroupedCouriersListRoutine())
  }, [])

  const couriersOptions = useMemo(() => {
    return ungroupedCouriers.map(courier => ({
      label: courier.name,
      value: courier.id
    }))
  }, [ungroupedCouriers])

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateCreateCourierGroupValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = () => {
      dispatch(fetchCouriersGroupsListRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      }))
      handleModalClose()
      toast.success('Dodano nową grupę kurierów')
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    addCouriersGroup(values)
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <AddButton onClick={handleModalOpen}>Dodaj grupę</AddButton>
      <Modal
        open={open}
        title='Dodaj nową grupę kurierów'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa grupy'
            value={values.name}
            onChange={handleValueChange}
            validate={validateCreateCourierGroupField(values)}
          />
          <Input
            select
            multiple
            options={couriersOptions}
            name='courierIds'
            label='Kurierzy'
            value={values.courierIds}
            onChange={handleValueChange}
          />
          <ColorPickerWrapper>
            <ColorPickerLabel>Kolor grupy:</ColorPickerLabel>
            <Tooltip title={(
              <CompactPicker
                color={values.color}
                onChange={(color) => handleValueChange('color', color.hex)}
              />
            )}>
              <Picker color={values.color} />
            </Tooltip>
          </ColorPickerWrapper>
          <Button
            type='submit'
            color='primary'
            disabled={!valid}
          >
            Dodaj
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default AddCouriersGroupModal

const ColorPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`

const ColorPickerLabel = styled.div`
  font-weight: bold;
`

const Picker = styled.div`
  height: 20px;
  width: 50px;
  background-color: ${({ color }) => color};
`
