import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import { useDispatch } from 'react-redux'
import Button from 'components/atoms/Button'
import RemoveIcon from 'components/icons/RemoveIcon'
import { removeDeliveryRoutine } from 'modules/delivery/ducks/actions'
import ButtonWrapper from 'theme/wrappers'

const RemoveDeliveryModal = ({ delivery }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(removeDeliveryRoutine({
      id: delivery.id,
      callback: handleModalClose
    }))
  }

  return (
    <>
      <RemoveIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Usunięcie dostawy'
        onClose={handleModalClose}
      >
        Czy na pewno chcesz usunąć dostawę?
        <ButtonWrapper>
          <Button
            onClick={handleModalClose}
            color='primary-outline'
          >
            Anuluj
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
          >
            Usuń
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default RemoveDeliveryModal
