import { pathOr, propOr } from 'ramda'
import { isNotEmpty } from './ramda'

export const ORDER_TYPES = {
  single_sku: '1 SKU',
  multi_sku: 'Multi SKU',
  undefined: ''
}

export const canRemoveList = list => {
  const status = pathOr('', ['status', 'value'], list)
  const subLists = propOr([], 'subLists', list)
  const hasSubLists = isNotEmpty(subLists)
  return hasSubLists
    ? status === 'pending' &&
        subLists.every(list => list.status.value === 'pending')
    : status === 'pending'
}
