import React, { useEffect, useMemo, useState } from 'react'
import Input from 'components/atoms/Input'
import Modal from 'components/atoms/Modal'
import EditIcon from 'components/icons/EditIcon'
import { useDispatch } from 'react-redux'
import { dissoc, equals, pathOr, pick, propOr } from 'ramda'
import Button from 'components/atoms/Button'
import {
  validateUpdateDeliveryItemField,
  validateUpdateDeliveryItemValues
} from 'modules/delivery/ducks/schema'
import { isNotNilOrEmpty } from 'utils/ramda'
import { fetchDeliveryItemsRoutine } from 'modules/delivery/ducks/actions'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import {
  updateDeliveryItem,
  updateDeliveryItemInventory
} from 'services/DeliveryService'
import toast from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import { useParams } from 'react-router-dom'
import StockUnitAmountForm from './StockUnitAmountForm'

const initialValues = {
  ordinalNumber: '',
  isMix: false,
  isNew: false,
  productName: '',
  productEan: '',
  stockUnitQuantities: [],
  productId: ''
}

const EditDeliveryItemModal = ({ item }) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [modalOpen, setModalOpen] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [valid, setValid] = useState(false)
  const [validAmounts, setValidAmount] = useState({})
  const [quantities, setQuantities] = useState([])
  const isMix = pathOr('', ['product', 'isMix'], item)
  const isNew = pathOr('', ['product', 'isNew'], item)
  const ordinalNumber = propOr('---', 'ordinalNumber', item).toString()
  const productName = pathOr(null, ['product', 'name'], item)
  const productId = pathOr(false, ['product', 'id'], item)
  const productEan = pathOr('', ['product', 'ean'], item)

  const difference = (obj, newObj) => {
    let changed = {}
    equals(Object.keys(obj), Object.keys(newObj))
      ? Object.keys(obj).forEach(key => {
          if (obj[key] !== newObj[key]) {
            changed = { ...changed, [key]: newObj[key] }
          }
        })
      : console.error('You can not compare object with different keys')
    return changed
  }

  const areQuantitiesValid = useMemo(() => {
    return Object.values(validAmounts).every(v => v)
  }, [validAmounts])

  useEffect(() => {
    modalOpen &&
      setValues({
        ordinalNumber,
        isMix,
        isNew,
        productName,
        productEan,
        stockUnitQuantities: isNotNilOrEmpty(propOr('---', 'pivot', item))
          ? propOr([], 'inventories', item).map(inventory => {
              return {
                deliveryId: id,
                stockUnitId: propOr('', 'inventorableId', inventory),
                quantity: pathOr(null, ['pivot', 'quantity'], inventory),
                name: pathOr(null, ['inventorable', 'code'], inventory),
                productId: pathOr('', ['product', 'id'], item),
                changed: false
              }
            })
          : [],
        productId
      })
  }, [item, modalOpen])

  const handleModalOpen = e => {
    e.stopPropagation()
    setModalOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setModalOpen(false)
    setValues(initialValues)
  }

  const handleChangeValues = (name, value) => {
    setValues(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeStockUnitQuantity = id => (name, value) => {
    const newQuantities =
      isNotNilOrEmpty(values.stockUnitQuantities) &&
      values.stockUnitQuantities.map(obj => {
        if (obj.stockUnitId === id) {
          return { ...obj, [name]: parseInt(value), changed: true }
        }
        return obj
      })
    setQuantities(newQuantities)
  }

  const handleValidateStockUnitQuantity = id => isValid => {
    setValidAmount(prev => ({ ...prev, [id]: isValid }))
  }

  useEffect(() => {
    validateUpdateDeliveryItemValues(
      dissoc('stockUnitQuantities', values),
      setValid
    )
  }, [values])

  const submitHandler = e => {
    e.preventDefault()

    const changedQuantities = quantities.filter(obj => {
      return obj.changed
    })

    const patchQuantities = async () =>
      await Promise.all(
        changedQuantities.map(async quantity => {
          await updateDeliveryItemInventory(quantity)
        })
      )

    isNotNilOrEmpty(changedQuantities) &&
      patchQuantities()
        .then(() => {
          dispatch(
            fetchDeliveryItemsRoutine({
              id,
              query: stringifyQuery(getCurrentParsedQuery())
            })
          )
          handleModalClose()
        })
        .catch(e => {
          toast.error(getApiErrors(e))
        })

    const origValues = {
      ordinalNumber,
      isMix,
      isNew,
      productName,
      productEan
    }

    const requestValues = pick(
      ['ordinalNumber', 'isMix', 'isNew', 'productName', 'productEan'],
      values
    )

    updateDeliveryItem({
      deliveryId: id,
      deliveryItemId: item.id,
      body: { ...difference(origValues, requestValues), productId }
    })
      .then(() => {
        dispatch(
          fetchDeliveryItemsRoutine({
            id,
            query: stringifyQuery(getCurrentParsedQuery())
          })
        )
        toast.success('Zapisano zmiany')
        handleModalClose()
      })
      .catch(e => {
        toast.error(getApiErrors(e))
      })
  }

  return (
    <>
      <EditIcon onClick={handleModalOpen} />
      <Modal
        open={modalOpen}
        title='Edytuj produkt w dostawie'
        onClose={handleModalClose}
      >
        <form onSubmit={submitHandler}>
          <Input
            name='ordinalNumber'
            label='Numer porządkowy'
            value={values.ordinalNumber}
            onChange={handleChangeValues}
            validate={validateUpdateDeliveryItemField(values)}
          />
          {isNotNilOrEmpty(item.product.parent) && (
            <Input
              name='productName'
              label='Nazwa'
              value={values.productName}
              onChange={handleChangeValues}
              validate={validateUpdateDeliveryItemField(values)}
            />
          )}
          {isNotNilOrEmpty(item.product.parent) && (
            <Input
              name='productEan'
              label='EAN'
              value={values.productEan}
              onChange={handleChangeValues}
              validate={validateUpdateDeliveryItemField(values)}
            />
          )}
          {isNotNilOrEmpty(values.stockUnitQuantities) &&
            values.stockUnitQuantities.map(item => (
              <StockUnitAmountForm
                key={item.stockUnitId}
                item={item}
                onChange={handleChangeStockUnitQuantity(item.stockUnitId)}
                onValidate={handleValidateStockUnitQuantity(item.stockUnitId)}
              />
            ))}
          <Button
            type='submit'
            color='primary'
            disabled={!valid || !areQuantitiesValid}
          >
            Zapisz
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default EditDeliveryItemModal
