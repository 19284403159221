import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSingleProductRoutine } from 'modules/products/ducks/actions'
import { useParams } from 'react-router-dom'
import ProductBasicDetails from 'modules/productDetails/components/ProductBasicDetails'
import BackButton from 'components/atoms/BackButton'
import PATHS from 'utils/paths'
import ProductDetailsTabs from 'modules/productDetails/components/ProductDetailsTabs'
import ImportProductsFromIAIModal from '../modules/products/components/ImportProductsFromIAIModal'
import styled from 'styled-components'
import EditProductModal from 'modules/products/components/EditProductModal'
import {
  selectCurrentProduct,
  selectCurrentProductLoading
} from 'modules/products/ducks/selectors'
import { fetchPackingTypesRoutine } from 'modules/packingTypes/ducks/actions'
import { isAuthorized, stringifyQuery } from 'utils/navigation'
import { fetchStockUnitKindsRoutine } from 'modules/stockUnits/ducks/actions'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { propOr } from 'ramda'
import RemoveProductModal from 'modules/products/components/RemoveProductModal'
import LoadingSpinner from 'components/atoms/LoadingSpinner'

const ProductDetails = () => {
  const product = useSelector(selectCurrentProduct)
  const isLoading = useSelector(selectCurrentProductLoading)
  const dispatch = useDispatch()
  const { id } = useParams()
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRoles = ['admin', 'foreman', 'bok']
  const priviledgedRolesUpdateProduct = [
    'admin',
    'foreman',
    'bok',
    'warehouseman'
  ]
  const priviledgedRolesRemoveProduct = ['admin']

  useEffect(() => {
    dispatch(
      fetchPackingTypesRoutine({
        query: stringifyQuery({
          page: 1,
          limit: 1000
        })
      })
    )
    dispatch(fetchStockUnitKindsRoutine())
  }, [])

  useEffect(() => {
    dispatch(fetchSingleProductRoutine({ id }))
  }, [id])

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <ActionWrapper>
        <BackButton withMargin path={PATHS.products} label='Lista produktów' />
        <Buttons>
          {isAuthorized(priviledgedRolesRemoveProduct, userRoles) ? (
            <RemoveProductModal product={product} isProductDetailsView />
          ) : null}
          {isAuthorized(priviledgedRolesUpdateProduct, userRoles) ? (
            <EditProductModal product={product} isProductDetailsView />
          ) : null}
          {isAuthorized(priviledgedRoles, userRoles) ? (
            <ImportProductsFromIAIModal />
          ) : null}
        </Buttons>
      </ActionWrapper>
      <ProductBasicDetails />
      <ProductDetailsTabs />
    </>
  )
}

export default ProductDetails

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  button {
    width: fit-content;
  }
`
