import React from 'react'
import styled from 'styled-components'

const Unauthorized = () => {
  return (
    <Wrapper>
      <Header>
        Nie posiadasz odpowiednich uprawnień
      </Header>
    </Wrapper>
  )
}

export default Unauthorized

const Wrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Header = styled.div`
  font-size: 40px;
  font-weight: bold;
`
