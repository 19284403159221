import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import RemoveIcon from 'components/icons/RemoveIcon'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { deleteMultipickRuleset } from 'services/MultipickService'
import ButtonWrapper from 'theme/wrappers'

const RemoveRulesetModal = ({ ruleset, handleFetchList }) => {
  const [open, setOpen] = useState(false)

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = () => {
      handleFetchList()
      handleModalClose()
      toast.success('Zestaw filtrów został usunięty')
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    deleteMultipickRuleset({ id: ruleset.id })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <RemoveIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Usunięcie zestawu filtrów'
        onClose={handleModalClose}
      >
        Czy na pewno chcesz usunąć filtry &quot;{ruleset.name}&quot;
        <ButtonWrapper>
          <Button
            onClick={handleModalClose}
            color='primary-outline'
          >
            Anuluj
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
          >
            Usuń
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default RemoveRulesetModal
