import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as replenishmentsService from 'services/ReplenishmentsService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchReplenishmentTasksRoutine = createRoutine('FETCH_REPLENISHMENTS')

// ACTIONS
function * fetchReplenishmentTasks ({ payload }) {
  yield put(fetchReplenishmentTasksRoutine.request())
  try {
    const { data } = yield call(replenishmentsService.getReplenishmentTasks, payload)
    yield put(fetchReplenishmentTasksRoutine.success(data))
  } catch (e) {
    yield put(fetchReplenishmentTasksRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * fetchReplenishmentTasksWatcher () {
  yield takeLatest(fetchReplenishmentTasksRoutine.TRIGGER, fetchReplenishmentTasks)
}

// SAGAS
export const replenishmentSagas = [
  fork(fetchReplenishmentTasksWatcher)
]
