import Table from 'components/Table'
import { fetchLocationHistoryRoutine } from 'modules/locations/ducks/actions'
import {
  selectLocationHistoryList,
  selectLocationHistoryListLoading,
  selectLocationHistoryListPagination
} from 'modules/locations/ducks/selectors'
import { dissoc, pathOr, propOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import FiltersButton from 'components/atoms/FiltersButton'
import LocationHistoryFilters from 'modules/locationDetails/components/LocationHistoryFilters'
import { TabFiltersWrapper } from 'theme/wrappers'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: '-created_at'
}

const LocationHistoryTab = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()
  const list = useSelector(selectLocationHistoryList)
  const pagination = useSelector(selectLocationHistoryListPagination)
  const isLoading = useSelector(selectLocationHistoryListLoading)
  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    changeQuery(defaultFilters)
  }, [])

  useEffect(() => {
    isNotNilOrEmpty(search)
      ? dispatch(fetchLocationHistoryRoutine({ id, query: search }))
      : changeQuery(defaultFilters)
  }, [search])

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const filters = dissoc('search', propOr({}, 'filter', query))
    setFiltersOpen(isNotNilOrEmpty(filters))
  }, [])

  const toggleFilters = () => setFiltersOpen(prev => !prev)

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Data i godzina zmiany', sort: 'created_at' },
    { children: 'Kod produktu', sort: 'product_ean' },
    { children: 'Lokalizacja źródłowa', sort: 'location_from' },
    { children: 'JM źródłowa', sort: 'stock_unit_from' },
    { children: 'Lokalizacja docelowa', sort: 'location_to' },
    { children: 'JM docelowa', sort: 'stock_unit_to' },
    { children: 'Ilość', sort: 'quantity' },
    { children: 'Typ operacji', sort: 'type' },
    { children: 'Zmienione przez' }
  ]

  const rows = list.map(row => {
    return {
      cells: [
        {
          children: formatDate(
            pathOr('---', ['createdAt'], row),
            DATE_FORMATS.dashedWithTime
          )
        },
        { children: pathOr('---', ['product', 'ean'], row) },
        { children: pathOr('---', ['locationFrom', 'name'], row) },
        { children: pathOr('---', ['stockUnitFrom', 'code'], row) },
        { children: pathOr('---', ['locationTo', 'name'], row) },
        { children: pathOr('---', ['stockUnitTo', 'code'], row) },
        { children: pathOr('---', ['quantity'], row) },
        { children: pathOr('---', ['type', 'label'], row) },
        { children: pathOr('---', ['movedBy', 'username'], row) }
      ]
    }
  })

  return (
    <>
      <TabFiltersWrapper>
        <FiltersButton active={filtersOpen} onClick={toggleFilters} />
      </TabFiltersWrapper>
      <LocationHistoryFilters open={filtersOpen} />
      <Table
        headers={headers}
        rows={rows}
        pagination={pagination}
        onPageChange={handlePageChange}
        isLoading={isLoading}
      />
    </>
  )
}

export default LocationHistoryTab
