import { mergeDeepRight } from 'ramda'
import axios from 'axios'
import applyConverters from 'axios-case-converter'
import { KEYS } from 'utils/localStorage'
import isUUID from 'is-uuid'

const apiUrl = process.env.REACT_APP_API_URL

export const mergeConfig = config => {
  const authToken = localStorage.getItem(KEYS.token)
  const authHeader = authToken ? { Authorization: authToken } : {}

  const defaultConfig = {
    headers: {
      ...authHeader
    }
  }
  return mergeDeepRight(defaultConfig)(config)
}

const get = instance => async (url, config = {}) => {
  return instance.get(url, mergeConfig(config))
}

const post = instance => async (url, data = {}, config = {}) => {
  return instance.post(url, data, mergeConfig(config))
}

const put = instance => async (url, data = {}, config = {}) => {
  return instance.put(url, data, mergeConfig(config))
}

const patch = instance => async (url, data = {}, config = {}) => {
  return instance.patch(url, data, mergeConfig(config))
}

const handleDelete = instance => async (url,  data = {}, config = {}) => {
  return instance.delete(url, mergeConfig(data ? { ...config, data } : config))
}

export default (baseURL = apiUrl) => {
  const instance = applyConverters(axios.create({
    baseURL,
    withCredentials: true
  }), {
    preservedKeys: input => {
      return isUUID.anyNonNil(input) || isUUID.nil(input)
    }
  })

  return {
    get: get(instance),
    post: post(instance),
    put: put(instance),
    patch: patch(instance),
    delete: handleDelete(instance)
  }
}
