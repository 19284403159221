import React, { useEffect, useState } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { pathOr, propOr } from 'ramda'
import {
  fetchSaleOrdersRoutine,
  fetchSaleOrdersStatisticsRoutine,
  fetchSuspendedSaleOrdersRoutine
} from 'modules/saleOrders/ducks/actions'
import {
  selectIsLoading,
  selectSuspendedSaleOrdersList,
  selectSuspendedSaleOrdersListPagination
} from 'modules/saleOrders/ducks/selectors'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { unsuspendOrder } from 'services/SaleOrdersService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import CreateListForSingleOrderModal from 'modules/saleOrders/components/CreateListForSingleOrderModal'
import { ActionsContainer } from 'theme/wrappers'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'ordered_at',
  filter: {}
}

const SuspendedSaleOrdersTable = () => {
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const list = useSelector(selectSuspendedSaleOrdersList)
  const pagination = useSelector(selectSuspendedSaleOrdersListPagination)
  const isLoading = useSelector(selectIsLoading)
  const {
    location: { search }
  } = useHistory()
  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => setOpen(false)

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchSuspendedSaleOrdersRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const handleUnsuspendOrders = () => {
    unsuspendOrder({ saleOrderIds: selected.map(s => s.id) })
      .then(() => {
        setSelected([])
        dispatch(fetchSaleOrdersRoutine({ query: search }))
        dispatch(fetchSaleOrdersStatisticsRoutine())
        handleModalClose()
      })
      .catch(err => {
        toast.error(getApiErrors(err))
      })
  }

  const headers = [
    { children: 'Numer IAI', sort: 'iai_order_serial_number' },
    { children: 'Status', sort: 'status' },
    { children: 'Data zamówienia', sort: 'ordered_at' },
    { children: 'Data importu', sort: 'created_at' },
    { children: 'Nabywca', sort: 'iai_client_login' },
    { children: 'Kurier', sort: 'courier_id' },
    { children: 'Źródło', sort: 'source_name' },
    { children: 'z B2B?', sort: 'is_from_b2b_platform' },
    { children: 'JM' },
    { children: 'Notatka' },
    { children: 'Komentarz' },
    { children: '', align: 'right' }
  ]

  const rows = list.map(row => {
    const stockUnits = propOr([], 'stockUnits', row).map(item => item.code)
    const uniqueStockUnits = Array.from(new Set(stockUnits))

    return {
      redirectPath: `/sale-orders/${row.id}`,
      original: row,
      cells: [
        { children: propOr('---', 'iaiOrderSerialNumber', row) },
        { children: pathOr('---', ['status', 'label'], row) },
        {
          children: formatDate(
            propOr('---', 'orderedAt', row),
            DATE_FORMATS.dashedWithTime
          )
        },
        {
          children: formatDate(
            propOr('---', 'createdAt', row),
            DATE_FORMATS.dashedWithTime
          )
        },
        { children: propOr('---', 'iaiClientLogin', row) },
        { children: pathOr('---', ['courier', 'name'], row) },
        { children: pathOr('---', ['source', 'name'], row) },
        { children: propOr(false, 'isFromB2bPlatform', row) ? '✔' : '✗' },
        {
          children:
            uniqueStockUnits.length > 0 ? (
              uniqueStockUnits.map(item => {
                return <div key={item}>{item}</div>
              })
            ) : (
              <div>{'---'}</div>
            )
        },
        { children: propOr('', 'clientNote', row) || '---' },
        { children: propOr('', 'comment', row) || '---' },
        {
          children: (
            <ActionsContainer>
              <CreateListForSingleOrderModal orderId={row.id} suspended />
            </ActionsContainer>
          ),
          align: 'right',
          preventRedirect: true
        }
      ]
    }
  })

  const toggleCheckbox = (row, value) => {
    value
      ? setSelected(prev => [...prev, row])
      : setSelected(prev => prev.filter(o => o.id !== row.id))
  }

  return (
    <>
      <OrdersCounter>Liczba zamówień: {pagination.itemsCount}</OrdersCounter>
      {isNotNilOrEmpty(selected) && (
        <SelectedOrdersWrapper>
          Zaznaczone: {selected.length}
          <Button small onClick={handleModalOpen}>
            Odblokuj zamówienia
          </Button>
        </SelectedOrdersWrapper>
      )}
      <Table
        withCheckboxes
        onCheck={toggleCheckbox}
        selected={selected}
        pagination={pagination}
        onPageChange={handlePageChange}
        headers={headers}
        rows={rows}
        emptyState='Brak wstrzymanych zamówień sprzedaży'
        isLoading={isLoading}
      />
      <Modal title='Odblokuj zamówienia' open={open} onClose={handleModalClose}>
        Czy na pewno chcesz odblokować wybrane zamówienia?
        <ModalButtons>
          <Button color='primary-outline' onClick={handleModalClose}>
            Anuluj
          </Button>
          <Button onClick={handleUnsuspendOrders}>Odblokuj zamówienia</Button>
        </ModalButtons>
      </Modal>
    </>
  )
}

export default SuspendedSaleOrdersTable

const OrdersCounter = styled.div`
  margin-bottom: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary.main};
`

const SelectedOrdersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 350px;
  margin-bottom: 20px;

  button {
    width: 200px;
  }
`

const ModalButtons = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
`
