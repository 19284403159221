import APIService from './APIService'
import { dissoc } from 'ramda'

const api = APIService()

export const fetchWarehouses = () => api.get('/warehouses')
export const createWarehouse = payload => api.post('/warehouses', payload)
export const updateWarehouse = payload =>
  api.patch(`/warehouses/${payload.id}`, dissoc('id', payload))
export const deleteWarehouse = payload =>
  api.delete(`/warehouses/${payload.id}`)
export const fetchWarehouseState = payload => api.get(`products/report${payload.query}`)
export const exportWarehouseStateToCsv = () =>
  api.get(`products/export/report-csv`)
