import React, { useState } from 'react'
import { exportLocationsToFile } from 'services/LocationsService'
import ExportButton from 'components/atoms/ExportButton'
import { Tooltip } from '@mui/material'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { downloadCSV } from 'utils/files'

const ExportLocationsFromFile = () => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    exportLocationsToFile()
      .then(resp => {
        downloadCSV(resp.data, `lokalizacje-${new Date().toLocaleDateString()}`)
        setLoading(false)
      })
      .catch(e => {
        toast.error(getApiErrors(e))
        setLoading(false)
      })
  }
  return (
    <Tooltip title='Export do pliku CSV'>
      <div>
        <ExportButton loading={loading} onClick={handleSubmit} />
      </div>
    </Tooltip>

  )
}

export default ExportLocationsFromFile
