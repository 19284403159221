import APIService from './APIService'
import { dissoc } from 'ramda'

const api = APIService()

export const fetchUsersList = payload => api.get(`/users${payload.query}`)
export const fetchUsersByRolesList = () => api.get(`/users/by-roles`)
export const createUser = payload => api.post('/users', payload)
export const updateUser = payload => api.patch(`/users/${payload.id}`, dissoc('id', payload))
export const resetUserPassword = payload => api.patch(`/users/${payload.id}/reset-password`)
export const getUserRoleLogs = payload => api.get(`/user-role-logs${payload.query}`)
export const exportUserLogsToCsv = payload => api.get(`/user-role-logs/csv${payload.query}`)
