import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentPackingOrder } from 'modules/packing/ducks/selectors'
import { assignPackageToOrder, getPossiblePackageSizesForOrder } from 'services/PackingService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { pathOr } from 'ramda'
import styled from 'styled-components'
import {
  setCurrentPackingOrderRoutine
} from 'modules/packing/ducks/actions'

const SelectPackageSizeModal = ({ open, setStatus, isPackageSizeRequired, setForcedPackingModalOpen }) => {
  const currentOrder = useSelector(selectCurrentPackingOrder)
  const [availableSizes, setAvailableSizes] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentOrder) {
      getPossiblePackageSizesForOrder({ id: currentOrder.id })
        .then(resp => {
          setAvailableSizes(pathOr([], ['data', 'data'], resp))
        })
        .catch(err => toast.error(getApiErrors(err), {
          duration: 5000,
          style: {
            fontSize: 22
          }
        }))
    }
  }, [currentOrder])

  const handleSizeSelect = size => () => {
    assignPackageToOrder({
      id: currentOrder.id,
      packageSize: size.id
    })
      .then(resp => {
        const data = resp.data.data
        dispatch(setCurrentPackingOrderRoutine(data))
        setStatus('sizeSelected')
        isPackageSizeRequired && setForcedPackingModalOpen(false)
      })
      .catch(err => toast.error(getApiErrors(err), {
        duration: 5000,
        style: {
          fontSize: 22
        }
      }))
  }

  return (
    <Modal
      title='Wybierz rozmiar opakowania'
      open={open}
      hideClose
      onClose={() => {}}
    >
      <SizeSelectWrapper>
        {
          availableSizes.map(size => {
            return (
              <SizeSelectItem
                key={size.id}
                onClick={handleSizeSelect(size)}
              >
                {size.name}
              </SizeSelectItem>
            )
          })
        }
      </SizeSelectWrapper>
    </Modal>
  )
}

export default SelectPackageSizeModal

const SizeSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const SizeSelectItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  transition: all .3s;
  cursor: pointer;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: #fff;
  }
`
