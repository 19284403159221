import React from 'react'
import Tabs from 'components/atoms/Tabs'
import AdditionalCodesTab from 'modules/productDetails/components/AdditionalCodesTab'
import LocalizationsTab from 'modules/productDetails/components/LocalizationsTab'
import ChangesHistoryTab from 'modules/productDetails/components/ChangesHistoryTab'
import ShiftHistoryTab from 'modules/productDetails/components/ShiftHistoryTab'
import ContentPanel from 'components/atoms/ContentPanel'
import ProductPhotosTab from 'modules/productDetails/components/ProductPhotosTab'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { isAuthorized } from 'utils/navigation'
import { filter, propOr } from 'ramda'
import { useSelector } from 'react-redux'

const ProductDetailsTabs = () => {
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRoles = ['admin', 'foreman', 'bok', 'warehouseman']

  const tabs = [
    {
      key: 'photos',
      title: 'Zdjęcia produktu',
      children: <ProductPhotosTab />
    },
    {
      key: 'additional_codes',
      title: 'Dodatkowe kody',
      children: <AdditionalCodesTab />
    },
    {
      key: 'locations',
      title: 'Lokalizacje',
      children: <LocalizationsTab />
    },
    {
      key: 'history_of_changes',
      title: 'Historia zmian',
      children: <ChangesHistoryTab />,
      isList: true
    },
    {
      key: 'history_of_shifts',
      title: 'Historia przesunięć',
      children: <ShiftHistoryTab />,
      isList: true
    },
    // to remove or implement when delivery profiles gets description
    // {
    //   key: 'delivery_profiles',
    //   title: 'Profile dostawy',
    //   children: <DeliveryProfilesTab />
    // }
  ]

  const basicTabs = filter(
    item => !['history_of_shifts', 'history_of_changes'].includes(item.key),
    tabs
  )

  return (
    <ContentPanel>
      <Tabs
        tabs={isAuthorized(priviledgedRoles, userRoles) ? tabs : basicTabs}
      />
    </ContentPanel>
  )
}

export default ProductDetailsTabs
