import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import { useDispatch } from 'react-redux'
import Button from 'components/atoms/Button'
import { deactivateStockUnit } from 'services/StockUnitsService'
import { fetchStockUnitsRoutine } from 'modules/stockUnits/ducks/actions'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import DeactivateIcon from 'components/icons/DeactivateIcon'
import ButtonWrapper from 'theme/wrappers'

const DeactivateStockUnitModal = ({ stockUnit }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = () => {
      dispatch(
        fetchStockUnitsRoutine({
          query: stringifyQuery(getCurrentParsedQuery())
        })
      )
      toast.success('Jednostka magazynowa została dezaktywowana')
      handleModalClose()
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    deactivateStockUnit({ id: stockUnit.id })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <DeactivateIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Deaktywacja jednostki'
        onClose={handleModalClose}
      >
        Czy na pewno chcesz deaktywować jednostkę magazynową &quot;
        {stockUnit.prefix}-{stockUnit.code}&quot;
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Deaktywuj
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default DeactivateStockUnitModal
