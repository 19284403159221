import React from 'react'
import styled, { css } from 'styled-components'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'

const FiltersButton = ({ onClick, active }) => {
  return (
    <Wrapper onClick={onClick} active={active}>
      <FilterAltOutlinedIcon />
    </Wrapper>
  )
}

export default FiltersButton

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: #fff;
    border-color: ${({ theme }) => theme.colors.primary.dark};
  }
  
  ${({ theme, active }) => active && css`
    background-color: ${theme.colors.primary.main};
    color: #fff;
    border-color: ${theme.colors.primary.dark};
  `}
`
