import React from 'react'
import SaleOrdersStatistics from 'modules/saleOrders/components/SaleOrdersStatistics'
import ContentPanel from 'components/atoms/ContentPanel'
import Tabs from 'components/atoms/Tabs'
import CreateMultipickTab from 'modules/multipick/components/CreateMultipickTab'
import MultipickListTab from 'modules/multipick/components/MultipickListTab'

const Multipick = () => {
  const tabs = [
    {
      key: 'new',
      title: 'Tworzenie',
      children: <CreateMultipickTab />
    },
    {
      key: 'created',
      title: 'Stworzone listy',
      children: <MultipickListTab />
    },

  ]
  return (
    <div>
      <SaleOrdersStatistics />
      <ContentPanel>
        <Tabs tabs={tabs} />
      </ContentPanel>
    </div>
  )
}

export default Multipick
