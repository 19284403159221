import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSingleDeliveryRoutine } from 'modules/delivery/ducks/actions'
import styled from 'styled-components'
import BackButton from 'components/atoms/BackButton'
import PATHS from 'utils/paths'
import DeliveryDetailsTabs from 'modules/deliveryDetails/components/DeliveryDetailsTabs'

const DeliveryDetails = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(fetchSingleDeliveryRoutine({ id }))
  }, [id])

  return (
    <>
      <HeaderWrapper>
        <BackButton
          path={PATHS.delivery}
          label='Lista dostaw'
          withMargin
        />
      </HeaderWrapper>
      <DeliveryDetailsTabs />
    </>
  )
}

export default DeliveryDetails

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
