import React from 'react'
import MultipickFilters from 'modules/multipick/components/filters/MultipickFilters'

const CreateMultipickTab = () => {
  return (
    <>
      <MultipickFilters />
    </>
  )
}

export default CreateMultipickTab
