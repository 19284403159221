import {
  fetchUserRolesDictionaryRoutine,
  fetchZonesDictionaryRoutine
} from 'ducks/dictionaries/actions'
import { dissoc } from 'ramda'

const defaultState = {
  userRoles: [],
  zones: []
}

const dictionariesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchUserRolesDictionaryRoutine.SUCCESS:
      const roles = dissoc('lists_creator', action.payload)
      return {
        ...state,
        userRoles: {
          ...roles, lists_creator: action.payload.lists_creator
        }
      }
    case fetchZonesDictionaryRoutine.SUCCESS:
      return {
        ...state,
        zones: action.payload
      }
    default:
      return state
  }
}

export default dictionariesReducer
