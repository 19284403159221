import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as warehousesService from 'services/WarehousesService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchWarehousesRoutine = createRoutine('FETCH_WAREHOUSES')
export const createWarehouseRoutine = createRoutine('CREATE_WAREHOUSE')
export const editWarehouseRoutine = createRoutine('EDIT_WAREHOUSE')
export const removeWarehouseRoutine = createRoutine('REMOVE_WAREHOUSE')
export const fetchWarehouseStateRoutine = createRoutine('FETCH_WAREHOUSE_STATE')

// ACTIONS
function * fetchWarehouses () {
  yield put(fetchWarehousesRoutine.request())
  try {
    const { data } = yield call(warehousesService.fetchWarehouses)
    yield put(fetchWarehousesRoutine.success(data.data))
  } catch (e) {
    yield put(fetchWarehousesRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * createWarehouse ({ payload }) {
  const { values, callback } = payload
  yield put(createWarehouseRoutine.request())
  try {
    yield call(warehousesService.createWarehouse, values)
    yield put(createWarehouseRoutine.success())
    yield put(fetchWarehousesRoutine())
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie dodano magazyn')
  } catch (e) {
    yield put(createWarehouseRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * editWarehouse ({ payload }) {
  const { values, callback } = payload
  yield put(editWarehouseRoutine.request())
  try {
    yield call(warehousesService.updateWarehouse, values)
    yield put(editWarehouseRoutine.success())
    yield put(fetchWarehousesRoutine())
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie zaktualizowano magazyn')
  } catch (e) {
    yield put(editWarehouseRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * removeWarehouse ({ payload }) {
  const { id, callback } = payload
  yield put(removeWarehouseRoutine.request())
  try {
    yield call(warehousesService.deleteWarehouse, { id })
    yield put(removeWarehouseRoutine.success())
    yield put(fetchWarehousesRoutine())
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie usunięto magazyn')
  } catch (e) {
    yield put(removeWarehouseRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchWarehouseState ({ payload }) {
  yield put(fetchWarehouseStateRoutine.request())
  try {
    const { data } = yield call(warehousesService.fetchWarehouseState, payload)
    yield put(fetchWarehouseStateRoutine.success(data))
  } catch (e) {
    yield put(fetchWarehouseStateRoutine.failure())
    toast.error(getApiErrors(e))
  }
}

// WATCHERS
export function * fetchWarehousesWatcher () {
  yield takeLatest(fetchWarehousesRoutine.TRIGGER, fetchWarehouses)
}

export function * createWarehouseWatcher () {
  yield takeLatest(createWarehouseRoutine.TRIGGER, createWarehouse)
}

export function * editWarehouseWatcher () {
  yield takeLatest(editWarehouseRoutine.TRIGGER, editWarehouse)
}

export function * removeWarehouseWatcher () {
  yield takeLatest(removeWarehouseRoutine.TRIGGER, removeWarehouse)
}

export function * fetchWarehouseStateWatcher () {
  yield takeLatest(fetchWarehouseStateRoutine.TRIGGER, fetchWarehouseState)
}

// SAGAS
export const warehousesSagas = [
  fork(fetchWarehousesWatcher),
  fork(createWarehouseWatcher),
  fork(editWarehouseWatcher),
  fork(removeWarehouseWatcher),
  fork(fetchWarehouseStateWatcher)
]
