import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import { useSelector } from 'react-redux'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import { downloadTechnicalLabel, printTechnicalLabel } from 'utils/packing'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import { selectCurrentPackingOrder } from 'modules/packing/ducks/selectors'
import { isNilOrEmpty } from 'utils/ramda'
import ButtonWrapper from 'theme/wrappers'

const PrintTechnicalLabelsModal = () => {
  const [open, setOpen] = useState(false)
  const [count, setCount] = useState(1)
  const currentOrder = useSelector(selectCurrentPackingOrder)

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handlePrintTechnicalLabel = () => {
    printTechnicalLabel({ orderId: currentOrder.id, count })
      .then(() => {
        handleModalClose()
      })
      .catch(err => toast.error(getApiErrors(err), {
        duration: 5000,
        style: {
          fontSize: 22
        }
      }))
  }

  return (
    <>
      <Button
        small
        disabled={isNilOrEmpty(currentOrder)}
        color='primary'
        onClick={handleModalOpen}
      >
        Drukuj etykiety tech.
      </Button>
      <Modal
        open={open}
        title='Wydruk etykiet technicznych'
        onClose={handleModalClose}
      >
        <Input
          name='count'
          label='Liczba etykiet'
          type='number'
          onChange={(_, value) => setCount(value)}
          value={count}
        />
        <ButtonWrapper>
          <Button
            onClick={handleModalClose}
            color='primary-outline'
          >
            Anuluj
          </Button>
          <Button
            disabled={!/[0-9]*/.test(count) || Number(count) < 1 || isNilOrEmpty(count)}
            onClick={handlePrintTechnicalLabel}
            color='primary'
          >
            Drukuj
          </Button>
          <Button onClick={() => downloadTechnicalLabel({ orderId: currentOrder.id, count })}>pobierz etykietę techniczną</Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default PrintTechnicalLabelsModal
