import React, { useMemo } from 'react'
import styled from 'styled-components'
import PackingOrderBox from 'modules/packing/components/PackingOrderBox'
import { useSelector } from 'react-redux'
import {
  selectCurrentPackingOrder,
  selectCurrentUnitCompletionList,
  selectCurrentUnitOrders
} from 'modules/packing/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { pathOr, propOr } from 'ramda'
import PackingProductBox from 'modules/packing/components/PackingProductBox'

const PackingMainContent = () => {
  const orders = useSelector(selectCurrentUnitOrders)
  const currentOrder = useSelector(selectCurrentPackingOrder)
  const completionList = useSelector(selectCurrentUnitCompletionList)
  const stockUnits = propOr([], 'stockUnits', completionList).map(
    unit => unit.code
  )
  const uniqueStockUnits = [...new Set(stockUnits)]

  const inProgressOrders = useMemo(() => {
    return orders
      .filter(order => pathOr('', ['status', 'value'], order) !== 'packed')
      .map(order => {
        return (
          <PackingOrderBox
            key={order.id}
            order={{
              ...order,
              completionList
            }}
          />
        )
      })
  }, [orders])

  const doneOrders = useMemo(() => {
    return orders
      .filter(order => pathOr('', ['status', 'value'], order) === 'packed')
      .map(order => {
        return (
          <PackingOrderBox
            key={order.id}
            order={{
              ...order,
              completionList
            }}
          />
        )
      })
  }, [orders])

  const currentOrderProducts = useMemo(() => {
    if (isNotNilOrEmpty(currentOrder)) {
      return propOr([], 'items', currentOrder).map(packingItem => (
        <PackingProductBox key={packingItem.id} packingItem={packingItem} />
      ))
    } else {
      return []
    }
  }, [currentOrder])

  const helperText = useMemo(() => {
    const orderItems = propOr([], 'items', currentOrder)
    const needsSizeSelection =
      pathOr('', ['status', 'value'], currentOrder) === 'partially_packed'
    const isFinished = orderItems.every(item => item.status.value === 'packed')
    const inPreviewMode = propOr(false, 'previewMode', currentOrder)

    switch (true) {
      case inPreviewMode:
        return `Podgląd zamówienia nr ${currentOrder.iaiOrderSerialNumber}`
      case needsSizeSelection && isFinished:
        return 'Wybierz rodzaj opakowania'
      case isNotNilOrEmpty(currentOrder) && isFinished:
        return `Wszystkie towary z zam. ${currentOrder.iaiOrderSerialNumber} zostały spakowane`
      case isNotNilOrEmpty(currentOrder):
        return `Zeskanuj kolejny towar z zamówienia nr ${currentOrder.iaiOrderSerialNumber}`
      default:
        return `Zeskanuj dowolny towar z ${
          uniqueStockUnits.length > 1
            ? 'jednostek magazynowych'
            : 'jednostki magazynowej'
        } nr ${uniqueStockUnits.join(', ')}`
    }
  }, [currentOrder])

  return (
    <ColumnWrapper>
      <Wrapper>
        <Box flex='0.196'>
          <BoxTitle>Do weryfikacji</BoxTitle>
          <BoxContent>{inProgressOrders}</BoxContent>
        </Box>
        <Box flex='0.608'>
          <BoxTitle>{helperText}</BoxTitle>
          <BoxContent>{currentOrderProducts}</BoxContent>
        </Box>
        <Box flex='0.196'>
          <BoxTitle>Zweryfikowane</BoxTitle>
          <BoxContent>{doneOrders}</BoxContent>
        </Box>
      </Wrapper>
    </ColumnWrapper>
  )
}

export default PackingMainContent

const ColumnWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Wrapper = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 15px;
  height: calc(100% - 15px);
`

const Box = styled.div`
  flex: ${({ flex }) => flex};
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  flex-direction: column;
`

const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary.main};
  color: #fff;
  font-weight: bold;
`

const BoxContent = styled.div`
  padding: 10px;
  background: #fff;
  flex: 1;
  overflow-y: auto;
`
