import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectCurrentProductFiles,
  selectCurrentProductIconLargeUrl
} from 'modules/products/ducks/selectors'
import AddProductPhotoModal from 'modules/productDetails/components/AddProductPhotoModal'
import styled from 'styled-components'
import ImageViewer from 'react-simple-image-viewer'
import RemoveProductPhotoModal from 'modules/productDetails/components/RemoveProductPhotoModal'
import { isNotNilOrEmpty } from 'utils/ramda'
import { concat, propOr } from 'ramda'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { isAuthorized } from 'utils/navigation'

const ProductPhotosTab = () => {
  const files = useSelector(selectCurrentProductFiles)
  const largeUrl = useSelector(selectCurrentProductIconLargeUrl)
  const iaiImage = {
    id: Math.round(Math.random() * 23423923234239),
    url: largeUrl
  }
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const wmsImages = files.filter(file => file.type === 'photo')
  const images = isNotNilOrEmpty(largeUrl)
    ? concat(wmsImages, [iaiImage])
    : wmsImages

  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRoles = ['admin', 'foreman', 'bok', 'warehouseman', 'lists_creator']

  const openImageViewer = index => () => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <>
      {isAuthorized(priviledgedRoles, userRoles) ? <AddProductPhotoModal /> : null}
      <Wrapper>
        {isNotNilOrEmpty(images) || isNotNilOrEmpty(largeUrl) ? (
          images.map((file, index) => (
            <Thumbnail
              url={file.url}
              onClick={openImageViewer(index)}
              key={`product-image-${file.id}`}
            >
              {file.type && isAuthorized(priviledgedRoles, userRoles) ? (
                <RemoveIconWrapper>
                  <RemoveProductPhotoModal file={file} />
                </RemoveIconWrapper>
              ) : null}
            </Thumbnail>
          ))
        ) : (
          <div>Brak zdjęć produktu</div>
        )}
        {isViewerOpen && (
          <ViewerWrapper>
            <ImageViewer
              src={images.map(file => file.url)}
              backgroundStyle={{
                background: 'rgba(0, 0, 0, .7)'
              }}
              currentIndex={currentImage}
              disableScroll
              closeOnClickOutside
              onClose={closeImageViewer}
            />
          </ViewerWrapper>
        )}
      </Wrapper>
    </>
  )
}

export default ProductPhotosTab

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
`

const Thumbnail = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  background: ${({ url, theme }) =>
    `${theme.colors.lightGrey} url(${url}) no-repeat center/cover`};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
  }
`

const RemoveIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: #fff;
  }
`

const ViewerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
`
