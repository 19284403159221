import React from 'react'
import Tabs from 'components/atoms/Tabs'
import ContentPanel from 'components/atoms/ContentPanel'
import SaleOrderProductsTable from 'modules/saleOrderDetails/components/SaleOrderProductsTable'
import SaleOrderHistory from './SaleOrderHistory'

const SaleOrderDetailsTabs = () => {
  const tabs = [
    {
      key: 'products',
      title: 'Produkty',
      children: <SaleOrderProductsTable />
    },
    {
      key: 'details',
      title: 'Szczegóły',
      children: <SaleOrderHistory />,
      isList: true
    }
  ]

  return (
    <ContentPanel>
      <Tabs tabs={tabs} />
    </ContentPanel>
  )
}

export default SaleOrderDetailsTabs
