import React, { useEffect, useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import ContentPanel from 'components/atoms/ContentPanel'
import styled from 'styled-components'
import ZonesTable from 'modules/zones/components/ZonesTable'
import { useDispatch } from 'react-redux'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { dissoc, propOr } from 'ramda'
import SearchInput from 'components/atoms/SearchInput'
import { fetchZonesDictionaryRoutine } from 'ducks/dictionaries/actions'
import AddZoneModal from 'modules/zones/components/AddZoneModal'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import FiltersButton from 'components/atoms/FiltersButton'
import ZonesFilters from 'modules/zones/components/ZonesFilters'
import { HeaderWrapper } from 'theme/wrappers'

const Zones = () => {
  const dispatch = useDispatch()
  const [filtersOpen, setFiltersOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchZonesDictionaryRoutine())

    const query = getCurrentParsedQuery()
    const filters = dissoc('search', propOr({}, 'filter', query))
    setFiltersOpen(isNotNilOrEmpty(filters))
  }, [])

  const handleSubmit = searchQuery => {
    const parsedQuery = getCurrentParsedQuery()
    const isEmpty = isNilOrEmpty(searchQuery)

    changeQuery({
      ...parsedQuery,
      page: 1,
      filter: isEmpty
        ? dissoc('search', propOr({}, 'filter', parsedQuery))
        : {
            ...propOr({}, 'filter', parsedQuery),
            search: searchQuery
          }
    })
  }

  const handleClear = () => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      filter: dissoc('search', propOr({}, 'filter', parsedQuery))
    })
  }

  const toggleFilters = () => {
    setFiltersOpen(prev => !prev)
  }

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Lista stref</PageHeader>
        <HeaderActions>
          <FiltersButton active={filtersOpen} onClick={toggleFilters} />
          <InputWrapper>
            <SearchInput
              onSubmit={handleSubmit}
              onClear={handleClear}
              placeholder='Znajdź strefę...'
            />
          </InputWrapper>
          <AddZoneModal />
        </HeaderActions>
      </HeaderWrapper>
      <ZonesFilters open={filtersOpen} />
      <ContentPanel>
        <ZonesTable />
      </ContentPanel>
    </>
  )
}

export default Zones

const InputWrapper = styled.div`
  max-width: 250px;
`

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
