export const INVENTORY_STATUSES = {
  combining: 'Łączenie JM',
  concatenation: 'Przenoszenie/Zwalnianie',
  registration: 'Rejestracja',
  deregistering: 'Wyrejestrowanie',
  branch_transfer: 'Przesunięcie towaru',
  interbranch_transfer: 'Przesunięcie międzymagazynowe',
  inventory: 'Inwentaryzacja',
  acceptance: 'Przyjęcie',
  completion: 'Kompletacja',
  supplement: 'Uzupełnienie',
  packing: 'Pakowanie',
  packing_cancelled: 'Anulowanie pakowania'
}
