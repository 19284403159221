import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { dissoc, propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import { DATE_FORMATS } from 'utils/date'
import moment from 'moment'
import { validateLocationHistoryFiltersField } from 'modules/locations/ducks/schema'
import { ButtonsWrapper } from 'theme/wrappers'
import { isNotNilOrEmpty } from 'utils/ramda'

const emptyValues = {
  created_after: moment().format(DATE_FORMATS.input),
  created_before: moment().format(DATE_FORMATS.input),
  role: 'all'
}

const EfficiencyStatsFilters = ({ open }) => {
  const [values, setValues] = useState(emptyValues)

  const {
    location: { search }
  } = useHistory()

  const resetFilters = () => {
    setValues(emptyValues)
    changeQuery({
      page: 1,
      filter: dissoc('role', emptyValues)
    })
  }

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    setValues({
      ...emptyValues,
      ...propOr({}, 'filter', currentQuery)
    })
  }, [search])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = () => {
    const currentQuery = getCurrentParsedQuery()
    let payload = {}

    for (let key in values) {
      if (isNotNilOrEmpty(values[key]) && values[key] !== 'all') {
        payload[key] = values[key]
      } else {
        payload = dissoc(key, payload)
      }
    }

    changeQuery({
      ...currentQuery,
      page: 1,
      filter: payload
    })
  }

  return (
    <Wrapper open={open}>
      <Input
        type='date'
        value={values.created_after}
        name='created_after'
        label='Data od'
        onChange={handleValueChange}
        validate={validateLocationHistoryFiltersField(values)}
        noMarginBottom
      />
      <Input
        type='date'
        value={values.created_before}
        name='created_before'
        label='Data do'
        onChange={handleValueChange}
        validate={validateLocationHistoryFiltersField(values)}
        noMarginBottom
      />
      <ButtonsWrapper>
        <Button small onClick={handleSubmit} color='primary'>
          Zastosuj
        </Button>
        <Button small onClick={resetFilters} color='primary-outline'>
          Resetuj
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default EfficiencyStatsFilters

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  width: 100%;
  overflow: hidden;
  height: 0;
  padding: 0 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 15px;

  ${({ open }) =>
    open &&
    css`
      padding: 20px;
      margin-bottom: 20px;
      height: 110px;
    `}
`
