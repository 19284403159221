import { validateField, validateValues } from 'utils/form'
import { object, string } from 'yup'

// warehouse schema
export const packingTypeSchema = object().shape({
  name: string().required('Nazwa jest wymagana')
})

export const validatePackingTypeField = values => validateField(packingTypeSchema, values)
export const validatePackingTypeValues = validateValues(packingTypeSchema)
