import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Button from 'components/atoms/Button'
import {
  selectCurrentPackingOrder,
  selectCurrentUnitNumber,
  selectCurrentUnitOrders
} from 'modules/packing/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { packingErrorEmitter, printLabel } from 'utils/packing'
import { getApiErrors } from 'utils/errors'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { pathOr, propOr } from 'ramda'
import { resetPackingOrder } from 'services/PackingService'
import {
  clearCurrentPackingOrderRoutine,
  clearCurrentStockUnitOrdersRoutine,
  fetchCompletionListsForStockUnitRoutine,
  setPackedItemEanRoutine,
  setPackedItemQuantityRoutine
} from 'modules/packing/ducks/actions'
import Modal from 'components/atoms/Modal'
import Table from 'components/Table'
import PrintTechnicalLabelsModal from 'modules/packing/components/PrintTechnicalLabelsModal'

const PackingActionButtons = ({
  setStatus,
  onClearUnits,
  onHandleFinish,
  onHandleFinishWithMissingProducts,
  status
}) => {
  const currentOrder = useSelector(selectCurrentPackingOrder)
  const orderItems = propOr([], 'items', currentOrder)
  const isFinished =
    isNotNilOrEmpty(orderItems) &&
    orderItems.every(item => item.status.value === 'packed')
  const currentUnitNumber = useSelector(selectCurrentUnitNumber)
  const dispatch = useDispatch()
  const [skipModalOpen, setSkipModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const orders = useSelector(selectCurrentUnitOrders)

  const isAnyOrderStarted = useMemo(() => {
    return (
      isNotNilOrEmpty(orders) &&
      orders.some(order => {
        const orderStatus = pathOr('', ['status', 'value'], order)
        return orderStatus === 'partially_packed'
      })
    )
  }, [orders])

  const handleSkipModalOpen = () => setSkipModalOpen(true)
  const handleSkipModalClose = () => setSkipModalOpen(false)

  const handleCancelModalOpen = () => setCancelModalOpen(true)
  const handleCancelModalClose = () => setCancelModalOpen(false)

  const handleSkipOrder = () => {
    resetPackingOrder({ id: currentOrder.id })
      .then(() => {
        dispatch(clearCurrentPackingOrderRoutine())
        dispatch(
          fetchCompletionListsForStockUnitRoutine({
            code: currentUnitNumber,
            callback: () => setStatus('waitingForProductScan')
          })
        )
        dispatch(setPackedItemQuantityRoutine(1))
        dispatch(setPackedItemEanRoutine(''))
        handleSkipModalClose()
      })
      .catch(err => {
        packingErrorEmitter.emit('error', getApiErrors(err))
      })
    onClearUnits()
  }

  const handleStopPacking = () => {
    const handleClear = () => {
      dispatch(clearCurrentPackingOrderRoutine())
      dispatch(clearCurrentStockUnitOrdersRoutine())
      dispatch(setPackedItemQuantityRoutine(1))
      dispatch(setPackedItemEanRoutine(''))
      setStatus('start')
    }
    if (isAnyOrderStarted) {
      resetPackingOrder({ id: currentOrder.id })
        .then(handleClear)
        .catch(err => {
          packingErrorEmitter.emit('error', getApiErrors(err))
        })
    } else {
      handleClear()
    }
    onClearUnits()
  }

  const alreadyPackedItems = orderItems
    .filter(item => item.packedQuantity > 0)
    .map(item => ({
      name: pathOr('', ['product', 'name'], item),
      stockUnits: propOr([], 'stockUnits', item)
        .map(unit => unit.code)
        .join(', ')
    }))

  const skipOrderHeaders = [{ children: 'Produkt' }, { children: 'Pojemnik' }]

  const skipOrderRows = alreadyPackedItems.map(item => ({
    cells: [{ children: item.name }, { children: item.stockUnits }]
  }))

  return (
    <Wrapper>
      <div style={{ flex: '0.196' }}>
        <Button
          small
          color='error'
          onClick={onHandleFinishWithMissingProducts}
          disabled={isNilOrEmpty(currentOrder) || currentOrder.status.value === 'packed'}
        >
          Zakończ kontrolę - brak produktu
        </Button>
      </div>
      <Flex style={{ flex: '0.608' }}>
        <Button
          small
          color='primary-outline'
          onClick={handleSkipModalOpen}
          disabled={!isAnyOrderStarted}
        >
          Pomiń zamówienie
        </Button>
        <Button
          small
          disabled={isNilOrEmpty(currentOrder) || !isFinished}
          color='primary'
          onClick={() => printLabel({ orderId: currentOrder.id })}
        >
          Drukuj etykietę
        </Button>
        <PrintTechnicalLabelsModal />
      </Flex>
      <div style={{ flex: '0.196' }}>
        <Button
          small
          color={status ? 'success' : 'error'}
          onClick={status ? onHandleFinish : handleCancelModalOpen}
        >
          Zakończ kontrolę jednostki
        </Button>
      </div>
      <Modal
        open={skipModalOpen}
        title='Pomiń zamówienie'
        onClose={handleSkipModalClose}
      >
        <SkipOrderModalContent>
          <SkipOrderText>
            Przed pominięciem zawolaj przełożonego i zwróć zabawki do
            pojemników:
          </SkipOrderText>
          <Table headers={skipOrderHeaders} rows={skipOrderRows} />
        </SkipOrderModalContent>
        <ButtonsWrapper>
          <Button color='primary-outline' onClick={handleSkipModalClose}>
            Anuluj
          </Button>
          <Button onClick={handleSkipOrder}>
            Już zwrócilem zabawki do pojemników
          </Button>
        </ButtonsWrapper>
      </Modal>
      <Modal
        open={cancelModalOpen}
        title='Zakończ kontrolę jednostki'
        onClose={handleCancelModalClose}
      >
        {isAnyOrderStarted ? (
          <SkipOrderModalContent>
            <SkipOrderText>
              Przed zakończeniem zawolaj przełożonego i zwróć zabawki do
              pojemników:
            </SkipOrderText>
            <Table headers={skipOrderHeaders} rows={skipOrderRows} />
          </SkipOrderModalContent>
        ) : (
          'Czy na pewno chcesz zakończyć kontrolę obecnej jednostki?'
        )}
        <ButtonsWrapper>
          <Button color='primary-outline' onClick={handleCancelModalClose}>
            Anuluj
          </Button>
          <Button onClick={handleStopPacking}>
            {isAnyOrderStarted
              ? 'Zakończ - zabawki zostały odłożone'
              : 'Zakończ'}
          </Button>
        </ButtonsWrapper>
      </Modal>
    </Wrapper>
  )
}

export default PackingActionButtons

const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
`

const Flex = styled.div`
  display: flex;
  gap: 15px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`

const SkipOrderText = styled.div`
  margin-bottom: 20px;
`

const SkipOrderModalContent = styled.div`
  width: 700px;
`
