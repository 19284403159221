import React from 'react'
import Tabs from 'components/atoms/Tabs'
import ContentPanel from 'components/atoms/ContentPanel'
import DeliveryDetailsBasicTab from 'modules/deliveryDetails/components/DeliveryDetailsBasicTab'
import DeliveryDetailsProductsTab from 'modules/deliveryDetails/components/DeliveryDetailsProductsTab'

const DeliveryDetailsTabs = () => {
  const tabs = [
    {
      key: 'basic',
      title: 'Informacje o dostawie',
      children: <DeliveryDetailsBasicTab />
    },
    {
      key: 'products',
      title: 'Produkty przyjęte w dostawie',
      children: <DeliveryDetailsProductsTab />,
      isList: true
    }
  ]

  return (
    <ContentPanel>
      <Tabs tabs={tabs} />
    </ContentPanel>
  )
}

export default DeliveryDetailsTabs
