import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork } from '@redux-saga/core/effects'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { omit, pathOr, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

export const changeTabRoutine = createRoutine('CHANGE_TAB')
export const fetchTabsListRoutine = createRoutine('FETCH_TABS_LIST')

// ACTIONS
function * changeTab ({ payload }) {
  yield put(changeTabRoutine.request())
  const currentQuery = getCurrentParsedQuery()
  const key = propOr('', 'key', payload)
  const filter = propOr({}, 'filter', payload)
  try {
    yield put(changeTabRoutine.success(key))
    payload.isList
      ? changeQuery({
          ...currentQuery,
          page: 1,
          activeTab: key,
          filter
        })
      : changeQuery({
          ...omit(['page', 'limit'], currentQuery),
          activeTab: key,
          filter
        })
  } catch (e) {
    yield put(changeTabRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchTabsList ({ payload }) {
  yield put(fetchTabsListRoutine.request())
  const tabs = propOr('', 'tabs', payload)
  const keys = isNotNilOrEmpty(tabs) ? tabs.map(tab => tab.key) : []
  const query = propOr({}, 'currentQuery', payload)
  const activeTab = keys.includes(query.activeTab)
    ? query.activeTab
    : pathOr('', [0, 'key'], tabs)
  try {
    yield put(fetchTabsListRoutine.success({ keys, activeTab }))
  } catch (e) {
    yield put(fetchTabsListRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * changeTabWatcher () {
  yield takeLatest(changeTabRoutine.TRIGGER, changeTab)
}

export function * fetchTabsListWatcher () {
  yield takeLatest(fetchTabsListRoutine.TRIGGER, fetchTabsList)
}

// SAGAS
export const tabsSagas = [fork(changeTabWatcher), fork(fetchTabsListWatcher)]
