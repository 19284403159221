import React, { useEffect, useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import EfficiencyStatsTable from 'modules/efficiecyStats/components/EfficiencyStatsTable'
import { getCurrentParsedQuery } from 'utils/navigation'
import { dissoc, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import FiltersButton from 'components/atoms/FiltersButton'
import EfficiencyStatsFilters from 'modules/efficiecyStats/components/EfficiencyStatsFilters'
import ExportEfficiencyStatsToCsvModal from 'modules/efficiecyStats/components/ExportEfficiencyStatsToCsvModal'
import { FiltersWrapper, HeaderWrapper } from 'theme/wrappers'

const EfficiencyStats = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const filters = dissoc('search', propOr({}, 'filter', query))
    setFiltersOpen(isNotNilOrEmpty(filters))
  }, [])

  const toggleFilters = () => setFiltersOpen(prev => !prev)

  return (
    <>
      <HeaderWrapper>
        <PageHeader withMargin>Statystyki efektywności</PageHeader>
        <FiltersWrapper>
          <ExportEfficiencyStatsToCsvModal/>
          <FiltersButton active={filtersOpen} onClick={toggleFilters} />
        </FiltersWrapper>
      </HeaderWrapper>
      <EfficiencyStatsFilters open={filtersOpen} />
      <EfficiencyStatsTable />
    </>
  )
}

export default EfficiencyStats
