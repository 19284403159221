import React, { useEffect, useState } from 'react'
import ExportButton from 'components/atoms/ExportButton'
import { Tooltip } from '@mui/material'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { downloadCSV } from 'utils/files'
import { exportStocktakingOrdersToCsv } from 'services/StocktakingOrdersService'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useHistory } from 'react-router-dom'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { dissoc, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import ButtonWrapper from 'theme/wrappers'

const emptyValues = {
  checked_after: '',
  checked_before: ''
}

const ExportStocktakingOrdersToFileModal = () => {
  const [values, setValues] = useState(emptyValues)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    setValues({
      ...emptyValues,
      ...propOr({}, 'filter', currentQuery)
    })
  }, [search])

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setLoading(false)
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    const currentQuery = getCurrentParsedQuery()
    let payload = {}

    for (let key in values) {
      if (isNotNilOrEmpty(values[key]) && values[key] !== 'all') {
        payload[key] = values[key]
      } else {
        payload = dissoc(key, payload)
      }
    }

    exportStocktakingOrdersToCsv({
      query: stringifyQuery({
        ...currentQuery,
        filter: payload
      })
    })
      .then(resp => {
        downloadCSV(
          resp.data,
          `Inwentaryzacja-${new Date().toLocaleDateString()}`
        )
        handleModalClose()
      })
      .catch(e => {
        toast.error(getApiErrors(e))
        handleModalClose()
      })
  }
  return (
    <>
      <Tooltip title='Export do pliku CSV'>
        <div>
          <ExportButton onClick={handleModalOpen} />
        </div>
      </Tooltip>

      <Modal open={open} title='Wybierz zakres dat' onClose={handleModalClose}>
        <Wrapper>
          <Input
            type='date'
            value={values.checked_after}
            name='checked_after'
            label='Data od'
            onChange={handleValueChange}
          />
          <Input
            type='date'
            value={values.checked_before}
            name='checked_before'
            label='Data do'
            onChange={handleValueChange}
          />
        </Wrapper>
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button
            type='submit'
            color='primary'
            onClick={handleSubmit}
            disabled={loading}
            isLoading={loading}
          >
            Zatwierdź
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default ExportStocktakingOrdersToFileModal

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
`
