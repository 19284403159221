import React, { useEffect, useMemo } from 'react'
import {
  fetchFlightControlSaleOrdersByStatusRoutine,
} from 'modules/flightControl/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectPackedOrders, selectPackedOrdersLoading } from 'modules/flightControl/ducks/selectors'
import styled from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import { values } from 'ramda'
import Table from 'components/Table'

const PackedOrdersTable = () => {
  const orders = useSelector(selectPackedOrders)
  const isLoading = useSelector(selectPackedOrdersLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFlightControlSaleOrdersByStatusRoutine({ status: 'packed' }))
  }, [])

  const couriers = useMemo(() => {
    if (isNotNilOrEmpty(orders)) {
      return values(orders)
    } else {
      return []
    }
  }, [orders])

  const headers = [
    { children: 'Kurier' },
    { children: 'Wszystkie' },
    { children: '1 SKU' },
    { children: 'Multi SKU' }
  ]

  const rows = couriers.map(courier => ({
    cells: [
      { children: courier.label },
      { children: courier.total },
      { children: courier.singleSku },
      { children: courier.multiSku }
    ]
  }))

  return (
    <Wrapper>
      <Header>Spakowane</Header>
      <Table
        rows={rows}
        headers={headers}
        isLoading={isLoading}
      />
    </Wrapper>
  )
}

export default PackedOrdersTable

const Wrapper = styled.div`
  max-width: 400px;
  min-width: 400px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f0f3f4;
`

const Header = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
`
