import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import MultipickOrderFilters from 'modules/multipick/components/filters/MultipickOrderFilters'
import MultipickProductFilters from 'modules/multipick/components/filters/MultipickProductFilters'
import MultipickListFilters from 'modules/multipick/components/filters/MultipickListFilters'
import MultipickSavedFilters from 'modules/multipick/components/filters/MultipickSavedFilters'
import Input from 'components/atoms/Input'
import { useDispatch, useSelector } from 'react-redux'
import {
  dissoc,
  find,
  flatten,
  head,
  omit,
  pathOr,
  propEq,
  propOr
} from 'ramda'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { ORDER_TYPES } from 'utils/multipick'
import { selectPackingTypesList } from 'modules/packingTypes/ducks/selectors'
import Button from 'components/atoms/Button'
import {
  changeQuery,
  getCurrentParsedQuery,
  stringifyQuery
} from 'utils/navigation'
import { useHistory } from 'react-router-dom'
import {
  clearSelectedMultipickDatesRoutine,
  clearSelectedMultipickValuesRoutine,
  clearSimulatedMultipicksRoutine,
  currentGeneratedOrdersRoutine,
  fetchMultipickDictionaryRoutine,
  fetchMultipickRulesetsListRoutine,
  saveSelectedMultipickDatesRoutine,
  saveSelectedMultipickValuesRoutine,
  simulateMultipicksRoutine
} from 'modules/multipick/ducks/actions'
import {
  selectIsDictionaryFetching,
  selectMultipickDictionary,
  selectSavedMultipicksDates,
  selectSavedMultipicksValues
} from 'modules/multipick/ducks/selectors'
import { generateMultipickLists } from 'services/MultipickService'
import MultipickSimulatedOrders from 'modules/multipick/components/filters/MultipickSimulatedOrders'
import { CircularProgress } from '@mui/material'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

const MultipickFilters = () => {
  const values = useSelector(selectSavedMultipicksValues)
  const dates = useSelector(selectSavedMultipicksDates)
  const [listName, setListName] = useState('')
  const packagingTypesList = useSelector(selectPackingTypesList)
  const {
    location: { search }
  } = useHistory()
  const dispatch = useDispatch()
  const multipickDictionary = useSelector(selectMultipickDictionary)
  const courierGroups = propOr([], 'courierGroups', multipickDictionary)
  const sources = propOr([], 'sources', multipickDictionary)
  const isLoading = useSelector(selectIsDictionaryFetching)
  const [isCreatingLists, setIsCreatingLists] = useState(false)

  useEffect(() => {
    const query = getCurrentParsedQuery()
    if (isNotNilOrEmpty(pathOr(null, ['filter', 'ordered_before'], query))) {
      dispatch(
        fetchMultipickDictionaryRoutine({
          query: stringifyQuery(dissoc('activeTab', query))
        })
      )
    } else {
      changeQuery({
        filter: dates
      })
    }
  }, [search])

  const handleValueChange = (name, value) => {
    const newValues = {
      ...values,
      [name]: value
    }
    dispatch(saveSelectedMultipickValuesRoutine({ values: newValues }))
  }

  const handleDateChange = (name, value) => {
    const newDates = {
      ...dates,
      [name]: value
    }
    dispatch(saveSelectedMultipickDatesRoutine({ values: newDates }))
    const currentQuery = getCurrentParsedQuery()
    changeQuery({
      ...currentQuery,
      filter: {
        ...currentQuery.filter,
        [name]: value
      }
    })
  }

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    if (
      currentQuery.ordered_after !== dates.ordered_after ||
      currentQuery.ordered_before !== dates.ordered_before
    ) {
      changeQuery({
        ...currentQuery,
        filter: {
          ...currentQuery.filter,
          ...dates
        }
      })
    }
  }, [dates])

  useEffect(() => {
    const addPart = (value, prefix) => {
      if (typeof value === 'object') {
        const isMix = value.length > 1
        return isNotNilOrEmpty(value)
          ? `${prefix ? `${prefix}-` : ''}${
              isMix ? 'MIX' : pathOr('', [0, 'name'], value)
            };`
          : ''
      } else {
        return isNotNilOrEmpty(value)
          ? `${prefix ? `${prefix}-` : ''}${value};`
          : ''
      }
    }
    const courier = propOr(
      '',
      'name',
      find(propEq('id', values.courierGroupId))(courierGroups)
    )
    const type = ORDER_TYPES[propOr('undefined', 'orderType', values)]
    const source = propOr([], 'sources', values).map(sourceId =>
      find(propEq('id', sourceId))(sources)
    )
    const packagingTypes = propOr([], 'packagingTypes', values).map(typeId =>
      find(propEq('id', typeId))(packagingTypesList)
    )
    const productTypes = [
      { name: 'Standardowe', value: values.allowStandard },
      { name: 'Ciężkie', value: values.allowHeavy },
      { name: 'Nieporęczne', value: values.allowCumbersome }
    ].filter(type => !!type.value)

    // eslint-disable-next-line max-len
    setListName(
      `${addPart(courier, 'Kurier')}${addPart(type, '')}${addPart(
        packagingTypes,
        'Pakowanie'
      )}${addPart(source, 'Źródło')}${addPart(productTypes, 'Typ')}`
    )
  }, [values])

  const handleRulesetSet = ruleset => () => {
    const valuesToSet = {
      ...omit(
        ['id', 'courierGroups', 'createdAt', 'deletedAt', 'name'],
        ruleset
      ),
      courierGroupId: propOr(
        '',
        'id',
        head(propOr([], 'courierGroups', ruleset))
      ),
      packagingTypes: propOr([], 'packagingTypes', ruleset).map(v => v.id),
      name: ruleset.listName,
      sources: propOr([], 'sources', ruleset).map(v => v.id),
      orderType: pathOr([], ['orderType', 'value'], ruleset),
      limitBy: propOr('items_count', 'limitBy', ruleset),
      stockUnitKinds: propOr([], 'stockUnitKinds', ruleset).map(v => v.id)
    }

    dispatch(saveSelectedMultipickValuesRoutine({ values: valuesToSet }))

    setTimeout(() => {
      setListName(ruleset.listName)
    }, 0)
  }

  const handleResetFilters = () => {
    dispatch(clearSelectedMultipickValuesRoutine())
    dispatch(clearSelectedMultipickDatesRoutine())
    dispatch(clearSimulatedMultipicksRoutine())
    dispatch(currentGeneratedOrdersRoutine([]))
  }

  const handleSimulateFilters = () => {
    setIsCreatingLists(true)
    const body = {
      ...omit(['courierGroupId', 'name', 'priority'], values),
      courierGroups: [values.courierGroupId].filter(i => isNotNilOrEmpty(i)),
      minItems: Number(values.minItems),
      maxItems: Number(values.maxItems),
      minUniqueProducts: Number(values.minUniqueProducts),
      maxUniqueProducts: Number(values.maxUniqueProducts)
    }
    const query = getCurrentParsedQuery()
    dispatch(currentGeneratedOrdersRoutine([]))
    dispatch(
      simulateMultipicksRoutine({
        query: stringifyQuery(dissoc('activeTab', query)),
        body,
        onSuccess: () => setIsCreatingLists(false),
        onFail: () => setIsCreatingLists(false)
      })
    )
  }

  const handleGenerateLists = () => {
    setIsCreatingLists(true)
    const body = {
      ...omit(['courierGroupId'], values),
      name: listName,
      courierGroups: [values.courierGroupId].filter(i => isNotNilOrEmpty(i)),
      minItems: Number(values.minItems),
      maxItems: Number(values.maxItems),
      minUniqueProducts: Number(values.minUniqueProducts),
      maxUniqueProducts: Number(values.maxUniqueProducts)
    }
    const query = getCurrentParsedQuery()
    const queryPayload = stringifyQuery(dissoc('activeTab', query))

    generateMultipickLists({
      query: queryPayload,
      body
    })
      .then(response => {
        setIsCreatingLists(false)
        dispatch(fetchMultipickRulesetsListRoutine({ query: queryPayload }))
        dispatch(fetchMultipickDictionaryRoutine({ query: queryPayload }))
        const { data } = response
        const orders = flatten(data.data.map(item => item.orders))
        dispatch(currentGeneratedOrdersRoutine(orders))

        isNotNilOrEmpty(data.data)
          ? toast.success('Pomyślnie utworzono listy kompletacji')
          : toast.error('Lista kompletacji dla wybranych filtrów jest pusta. Sprawdź ustawienia.')
      })
      .catch(e => {
        toast.error(getApiErrors(e))
        setIsCreatingLists(false)
      })
  }

  const isGenerateDisabled = useMemo(() => {
    const valuesToCheck = [
      values.courierGroupId,
      listName,
      values.priority,
      values.orderType
    ]
    return valuesToCheck.some(value => isNilOrEmpty(value))
  }, [values, listName])

  return (
    <Wrapper>
      {(isLoading || isCreatingLists) && (
        <LoadingState>
          <CircularProgress />
        </LoadingState>
      )}
      <FiltersWrapper>
        <MultipickOrderFilters
          values={values}
          handleValueChange={handleValueChange}
          dates={dates}
          handleDateChange={handleDateChange}
        />
        <MultipickProductFilters
          values={values}
          handleValueChange={handleValueChange}
        />
        <MultipickListFilters
          values={values}
          handleValueChange={handleValueChange}
        />
        <MultipickSavedFilters
          listName={listName}
          values={values}
          handleRulesetSet={handleRulesetSet}
        />
      </FiltersWrapper>
      <ListNameWrapper>
        <ListNameLabel>Nazwa listy:</ListNameLabel>
        <Input
          name='listName'
          value={listName}
          onChange={(_, value) => setListName(value)}
        />
        <ButtonsWrapper>
          <Button color='primary-outline' onClick={handleResetFilters}>
            Resetuj
          </Button>
          <Button
            color='secondary'
            disabled={
              isNilOrEmpty(values.orderType) ||
              isNilOrEmpty(values.courierGroupId)
            }
            onClick={handleSimulateFilters}
          >
            Sprawdź ustawienia
          </Button>
          <Button
            color='primary'
            disabled={isGenerateDisabled}
            onClick={handleGenerateLists}
          >
            Generuj listy
          </Button>
        </ButtonsWrapper>
      </ListNameWrapper>
      <MultipickSimulatedOrders handleSimulateFilters={handleSimulateFilters} />
    </Wrapper>
  )
}

export default MultipickFilters

const Wrapper = styled.div`
  position: relative;
`

const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  .MuiFilledInput-root {
    height: 56px !important;
    font-size: 12px !important;
  }

  input {
    font-size: 12px !important;
  }

  & > div {
    padding: 0 20px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    &:not(:last-of-type) {
      border-right: 1px solid ${({ theme }) => theme.colors.border};
    }
  }
`

const ListNameWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 30px 0;
`

const ListNameLabel = styled.div`
  min-width: fit-content;
  font-weight: bold;
  font-size: 18px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  min-width: 550px;
`

const LoadingState = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
`
