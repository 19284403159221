import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { MultipickFiltersTitle } from 'modules/multipick/components/filters/MultipickFiltersCommonComponents'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCouriersGroupsListRoutine } from 'modules/couriers/ducks/actions'
import { stringifyQuery } from 'utils/navigation'
import Input from 'components/atoms/Input'
import { selectMultipickDictionary } from 'modules/multipick/ducks/selectors'
import { propOr } from 'ramda'
import DateInput from 'components/atoms/DateInput'

const MultipickOrderFilters = ({ values, dates, handleDateChange, handleValueChange }) => {
  const dispatch = useDispatch()
  const multipickDictionary = useSelector(selectMultipickDictionary)
  const couriersGroups = propOr([], 'courierGroups', multipickDictionary)
  const sources = propOr([], 'sources', multipickDictionary)
  const orderTypes = propOr({}, 'orderTypes', multipickDictionary)

  useEffect(() => {
    dispatch(fetchCouriersGroupsListRoutine({
      query: stringifyQuery({
        page: 1,
        limit: 1000
      })
    }))
  }, [])

  const courierOptions = useMemo(() => {
    return couriersGroups.map(group => ({
      label: `${group.name} (${group.matchingOrdersCount})`,
      value: group.id,
      disabled: group.matchingOrdersCount < 1
    }))
  }, [couriersGroups])

  const sourcesOptions = useMemo(() => {
    return sources.map(source => ({
      label: `${source.name} (${source.matchingOrdersCount})`,
      value: source.id,
      disabled: source.matchingOrdersCount < 1
    }))
  }, [sources])

  return (
    <Wrapper>
      <MultipickFiltersTitle>Parametry zamówień</MultipickFiltersTitle>
      <Row>
        <DateInput
          name='ordered_after'
          value={dates.ordered_after}
          label='Data od'
          showTimeSelect
          onChange={handleDateChange}
        />
        <DateInput
          name='ordered_before'
          value={dates.ordered_before}
          label='Data do'
          showTimeSelect
          onChange={handleDateChange}
        />
      </Row>
      <Row>
        <Input
          select
          options={[
            { label: `1 SKU (${orderTypes.singleSku})`, value: 'single_sku', disabled: orderTypes.singleSku < 1 },
            { label: `Multi SKU (${orderTypes.multiSku})`, value: 'multi_sku', disabled: orderTypes.multiSku < 1 }
          ]}
          name='orderType'
          label='Typ zamówienia'
          value={values.orderType}
          onChange={handleValueChange}
        />
        <Input
          select
          options={courierOptions}
          name='courierGroupId'
          label='Kurier'
          value={values.courierGroupId}
          onChange={handleValueChange}
        />
      </Row>
      <Input
        select
        multiple
        options={sourcesOptions}
        name='sources'
        label='Źródło'
        value={values.sources}
        onChange={handleValueChange}
      />
    </Wrapper>
  )
}

export default MultipickOrderFilters

const Wrapper = styled.div`
  min-width: 350px !important;
`

const Row = styled.div`
  display: flex;
  gap: 10px;
`
