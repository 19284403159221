import React from 'react'
import ContentPanel from 'components/atoms/ContentPanel'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { pathOr, propOr } from 'ramda'
import {
  selectCurrentStockUnit,
  selectCurrentStockUnitLoading
} from 'modules/stockUnits/ducks/selectors'
import LoadingSpinner from 'components/atoms/LoadingSpinner'

const BasicStockUnitDetails = () => {
  const stockUnit = useSelector(selectCurrentStockUnit)
  const isLoading = useSelector(selectCurrentStockUnitLoading)

  return (
    <ContentPanel withMargin>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <LocationName>
            Jednostka magazynowa{' '}
            <span>
              {stockUnit.prefix}-{stockUnit.code}
            </span>
          </LocationName>
          <DetailedInfo>
            <DetailItem>
              Liczba produktów:{' '}
              <span>{propOr(0, 'productsQuantity', stockUnit)}</span>
            </DetailItem>
            <DetailItem>
              Waga produktów:{' '}
              <span>
                {propOr(0, 'productsWeight', stockUnit)}
                {pathOr('', ['productsWeightUnit'], stockUnit)}
              </span>
            </DetailItem>
            <DetailItem>
              Rodzaj: <span>{pathOr('---', ['kind', 'name'], stockUnit)}</span>
            </DetailItem>
            <DetailItem>
              Lokalizacja:{' '}
              <span>{pathOr('---', ['location', 'name'], stockUnit)}</span>
            </DetailItem>
            <DetailItem>
              Magazyn:{' '}
              <span>
                {pathOr('---', ['location', 'warehouse', 'name'], stockUnit)}
              </span>
            </DetailItem>
            <DetailItem>
              Strefa:{' '}
              <span>
                {pathOr('---', ['location', 'zone', 'name'], stockUnit)}
              </span>
            </DetailItem>
          </DetailedInfo>
        </>
      )}
    </ContentPanel>
  )
}

export default BasicStockUnitDetails

const LocationName = styled.div`
  font-size: 24px;
  span {
    font-weight: bold;
  }
`

const DetailItem = styled.div`
  font-size: 12px;
  text-transform: uppercase;

  span {
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
  }
`

const DetailedInfo = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 20px;
`
