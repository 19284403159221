import React, { useEffect, useMemo, useState } from 'react'
import AddButton from 'components/atoms/AddButton'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  validateCreateStockUnitValues,
  validateCreateStockUnitField
} from 'modules/stockUnits/ducks/schema'
import {
  createStockUnitRoutine,
  fetchStockUnitKindsRoutine
} from 'modules/stockUnits/ducks/actions'
import { propOr } from 'ramda'
import { getCsvCodes } from 'services/StockUnitsService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { isNotNilOrEmpty } from 'utils/ramda'
import { downloadCSV } from 'utils/files'
import { selectStockUnitKindList } from 'modules/stockUnits/ducks/selectors'

const emptyValues = {
  prefix: '',
  type: 'permanent',
  location: '',
  count: 1,
  kind_id: ''
}

const AddStockUnitModal = () => {
  const [open, setOpen] = useState(false)
  const [created, setCreated] = useState(false)
  const [csvString, setCsvString] = useState('')
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const stockUnitKindsList = useSelector(selectStockUnitKindList)

  useEffect(() => {
    dispatch(fetchStockUnitKindsRoutine())
  }, [])

  const stockUnitKindsOptions = useMemo(() => {
    return stockUnitKindsList.map(kind => ({
      label: kind.name,
      value: kind.id
    }))
  }, [stockUnitKindsList])

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
    setCreated(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateCreateStockUnitValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = data => {
      const codes = propOr([], 'data', data).map(unit => unit.code)
      getCsvCodes({ codes })
        .then(resp => {
          setCsvString(resp.data)
        })
        .catch(err => {
          toast.error(getApiErrors(err))
        })
      setCreated(true)
    }

    dispatch(
      createStockUnitRoutine({
        values: {
          ...values,
          location: values.location.toLowerCase()
        },
        callback: handleSuccess
      })
    )
  }

  const handleDownload = () => {
    downloadCSV(csvString, `kody_jednostek-${new Date().toLocaleDateString()}`)
    handleModalClose()
  }

  return (
    <>
      <AddButton onClick={handleModalOpen}>Dodaj jednostki</AddButton>
      <Modal
        open={open}
        title='Dodaj jednostki magazynowe'
        onClose={handleModalClose}
      >
        {created && isNotNilOrEmpty(csvString) ? (
          <div>
            <Button onClick={handleDownload}>Pobierz CSV z kodami</Button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <Input
              name='location'
              label='Lokalizacja'
              value={values.location}
              onChange={handleValueChange}
              validate={validateCreateStockUnitField(values)}
            />
            <Input
              name='prefix'
              label='Prefix'
              value={values.prefix}
              onChange={handleValueChange}
              validate={validateCreateStockUnitField(values)}
            />
            <Input
              select
              name='type'
              label='Typ jednostki'
              value={values.type}
              onChange={handleValueChange}
              options={[
                { label: 'Stała', value: 'permanent' },
                { label: 'Tymczasowa', value: 'disposable' }
              ]}
            />
            <Input
              name='count'
              label='Liczba jednostek'
              value={values.count}
              onChange={handleValueChange}
              validate={validateCreateStockUnitField(values)}
            />
            <Input
              select
              options={stockUnitKindsOptions}
              value={values.kind_id}
              name='kind_id'
              label='Rodzaj jednostki'
              onChange={handleValueChange}
              validate={validateCreateStockUnitField(values)}
            />
            <Button type='submit' color='primary' disabled={!valid}>
              Dodaj
            </Button>
          </form>
        )}
      </Modal>
    </>
  )
}

export default AddStockUnitModal
