import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import { useDispatch } from 'react-redux'
import Button from 'components/atoms/Button'
import RemoveIcon from 'components/icons/RemoveIcon'
import ButtonWrapper from 'theme/wrappers'
import { removeProductRoutine } from '../ducks/actions'
import { redirect } from 'utils/navigation'
import PATHS from 'utils/paths'

const RemoveProductModal = ({ product, isProductDetailsView }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    redirect(PATHS.products)
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(
      removeProductRoutine({
        id: product.id,
        callback: handleModalClose
      })
    )
  }

  return (
    <>
      {isProductDetailsView ? (
        <Button
          medium
          onClick={handleModalOpen}
          color='primary'
          disabled={product.totalQuantity > 0}
        >
          Usuń produkt
        </Button>
      ) : (
        <RemoveIcon
          disabled={product.totalQuantity > 0}
          onClick={handleModalOpen}
        />
      )}
      <Modal open={open} title='Usuń produkt' onClose={handleModalClose}>
        Czy na pewno chcesz usunąć produkt &quot;{product.name}&quot;
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Usuń
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default RemoveProductModal
