import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import Table from 'components/Table'
import { propOr } from 'ramda'
import styled from 'styled-components'
import { getUserRoleLogsRoutine } from 'modules/users/ducks/actions'
import { selectUsersLogs, selectUsersLogsLoading } from 'modules/users/ducks/selectors'
import { fetchUserRolesDictionaryRoutine } from 'ducks/dictionaries/actions'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import ContentPanel from 'components/atoms/ContentPanel'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'username',
  filter: {
    created_after: moment().format(DATE_FORMATS.input),
    created_before: moment().format(DATE_FORMATS.input)
  }
}

const EfficiencyStatsTable = () => {
  const list = useSelector(selectUsersLogs)
  const isLoading = useSelector(selectUsersLogsLoading)
  const dispatch = useDispatch()
  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(getUserRoleLogsRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  useEffect(() => {
    const parsedQuery = getCurrentParsedQuery()
    dispatch(fetchUserRolesDictionaryRoutine())
    changeQuery({
      ...parsedQuery,
      filter: {
        ...defaultFilters.filter,
        ...propOr({}, 'filter', parsedQuery)
      }
    })
  }, [])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Operator' },
    { children: 'Ilość zadań' },
    { children: 'Waga zebranych produktów (kg)' },
    { children: 'Ilość zadań łączenia' },
    { children: 'Ilość paczek 1 SKU' },
    { children: 'Ilość paczek MULTI' },
    { children: 'Waga skontrolowanych paczek (kg)' },
    { children: 'Ilość zadań uzupełnienia' },
    { children: 'Ilość linii produktów uzupełnionych' },
    { children: 'Ilość przyjętych produktów w dostawie' },
    { children: 'Ilość przesunięć produktów' },
    { children: 'Ilość linii produktów przesuniętych' },
    { children: 'Ilość wykonanych zadań inwentaryzacji' },
    {
      children: 'Ilość zinwentaryzowanych linii produktów',
      sort: 'stocktaking_product_lines_made_number'
    },
    { children: 'Ilość wykonanych zmian ilości produktów' },
    { children: 'Ilość linii produktów dodanych/odjętych ze stanu' }
  ]

  const rows =
    list &&
    list.map(row => ({
      cells: [
        { children: propOr(0, ['username'], row) },
        { children: propOr(0, ['picksNumber'], row) },
        { children: propOr(0, ['productsWeight'], row) / 1000 },
        { children: propOr(0, ['joinTasksNumber'], row) },
        { children: propOr(0, ['inspectedPackagesSkuNumber'], row) },
        { children: propOr(0, ['inspectedPackagesMultiSkuNumber'], row) },
        { children: propOr(0, ['inspectedPackagesWeight'], row) / 1000 },
        { children: propOr(0, ['replenishmentTasksNumber'], row) },
        { children: propOr(0, ['replenishmentTasksProductLinesNumber'], row) },
        { children: propOr(0, ['productsReceivedInDeliveryNumber'], row) },
        { children: propOr(0, ['productsMovesNumber'], row) },
        { children: propOr(0, ['productLinesMovesNumber'], row) },
        { children: propOr(0, ['stocktakingOrdersMadeNumber'], row) },
        { children: propOr(0, ['stocktakingProductLinesMadeNumber'], row) },
        { children: propOr(0, ['productQuantityChangesMadeNumber'], row) },
        { children: propOr(0, ['productsChangedOnStockNumber'], row) }
      ]
    }))

  return (
    <ContentPanel>
      <TableWrapper>
        <Table
          onPageChange={handlePageChange}
          headers={headers}
          rows={rows}
          emptyState='Brak statystyk'
          isLoading={isLoading}
        />
      </TableWrapper>
    </ContentPanel>
  )
}

export default EfficiencyStatsTable

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`
