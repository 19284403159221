import { fetchSettingsRoutine } from 'modules/settings/ducks/actions'

const defaultState = {
  list: []
}

const settingsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchSettingsRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    default:
      return state
  }
}

export default settingsReducer
