import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentLocation } from 'modules/locations/ducks/selectors'
import { pathOr, propOr } from 'ramda'
import Table from 'components/Table'

const LocationProductsTab = () => {
  const location = useSelector(selectCurrentLocation)
  const products = propOr([], 'inventory', location)

  const headers = [
    { children: 'EAN' },
    { children: 'Nazwa' },
    { children: 'Ilość' },
    { children: 'Zablokowane (inwentaryzacja)' }
  ]

  const rows = products.map(function (row) {
    const isBlocked = pathOr(false, ['isBlocked'], row)

    return {
      redirectPath: `/products/${pathOr('', ['product', 'id'], row)}`,
      cells: [
        { children: pathOr('---', ['product', 'ean'], row) },
        { children: pathOr('---', ['product', 'name'], row) },
        { children: pathOr('---', ['quantity'], row) },
        { children: isBlocked ? 'Tak' : 'Nie' }
      ]
    }
  })

  return (
    <div>
      <Table
        headers={headers}
        rows={rows}
        emptyState='Brak produktów w lokalizacji'
      />
    </div>
  )
}

export default LocationProductsTab
