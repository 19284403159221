import { validateField, validateValues } from 'utils/form'
import { object, string } from 'yup'

export const zoneSchema = object().shape({
  name: string().required('Nazwa jest wymagana'),
  storageType: string().required('Typ składowania jest wymagany'),
  zoneType: string().required('Typ strefy jest wymagany'),
})

export const zoneFiltersSchema = object().shape({
  storage_type: string().required('Typ składowania jest wymagany'),
  zone_type: string().required('Typ strefy jest wymagany'),
})

export const validateZoneField = values => validateField(zoneSchema, values)
export const validateZoneValues = validateValues(zoneSchema)
export const validateFiltersField = values => validateField(zoneFiltersSchema, values)
export const validateFiltersValues = validateValues(zoneFiltersSchema)
