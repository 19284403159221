import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectFlightControlCutOffTime,
  selectFlightControlSummary
} from 'modules/flightControl/ducks/selectors'
import { propOr } from 'ramda'
import styled from 'styled-components'

const FlightControlStats = () => {
  const summary = useSelector(selectFlightControlSummary)
  // hidden because loading state for summary lasts to long
  // const isLoading = useSelector(selectFlightControlSummaryLoading)
  const packersSummary = propOr({}, 'packers', summary)
  const connectorsSummary = propOr({}, 'connectors', summary)
  const pickersSummary = propOr({}, 'collectors', summary)
  const cutOffTime = useSelector(selectFlightControlCutOffTime)

  return (
    <SummaryWrapper>
      {/* {isLoading ? (
        <LoadingSpinner />
      ) : ( */}
      <>
        <SummarySection>
          <SummarySectionTitle>Pakowanie</SummarySectionTitle>
          <SummarySectionContent>
            <SummaryBox color='#7e2099'>
              <Value>{propOr(0, 'currentRate', packersSummary)}/h</Value>
              <Label>Tempo pakowania</Label>
            </SummaryBox>
            <SummaryBox color='#0072a7'>
              <Value>{propOr(0, 'goal', packersSummary)}</Value>
              <Value>({propOr(0, 'estimatedGoal', packersSummary)})</Value>
              <Label>Pozostało do spakowania</Label>
            </SummaryBox>
            <SummaryBox color='#0092d5'>
              <Label>Do {cutOffTime} spakujemy</Label>
              <Value>{propOr(0, 'estimatedFinalCount', packersSummary)}</Value>
              <Label>Paczek</Label>
            </SummaryBox>
            <SummaryBox color='#004f74'>
              <Label>Potrzeba</Label>
              <Value>{propOr(0, 'personnelNeeded', packersSummary)}</Value>
              <Label>pakujących</Label>
            </SummaryBox>
          </SummarySectionContent>
        </SummarySection>
        <SummarySection>
          <SummarySectionTitle>Łącznik</SummarySectionTitle>
          <SummarySectionContent>
            <SummaryBox color='#7e2099'>
              <Value>{propOr(0, 'currentRate', connectorsSummary)}/h</Value>
              <Label>Tempo łączenia</Label>
            </SummaryBox>
          </SummarySectionContent>
        </SummarySection>
        <SummarySection>
          <SummarySectionTitle>Zbieranie</SummarySectionTitle>
          <SummarySectionContent>
            <SummaryBox color='#7e2099'>
              <Value>{propOr(0, 'currentRate', pickersSummary)}/h</Value>
              <Label>Tempo zbierania</Label>
            </SummaryBox>
            <SummaryBox color='#0092d5'>
              <Label>Do {cutOffTime} zbierzemy</Label>
              <Value>{propOr(0, 'estimatedFinalCount', pickersSummary)}</Value>
              <Label>Paczek</Label>
            </SummaryBox>
            <SummaryBox color='#004f74'>
              <Label>Potrzeba</Label>
              <Value>{propOr(0, 'personnelNeeded', pickersSummary)}</Value>
              <Label>Zbieraczy</Label>
            </SummaryBox>
          </SummarySectionContent>
        </SummarySection>
      </>
      {/* )} */}
    </SummaryWrapper>
  )
}

export default FlightControlStats

const SummaryWrapper = styled.div`
  display: flex;
  gap: 30px;
  position: relative;
  width: 100%;
`

const SummarySection = styled.div``

const SummarySectionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`

const SummarySectionContent = styled.div`
  display: flex;
  gap: 5px;
`

const SummaryBox = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ color }) => color || '#666'};
  border-radius: 6px;
  color: #fff;
  height: 91px;
`

const Label = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`

const Value = styled.div`
  font-size: 18px;
  font-weight: bold;
`
