import { CircularProgress } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const LoadingSpinner = () => {
  return (
    <LoadingState>
      <CircularProgress />
    </LoadingState>
  )
}

export default LoadingSpinner

const LoadingState = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 160px;
  width: 100%;
`
