import {
  fetchFlightControlSaleOrdersByStatusRoutine,
  fetchFlightControlSaleOrdersRoutine,
  fetchFlightControlSettingsRoutine,
  fetchFlightControlStatsByRoleRoutine,
  fetchFlightControlSummaryRoutine
} from 'modules/flightControl/ducks/actions'

const defaultState = {
  summary: {},
  saleOrders: [],
  settings: {},
  // hidden because loading state for summary lasts to long
  // isLoadingSummary: false,
  isLoadingSaleOrders: false,
  isLoadingByStatus: {
    packed: false,
    for_packing: false,
    allocated: false,
    collected: false
  },
  isLoadingStatsByRole: {
    picker: false,
    packer: false,
    connector: false
  },
  isLoadingSettings: false
}

const flightControlReducer = (state = defaultState, action) => {
  switch (action.type) {
    // hidden because loading state for summary lasts to long
    // case fetchFlightControlSummaryRoutine.TRIGGER:
    //   return {
    //     ...state,
    //     isLoadingSummary: true
    //   }
    // case fetchFlightControlSummaryRoutine.FAILURE:
    //   return {
    //     ...state,
    //     isLoadingSummary: false
    //   }
    case fetchFlightControlSummaryRoutine.SUCCESS:
      return {
        ...state,
        summary: action.payload,
        // isLoadingSummary: false
      }
    case fetchFlightControlSaleOrdersRoutine.TRIGGER:
      return {
        ...state,
        isLoadingSaleOrders: true
      }
    case fetchFlightControlSaleOrdersRoutine.FAILURE:
      return {
        ...state,
        isLoadingSaleOrders: false
      }
    case fetchFlightControlSaleOrdersRoutine.SUCCESS:
      return {
        ...state,
        saleOrders: action.payload,
        isLoadingSaleOrders: false
      }
    case fetchFlightControlSaleOrdersByStatusRoutine.TRIGGER:
      return {
        ...state,
        isLoadingByStatus: {
          ...state.isLoadingByStatus,
          [action.payload.status]: true
        }
      }
    case fetchFlightControlSaleOrdersByStatusRoutine.FAILURE:
      return {
        ...state,
        isLoadingByStatus: {
          ...state.isLoadingByStatus,
          [action.payload.status]: false
        }
      }
    case fetchFlightControlSaleOrdersByStatusRoutine.SUCCESS:
      return {
        ...state,
        [action.payload.status]: action.payload.data,
        isLoadingByStatus: {
          ...state.isLoadingByStatus,
          [action.payload.status]: false
        }
      }
    case fetchFlightControlStatsByRoleRoutine.TRIGGER:
      return {
        ...state,
        isLoadingStatsByRole: {
          ...state.isLoadingStatsByRole,
          [action.payload.role]: true
        }
      }
    case fetchFlightControlStatsByRoleRoutine.FAILURE:
      return {
        ...state,
        isLoadingStatsByRole: {
          ...state.isLoadingStatsByRole,
          [action.payload.role]: false
        }
      }
    case fetchFlightControlStatsByRoleRoutine.SUCCESS:
      return {
        ...state,
        [action.payload.role]: action.payload.data,
        isLoadingStatsByRole: {
          ...state.isLoadingStatsByRole,
          [action.payload.role]: false
        }
      }
    case fetchFlightControlSettingsRoutine.TRIGGER:
      return {
        ...state,
        isLoadingSettings: true
      }
    case fetchFlightControlSettingsRoutine.FAILURE:
      return {
        ...state,
        isLoadingSettings: false
      }
    case fetchFlightControlSettingsRoutine.SUCCESS:
      return {
        ...state,
        settings: action.payload,
        isLoadingSettings: false
      }
    default:
      return state
  }
}

export default flightControlReducer
