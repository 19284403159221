import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectStockUnits = state => state.stockUnits

export const selectStockUnitsList = createSelector(
  selectStockUnits,
  propOr([], 'list')
)

export  const selectStockUnitsListLoading = createSelector(
  selectStockUnits,
  propOr(false, 'isLoading')
)

export const selectCurrentStockUnit = createSelector(
  selectStockUnits,
  propOr([], 'currentStockUnit')
)

export const selectCurrentStockUnitLoading = createSelector(
  selectStockUnits,
  propOr(false, 'isLoadingCurrentStockUnit')
)

export const selectStockUnitHistoryList = createSelector(
  selectStockUnits,
  propOr([], 'stockUnitHistoryList')
)

export const selectStockUnitHistoryListLoading = createSelector(
  selectStockUnits,
  propOr(false, 'isLoadingStockUnitHistoryList')
)

export const selectStockUnitKindList = createSelector(
  selectStockUnits,
  propOr([], 'stockUnitKindList')
)

export const selectStockUnitKindListLoading = createSelector(
  selectStockUnits,
  propOr(false, 'isLoadingStockUnitKindList')
)

export const selectStockUnitsPagination = createSelector(
  selectStockUnits,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'pagination'
  )
)

export const selectStockUnitHistoryListPagination = createSelector(
  selectStockUnits,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'stockUnitHistoryListPagination'
  )
)
