import APIService from './APIService'

const api = APIService()

export const fetchMultipickRulesets = payload => api.get(`/multipicks/rulesets${payload.query}`)
export const createMultipickRuleset = payload => api.post('/multipicks/rulesets', payload)
export const getMultipickDictionary = payload => api.get(`/multipicks/rulesets/dictionaries${payload.query}`)
export const deleteMultipickRuleset = payload => api.delete(`/multipicks/rulesets/${payload.id}`)
export const simulateMultipickRuleset = payload => api.post(`/multipicks/rulesets/simulate${payload.query}`, payload.body)
export const generateMultipickLists = payload => api.post(`/multipicks/rulesets/generate-lists${payload.query}`, payload.body)
export const fetchGeneratedMultipickLists = payload => api.get(`/multipicks/completion-lists${payload.query}`)
export const getMultipickListDetails = payload => api.get(`/multipicks/completion-lists/${payload.id}`)
export const changeMultipickListsPriority = payload => api.patch('/multipicks/completion-lists/priority', payload)
export const deleteCompletionList = payload => api.delete(`/multipicks/completion-lists/${payload.id}`)
export const bulkDeleteCompletionLists = payload => api.patch(`/multipicks/completion-lists/discard/`, payload)
export const createMultipickListManually = payload => api.post('/multipicks/completion-lists/generate-for-sale-orders', payload)
