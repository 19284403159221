import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as inventoryService from 'services/InventoryService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchInventoryMoveLogsRoutine = createRoutine('FETCH_INVENTORY_MOVE_LOGS')

// ACTIONS
function * fetchMoveLogs ({ payload }) {
  yield put(fetchInventoryMoveLogsRoutine.request())
  try {
    const { data } = yield call(inventoryService.fetchInventoryMoveLogs, payload)
    yield put(fetchInventoryMoveLogsRoutine.success(data))
  } catch (e) {
    yield put(fetchInventoryMoveLogsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * fetchInventoryMoveLogsWatcher () {
  yield takeLatest(fetchInventoryMoveLogsRoutine.TRIGGER, fetchMoveLogs)
}

// SAGAS
export const moveLogsSagas = [
  fork(fetchInventoryMoveLogsWatcher)
]
