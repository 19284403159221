import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import styled from 'styled-components'
import { propOr } from 'ramda'

const AutoComplete = props => {
  const {
    name,
    type,
    variant,
    size,
    placeholder,
    onChange,
    onBlur,
    onFocus,
    label,
    required,
    initialValue,
    validate,
    options,
    disabled,
    setSearchQuery,
    setAutocompleteOptions
  } = props

  const [selectedValue, setSelectedValue] = useState(initialValue)
  const [touched, _setTouched] = useState(false)
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: ''
  })

  useEffect(() => {
    if (touched) {
      validate(name, v => {
        _validate({ valid: v.valid, error: v.errors })
      })
    }
  }, [selectedValue, touched])

  useEffect(() => {
    setSelectedValue(initialValue)
  }, [initialValue])

  const handleBlur = () => {
    _setTouched(true)
    typeof onBlur === 'function' && onBlur()
    setAutocompleteOptions([])
  }
  const handleFocus = () => {
    typeof onFocus === 'function' && onFocus()
  }
  const handleChange = (_setTouched, value) => {
    onChange(name, propOr('', 'value', value))
    setSelectedValue(value)
  }

  const handleChangeQuery = e => {
    setSearchQuery(e.target.value)
  }

  return (
    <InputWrapper>
      <Autocomplete
        options={options}
        value={selectedValue}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChangeQuery}
            placeholder={placeholder}
            name={name}
            type={type}
            variant={variant}
            size={size}
            required={required}
            error={!valid && touched && !disabled}
            helperText={error}
            fullWidth
          />
        )}
      />
    </InputWrapper>
  )
}

AutoComplete.defaultProps = {
  name: '',
  type: 'text',
  size: 'big',
  label: '',
  value: '',
  required: false,
  variant: 'filled',
  disabled: false,
  inputProps: {},
  placeholder: '',
  onChange: () => {},
  onBlur: () => {},
  validate: () => {},
  onFocus: () => {}
}

export default AutoComplete

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? 0 : '20px')};
  background-color: #fff;
  width: 100%;

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary.main};
  }

  .MuiSelect-select {
    &:focus {
      background-color: #fff !important;
    }
  }

  .MuiFilledInput-root {
    background-color: #fff !important;

    &:hover,
    &:active,
    &:focus {
      background-color: #fff !important;
    }
  }

  .Mui-error {
    color: ${({ theme }) => theme.colors.error} !important;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    margin: 0;
  }
`
