import React, { useEffect } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { pathOr, propOr } from 'ramda'
import { fetchSaleOrdersRoutine } from 'modules/saleOrders/ducks/actions'
import {
  selectIsLoading,
  selectSaleOrdersList,
  selectSaleOrdersListPagination
} from 'modules/saleOrders/ducks/selectors'
import { DATE_FORMATS, formatDate } from 'utils/date'
import CreateListForSingleOrderModal from './CreateListForSingleOrderModal'
import CancelSaleOrderModal from './CancelSaleOrderModal'
import { ActionsContainer } from 'theme/wrappers'
import SublistNumbersList from './SublistNumbersList'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'status',
  activeTab: 'active'
}

const SaleOrdersTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectSaleOrdersList)
  const pagination = useSelector(selectSaleOrdersListPagination)
  const isLoading = useSelector(selectIsLoading)

  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchSaleOrdersRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Numer IAI', sort: 'iai_order_serial_number' },
    { children: 'Status', sort: 'status' },
    { children: 'Data zamówienia', sort: 'ordered_at' },
    { children: 'Data importu', sort: 'created_at' },
    { children: 'Nabywca', sort: 'iai_client_login' },
    { children: 'Kurier', sort: 'courier_id' },
    { children: 'Źródło', sort: 'source_name' },
    { children: 'z B2B?', sort: 'is_from_b2b_platform' },
    { children: 'Numer listy kompletacji' },
    { children: 'Nazwa listy kompletacji' },
    { children: 'JM' },
    { children: 'Notatka' },
    { children: 'Komentarz' },
    { children: '', align: 'right' }
  ]

  const rows = list.map(row => {
    const stockUnits = propOr([], 'stockUnits', row).map(item => item.code)
    const uniqueStockUnits = Array.from(new Set(stockUnits))
    const completionList = propOr({}, 'completionList', row)

    return {
      redirectPath: `/sale-orders/${row.id}`,
      cells: [
        { children: propOr('---', 'iaiOrderSerialNumber', row) },
        { children: pathOr('---', ['status', 'label'], row) },
        {
          children: formatDate(
            propOr('---', 'orderedAt', row),
            DATE_FORMATS.dashedWithTime
          )
        },
        {
          children: formatDate(
            propOr('---', 'createdAt', row),
            DATE_FORMATS.dashedWithTime
          )
        },
        { children: propOr('---', 'iaiClientLogin', row) },
        { children: pathOr('---', ['courier', 'name'], row) },
        { children: pathOr('---', ['source', 'name'], row) },
        { children: propOr(false, 'isFromB2bPlatform', row) ? '✔' : '✗' },
        {
          children: isNotNilOrEmpty(completionList) ? (
            <SublistNumbersList completionList={completionList} />
          ) : (
            propOr('---', 'listNumber', completionList)
          ),
          preventRedirect: true
        },
        {
          children: propOr('---', 'name', completionList)
        },
        {
          children:
            uniqueStockUnits.length > 0 ? (
              uniqueStockUnits.map(item => {
                return <div key={item}>{item}</div>
              })
            ) : (
              <div>{'---'}</div>
            )
        },
        { children: propOr('', 'clientNote', row) || '---' },
        { children: propOr('', 'comment', row) || '---' },
        ['new', 'allocated', 'unallocated'].includes(
          pathOr('', ['status', 'value'], row)
        ) && {
          children: <CancelSaleOrderModal saleOrder={row} />,
          preventRedirect: true
        },
        {
          children: (
            <ActionsContainer>
              {['suspended', 'new'].includes(
                pathOr('', ['status', 'value'], row)
              ) && <CreateListForSingleOrderModal orderId={row.id} />}
            </ActionsContainer>
          ),
          align: 'right',
          preventRedirect: true
        }
      ]
    }
  })

  return (
    <>
      <OrdersCounter>Liczba zamówień: {pagination.itemsCount}</OrdersCounter>
      <Table
        pagination={pagination}
        onPageChange={handlePageChange}
        headers={headers}
        rows={rows}
        emptyState='Brak zamówień sprzedaży do wyświetlenia'
        isLoading={isLoading}
      />
    </>
  )
}

export default SaleOrdersTable

const OrdersCounter = styled.div`
  margin-bottom: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary.main};
`
