import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectSaleOrders = state => state.saleOrders

export const selectSaleOrdersList = createSelector(
  selectSaleOrders,
  propOr([], 'list')
)

export const selectIsLoading = createSelector(
  selectSaleOrders,
  propOr(false, 'isLoading')
)

export const selectSuspendedSaleOrdersList = createSelector(
  selectSaleOrders,
  propOr([], 'suspendedList')
)

export const selectSuspendedSaleOrdersListPagination = createSelector(
  selectSaleOrders,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'suspendedPagination'
  )
)

export const selectCurrentSaleOrder = createSelector(
  selectSaleOrders,
  propOr({}, 'currentSaleOrder')
)

export const selectCurrentSaleOrderLoading = createSelector(
  selectSaleOrders,
  propOr(false, 'isLoadingCurrentSaleOrder')
)

export const selectSaleOrdersStatistics = createSelector(
  selectSaleOrders,
  propOr([], 'statistics')
)

export const selectSaleOrdersListPagination = createSelector(
  selectSaleOrders,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'pagination'
  )
)

export const selectStatusLogList = createSelector(
  selectSaleOrders,
  propOr([], 'statusLogList')
)

export const selectStatusLogListLoading = createSelector(
  selectSaleOrders,
  propOr(false, 'isLoadingStatusLogList')
)

export const selectStatuLogListPagination = createSelector(
  selectSaleOrders,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'statusLogListPagination'
  )
)
