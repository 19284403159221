import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AppLogo from 'components/atoms/AppLogo'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { loginUserRoutine } from 'modules/auth/ducks/actions'
import { validateLoginField, validateLoginValues } from 'modules/auth/ducks/schema'
import { selectIsLoggingIn } from 'modules/auth/ducks/selectors'

const emptyValues = {
  username: '',
  password: ''
}

const LoginForm = () => {
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false)
  const isLoading = useSelector(selectIsLoggingIn)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateLoginValues(values, setValid)
  }, [values])

  const handleResetPasswordModalOpen = () => {
    setForgotPasswordModalOpen(true)
  }

  const handleResetPasswordModalClose = () => {
    setForgotPasswordModalOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(loginUserRoutine({
      username: values.username.toLowerCase(),
      password: values.password
    }))
  }

  return (
    <Wrapper>
      <AppLogo />
      <Form onSubmit={handleSubmit}>
        <Input
          label='Login'
          name='username'
          onChange={handleValueChange}
          value={values.username}
          validate={validateLoginField(values)}
        />
        <Input
          type='password'
          label='Hasło'
          name='password'
          onChange={handleValueChange}
          value={values.password}
          validate={validateLoginField(values)}
          noMarginBottom
        />
        <ForgotPasswordWrapper>
          <ForgotPasswordButton onClick={handleResetPasswordModalOpen}>
            Zapomniałeś hasła?
          </ForgotPasswordButton>
        </ForgotPasswordWrapper>
        <Button
          color='primary'
          type='submit'
          isLoading={isLoading}
          disabled={!valid || isLoading}
        >
          Zaloguj
        </Button>
      </Form>
      <Modal
        open={forgotPasswordModalOpen}
        title='Reset hasła'
        onClose={handleResetPasswordModalClose}
      >
        Aby zresetować hasło skontaktuj się ze swoim przełożonym.
      </Modal>
    </Wrapper>
  )
}

export default LoginForm

const Wrapper = styled.div`
  padding: 30px;
  width: 400px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  z-index: 1;
`

const Form = styled.form`
  margin-top: 20px;
`

const ForgotPasswordWrapper = styled.div`
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ForgotPasswordButton = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  cursor: pointer;
  width: fit-content;
  transition: all .3s;
  font-size: 14px;
  
  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`
