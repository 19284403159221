import React from 'react'
import { identity, compose } from 'ramda'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootReducer, { rootSaga } from 'ducks/rootReducer'

const ReduxProvider = ({ children }) => {
  const middleware = createSagaMiddleware()
  const loggerMiddleware = createLogger({ collapsed: true })

  const middlewares = [middleware]

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(loggerMiddleware)
  }

  const reduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : identity()

  const enhancer = compose(applyMiddleware(...middlewares), reduxDevtools)
  const store = createStore(rootReducer, enhancer)

  middleware.run(rootSaga)

  return <Provider store={store}>{children}</Provider>
}

export default ReduxProvider
