import { validateField, validateValues } from 'utils/form'
import { isNotNilOrEmpty } from 'utils/ramda'
import { object, string } from 'yup'

export const createStockUnitSchema = object().shape({
  prefix: string().required('Pole wymagane'),
  location: string().required('Pole wymagane'),
  type: string().required('Pole wymagane'),
  count: string()
    .required('Pole wymagane')
    .matches(/^[1-9][0-9]*$/, 'Musi być wartością liczbową, większą od 0'),
  kind_id: string().required('Pole wymagane')
})

export const stockUnitHistoryFiltersSchema = object().shape({
  quantity: string()
    .matches(/^\d+$/, 'Musi być wartością liczbową')
    .nullable()
    .transform(value => (isNotNilOrEmpty(value) ? value : null))
})

export const validateStockUnitHistoryFiltersField = values =>
  validateField(stockUnitHistoryFiltersSchema, values)
export const validateStockUnitHistoryFiltersValues = validateValues(
  stockUnitHistoryFiltersSchema
)

export const validateCreateStockUnitField = values =>
  validateField(createStockUnitSchema, values)
export const validateCreateStockUnitValues = validateValues(
  createStockUnitSchema
)
