import APIService from './APIService'
import { dissoc } from 'ramda'

const api = APIService()

export const fetchCouriers = payload => api.get(`/couriers${payload.query}`)
export const addCouriersGroup = payload => api.post('/couriers/groups', payload)
export const updateCouriersGroup = payload =>
  api.patch(`/couriers/groups/${payload.id}`, dissoc('id', payload))
export const deleteCourierGroup = payload =>
  api.delete(`/couriers/groups/${payload.id}`)
export const fetchUngroupedCouriers = () => api.get('/couriers/ungrouped')
export const fetchCouriersGroups = payload =>
   api.get(`/couriers/groups${payload.query}`)
export const fetchSingleCourierGroup = payload =>
  api.get(`/couriers/groups/${payload.id}`)
export const updateCourier = payload => api.patch(`/couriers/${payload.id}`, dissoc('id', payload))
