import { isEmpty, not, pathOr, pipe, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectAuth = state => state.auth

export const selectCurrentUser = createSelector(
  selectAuth,
  propOr({}, 'user')
)

export const selectCurrentUserActiveRole = createSelector(
  selectAuth,
  pathOr('', ['user', 'activeRole', 'name'])
)

export const selectIsLoggingIn = createSelector(
  selectAuth,
  propOr(false, 'isLoading')
)

export const selectIsLoggedIn = createSelector(
  selectAuth,
  pipe(
    propOr({}, 'user'),
    isEmpty,
    not
  )
)
