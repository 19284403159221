import React from 'react'
import Tabs from 'components/atoms/Tabs'
import ContentPanel from 'components/atoms/ContentPanel'
import StockUnitsProductsTab from 'modules/stockUnitDetails/components/StockUnitsProductsTab'
import StockUnitsHistoryTab from 'modules/stockUnitDetails/components/StockUnitsHistoryTab'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { isAuthorized } from 'utils/navigation'
import { filter, propOr } from 'ramda'

const StockUnitsDetailsTabs = () => {
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRoles = ['admin', 'foreman', 'bok']

  const tabs = [
    {
      key: 'products',
      title: 'Produkty',
      children: <StockUnitsProductsTab />
    },
    {
      key: 'history_of_changes',
      title: 'Historia zmian',
      children: <StockUnitsHistoryTab />,
      isList: true
    }
  ]

  const basicTabs = filter((item => item.key !== 'history_of_changes'), tabs)

  return (
    <ContentPanel>
      <Tabs tabs={isAuthorized(priviledgedRoles, userRoles) ? tabs : basicTabs} />
    </ContentPanel>
  )
}

export default StockUnitsDetailsTabs
