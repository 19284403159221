import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as locationsService from 'services/LocationsService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'

export const fetchLocationsRoutine = createRoutine('FETCH_LOCATIONS')
export const fetchSingleLocationRoutine = createRoutine('FETCH_SINGLE_LOCATION')
export const createLocationRoutine = createRoutine('CREATE_LOCATION')
export const updateLocationRoutine = createRoutine('UPDATE_LOCATION')
export const removeLocationRoutine = createRoutine('REMOVE_LOCATION')
export const fetchLocationHistoryRoutine = createRoutine('FETCH_LOCATION_HISTORY')

// ACTIONS
function * fetchLocations ({ payload }) {
  yield put(fetchLocationsRoutine.request())
  try {
    const { data } = yield call(locationsService.fetchLocations, payload)
    yield put(fetchLocationsRoutine.success(data))
  } catch (e) {
    yield put(fetchLocationsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchSingleLocation ({ payload }) {
  yield put(fetchSingleLocationRoutine.request())
  try {
    const { data } = yield call(locationsService.fetchSingleLocation, payload)
    yield put(fetchSingleLocationRoutine.success(data.data))
  } catch (e) {
    yield put(fetchSingleLocationRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * createLocation ({ payload }) {
  const { values, callback } = payload
  yield put(createLocationRoutine.request())
  try {
    yield call(locationsService.createLocation, values)
    yield put(createLocationRoutine.success())
    yield put(
      fetchLocationsRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie utworzono lokalizację')
  } catch (e) {
    yield put(createLocationRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * updateLocation ({ payload }) {
  const { values, callback } = payload
  yield put(updateLocationRoutine.request())
  try {
    yield call(locationsService.updateLocation, values)
    yield put(updateLocationRoutine.success())
    yield put(
      fetchLocationsRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie edytowano lokalizację')
  } catch (e) {
    yield put(updateLocationRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * removeLocation ({ payload }) {
  const { id, callback } = payload
  yield put(removeLocationRoutine.request())
  try {
    yield call(locationsService.removeLocation, { id })
    yield put(removeLocationRoutine.success())
    yield put(
      fetchLocationsRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie usunięto lokalizację')
  } catch (e) {
    yield put(removeLocationRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchLocationHistory ({ payload }) {
  yield put(fetchLocationHistoryRoutine.request())
  try {
    const { data } = yield call(locationsService.fetchLocationHistory, payload)
    yield put(fetchLocationHistoryRoutine.success(data))
  } catch (e) {
    yield put(fetchLocationHistoryRoutine.failure(e))
    console.error(e)
  }
}

// WATCHERS
export function * fetchLocationsWatcher () {
  yield takeLatest(fetchLocationsRoutine.TRIGGER, fetchLocations)
}

export function * fetchSingleLocationWatcher () {
  yield takeLatest(fetchSingleLocationRoutine.TRIGGER, fetchSingleLocation)
}

export function * createLocationWatcher () {
  yield takeLatest(createLocationRoutine.TRIGGER, createLocation)
}

export function * updateLocationWatcher () {
  yield takeLatest(updateLocationRoutine.TRIGGER, updateLocation)
}

export function * removeLocationWatcher () {
  yield takeLatest(removeLocationRoutine.TRIGGER, removeLocation)
}

export function * fetchLocationHistoryWatcher () {
  yield takeLatest(fetchLocationHistoryRoutine.TRIGGER, fetchLocationHistory)
}

// SAGAS
export const locationsSagas = [
  fork(fetchLocationsWatcher),
  fork(fetchSingleLocationWatcher),
  fork(createLocationWatcher),
  fork(removeLocationWatcher),
  fork(updateLocationWatcher),
  fork(fetchLocationHistoryWatcher)
]
