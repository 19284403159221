import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import { useDispatch } from 'react-redux'
import {
  fetchSaleOrdersRoutine,
  fetchSaleOrdersStatisticsRoutine,
  fetchSingleSaleOrderRoutine
} from '../ducks/actions'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { updateSaleOrder } from 'services/SaleOrdersService'
import ImportButton from 'components/atoms/ImportButton'
import { propOr } from 'ramda'
import ButtonWrapper from 'theme/wrappers'

const AllowReimportSaleOrdersModal = ({ saleOrder }) => {
  const id = propOr('', 'id', saleOrder)
  const allowReimport = propOr(false, 'allowReimportFromIai', saleOrder)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = () => {
      dispatch(
        fetchSaleOrdersRoutine({
          query: stringifyQuery(getCurrentParsedQuery())
        })
      )
      dispatch(fetchSaleOrdersStatisticsRoutine())
      dispatch(fetchSingleSaleOrderRoutine({ id }))
      toast.success('Możliwy ponowny import zamówienia')
      handleModalClose()
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    updateSaleOrder({ id, allowReimport: true })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <ImportButton onClick={handleModalOpen} disabled={allowReimport}>
        Zezwól na ponowny import
      </ImportButton>
      <Modal
        open={open}
        title='Ponowny import zamówienia'
        onClose={handleModalClose}
      >
        Czy na pewno chcesz zezwolić na ponowny import?
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Zezwól
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default AllowReimportSaleOrdersModal
