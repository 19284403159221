import APIService from './APIService'
import { dissoc } from 'ramda'

const api = APIService()

export const createDelivery = payload => api.post('/deliveries', payload)
export const updateDelivery = payload => api.patch(`/deliveries/${payload.id}`, dissoc('id', payload))
export const getDeliveries = payload => api.get(`/deliveries${payload.query}`)
export const getDefaultFilteredDeliveries = payload => api.get(`/deliveries/not-confirmed-or-with-diff${payload.query}`)
export const getSingleDelivery = payload => api.get(`/deliveries/${payload.id}`)
export const deleteDelivery = payload => api.delete(`/deliveries/${payload.id}`)
export const getDeliveryItems = payload => api.get(`/deliveries/${payload.id}/items${payload.query}`)
export const deleteDeliveryItem = payload => api.delete(`/deliveries/${payload.deliveryId}/items/${payload.id}`)
export const updateDeliveryItem = payload =>
 api.patch(
    `/deliveries/${payload.deliveryId}/items/${payload.deliveryItemId}`,
    payload.body
  )
export const updateDeliveryItemInventory = payload =>
  api.patch(
    `/deliveries/${payload.deliveryId}/items/${payload.productId}/inventories/${payload.stockUnitId}`,
    { quantity: payload.quantity }
  )
