import React from 'react'
import LockResetIcon from '@mui/icons-material/LockReset'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'

const ResetPasswordIcon = ({ onClick }) => {
  return (
    <Tooltip title='Zresetuj hasło'>
      <Content>
        <StyledIcon onClick={onClick} />
      </Content>
    </Tooltip>
  )
}

export default ResetPasswordIcon

const StyledIcon = styled(LockResetIcon)`
  cursor: pointer;
  font-size: 18px !important;
`

const Content = styled.div`
  display: flex;
  align-items: center;
`
