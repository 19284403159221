import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import PATHS from 'utils/paths'
import Login from 'pages/Login'
import SideNavigation from 'components/navigation/SideNavigation'
import TopNavigation from 'components/navigation/TopNavigation'
import Products from 'pages/Products'
import Warehouses from 'pages/Warehouses'
import Delivery from 'pages/Delivery'
import Users from 'pages/Users'
import Settings from 'pages/Settings'
import Modal from 'components/atoms/Modal'
import ChangePasswordForm from 'modules/auth/components/ChangePasswordForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentUser,
  selectIsLoggedIn
} from 'modules/auth/ducks/selectors'
import { propOr } from 'ramda'
import {
  authCheckRoutine,
  changeUserPasswordRoutine,
  fetchAuthUserRoutine,
  logoutUserRoutine,
  switchUserRoleRoutine
} from 'modules/auth/ducks/actions'
import PackingTypes from 'pages/PackingTypes'
import ProductDetails from 'pages/ProductDetails'
import Zones from 'pages/Zones'
import PrivateRoute from 'components/PrivateRoute'
import PageNotFound from 'pages/PageNotFound'
import Unauthorized from 'pages/Unauthorized'
import Locations from 'pages/Locations'
import LocationDetails from 'pages/LocationDetails'
import Packing from 'pages/Packing'
import Multipick from 'pages/Multipick'
import FlightControl from 'pages/FlightControl'
import SaleOrders from 'pages/SaleOrders'
import StockUnits from 'pages/StockUnits'
import WarehouseState from 'pages/WarehouseState'
import WarehouseDealings from 'pages/WarehouseDealings'
import EfficiencyStats from 'pages/EfficiencyStats'
import Integrations from 'pages/Integrations'
import ZoneDetails from 'pages/ZoneDetails'
import StocktakingOrders from 'pages/StocktakingOrders'
import SaleOrderDetails from 'pages/SaleOrderDetails'
import Couriers from 'pages/Couriers'
import StockUnitDetails from 'pages/StockUnitDetails'
import ImportJobsReport from 'pages/ImportJobsReport'
import Suppliers from 'pages/Suppliers'
import DeliveryDetails from 'pages/DeliveryDetails'
import Replenishments from 'pages/Replenishments'
import StockUnitKinds from 'pages/StockUnitKinds'
import PackerStatistics from 'pages/PackerStatistics'
import InventoryMoveLogs from 'pages/InventoryMoveLogs'
import DeliveryOrdersLogs from 'pages/DeliveryOrdersLogs'
import { isNilOrEmpty } from 'utils/ramda'

const App = () => {
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const user = useSelector(selectCurrentUser)
  const needsNewPassword = propOr(false, 'forcePasswordReset', user)
  const dispatch = useDispatch()
  const userRoles = propOr([], 'roles', user)
  const { pathname } = useLocation()

  useEffect(() => {
    setPasswordModalOpen(isLoggedIn && needsNewPassword)
  }, [needsNewPassword, isLoggedIn])

  const handlePasswordSave = password => {
    dispatch(
      changeUserPasswordRoutine({
        currentPassword: process.env.REACT_APP_DEFAULT_PASSWORD,
        newPassword: password,
        newPasswordConfirmation: password
      })
    )
  }

  useEffect(() => {
    dispatch(fetchAuthUserRoutine())
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        dispatch(authCheckRoutine())
      }
    })
    window.addEventListener('storage', () => {
      isNilOrEmpty(localStorage.getItem('role')) &&
        dispatch(switchUserRoleRoutine())
    })
  }, [])

  useEffect(() => {
    const currentPath = pathname
    if (currentPath !== PATHS.packing) {
      if (userRoles.includes('packer')) {
        userRoles.length > 1
          ? dispatch(switchUserRoleRoutine())
          : dispatch(logoutUserRoutine())
      }
    }
  }, [pathname])

  return (
    <>
      {isLoggedIn && (
        <>
          <TopNavigation />
          <SideNavigation />
        </>
      )}
      <AppContent isLoggedIn={isLoggedIn}>
        <Switch>
          <Route exact path={PATHS.home} component={Login} />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman', 'bok', 'lists_creator']}
            path={PATHS.control}
            component={FlightControl}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman', 'lists_creator']}
            path={PATHS.lists}
            component={Multipick}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman', 'packer']}
            path={PATHS.packing}
            component={Packing}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman', 'bok', 'lists_creator']}
            path={PATHS.saleOrders}
            component={SaleOrders}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman', 'bok', 'lists_creator']}
            path={PATHS.saleOrderDetails}
            component={SaleOrderDetails}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={[
              'admin',
              'foreman',
              'stocktaker',
              'inbound',
              'warehouseman',
              'bok',
              'lists_creator'
            ]}
            path={PATHS.stockUnits}
            component={StockUnits}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={[
              'admin',
              'foreman',
              'stocktaker',
              'inbound',
              'warehouseman',
              'bok',
              'lists_creator'
            ]}
            path={PATHS.stockUnitDetails}
            component={StockUnitDetails}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin']}
            path={PATHS.stockUnitKinds}
            component={StockUnitKinds}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={[
              'admin',
              'foreman',
              'stocktaker',
              'bok',
              'lists_creator'
            ]}
            path={PATHS.stocktakingOrders}
            component={StocktakingOrders}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin']}
            path={PATHS.couriers}
            component={Couriers}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman']}
            path={PATHS.warehouseState}
            component={WarehouseState}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman']}
            path={PATHS.warehouseDealings}
            component={WarehouseDealings}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman']}
            path={PATHS.efficiencyStats}
            component={EfficiencyStats}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={[
              'admin',
              'foreman',
              'stocktaker',
              'inbound',
              'warehouseman',
              'bok',
              'lists_creator',
              'picker',
              'packer',
              'connector',
              'outbound'
            ]}
            path={PATHS.unauthorized}
            component={Unauthorized}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={[
              'admin',
              'foreman',
              'stocktaker',
              'inbound',
              'warehouseman',
              'bok',
              'lists_creator'
            ]}
            path={PATHS.products}
            component={Products}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={[
              'admin',
              'foreman',
              'stocktaker',
              'inbound',
              'warehouseman',
              'bok',
              'lists_creator'
            ]}
            path={PATHS.productDetails}
            component={ProductDetails}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={[
              'admin',
              'foreman',
              'stocktaker',
              'inbound',
              'warehouseman',
              'bok',
              'lists_creator'
            ]}
            path={PATHS.locations}
            component={Locations}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={[
              'admin',
              'foreman',
              'stocktaker',
              'inbound',
              'warehouseman',
              'bok',
              'lists_creator'
            ]}
            path={PATHS.locationDetails}
            component={LocationDetails}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin']}
            path={PATHS.warehouses}
            component={Warehouses}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin']}
            path={PATHS.zones}
            component={Zones}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin']}
            path={PATHS.zoneDetails}
            component={ZoneDetails}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman']}
            path={PATHS.packingTypes}
            component={PackingTypes}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman', 'inbound']}
            path={PATHS.delivery}
            component={Delivery}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman', 'inbound']}
            path={PATHS.deliveryDetails}
            component={DeliveryDetails}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin']}
            path={PATHS.users}
            component={Users}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin']}
            path={PATHS.settings}
            component={Settings}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin']}
            path={PATHS.integrations}
            component={Integrations}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin']}
            path={PATHS.importJobsReport}
            component={ImportJobsReport}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin']}
            path={PATHS.suppliers}
            component={Suppliers}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={[
              'admin',
              'foreman',
              'stocktaker',
              'warehouseman',
              'lists_creator'
            ]}
            path={PATHS.replenishments}
            component={Replenishments}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman']}
            path={PATHS.packerStatistics}
            component={PackerStatistics}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={[
              'admin',
              'foreman',
              'inbound',
              'bok',
              'lists_creator'
            ]}
            path={PATHS.moveLogs}
            component={InventoryMoveLogs}
            userRoles={userRoles}
          />
          <PrivateRoute
            exact
            allowedRoles={['admin', 'foreman', 'stocktaker', 'inbound', 'bok']}
            path={PATHS.deliveryOrdersLogs}
            component={DeliveryOrdersLogs}
            userRoles={userRoles}
          />
          <Route path='*'>
            <PageNotFound />
          </Route>
        </Switch>
      </AppContent>
      <Modal title='Zmiana hasła' open={passwordModalOpen} hideClose>
        <HintText>Aby korzystać z systemu musisz ustawić swoje hasło.</HintText>
        <ChangePasswordForm onSave={handlePasswordSave} />
      </Modal>
    </>
  )
}

export default App

const AppContent = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme, isLoggedIn }) =>
    isLoggedIn
      ? `calc(${theme.dimensions.topBarHeight} + 15px) 15px 15px calc(${theme.dimensions.sideBarWidth} + 15px)`
      : '15px'};
`

const HintText = styled.div`
  margin-bottom: 10px;
`
