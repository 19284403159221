import React, { useMemo } from 'react'
import { Tooltip } from '@mui/material'
import styled from 'styled-components'
import { redirect } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'

const LocalizationsTooltip = ({ totalQuantity, locations }) => {
  const tooltipContent = useMemo(() => {
    return locations.map(location => (
      <LocationItem key={location.id}>
        <LocationName
          onClick={() => redirect(`/locations/${location.id}`)}
        >
          {location.name}
        </LocationName>
        <div>{location.quantity}</div>
      </LocationItem>
    ))
  }, [locations])

  return (
    <Tooltip
      placement='bottom-start'
      title={(
        <TooltipContent
          onClick={e => e.stopPropagation()}
        >
          {isNotNilOrEmpty(locations)
            ? tooltipContent
            : <div>Brak lokalizacji dla produktu</div>
          }
        </TooltipContent>
      )}
    >
      <div>{totalQuantity}</div>
    </Tooltip>
  )
}

export default LocalizationsTooltip

const TooltipContent = styled.div`
  width: 150px;
  color: ${({ theme }) => theme.colors.text};
`

const LocationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`

const LocationName = styled.div`
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`
