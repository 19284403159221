import React from 'react'
import PageHeader from 'components/atoms/PageHeader'
import ContentPanel from 'components/atoms/ContentPanel'
import PackingTypesTable from 'modules/packingTypes/components/PackingTypesTable'
import AddPackingTypeModal from 'modules/packingTypes/components/AddPackingTypeModal'
import { HeaderWrapper } from 'theme/wrappers'

const PackingTypes = () => {
  return (
    <>
      <HeaderWrapper>
        <PageHeader>Typy opakowań</PageHeader>
        <AddPackingTypeModal />
      </HeaderWrapper>
      <ContentPanel>
        <PackingTypesTable />
      </ContentPanel>
    </>
  )
}

export default PackingTypes
