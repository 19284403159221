import { validateField, validateValues } from 'utils/form'
import { object, string } from 'yup'

export const flightControlSchema = object().shape({
  flightControlEstimate: string()
    .required('Pole jest wymagane')
    .matches(/^\d+$/, 'Musi być wartością liczbową')
    .nullable(),
  flightControlProductsMultiplier: string()
    .required('Pole jest wymagane')
    .matches(/^([0-9]+([.][0-9]*)?|[.][0-9]+)$/, 'Musi być wartością liczbową')
    .nullable(),
  flightControlEndOfPacking: string().required('Pole jest wymagane').nullable()
})

export const validateFlightControlField = values =>
  validateField(flightControlSchema, values)
export const validateFlightControlValues = validateValues(flightControlSchema)

export const flightControlCourierSchema = object().shape({
  cutOffTime: string().required('Pole jest wymagane'),
  couriersPercentage: string()
    .required('Pole jest wymagane')
    .matches(/^\d+$/, 'Musi być wartością liczbową')
    .nullable(),
  singleSkuPercentage: string()
    .required('Pole jest wymagane')
    .matches(/^\d+$/, 'Musi być wartością liczbową')
    .nullable(),
  multiSkuPercentage: string()
    .required('Pole jest wymagane')
    .matches(/^\d+$/, 'Musi być wartością liczbową')
    .nullable()
})

export const validateFlightControlCourierField = values =>
  validateField(flightControlCourierSchema, values)
export const validateFlightControlCourierValues = validateValues(
  flightControlCourierSchema
)
