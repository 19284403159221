import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { logoutUserRoutine } from 'modules/auth/ducks/actions'
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined'

const LogoutButton = () => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logoutUserRoutine())
  }

  return (
    <ItemWrapper onClick={handleLogout}>
      <IconWrapper>
        <StyledIcon />
      </IconWrapper>
      <ItemLabel>
        Wyloguj
      </ItemLabel>
    </ItemWrapper>
  )
}

export default LogoutButton

const ItemWrapper = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    div, svg {
      color: ${({ theme }) => theme.colors.secondary.main} !important;  
    }
  }
`

const IconWrapper = styled.div`
  min-width: ${({ theme }) => theme.dimensions.sideBarWidth};
  max-width: ${({ theme }) => theme.dimensions.sideBarWidth};
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ItemLabel = styled.div`
  color: #fff;
  transition: all .3s;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
`

const StyledIcon = styled(PowerSettingsNewOutlinedIcon)`
  color: #fff;
  transition: all .3s !important;
`
