import React, { useEffect } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { fetchZonesRoutine } from 'modules/zones/ducks/actions'
import { selectZonesList, selectZonesListLoading, selectZonesListPagination } from 'modules/zones/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { useHistory } from 'react-router-dom'
import { selectZonesDictionary } from 'ducks/dictionaries/selectors'
import { propOr } from 'ramda'
import { toCamelCase } from 'js-convert-case'
import EditZoneModal from 'modules/zones/components/EditZoneModal'
import RemoveZoneModal from 'modules/zones/components/RemoveZoneModal'
import { ActionsContainer } from 'theme/wrappers'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'name'
}

const ZonesTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectZonesList)
  const pagination = useSelector(selectZonesListPagination)
  const isLoading = useSelector(selectZonesListLoading)
  const zonesDictionary = useSelector(selectZonesDictionary)
  const storageTypes = propOr({}, 'storageTypes', zonesDictionary)
  const zoneTypes = propOr({}, 'zoneTypes', zonesDictionary)
  const { location: { search } } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchZonesRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Nazwa strefy', sort: 'name' },
    { children: 'Typ strefy' },
    { children: 'Typ składowania' },
    { children: '', align: 'right' }
  ]

  const rows = list.map(row => ({
    cells: [
      { children: row.name },
      { children: propOr('---', toCamelCase(row.zoneType), zoneTypes) },
      { children: propOr('---', toCamelCase(row.storageType), storageTypes) },
      {
        children: (
          <ActionsContainer>
            <EditZoneModal zone={row} />
            <RemoveZoneModal zone={row} />
          </ActionsContainer>
        ),
        align: 'right',
        preventRedirect: true
      }
    ]
  }))

  return (
    <Table
      pagination={pagination}
      onPageChange={handlePageChange}
      headers={headers}
      rows={rows}
      emptyState='Brak stref do wyświetlenia'
      isLoading={isLoading}
    />
  )
}

export default ZonesTable
