import { validateField, validateValues } from 'utils/form'
import { object, string } from 'yup'
import { isNotNilOrEmpty } from 'utils/ramda'

export const createDeliverySchema = object().shape({
  scheduledFor: string().required('Data jest wymagana'),
  supplierId: string().required('Dostawca jest wymagany'),
  comment: string().required('Komentarz jest wymagany'),
  priority: string().required('Priorytet jest wymagany')
})

export const validateCreateDeliveryField = values =>
  validateField(createDeliverySchema, values)
export const validateCreateDeliveryValues = validateValues(createDeliverySchema)

export const updateDeliverySchema = object().shape({
  scheduledFor: string().required('Data jest wymagana'),
  supplierId: string().required('Dostawca jest wymagany'),
  warehouseId: string()
    .nullable()
    .transform(value => (isNotNilOrEmpty(value) ? value : null)),
  documentNo: string().required('Numer dokumentu jest wymagany')
    .nullable()
    .transform(value => (isNotNilOrEmpty(value) ? value : null)),
  ordinalNo: string().required('Numer porządkowy jest wymagany')
    .matches(/^\d+$/, 'Musi być wartością liczbową')
    .nullable()
    .transform(value => (isNotNilOrEmpty(value) ? value : null)),
  comment: string().required('Komentarz jest wymagany'),
  priority: string().required('Priorytet jest wymagany')
})

export const validateUpdateDeliveryField = values =>
  validateField(updateDeliverySchema, values)
export const validateUpdateDeliveryValues = validateValues(updateDeliverySchema)

export const updateDeliveryItemSchema = object().shape({
  ordinalNumber: string()
  .required('Ilość produktów jest wymagana')
  .matches(/^[1-9][0-9]*$/, 'Musi być wartością liczbową nie mniejszą niż jeden'),
  productName: string()
    .required('Nazwa jest wymagana')
    .min(3, 'Minimum 3 znaki'),
  productEan: string()
    .required('Ean jest wymagany')
    .matches(/^\d+$/, 'Musi być wartością liczbową')
})

export const validateUpdateDeliveryItemField = values =>
  validateField(updateDeliveryItemSchema, values)
export const validateUpdateDeliveryItemValues = validateValues(
  updateDeliveryItemSchema
)

export const updateStockUnitAmountSchema = object().shape({
  quantity: string()
    .required('Ilość produktów jest wymagana')
    .matches(/^[1-9][0-9]*$/, 'Musi być wartością liczbową nie mniejszą niż jeden'),
})

export const validateStockUnitAmountField = value => {
  return validateField(updateStockUnitAmountSchema, value)
}

export const validateStockUnitAmountValues = validateValues(updateStockUnitAmountSchema)
