import { validateField, validateValues } from 'utils/form'
import { object, string } from 'yup'

export const createListSchema = object().shape({
  name: string()
    .required('Nazwa jest wymagana'),
  priority: string()
    .required('Priorytet jest wymagany')
    .matches(/^\d+$/, 'Musi być wartością liczbową'),
  comment: string()
})

export const validateCreateListField = values => validateField(createListSchema, values)
export const validateCreateListValues = validateValues(createListSchema)
