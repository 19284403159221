import React from 'react'
import Modal from 'components/atoms/Modal'
import styled from 'styled-components'
import Button from 'components/atoms/Button'
import { ActionsContainer } from 'theme/wrappers'

const PackingEndWithMissingProductsModal = ({
  open,
  setEndWithMissingModalOpen,
  onHandleFinalize
}) => {
  const handleCancel = () => {
    setEndWithMissingModalOpen(false)
  }

  return (
    <Modal open={open} title='Zakończ kontrolę z brakiem'>
      <Content>
        <ContentText>
          Czy na pewno chcesz zakończyć kontrolę JM z brakiem?
        </ContentText>
        <ActionsContainer>
          <Button color='primary-outline' onClick={handleCancel}>
            Wróć
          </Button>
          <Button color='primary' onClick={onHandleFinalize}>
            Zakończ
          </Button>
        </ActionsContainer>
      </Content>
    </Modal>
  )
}

export default PackingEndWithMissingProductsModal

const Content = styled.div`
  text-align: center;
  font-size: 24px;

  span {
    font-weight: bold;
  }
`
const ContentText = styled.div`
  margin-bottom: 20px;
`
