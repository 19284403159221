import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectDictionaries = state => state.dictionaries

export const selectUserRolesDictionary = createSelector(
  selectDictionaries,
  propOr([], 'userRoles')
)

export const selectZonesDictionary = createSelector(
  selectDictionaries,
  propOr([], 'zones')
)
