import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PackingOrderDetails from 'modules/packing/components/PackingOrderDetails'
import PackingCurrentStatus from 'modules/packing/components/PackingCurrentStatus'
import PackingMainContent from 'modules/packing/components/PackingMainContent'
import styled from 'styled-components'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import PackingActionButtons from 'modules/packing/components/PackingActionButtons'
import {
  clearCurrentPackingOrderRoutine,
  clearCurrentStockUnitOrdersRoutine,
  fetchCompletionListsForStockUnitRoutine,
  setCurrentPackingOrderRoutine,
  setPackedItemEanRoutine,
  setPackedItemQuantityRoutine
} from 'modules/packing/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import { fetchPackingTypesRoutine } from 'modules/packingTypes/ducks/actions'
import { stringifyQuery } from 'utils/navigation'
import {
  selectCurrentUser,
  selectCurrentUserActiveRole
} from 'modules/auth/ducks/selectors'
import { switchUserRoleRoutine } from 'modules/auth/ducks/actions'
import { finalizePackingOrder, packItem } from 'services/PackingService'
import {
  selectCurrentPackingOrder,
  selectCurrentUnitNumber,
  selectCurrentUnitOrders,
  selectPackedItemQuantity
} from 'modules/packing/ducks/selectors'
import { getApiErrors } from 'utils/errors'
import { isEmpty, pathOr, propOr } from 'ramda'
import SelectPackageSizeModal from 'modules/packing/components/SelectPackageSizeModal'
import { packingErrorEmitter, printLabel } from 'utils/packing'
import PackingOrderSuccessModal from 'modules/packing/components/PackingOrderSuccessModal'
import PackingUnitSuccessModal from 'modules/packing/components/PackingUnitSuccessModal'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import EditIcon from 'components/icons/EditIcon'
import { toast } from 'react-hot-toast'
import PackingEndWithMissingProductsModal from 'modules/packing/components/PackingEndWithMissingProductsModal'
import RemainingStockUnitsModal from 'modules/packing/components/RemainingStockUnitsModal'
import AddTestPackingUnit from 'modules/packing/components/AddTestPackingUnit'

const Packing = () => {
  const wrapperRef = useRef()
  const [status, setStatus] = useState('')
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const needsNewPassword = propOr(false, 'forcePasswordReset', user)
  const currentUserRole = useSelector(selectCurrentUserActiveRole)
  const currentUnitNumber = useSelector(selectCurrentUnitNumber)
  const currentOrder = useSelector(selectCurrentPackingOrder)
  const [successOrderModalOpen, setSuccessOrderModalOpen] = useState(false)
  const [successUnitModalOpen, setSuccessUnitModalOpen] = useState(false)
  const orders = useSelector(selectCurrentUnitOrders)
  const quantity = useSelector(selectPackedItemQuantity)
  const [inputBarcode, setInputBarcode] = useState('')
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [error, setError] = useState('')
  const [workstation, setWorkstation] = useState('')
  const workstationName = localStorage.getItem('workstation') || ''
  const isUnitFinished = orders.every(
    order => pathOr('', ['status', 'value'], order) === 'packed'
  )
  const [readyToFinalize, setReadyToFinalize] = useState(false)
  const [endWithMissingModalOpen, setEndWithMissingModalOpen] = useState(false)
  const [remaining, setRemaining] = useState([])
  const [remainingModalOpen, setRemainingModalOpen] = useState(false)
  const [forcedPackingModalOpen, setForcedPackingModalOpen] = useState(false)
  const isPackageSizeRequired = propOr(
    false,
    'requirePackageSizeSelection',
    currentOrder
  )

  const handleFinalize = () => {
    finalizePackingOrder({ code: currentUnitNumber })
      .then(response => {
        dispatch(clearCurrentPackingOrderRoutine())
        dispatch(clearCurrentStockUnitOrdersRoutine())
        setStatus('start')
        setReadyToFinalize(false)
        const { data } = response
        setRemaining(propOr([], 'remaining', data))
      })
      .catch(err =>
        toast.error(getApiErrors(err), {
          duration: 5000,
          style: {
            fontSize: 22
          }
        })
      )
  }

  const handleFinalizeSuccess = () => {
    handleFinalize()
    setSuccessUnitModalOpen(false)
  }

  const handleFinalizeWithMissing = () => {
    handleFinalize()
    setEndWithMissingModalOpen(false)
  }

  const handleFinish = () => {
    if (isUnitFinished) {
      setSuccessUnitModalOpen(true)
    } else {
      toast.error('Nie wszystkie towary zostały zeskanowane')
    }
  }

  const doneOrders = useMemo(() => {
    return orders.filter(
      order => pathOr('', ['status', 'value'], order) === 'packed'
    )
  }, [orders])

  const handleFinishWithMissing = () => {
    setEndWithMissingModalOpen(true)
  }

  useEffect(() => {
    if (status === 'waitingForProductScan' && isEmpty(orders)) {
      handleFinalize()
    } else if (status === 'orderSuccess') {
      if (!isUnitFinished) {
        setSuccessOrderModalOpen(true)
        setTimeout(() => {
          setSuccessOrderModalOpen(false)
          setStatus('waitingForProductScan')
        }, 3000)
      } else {
        setReadyToFinalize(true)
      }
    } else {
      if (isNotNilOrEmpty(doneOrders)) {
        setReadyToFinalize(isNotNilOrEmpty(status) && isUnitFinished)
      } else {
        setReadyToFinalize(false)
      }
    }
  }, [status, orders])

  // triggered on pack api call
  useEffect(() => {
    if (currentOrder && !currentOrder.previewMode) {
      const orderItems = propOr([], 'items', currentOrder)
      const needsSizeSelection =
        pathOr('', ['status', 'value'], currentOrder) === 'partially_packed'
      const isFinished = orderItems.every(
        item => item.status.value === 'packed'
      )

      if (
        isFinished &&
        isNotNilOrEmpty(orderItems) &&
        status !== 'sizeSelected' &&
        needsSizeSelection
      ) {
        setStatus('readyToPack')
      } else if (
        isFinished &&
        isNotNilOrEmpty(orderItems) &&
        (!needsSizeSelection || status === 'sizeSelected')
      ) {
        if (pathOr('', ['status', 'value'], currentOrder) === 'packed') {
          printLabel({ orderId: currentOrder.id })
            .then(() => {
              dispatch(
                fetchCompletionListsForStockUnitRoutine({
                  setStatus: setStatus,
                  code: currentUnitNumber,
                  callback: () => setStatus('orderSuccess')
                })
              )
            })
            .catch(() => {
              dispatch(
                fetchCompletionListsForStockUnitRoutine({
                  setStatus: setStatus,
                  code: currentUnitNumber,
                  callback: () => setStatus('orderSuccess')
                })
              )
            })
        } else {
          isPackageSizeRequired && setForcedPackingModalOpen(true)
        }
      }
    }
  }, [currentOrder])

  const packingModalOpen = useMemo(() => {
    return status === 'readyToPack'
  }, [status])

  const displayError = error => {
    setErrorModalOpen(true)
    setError(error)
  }

  useEffect(() => {
    dispatch(
      fetchPackingTypesRoutine({
        query: stringifyQuery({
          page: 1,
          limit: 1000
        })
      })
    )
    dispatch(clearCurrentPackingOrderRoutine())

    const selectedWorkstation = localStorage.getItem('workstation')

    if (isNilOrEmpty(selectedWorkstation)) {
      setStatus('workstation')
    } else {
      setStatus('start')
    }

    packingErrorEmitter.on('error', displayError)

    return () => {
      packingErrorEmitter.off('error', displayError)
    }
  }, [])

  useEffect(() => {
    !needsNewPassword &&
      currentUserRole !== 'packer' &&
      dispatch(switchUserRoleRoutine({ role: 'packer' }))
  }, [currentUserRole])

  const getCurrentStatus = useCallback(() => {
    return status
  }, [status])

  useEffect(() => {
    if (isNotNilOrEmpty(remaining)) {
      setRemainingModalOpen(true)
    }
  }, [remaining, orders])

  const handleBarcode = code => {
    const currentStatus = getCurrentStatus()
    if (currentStatus === 'start') {
      dispatch(
        fetchCompletionListsForStockUnitRoutine({
          code,
          withToast: true,
          callback: () => {
            setStatus('waitingForProductScan')
          }
        })
      )
      setInputBarcode('')
    } else if (currentStatus === 'waitingForProductScan') {
      const station = isNotNilOrEmpty(workstationName)
        ? { workstation: workstationName }
        : {}
      packItem({
        ...station,
        ean: code,
        unitNumber: currentUnitNumber,
        quantity: isNotNilOrEmpty(quantity) ? quantity : 1
      })
        .then(resp => {
          const data = resp.data.data

          // @agata - response does not return stockunits for order items so we need to get it from current order
          const currentOrder = orders.find(order => order.id === data.id)

          const dataItemsWithStockUnits = data?.items?.map(item => {
            const currentOrderItem = currentOrder?.items?.find(
              orderItem => orderItem.id === item.id
            )
            return {
              ...item,
              stockUnits: currentOrderItem?.stockUnits || []
            }
          })

          dispatch(
            setCurrentPackingOrderRoutine({
              ...data,
              items: dataItemsWithStockUnits
            })
          )

          dispatch(setPackedItemQuantityRoutine(1))
          dispatch(setPackedItemEanRoutine(''))
          dispatch(
            fetchCompletionListsForStockUnitRoutine({ code: currentUnitNumber })
          )
        })
        .catch(e => {
          packingErrorEmitter.emit('error', getApiErrors(e))
        })
    }
    if (wrapperRef.current) {
      wrapperRef.current.focus()
    }
  }

  let barcode = ''
  let interval

  const scannerHandler = e => {
    if (interval) clearInterval(interval)
    if (e.code === 'Enter') {
      if (barcode) {
        handleBarcode(barcode)
        barcode = ''
        return
      }
    }
    if (e.code && !e.code.includes('Shift')) {
      barcode += e.key
    }
    interval = setInterval(() => (barcode = ''), 50)
  }

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.focus()
    }
  }, [currentUnitNumber, currentOrder])

  useEffect(() => {
    localStorage.setItem('role', 'packer')
    const removeRole = () => {
      localStorage.setItem('role', '')
    }
    window.addEventListener('beforeunload', removeRole)
    return () => window.removeEventListener('beforeunload', removeRole)
  }, [])

  const handleWorkstationSave = () => {
    localStorage.setItem('workstation', workstation)
    setStatus('start')
  }

  const handleWorkstationEdit = () => {
    setWorkstation(workstationName)
    setStatus('workstation')
  }

  return (
    <Wrapper
      onKeyDown={scannerHandler}
      id='packing-module'
      ref={wrapperRef}
      tabIndex={0}
    >
      {/* dev-only */}
      {(process.env.REACT_APP_API_URL?.includes('test') ||
        process.env.REACT_APP_API_URL?.includes('beta')) && (
        <AddTestPackingUnit />
      )}
      {status === 'workstation' ? (
        <SelectWorkstationWrapper>
          Podaj nazwę stanowiska, przy którym się znajdujesz
          <WorkstationInputWrapper>
            <Input
              name='workstation'
              label='Nazwa stanowiska'
              value={workstation}
              onChange={(_, value) => setWorkstation(value)}
            />
          </WorkstationInputWrapper>
          <Button
            onClick={handleWorkstationSave}
            disabled={isNilOrEmpty(workstation)}
          >
            Zapisz
          </Button>
        </SelectWorkstationWrapper>
      ) : status === 'start' ? (
        <>
          <WorkstationInfo>
            Stanowisko: <span>{workstationName}</span>
            <EditIcon onClick={handleWorkstationEdit} />
          </WorkstationInfo>
          <ScanCta>
            <QrCodeScannerIcon style={{ fontSize: 40, marginBottom: 10 }} />
            <div>
              Zeskanuj pojemnik lub wprowadź numer ręcznie, aby rozpocząć
              pakowanie
            </div>
            <BarcodeForm
              onSubmit={e => {
                e.preventDefault()
                handleBarcode(inputBarcode)
              }}
            >
              <Input
                name='inputBarcode'
                value={inputBarcode}
                onChange={(_, value) => setInputBarcode(value)}
                label='Kod jednostki'
              />
              <Button type='submit' disabled={isNilOrEmpty(inputBarcode)}>
                Start
              </Button>
            </BarcodeForm>
          </ScanCta>
        </>
      ) : isNotNilOrEmpty(status) ? (
        <>
          <PackingOrderDetails />
          <PackingCurrentStatus />
          <PackingMainContent />
          <PackingActionButtons
            setStatus={setStatus}
            onHandleFinish={handleFinish}
            status={readyToFinalize}
            onHandleFinishWithMissingProducts={handleFinishWithMissing}
          />
        </>
      ) : null}
      <SelectPackageSizeModal
        setStatus={setStatus}
        open={packingModalOpen ? packingModalOpen : forcedPackingModalOpen}
        isPackageSizeRequired={isPackageSizeRequired && !packingModalOpen}
        setForcedPackingModalOpen={setForcedPackingModalOpen}
      />
      <PackingOrderSuccessModal
        open={successOrderModalOpen}
        currentOrder={currentOrder}
      />
      <PackingUnitSuccessModal
        open={successUnitModalOpen}
        setSuccessUnitModalOpen={setSuccessUnitModalOpen}
        onHandleFinalize={handleFinalizeSuccess}
      />
      <RemainingStockUnitsModal
        open={remainingModalOpen}
        setRemainingModalOpen={setRemainingModalOpen}
        remaining={remaining}
        setRemaining={setRemaining}
      />
      <PackingEndWithMissingProductsModal
        setEndWithMissingModalOpen={setEndWithMissingModalOpen}
        open={endWithMissingModalOpen}
        onHandleFinalize={handleFinalizeWithMissing}
      />
      {errorModalOpen && (
        <ErrorModalOverlay>
          <ErrorModalWrapper>
            <ErrorIconWrapper>
              <ErrorIcon />
            </ErrorIconWrapper>
            <div>{error}</div>
            <CloseErrorModalButton>
              <Button
                onClick={() => {
                  setErrorModalOpen(false)
                  setError('')
                }}
              >
                Rozumiem, chcę kontynuować
              </Button>
            </CloseErrorModalButton>
          </ErrorModalWrapper>
        </ErrorModalOverlay>
      )}
    </Wrapper>
  )
}

export default Packing

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ theme }) =>
    `calc(100vh - ${theme.dimensions.topBarHeight} - 30px)`};
  outline: none;
`

const ScanCta = styled.div`
  width: 400px;
  font-size: 18px;
  background-color: #fff;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  box-shadow: ${({ theme }) => theme.shadows.main};
`

const BarcodeForm = styled.form`
  margin-top: 20px;
`

const ErrorModalOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`

const ErrorModalWrapper = styled.div`
  min-width: 300px;
  max-width: 700px;
  background-color: #fff;
  padding: 30px;
`

const CloseErrorModalButton = styled.div`
  margin-top: 20px;
`

const ErrorIcon = styled(ReportProblemIcon)`
  font-size: 70px !important;
  color: ${({ theme }) => theme.colors.error};
  margin: 0 auto 30px;
`

const ErrorIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const SelectWorkstationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
`

const WorkstationInputWrapper = styled.div`
  margin: 10px 0;
`

const WorkstationInfo = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
  display: flex;
  gap: 5px;
  justify-content: center;

  span {
    font-weight: bold;
  }
`
