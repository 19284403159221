import history from 'utils/history'
import qs from 'qs'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import PATHS from 'utils/paths'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { find, flatten, intersection, propEq } from 'ramda'

export const redirect = (path, state) => {
  history.push(path, state)
}

export const getCurrentPathname = () => {
  return history.location.pathname
}

export const parseQuery = search => {
  return qs.parse(search, { ignoreQueryPrefix: true })
}

export const stringifyQuery = search => {
  return qs.stringify(search, { addQueryPrefix: true })
}

export const getCurrentParsedQuery = () => {
  return parseQuery(history.location.search)
}

export const changeQuery = query => {
  redirect(`${getCurrentPathname()}${stringifyQuery(query)}`)
}

export const redirectWithQuery = (path, query, state) => {
  history.push(`${path}${stringifyQuery(query)}`, state)
}

export const navItems = [
  {
    icon: InventoryOutlinedIcon,
    label: 'Kontrola lotu',
    path: PATHS.control,
    roles: ['admin', 'foreman', 'bok', 'lists_creator']
  },
  {
    icon: AccountTreeIcon,
    label: 'Tworzenie list',
    path: PATHS.lists,
    roles: ['admin', 'foreman', 'lists_creator']
  },
  {
    icon: Inventory2OutlinedIcon,
    label: 'Pakowanie',
    path: PATHS.packing,
    roles: ['admin', 'foreman', 'packer']
  },
  {
    icon: BallotOutlinedIcon,
    label: 'Zamówienia',
    path: PATHS.saleOrders,
    roles: ['admin', 'foreman', 'bok', 'lists_creator']
  },
  {
    icon: ListAltOutlinedIcon,
    label: 'Asortyment',
    sub: [
      {
        label: 'Produkty',
        path: PATHS.products,
        roles: [
          'admin',
          'foreman',
          'stocktaker',
          'inbound',
          'warehouseman',
          'bok',
          'lists_creator'
        ]
      },
      {
        label: 'Opakowania',
        path: PATHS.packingTypes,
        roles: ['admin', 'foreman']
      }
    ],
    roles: [
      'admin',
      'foreman',
      'stocktaker',
      'inbound',
      'warehouseman',
      'bok',
      'lists_creator'
    ]
  },
  {
    icon: WarehouseOutlinedIcon,
    label: 'Magazyn',
    sub: [
      {
        label: 'Lista magazynów',
        path: PATHS.warehouses,
        roles: ['admin']
      },
      {
        label: 'Lokalizacje',
        path: PATHS.locations,
        roles: [
          'admin',
          'foreman',
          'stocktaker',
          'inbound',
          'warehouseman',
          'bok',
          'lists_creator'
        ]
      },
      {
        label: 'Jednostki magazynowe',
        path: PATHS.stockUnits,
        roles: [
          'admin',
          'foreman',
          'stocktaker',
          'inbound',
          'warehouseman',
          'bok',
          'lists_creator'
        ]
      },
      {
        label: 'Rodzaje jednostek magazynowych',
        path: PATHS.stockUnitKinds,
        roles: ['admin']
      },
      {
        label: 'Strefy',
        path: PATHS.zones,
        roles: ['admin']
      },
      {
        label: 'Inwentaryzacja',
        path: PATHS.stocktakingOrders,
        roles: ['admin', 'foreman', 'stocktaker', 'bok', 'lists_creator']
      },
      {
        label: 'Uzupełnienia',
        path: PATHS.replenishments,
        roles: [
          'admin',
          'foreman',
          'stocktaker',
          'warehouseman',
          'lists_creator'
        ]
      }
    ],
    roles: [
      'admin',
      'foreman',
      'stocktaker',
      'warehouseman',
      'bok',
      'lists_creator'
    ]
  },
  {
    icon: LocalShippingOutlinedIcon,
    label: 'Dostawy',
    path: PATHS.delivery,
    roles: ['admin', 'foreman', 'inbound']
  },
  {
    icon: SsidChartOutlinedIcon,
    label: 'Raporty',
    sub: [
      {
        label: 'Raport stan magazynu',
        path: PATHS.warehouseState,
        roles: ['admin', 'foreman']
      },
      {
        label: 'Raport efektywności',
        path: PATHS.efficiencyStats,
        roles: ['admin', 'foreman']
      },
      {
        label: 'Raport przesunięć',
        path: PATHS.moveLogs,
        roles: [
          'admin',
          'foreman',
          'stocktaker',
          'inbound',
          'bok',
          'lists_creator'
        ]
      },
      {
        label: 'Raport dostaw',
        path: PATHS.deliveryOrdersLogs,
        roles: ['admin', 'foreman', 'inbound', 'bok']
      },
      {
        label: 'Statystyki pakowaczy',
        path: PATHS.packerStatistics,
        roles: ['admin', 'foreman']
      },
      {
        label: 'Zadania importu',
        path: PATHS.importJobsReport,
        roles: ['admin']
      }
    ],
    roles: ['admin', 'foreman', 'stocktaker', 'inbound', 'bok', 'lists_creator']
  },
  {
    icon: SettingsOutlinedIcon,
    label: 'Konfiguracja',
    sub: [
      { label: 'Użytkownicy', path: PATHS.users, roles: ['admin']},
      { label: 'Kurierzy', path: PATHS.couriers, roles: ['admin']},
      { label: 'Dostawcy', path: PATHS.suppliers, roles: ['admin']},
      { label: 'Ustawienia', path: PATHS.settings, roles: ['admin']},
      { label: 'Integracje', path: PATHS.integrations, roles: ['admin']},
    ],
    roles: ['admin']
  }
]

export const isAuthorized = (allowedRoles, userRoles) => {
  return intersection(allowedRoles, userRoles).length > 0
}

export const isAuthorizedToPath = (path, userRoles) => {
  const items = flatten(
    navItems.map(item => {
      const obj = item.sub
        ? [
            ...item.sub.map(subItem => {
              return { path: subItem.path, roles: subItem.roles }
            })
          ]
        : { path: item.path, roles: item.roles }
      return obj
    })
  )
  const pathRoles = find(propEq('path', path))(items).roles
  return intersection(pathRoles, userRoles).length > 0
}
