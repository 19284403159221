import { validateField, validateValues } from 'utils/form'
import { array, object, string } from 'yup'

export const createCourierGroupSchema = object().shape({
  name: string().required('Nazwa jest wymagana'),
  courierIds: array().of(string()).nullable()
})

export const validateCreateCourierGroupField = values => validateField(createCourierGroupSchema, values)
export const validateCreateCourierGroupValues = validateValues(createCourierGroupSchema)
