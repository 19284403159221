import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as productsService from 'services/ProductsService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'

export const fetchProductsRoutine = createRoutine('FETCH_PRODUCTS')
export const fetchSingleProductRoutine = createRoutine('FETCH_SINGLE_PRODUCT')
export const updateProductRoutine = createRoutine('UPDATE_PRODUCT')
export const updateProductsFromSGTRoutine = createRoutine(
  'UPDATE_PRODUCTS_FROM_SGT'
)
export const updateSingleProductFromIAIRoutine = createRoutine(
  'UPDATE_SINGLE_PRODUCT_FROM_IAI'
)
export const updateProductsFromIAIRoutine = createRoutine(
  'UPDATE_PRODUCTS_FROM_IAI'
)
export const fetchShiftHistoryRoutine = createRoutine('FETCH_SHIFT_HISTORY')
export const fetchChangesHistoryRoutine = createRoutine('FETCH_CHANGES_HISTORY')

export const removeProductRoutine = createRoutine('REMOVE_PRODUCT')
export const createProductRoutine = createRoutine('CREATE_PRODUCT')

// ACTIONS
function * fetchProducts ({ payload }) {
  yield put(fetchProductsRoutine.request())
  try {
    const { data } = yield call(productsService.fetchProducts, payload)
    yield put(fetchProductsRoutine.success(data))
  } catch (e) {
    yield put(fetchProductsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchSingleProduct ({ payload }) {
  yield put(fetchSingleProductRoutine.request())
  try {
    const { data } = yield call(productsService.fetchSingleProduct, payload)
    yield put(fetchSingleProductRoutine.success(data.data))
  } catch (e) {
    yield put(fetchSingleProductRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * updateProduct ({ payload }) {
  const { values, callback, isProductDetailsView } = payload
  yield put(updateProductRoutine.request())
  try {
    yield call(productsService.updateProduct, values)
    yield put(updateProductRoutine.success())
    isProductDetailsView
      ? yield put(fetchSingleProductRoutine({ id: values.id }))
      : yield put(
          fetchProductsRoutine({
            query: stringifyQuery(getCurrentParsedQuery())
          })
        )
    yield put(
      fetchChangesHistoryRoutine({
        id: values.id,
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie edytowano produkt')
  } catch (e) {
    yield put(updateProductRoutine.failure(e))
    toast.error(getApiErrors(e))
  }
}

function * updateProductsFromSGT ({ payload }) {
  const { updateExisting, callback } = payload
  yield put(updateProductsFromSGTRoutine.request())
  try {
    yield call(productsService.importProductsFromSGT, { updateExisting })
    yield put(updateProductsFromSGTRoutine.success())
    typeof callback === 'function' && callback()
    toast.success('Zlecono import. Sprawdź wynik w module Zadania Importu')
  } catch (e) {
    yield put(updateProductsFromSGTRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * updateSingleProductFromIAI ({ payload }) {
  const { id, callback } = payload
  yield put(updateSingleProductFromIAIRoutine.request())
  try {
    const { data } = yield call(productsService.importSingleProductFromIAI, {
      id
    })
    yield put(updateSingleProductFromIAIRoutine.success(data.data))
    if (data.data.result) {
      const { error, successful, imported } = data.data.result
      if (successful) {
        typeof callback === 'function' && callback()
        imported
          ? toast.success('Zaimportowano pomyślnie')
          : toast('Zaimportowano 0')
      } else {
        toast.error(error ? error : 'Wystąpił błąd')
      }
    }
  } catch (e) {
    yield put(updateSingleProductFromIAIRoutine.failure(e))
    toast.error(getApiErrors(e))
  }
}

function * updateProductsFromIAI ({ payload }) {
  const { updateAllRecords, callback } = payload

  yield put(updateProductsFromIAIRoutine.request())
  try {
    yield call(productsService.importProductsFromIAI, { updateAllRecords })
    yield put(updateProductsFromIAIRoutine.success())
    typeof callback === 'function' && callback()
    toast.success('Zlecono import. Sprawdź wynik w module Zadania Importu')
  } catch (e) {
    yield put(updateProductsFromIAIRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}
function * fetchShiftHistory ({ payload }) {
  yield put(fetchShiftHistoryRoutine.request())
  try {
    const { data } = yield call(productsService.fetchShiftHistory, payload)
    yield put(fetchShiftHistoryRoutine.success(data))
  } catch (e) {
    yield put(fetchShiftHistoryRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchChangesHistory ({ payload }) {
  yield put(fetchChangesHistoryRoutine.request())
  try {
    const { data } = yield call(productsService.fetchChangesHistory, payload)
    yield put(fetchChangesHistoryRoutine.success(data))
  } catch (e) {
    yield put(fetchChangesHistoryRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * removeProduct ({ payload }) {
  const { id, callback } = payload
  yield put(removeProductRoutine.request())
  try {
    yield call(productsService.removeProduct, { id })
    yield put(removeProductRoutine.success())
    yield put(
      fetchProductsRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie usunięto produkt')
  } catch (e) {
    yield put(removeProductRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * createProduct ({ payload }) {
  const { values, callback } = payload
  yield put(createProductRoutine.request())
  try {
    yield call(productsService.createProduct, values)
    yield put(createProductRoutine.success())
    yield put(
      fetchProductsRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      })
    )
    typeof callback === 'function' && callback()
    toast.success('Pomyślnie dodano produkt')
  } catch (e) {
    yield put(createProductRoutine.failure(e))
    toast.error(getApiErrors(e))
  }
}

// WATCHERS
export function * fetchProductsWatcher () {
  yield takeLatest(fetchProductsRoutine.TRIGGER, fetchProducts)
}

export function * fetchSingleProductWatcher () {
  yield takeLatest(fetchSingleProductRoutine.TRIGGER, fetchSingleProduct)
}

export function * updateProductWatcher () {
  yield takeLatest(updateProductRoutine.TRIGGER, updateProduct)
}

export function * updateProductsFromSGTWatcher () {
  yield takeLatest(updateProductsFromSGTRoutine.TRIGGER, updateProductsFromSGT)
}

export function * updateSingleProductFromIAIWatcher () {
  yield takeLatest(
    updateSingleProductFromIAIRoutine.TRIGGER,
    updateSingleProductFromIAI
  )
}

export function * updateProductsFromIAIWatcher () {
  yield takeLatest(updateProductsFromIAIRoutine.TRIGGER, updateProductsFromIAI)
}

export function * fetchShiftHistoryWatcher () {
  yield takeLatest(fetchShiftHistoryRoutine.TRIGGER, fetchShiftHistory)
}

export function * fetchChangesHistoryWatcher () {
  yield takeLatest(fetchChangesHistoryRoutine.TRIGGER, fetchChangesHistory)
}

export function * removeProductWatcher () {
  yield takeLatest(removeProductRoutine.TRIGGER, removeProduct)
}

export function * createProductWatcher () {
  yield takeLatest(createProductRoutine.TRIGGER, createProduct)
}

// SAGAS
export const productsSagas = [
  fork(fetchProductsWatcher),
  fork(fetchSingleProductWatcher),
  fork(updateProductWatcher),
  fork(updateProductsFromSGTWatcher),
  fork(updateSingleProductFromIAIWatcher),
  fork(updateProductsFromIAIWatcher),
  fork(fetchShiftHistoryWatcher),
  fork(fetchChangesHistoryWatcher),
  fork(removeProductWatcher),
  fork(createProductWatcher)
]
