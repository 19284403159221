import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { dissoc, propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import { SALE_ORDERS_STATUSES } from 'utils/saleOrders'
import { useSelector } from 'react-redux'
import { selectActiveTab } from 'ducks/tabs/selectors'
import { ButtonsWrapper } from 'theme/wrappers'

const emptyValues = {
  is_from_b2b_platform: 'all',
  ordered_before: '',
  ordered_after: '',
  status: 'all',
  stock_unit: '',
  courier: '',
  source_name: ''
}

const SaleOrdersFilters = ({ open }) => {
  const [values, setValues] = useState(emptyValues)
  const activeTab = useSelector(selectActiveTab)
  const statusesFilterableByStockUnit = [
    'partially_collected',
    'collected',
    'combining',
    'for_packing',
    'partially_packed'
  ]

  const {
    location: { search }
  } = useHistory()

  const resetFilters = () => {
    setValues(emptyValues)
    changeQuery({
      page: 1,
      sort: 'status',
      activeTab
    })
  }

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    setValues({
      ...emptyValues,
      ...propOr({}, 'filter', currentQuery)
    })
  }, [search])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const statusOptions = useMemo(() => {
    let items = [{ label: 'Wszystkie', value: 'all' }]
    for (let key in SALE_ORDERS_STATUSES) {
      items = [...items, { label: SALE_ORDERS_STATUSES[key], value: key }]
    }
    return items
  }, [])

  const handleSubmit = () => {
    const currentQuery = getCurrentParsedQuery()
    let payload = {}

    for (let key in values) {
      if (isNotNilOrEmpty(values[key]) && values[key] !== 'all') {
        payload[key] = values[key]
      } else {
        payload = dissoc(key, payload)
      }
    }

    changeQuery({
      ...currentQuery,
      page: 1,
      filter: payload
    })
  }

  return (
    <Wrapper open={open}>
      <Input
        type='date'
        value={values.ordered_after}
        name='ordered_after'
        label='Data od'
        onChange={handleValueChange}
        noMarginBottom
      />
      <Input
        type='date'
        value={values.ordered_before}
        name='ordered_before'
        label='Data do'
        onChange={handleValueChange}
        noMarginBottom
      />
      {activeTab === 'active' && (
        <Input
          select
          options={statusOptions || []}
          value={values.status}
          name='status'
          label='Status'
          onChange={handleValueChange}
          noMarginBottom
        />
      )}
      <Input
        select
        options={[
          { label: 'Wszystkie', value: 'all' },
          { label: 'Tak', value: 'true' },
          { label: 'Nie', value: 'false' }
        ]}
        value={values.is_from_b2b_platform}
        name='is_from_b2b_platform'
        label='Zamówienie z platformy B2B'
        onChange={handleValueChange}
        noMarginBottom
      />
      {activeTab === 'active' && (
        <>
          {statusesFilterableByStockUnit.includes(values.status) ? (
            <Input
              clearable
              value={values.stock_unit}
              name='stock_unit'
              label='Jednostka magazynowa'
              onChange={handleValueChange}
              noMarginBottom
            />
          ) : null}
          <Input
            clearable
            value={values.courier}
            name='courier'
            label='Kurier'
            onChange={handleValueChange}
            noMarginBottom
          />
          <Input
            clearable
            value={values.source_name}
            name='source_name'
            label='Źródło'
            onChange={handleValueChange}
            noMarginBottom
          />
        </>
      )}
      <ButtonsWrapper>
        <Button small onClick={handleSubmit} color='primary'>
          Zastosuj
        </Button>
        <Button small onClick={resetFilters} color='primary-outline'>
          Resetuj
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default SaleOrdersFilters

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  width: 100%;
  overflow: hidden;
  height: 0;
  padding: 0 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 15px;

  ${({ open }) =>
    open &&
    css`
      padding: 20px;
      margin-bottom: 20px;
      height: 110px;
    `}
`
