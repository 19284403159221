import React from 'react'
import Modal from 'components/atoms/Modal'
import styled from 'styled-components'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import Button from 'components/atoms/Button'

import { ActionsContainer } from 'theme/wrappers'

const PackingUnitSuccessModal = ({
  open,
  setSuccessUnitModalOpen,
  onHandleFinalize
}) => {
  const handleCancel = () => {
    setSuccessUnitModalOpen(false)
  }

  return (
    <Modal
      open={open}
      onClose={() => {}}
      title='Pakowanie jednostki zakończone'
    >
      <IconWrapper>
        <StyledIcon />
      </IconWrapper>
      <Content>
        <ContentText>
          Gratulacje! <br />
          Wszystkie zamówienia z wózka zostały spakowane.
        </ContentText>
        <ActionsContainer>
          <Button color='primary-outline' onClick={handleCancel}>
            Wróć
          </Button>
          <Button color='primary' onClick={onHandleFinalize}>
            Zakończ
          </Button>
        </ActionsContainer>
      </Content>
    </Modal>
  )
}

export default PackingUnitSuccessModal

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const StyledIcon = styled(CheckCircleIcon)`
  font-size: 70px !important;
  color: ${({ theme }) => theme.colors.success};
`

const Content = styled.div`
  text-align: center;
  font-size: 24px;

  span {
    font-weight: bold;
  }
`
const ContentText = styled.div`
  margin-bottom: 20px;
`
