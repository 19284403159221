import {
  fetchSaleOrdersRoutine,
  fetchSaleOrdersStatisticsRoutine,
  fetchSingleSaleOrderRoutine,
  fetchStatusLogsRoutine,
  fetchSuspendedSaleOrdersRoutine
} from 'modules/saleOrders/ducks/actions'

const defaultState = {
  list: [],
  suspendedList: [],
  statusLogList: [],
  currentSaleOrder: {},
  pagination: null,
  suspendedPagination: null,
  statusLogListPagination: null,
  isLoading: false,
  isLoadingCurrentSaleOrder: false,
  isLoadingStatusLogList: false
}

const saleOrdersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchSaleOrdersRoutine.TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case fetchSaleOrdersRoutine.FAILURE:
      return {
        ...state,
        isLoading: false,
        user: defaultState.user
      }
    case fetchSaleOrdersRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoading: false
      }
    case fetchSuspendedSaleOrdersRoutine.TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case fetchSuspendedSaleOrdersRoutine.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case fetchSuspendedSaleOrdersRoutine.SUCCESS:
      return {
        ...state,
        suspendedList: action.payload.data,
        suspendedPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoading: false
      }
    case fetchSingleSaleOrderRoutine.TRIGGER:
      return {
        ...state,
        isLoadingCurrentSaleOrder: true
      }
    case fetchSingleSaleOrderRoutine.FAILURE:
      return {
        ...state,
        isLoadingCurrentSaleOrder: false
      }
    case fetchSingleSaleOrderRoutine.SUCCESS:
      return {
        ...state,
        currentSaleOrder: action.payload,
        isLoadingCurrentSaleOrder: false
      }
    case fetchSaleOrdersStatisticsRoutine.SUCCESS:
      return {
        ...state,
        statistics: action.payload
      }
    case fetchStatusLogsRoutine.TRIGGER:
      return {
        ...state,
        isLoadingStatusLogList: true
      }
    case fetchStatusLogsRoutine.FAILURE:
      return {
        ...state,
        isLoadingStatusLogList: false
      }
    case fetchStatusLogsRoutine.SUCCESS:
      return {
        ...state,
        statusLogList: action.payload.data,
        statusLogListPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoadingStatusLogList: false
      }
    default:
      return state
  }
}

export default saleOrdersReducer
