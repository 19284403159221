import React, { useEffect, useState } from 'react'
import { DATE_FORMATS, formatDate } from 'utils/date'
import RemoveListModal from 'modules/multipick/components/RemoveListModal'
import Checkbox from 'components/atoms/Checkbox'
import { pathOr, propOr } from 'ramda'
import styled, { css } from 'styled-components'
import { isNotEmpty, isNotNilOrEmpty } from 'utils/ramda'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Tooltip } from '@mui/material'

const MultipickListsListItem = ({
  clearCurrentList,
  isSublist,
  listItem,
  handleRowClick,
  currentList,
  toggleCheckbox,
  selectedLists,
  isOpen
}) => {
  const [open, setOpen] = useState(false)
  const subLists = propOr([], 'subLists', listItem)
  const hasSubLists = isNotEmpty(subLists)

  useEffect(() => {
    isOpen && setOpen(isOpen)
  }, [isOpen])

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleClose = e => {
    e.stopPropagation()
    const isCurrentListASublist = isNotNilOrEmpty(currentList.parentListId)
    isCurrentListASublist && clearCurrentList()
    setOpen(false)
  }

  return (
    <>
      <ItemsListItem
        isSublist={isSublist}
        onClick={handleRowClick(listItem)}
        isActive={listItem.id === currentList.id}
      >
        <ListDate>
          {formatDate(listItem.createdAt, DATE_FORMATS.dashedWithTime)}
          {!isSublist && <RemoveListModal list={listItem} />}
        </ListDate>
        <ListName>
          {!isSublist && (
            <CheckboxWrapper onClick={e => e.stopPropagation()}>
              <Checkbox
                name='check'
                onChange={toggleCheckbox(listItem)}
                isChecked={selectedLists.some(list => list.id === listItem.id)}
              />
            </CheckboxWrapper>
          )}
          <Tooltip title={listItem.name}>
            <ListNameValue>{listItem.name}</ListNameValue>
          </Tooltip>
          <ListNumber>
            {' nr: '}
            {listItem.listNumber}
          </ListNumber>
        </ListName>
        <ListDetails>
          <ListDetailsItemStatus>
            <ListDetailsItemTitle>Status</ListDetailsItemTitle>
            <ListDetailsItemContent>
              {pathOr('---', ['status', 'label'], listItem)}
            </ListDetailsItemContent>
          </ListDetailsItemStatus>
          <div>
            <ListDetailsItemTitle>Priorytet</ListDetailsItemTitle>
            <ListDetailsItemContent>
              {pathOr('---', ['priority'], listItem)}
            </ListDetailsItemContent>
          </div>
          {!isSublist && (
            <>
              <div>
                <ListDetailsItemTitle>Zamówienia</ListDetailsItemTitle>
                <ListDetailsItemContent>
                  {propOr('---', 'ordersCount', listItem)}
                </ListDetailsItemContent>
              </div>
              {hasSubLists && (
                <div>
                  <ListDetailsItemTitle>Podlisty</ListDetailsItemTitle>
                  <ListDetailsSublistsItemContent
                    onClick={e => e.stopPropagation()}
                  >
                    {subLists.length}
                    {hasSubLists && (
                      <>
                        {open ? (
                          <ArrowDropUpIcon onClick={handleClose} />
                        ) : (
                          <ArrowDropDownIcon onClick={handleOpen} />
                        )}
                      </>
                    )}
                  </ListDetailsSublistsItemContent>
                </div>
              )}
            </>
          )}
          {!hasSubLists && (
            <div>
              <ListDetailsItemTitle>Zadania</ListDetailsItemTitle>
              <ListDetailsItemContent>
                {propOr('---', 'itemsCount', listItem)}
              </ListDetailsItemContent>
            </div>
          )}
        </ListDetails>
      </ItemsListItem>
      {hasSubLists &&
        open &&
        subLists.map(subList => (
          <MultipickListsListItem
            isSublist
            clearCurrentList={clearCurrentList}
            listItem={subList}
            key={subList.id}
            handleRowClick={handleRowClick}
            currentList={currentList}
            toggleCheckbox={toggleCheckbox}
            selectedLists={selectedLists}
          />
        ))}
    </>
  )
}

export default MultipickListsListItem

const CheckboxWrapper = styled.div``

const ItemsListItem = styled.div`
  background-color: ${({ isActive }) => (isActive ? '#fdedce' : '#fff')};
  padding: 5px;
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }

  ${({ isSublist }) =>
    isSublist &&
    css`
      padding-left: 15px;
      border-left: 3px solid ${({ theme }) => theme.colors.primary.main};
      background-color: ${({ isActive }) =>
        isActive ? '#fdedce' : '#eaf4fb'}; ;
    `}
`

const ListName = styled.div`
  display: flex;
  align-items: center;
`
const ListNameValue = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
`

const ListDate = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ListDetails = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ListDetailsItemStatus = styled.div`
  width: 25%;
`
const ListDetailsItemTitle = styled.div`
  text-transform: uppercase;
  font-size: 12px;
`

const ListDetailsItemContent = styled.div`
  font-size: 14px;
`

const ListDetailsSublistsItemContent = styled.div`
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
`
const ListNumber = styled.span`
  font-weight: bolder;
  margin-left: 4px;
`
