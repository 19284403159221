import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as multipickService from 'services/MultipickService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { omit } from 'ramda'

export const fetchMultipickRulesetsListRoutine = createRoutine('FETCH_MULTIPICK_RULESETS')
export const fetchMultipickDictionaryRoutine = createRoutine('FETCH_MULTIPICK_DICTIONARY')
export const fetchGeneratedMultipickListsRoutine = createRoutine('FETCH_GENERATED_MULTIPICKS')
export const simulateMultipicksRoutine = createRoutine('SIMULATE_MULTIPICKS')
export const clearSimulatedMultipicksRoutine = createRoutine('CLEAR_SIMULATED_MULTIPICKS')
export const saveSelectedMultipickValuesRoutine = createRoutine('SAVE_SELECTED_MULTIPICK_VALUES')
export const clearSelectedMultipickValuesRoutine = createRoutine('CLEAR_SELECTED_MULTIPICK_VALUES')
export const saveSelectedMultipickDatesRoutine = createRoutine('SAVE_SELECTED_MULTIPICK_DATES')
export const clearSelectedMultipickDatesRoutine = createRoutine('CLEAR_SELECTED_MULTIPICK_DATES')
export const currentGeneratedOrdersRoutine = createRoutine('CURRENT_GENERATED_ORDERS')

// ACTIONS
function * fetchMultipickRulesetsList ({ payload }) {
  yield put(fetchMultipickRulesetsListRoutine.request())
  try {
    const { data } = yield call(multipickService.fetchMultipickRulesets, payload)
    yield put(fetchMultipickRulesetsListRoutine.success(data.data))
  } catch (e) {
    yield put(fetchMultipickRulesetsListRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchMultipickDictionary ({ payload }) {
  yield put(fetchMultipickDictionaryRoutine.request())
  try {
    const { data } = yield call(multipickService.getMultipickDictionary, payload)
    yield put(fetchMultipickDictionaryRoutine.success(data.data))
  } catch (e) {
    yield put(fetchMultipickDictionaryRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchGeneratedMultipickLists ({ payload }) {
  yield put(fetchGeneratedMultipickListsRoutine.request())
  try {
    const { data } = yield call(multipickService.fetchGeneratedMultipickLists, payload)
    yield put(fetchGeneratedMultipickListsRoutine.success(data))
  } catch (e) {
    yield put(fetchGeneratedMultipickListsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * simulateMultipicks ({ payload }) {
  const { onSuccess, onFail } = payload
  yield put(simulateMultipicksRoutine.request())
  try {
    const { data } = yield call(multipickService.simulateMultipickRuleset, omit(['onSuccess', 'onFail'], payload))
    yield put(simulateMultipicksRoutine.success(data.data))
    toast.success(`Symulacja zakończona
    Zamówienia: ${data.data.ordersCount}
    Zamówienia bez alokacji: ${data.data.ordersWithoutAllocationCount}
    Listy do stworzenia: ${data.data.listsCount}`)
    onSuccess && onSuccess()
  } catch (e) {
    yield put(simulateMultipicksRoutine.failure(e))
    toast.error(getApiErrors(e))
    onFail && onFail()
    console.error(e)
  }
}

function * saveSelectedMultipickValues ({ payload }) {
  yield put(saveSelectedMultipickValuesRoutine.success(payload.values))
}

function * clearSelectedMultipickValues () {
  yield put(clearSelectedMultipickValuesRoutine.success())
}

function * saveSelectedMultipickDates ({ payload }) {
  yield put(saveSelectedMultipickDatesRoutine.success(payload.values))
}

function * clearSelectedMultipickDates () {
  yield put(clearSelectedMultipickDatesRoutine.success())
}

function * clearSimulatedMultipicks () {
  yield put(clearSimulatedMultipicksRoutine.success())
}

function * currentGeneratedOrders ({ payload }) {
  yield put(currentGeneratedOrdersRoutine.success(payload))
}

// WATCHERS
export function * fetchMultipickRulesetsListWatcher () {
  yield takeLatest(fetchMultipickRulesetsListRoutine.TRIGGER, fetchMultipickRulesetsList)
}

export function * fetchMultipickDictionaryWatcher () {
  yield takeLatest(fetchMultipickDictionaryRoutine.TRIGGER, fetchMultipickDictionary)
}

export function * fetchGeneratedMultipickListsWatcher () {
  yield takeLatest(fetchGeneratedMultipickListsRoutine.TRIGGER, fetchGeneratedMultipickLists)
}

export function * simulateMultipicksWatcher () {
  yield takeLatest(simulateMultipicksRoutine.TRIGGER, simulateMultipicks)
}

export function * clearSimulatedMultipicksWatcher () {
  yield takeLatest(clearSimulatedMultipicksRoutine.TRIGGER, clearSimulatedMultipicks)
}

export function * saveSelectedMultipickValuesWatcher () {
  yield takeLatest(saveSelectedMultipickValuesRoutine.TRIGGER, saveSelectedMultipickValues)
}

export function * clearSelectedMultipickValuesWatcher () {
  yield takeLatest(clearSelectedMultipickValuesRoutine.TRIGGER, clearSelectedMultipickValues)
}

export function * saveSelectedMultipickDatesWatcher () {
  yield takeLatest(saveSelectedMultipickDatesRoutine.TRIGGER, saveSelectedMultipickDates)
}

export function * clearSelectedMultipickDatesWatcher () {
  yield takeLatest(clearSelectedMultipickDatesRoutine.TRIGGER, clearSelectedMultipickDates)
}

export function * currentGeneratedOrdersWatcher () {
  yield takeLatest(currentGeneratedOrdersRoutine.TRIGGER, currentGeneratedOrders)
}

// SAGAS
export const multipickSagas = [
  fork(fetchMultipickRulesetsListWatcher),
  fork(fetchMultipickDictionaryWatcher),
  fork(fetchGeneratedMultipickListsWatcher),
  fork(simulateMultipicksWatcher),
  fork(clearSimulatedMultipicksWatcher),
  fork(saveSelectedMultipickValuesWatcher),
  fork(clearSelectedMultipickValuesWatcher),
  fork(saveSelectedMultipickDatesWatcher),
  fork(clearSelectedMultipickDatesWatcher),
  fork(currentGeneratedOrdersWatcher)
]
