import { changeTabRoutine, fetchTabsListRoutine } from 'ducks/tabs/actions'
import { selectActiveTab } from 'ducks/tabs/selectors'
import { propOr } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { getCurrentParsedQuery } from 'utils/navigation'

const Tabs = ({ tabs }) => {
  const activeTab = useSelector(selectActiveTab)
  const currentQuery = getCurrentParsedQuery()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTabsListRoutine({ tabs, currentQuery }))
  }, [])

  const changeTabHandler = (key, isList) => () => {
    dispatch(changeTabRoutine({ key, isList }))
  }

  const tabsHeaders = useMemo(() => {
    return tabs.map(tab => (
      <TabTitle
        key={tab.key}
        onClick={changeTabHandler(tab.key, tab.isList)}
        active={tab.key === activeTab}
      >
        {tab.title}
      </TabTitle>
    ))
  }, [tabs, activeTab])

  const tabContent = useMemo(() => {
    const tab = tabs.find(item => item.key === activeTab)
    return propOr(null, 'children', tab)
  }, [tabs, activeTab])

  return (
    <>
      <TabsWrapper>{tabsHeaders}</TabsWrapper>
      <TabContent>{tabContent}</TabContent>
    </>
  )
}

Tabs.defaultProps = {
  tabs: []
}

export default Tabs

const TabsWrapper = styled.div`
  display: flex;
`

const TabTitle = styled.div`
  flex: 1;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.primary.main};
      border-color: ${({ theme }) => theme.colors.primary.main};
    `}
`

const TabContent = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 20px 0;
`
