import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import FileInput from 'components/atoms/FileInput'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import FileUploadButton from 'components/atoms/FileUploadButton'
import { importLocationsFromFile } from 'services/LocationsService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { fetchLocationsRoutine } from 'modules/locations/ducks/actions'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { Tooltip } from '@mui/material'

const ImportLocationsFromFileModal = () => {
  const [open, setOpen] = useState(false)

  const [file, setFile] = useState({})
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
    setFile({})
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    const handleSuccess = () => {
      handleModalClose()
      setLoading(false)
      dispatch(fetchLocationsRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      }))
      toast.success('Pomyślnie zaimportowano dane z pliku')
    }

    const handleError = e => {
      setLoading(false)
      toast.error(getApiErrors(e))
    }

    importLocationsFromFile({ file })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <Tooltip title='Import z pliku CSV'>
        <div>
          <FileUploadButton onClick={handleModalOpen} />
        </div>
      </Tooltip>
      <Modal
        open={open}
        title='Importuj z pliku CSV'
        onClose={handleModalClose}
      >
          <FileInput
            onChange={file => setFile(file)}
            accept='.csv'
            isUploaded={isNotNilOrEmpty(file)}
            isLoading={loading}
          />
          <Button
            type='submit'
            color='primary'
            onClick={handleSubmit}
            disabled={isNilOrEmpty(file) || loading}
          >
            Importuj
          </Button>
      </Modal>
    </>
  )
}

export default ImportLocationsFromFileModal
