import { validateField, validateValues } from 'utils/form'
import { object, string } from 'yup'

// warehouse schema
export const warehouseSchema = object().shape({
  name: string().required('Nazwa jest wymagana'),
  subiektId: string().notRequired().nullable()
})

export const validateWarehouseField = values => validateField(warehouseSchema, values)
export const validateWarehouseValues = validateValues(warehouseSchema)
