import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { values } from 'ramda'
import CheckIcon from '@mui/icons-material/Check'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const TestOption = ({ valid, label }) => {
  return (
    <OptionWrapper valid={valid}>
      <OptionMark>
        {valid ? <StyledCheckIcon /> : <StyledDotIcon />}
      </OptionMark>
      <OptionLabel>
        {label}
      </OptionLabel>
    </OptionWrapper>
  )
}

const PasswordHint = ({ password, validatePasswordCallback }) => {
  const [valid, setValid] = useState(false)
  const [testValues, setTestValues] = useState({})

  const regTest = reg => reg.test(password)

  useEffect(() => {
    setTestValues({
      length: regTest( /(?=.{8,})/),
      lowercase: regTest( /(?=.*[a-z])/),
      uppercase: regTest( /(?=.*[A-Z])/),
      number: regTest( /(?=.*[0-9])/)
    })
  }, [password])

  useEffect(() => {
    const isValid = values(testValues).every(val => val === true)
    setValid(isValid)
    validatePasswordCallback(isValid)
  }, [testValues])

  return (
    <Wrapper valid={valid}>
      <TestOption
        valid={testValues.length}
        label='Min. 8 znaków'
      />
      <TestOption
        valid={testValues.number}
        label='Min. 1 cyfra'
      />
      <TestOption
        valid={testValues.lowercase}
        label='Min. 1 mała litera'
      />
      <TestOption
        valid={testValues.uppercase}
        label='Min. 1 wielka litera'
      />
    </Wrapper>
  )
}

PasswordHint.defaultProps = {
  password: '',
  validatePasswordCallback: () => {}
}

export default PasswordHint

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  
  & > div {
    width: 50%;
  }
`

const OptionWrapper = styled.div`
  display: flex;
  color: ${({ theme, valid }) => valid ? theme.colors.primary.main : theme.colors.text};
  align-items: center;
  height: 18px;
  
  &:not(:last-of-type) {
    margin-bottom: 5px
  }
`
const OptionMark = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 20px;
`

const OptionLabel = styled.div`
  font-size: 13px;
`

const StyledCheckIcon = styled(CheckIcon)`
  font-size: 18px !important;
`

const StyledDotIcon = styled(FiberManualRecordIcon)`
  color: ${({ theme }) => theme.colors.lightGrey};
  font-size: 16px !important;
`
