import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import DeactivateIcon from 'components/icons/DeactivateIcon'
import { useDispatch } from 'react-redux'
import { fetchStocktakingOrdersRoutine } from '../ducks/actions'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { resetStocktakingOrder } from 'services/StocktakingOrdersService'
import ButtonWrapper from 'theme/wrappers'

const ResetStocktakingOrderAssignmentModal = ({ stocktakingOrder }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = () => {
      dispatch(
        fetchStocktakingOrdersRoutine({
          query: stringifyQuery(getCurrentParsedQuery())
        })
      )
      toast.success('Zlecone zadanie zostało zresetowane')
      handleModalClose()
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    resetStocktakingOrder({ id: stocktakingOrder.id })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <DeactivateIcon onClick={handleModalOpen} title='Resetuj zadanie'/>
      <Modal open={open} title='Reset zadania inwentaryzacji' onClose={handleModalClose}>
        Czy na pewno chcesz przerwać rozpoczęte zadanie?
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Przerwij
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default ResetStocktakingOrderAssignmentModal
