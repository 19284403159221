import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import ResetPasswordIcon from 'components/icons/ResetPasswordIcon'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import { resetUserPasswordRoutine } from 'modules/users/ducks/actions'
import ButtonWrapper from 'theme/wrappers'

const ResetUserPasswordModal = ({ user }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => setOpen(false)

  const handleSubmit = () => {
    dispatch(resetUserPasswordRoutine({
      values: {
        id: user.id
      },
      callback: handleModalClose
    }))
  }

  return (
    <>
      <ResetPasswordIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Reset hasła'
        onClose={handleModalClose}
      >
        Czy na pewno chcesz zresetować hasło użytkownika &quot;{user.username}&quot;?
        <ButtonWrapper>
          <Button
            onClick={handleModalClose}
            color='primary-outline'
          >
            Anuluj
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
          >
            Resetuj
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default ResetUserPasswordModal
