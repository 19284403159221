import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectZones = state => state.zones

export const selectZonesList = createSelector(
  selectZones,
  propOr([], 'list')
)

export const selectZonesListLoading = createSelector(
  selectZones,
  propOr(false, 'isLoading')
)

export const selectCurrentZone = createSelector(
  selectZones,
  propOr({}, 'currentZone')
)

export const selectZonesListPagination = createSelector(
  selectZones,
  propOr({
    totalPages: 1,
    perPage: 20,
    itemsCount: 0
  }, 'pagination')
)
