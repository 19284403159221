export const DELIVERY_STATUSES = {
  new: 'Nowa',
  received: 'Przyjęta',
  unloaded: 'Rozpakowana',
  moved_to_warehouse: 'Rozniesiona',
  confirmed: 'Potwierdzona'
}

export const DELIVERY_STATUS_COLORS = {
  new: '#2D97D0',
  received: '#FCB72E',
  unloaded: '#cc6599',
  moved_to_warehouse: '#46AFB7',
  confirmed: '#00cc66',
  undefined: '#2D97D0'
}

export const REMOVABLE_DELIVERY_STATUSES = {
  new: 'Nowa',
  received: 'Przyjęta',
  unloaded: 'Rozpakowana'
}

export const DELIVERY_PRIORITIES = {
  low: 'Niski',
  medium: 'Średni',
  high: 'Wysoki'
}
