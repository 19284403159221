import React, { useEffect } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  changeQuery,
  getCurrentParsedQuery,
  isAuthorized
} from 'utils/navigation'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { pathOr, propOr } from 'ramda'
import {
  selectLocationsList,
  selectLocationsListLoading,
  selectLocationsListPagination
} from 'modules/locations/ducks/selectors'
import { fetchLocationsRoutine } from 'modules/locations/ducks/actions'
import ProductsTooltip from 'modules/locations/components/ProductsTooltip'
import EditLocationModal from 'modules/locations/components/EditLocationModal'
import RemoveLocationModal from 'modules/locations/components/RemoveLocationModal'
import { Tooltip } from '@mui/material'
import Barcode from 'react-barcode'
import CreateStocktakingOrderModal from 'modules/locations/components/CreateStocktakingOrderModal'
import { ActionsContainer } from 'theme/wrappers'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'name'
}

const LocationsTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectLocationsList)
  const pagination = useSelector(selectLocationsListPagination)
  const isLoading = useSelector(selectLocationsListLoading)
  const {
    location: { search }
  } = useHistory()
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRolesCRUD = ['admin']
  const priviledgedRolesCreate = ['admin', 'bok', 'warehouseman', 'stocktaker']

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchLocationsRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Nazwa', sort: 'name' },
    { children: 'Magazyn', sort: 'warehouse' },
    { children: 'Strefa', sort: 'zone' },
    { children: 'Liczba produktów', sort: 'products_quantity' },
    { children: 'Limit wagowy' },
    { children: 'Waga produktów', sort: 'products_weight' },
    { children: 'Kolejność', sort: 'sort_order' },
    { children: '', align: 'right' }
  ]

  const rows = list.map(row => {
    const inventory = propOr([], 'inventory', row)
    return {
      redirectPath: `/locations/${row.id}`,
      cells: [
        {
          children: (
            <Tooltip title={<Barcode value={propOr('---', 'name', row)} />}>
              <div>{propOr('---', 'name', row)}</div>
            </Tooltip>
          )
        },
        { children: pathOr('---', ['warehouse', 'name'], row) },
        { children: pathOr('---', ['zone', 'name'], row) },
        {
          children: (
            <ProductsTooltip
              totalQuantity={row.productsQuantity}
              products={inventory.map(product => ({
                name: pathOr('', ['product', 'name'], product),
                ean: pathOr('', ['product', 'ean'], product),
                quantity: pathOr(0, ['quantity'], product),
                id: pathOr('', ['product', 'id'], product)
              }))}
            />
          )
        },
        {
          children: `${propOr('---', 'weightLimit', row)}${propOr(
            '',
            'weightLimitUnit',
            row
          )}`
        },
        {
          children: `${propOr('---', 'productsWeight', row)}${propOr(
            '',
            'productsWeightUnit',
            row
          )}`
        },
        { children: propOr('---', 'sortOrder', row) },
        {
          children: (
            <ActionsContainer>
              {isAuthorized(priviledgedRolesCRUD, userRoles) &&
              isNilOrEmpty(inventory) ? (
                <RemoveLocationModal location={row} />
              ) : null}
              {isAuthorized(priviledgedRolesCreate, userRoles) ? (
                <CreateStocktakingOrderModal location={row} />
              ) : null}
              {isAuthorized(priviledgedRolesCRUD, userRoles) ? (
                <EditLocationModal location={row} />
              ) : null}
            </ActionsContainer>
          ),
          align: 'right',
          preventRedirect: true
        }
      ]
    }
  })

  return (
    <Table
      pagination={pagination}
      onPageChange={handlePageChange}
      headers={headers}
      rows={rows}
      emptyState='Brak lokalizacji do wyświetlenia'
      isLoading={isLoading}
    />
  )
}

export default LocationsTable
