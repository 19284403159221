import React from 'react'
import Tabs from 'components/atoms/Tabs'
import ContentPanel from 'components/atoms/ContentPanel'
import LocationProductsTab from 'modules/locationDetails/components/LocationProductsTab'
import LocationHistoryTab from 'modules/locationDetails/components/LocationHistoryTab'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { isAuthorized } from 'utils/navigation'
import { filter, propOr } from 'ramda'

const LocationDetailsTabs = () => {
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr  ([], 'roles', currentUser)
  const priviledgedRoles = ['admin', 'foreman', 'stocktaker', 'bok', 'warehouseman']

  const tabs = [
    {
      key: 'products',
      title: 'Produkty',
      children: <LocationProductsTab />
    },
    {
      key: 'history_of_changes',
      title: 'Historia zmian',
      children: <LocationHistoryTab />,
      isList: true
    }
  ]

  const basicTabs = filter(
    item => !['history_of_changes'].includes(item.key),
    tabs
  )

  return (
    <ContentPanel>
      <Tabs tabs={isAuthorized(priviledgedRoles, userRoles) ? tabs : basicTabs} />
    </ContentPanel>
  )
}

export default LocationDetailsTabs
