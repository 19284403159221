import Table from 'components/Table'
import { fetchStatusLogsRoutine } from 'modules/saleOrders/ducks/actions'
import {
  selectStatusLogList,
  selectStatusLogListLoading
} from 'modules/saleOrders/ducks/selectors'
import { pathOr, propOr } from 'ramda'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { changeQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getStatusColor } from 'utils/saleOrders'

const defaultFilters = {
  page: 1,
  limit: 20
}

const SaleOrderStatusHistory = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const statusLogList = useSelector(selectStatusLogList)
  const isLoading = useSelector(selectStatusLogListLoading)
  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchStatusLogsRoutine({ id, query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const headers = [
    { children: 'Data i godzina zmiany' },
    { children: 'Stary status' },
    { children: 'Nowy status' },
    { children: 'Stanowisko' },
    { children: 'Zmienione przez' }
  ]

  const rows = statusLogList.map(row => {
    const statusFrom = pathOr('---', ['statusFrom', 'value'], row)
    const statusTo = pathOr('---', ['statusTo', 'value'], row)

    return {
      cells: [
        {
          children: formatDate(
            propOr('---', 'createdAt', row),
            DATE_FORMATS.dashedWithTime
          )
        },
        {
          children: (
            <StatusLabel color={getStatusColor(statusFrom)}>
              {pathOr('---', ['statusFrom', 'label'], row)}
            </StatusLabel>
          )
        },
        {
          children: (
            <StatusLabel color={getStatusColor(statusTo)}>
              {pathOr('---', ['statusTo', 'label'], row)}
            </StatusLabel>
          )
        },
        { children: propOr('---', 'workstation', row) },
        { children: pathOr('---', ['changedBy', 'username'], row) }
      ]
    }
  })

  return (
    <Table
      headers={headers}
      rows={rows}
      withStickyHeader
      isLoading={isLoading}
    />
  )
}

export default SaleOrderStatusHistory

const StatusLabel = styled.div`
  background-color: ${({ color }) => color};
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
  width: 140px;
`
