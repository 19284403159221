import { join, pathOr, pipe, values } from 'ramda'

export const extractMessageFromErrorResponse = pathOr('Wystąpił błąd', [
  'response',
  'data',
  'message'
])

export const getApiErrors = error => {
  const getErrorsFromResponse = pipe(
    pathOr({}, ['response', 'data', 'errors']),
    values,
    join('\n')
  )(error)

  // if there is only one error, it must be handled with previous helper
  return getErrorsFromResponse || extractMessageFromErrorResponse(error)
}
