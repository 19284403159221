import React, { useMemo } from 'react'
import { Tooltip } from '@mui/material'
import styled from 'styled-components'
import { redirect } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'

const ProductsTooltip = ({ totalQuantity, products }) => {
  const tooltipContent = useMemo(() => {
    return products.map(product => (
      <ProductItem key={product.id}>
        <ProductDetails onClick={() => redirect(`/products/${product.id}`)}>
          <ProductName>
            {product.name}
          </ProductName>
          <ProductEan>
            {product.ean}
          </ProductEan>
        </ProductDetails>
        <div>{product.quantity}</div>
      </ProductItem>
    ))
  }, [products])

  return (
    <Tooltip
      placement='bottom-start'
      title={(
        <TooltipContent
          onClick={e => e.stopPropagation()}
        >
          {isNotNilOrEmpty(products)
            ? tooltipContent
            : <div>Brak produktów w lokalizacji</div>
          }
        </TooltipContent>
      )}
    >
      <div>{totalQuantity}</div>
    </Tooltip>
  )
}

export default ProductsTooltip

const TooltipContent = styled.div`
  width: 200px;
  color: ${({ theme }) => theme.colors.text};
`

const ProductItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`

const ProductDetails = styled.div`
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`

const ProductName = styled.div`
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ProductEan = styled.div`
  font-size: 12px;
`
