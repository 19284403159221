import React, { useEffect, useState } from 'react'
import Input from 'components/atoms/Input'
import Modal from 'components/atoms/Modal'
import { useDispatch } from 'react-redux'
import Button from 'components/atoms/Button'
import { createProductRoutine } from 'modules/products/ducks/actions'
import {
  validateCreateField,
  validateCreateValues
} from 'modules/products/ducks/schema'
import { fetchStockUnitKindsRoutine } from 'modules/stockUnits/ducks/actions'
import ImportButton from 'components/atoms/ImportButton'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { omit } from 'ramda'

const initialValues = {
  name: '',
  ean: '',
  mix_codes: [],
  width: 0,
  height: 0,
  depth: 0,
  dimensionsUnit: 'cm',
  is_new: false
}

const AddProductModal = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    modalOpen && dispatch(fetchStockUnitKindsRoutine())
  }, [modalOpen])

  const handleModalOpen = e => {
    e.stopPropagation()
    setModalOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setModalOpen(false)
    setValues(initialValues)
  }

  const handleChangeValues = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    validateCreateValues(values, setValid)
  }, [values])

  const submitHandler = e => {
    e.preventDefault()
    const unitFactor =
      values.dimensionsUnit === 'm'
        ? 100
        : values.dimensionsUnit === 'mm'
        ? 0.1
        : 1
    dispatch(
      createProductRoutine({
        values: {
          ...omit(['dimensionsUnit'], values),
          height: parseInt(values.height) * unitFactor,
          width: parseInt(values.width) * unitFactor,
          depth: parseInt(values.depth) * unitFactor
        },
        callback: handleModalClose
      })
    )
  }

  return (
    <>
      <ImportButton onClick={handleModalOpen}>
        <AddOutlinedIcon />
        <span> Dodaj Produkt</span>
      </ImportButton>
      <Modal open={modalOpen} title='Dodaj produkt' onClose={handleModalClose}>
        <form onSubmit={submitHandler}>
          <Input
            name='name'
            label='Nazwa'
            value={values.name}
            onChange={handleChangeValues}
            validate={validateCreateField(values)}
          />
          <Input
            name='ean'
            label='EAN'
            value={values.ean}
            onChange={handleChangeValues}
            validate={validateCreateField(values)}
          />
          <Input
            name='height'
            label='Wysokość'
            value={values.height}
            onChange={handleChangeValues}
            validate={validateCreateField(values)}
          />
          <Input
            name='width'
            label='Szerokość'
            value={values.width}
            onChange={handleChangeValues}
            validate={validateCreateField(values)}
          />
          <Input
            name='depth'
            label='Głębokość'
            value={values.depth}
            onChange={handleChangeValues}
            validate={validateCreateField(values)}
          />
          <Input
            select
            name='dimensionsUnit'
            label='Jednostka wymiarów'
            options={[
              { label: 'mm', value: 'mm' },
              { label: 'cm', value: 'cm' },
              { label: 'm', value: 'm' }
            ]}
            value={values.dimensionsUnit}
            onChange={handleChangeValues}
            validate={validateCreateField(values)}
          />
          <Input
            select
            name='isNew'
            label='Nowość?'
            options={[
              { label: 'Nie', value: false },
              { label: 'Tak', value: true }
            ]}
            value={values.is_new}
            onChange={handleChangeValues}
            validate={validateCreateField(values)}
          />
          <Button type='submit' color='primary' disabled={!valid}>
            Zapisz
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default AddProductModal
