import AutoComplete from 'components/atoms/AutoComplete'
import { fetchSuppliersRoutine } from 'modules/suppliers/ducks/actions'
import { selectSuppliersList } from 'modules/suppliers/ducks/selectors'
import { propOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { stringifyQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'

const SuppliersAutocomplete = ({ supplier, onHandleValuesChange, onValidate }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [suppliersOptions, setSuppliersOptions] = useState([])
  const suppliers = useSelector(selectSuppliersList)
  const dispatch = useDispatch()

  useEffect(() => {
    isNotNilOrEmpty(searchQuery) &&
      searchQuery.length > 1 &&
      dispatch(
        fetchSuppliersRoutine({
          query: stringifyQuery({
            page: 1,
            limit: 1000,
            sort: 'name',
            filter: { search: searchQuery }
          })
        })
      )
  }, [searchQuery])

  useEffect(() => {
    const options = isNotNilOrEmpty(searchQuery)
      ? suppliers.map(supplier => ({
          label: supplier.name,
          value: supplier.id
        }))
      : []
    setSuppliersOptions(options)
  }, [suppliers])

  const handleValueChange = (name, value) => {
    setSearchQuery(value)
    onHandleValuesChange(name, value)
  }

  return (
    <AutoComplete
      name='supplierId'
      label='Dostawca'
      initialValue={propOr('', 'name', supplier)}
      placeholder='Wprowadź min. 2 znaki'
      options={suppliersOptions}
      onChange={handleValueChange}
      validate={onValidate}
      setSearchQuery={setSearchQuery}
      setAutocompleteOptions={setSuppliersOptions}
    />
  )
}

export default SuppliersAutocomplete
