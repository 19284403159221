import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import RemoveIcon from 'components/icons/RemoveIcon'
import { deleteCompletionList } from 'services/MultipickService'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { omit } from 'ramda'
import { fetchGeneratedMultipickListsRoutine } from 'modules/multipick/ducks/actions'
import ButtonWrapper from 'theme/wrappers'
import { fetchSaleOrdersStatisticsRoutine } from 'modules/saleOrders/ducks/actions'
import { canRemoveList } from 'utils/multipick'

const RemoveListModal = ({ list }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = () => {
      const query = getCurrentParsedQuery()
      const payloadQuery = omit(
        ['activeTab', 'ordered_after', 'ordered_before'],
        query
      )
      dispatch(
        fetchGeneratedMultipickListsRoutine({
          query: stringifyQuery(payloadQuery)
        })
      )
      dispatch(fetchSaleOrdersStatisticsRoutine())
      handleModalClose()
      toast.success('Rozgrupowano listę')
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    deleteCompletionList({ id: list.id }).then(handleSuccess).catch(handleError)
  }

  return (
    <>
      <RemoveIcon onClick={handleModalOpen} disabled={!canRemoveList(list)}/>
      <Modal open={open} title='Rozgrupuj listę' onClose={handleModalClose}>
        Czy na pewno chcesz rozgrupować tę listę?
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Rozgrupuj
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default RemoveListModal
