import React, { useEffect, useState } from 'react'
import PageHeader from 'components/atoms/PageHeader'
import ContentPanel from 'components/atoms/ContentPanel'
import SearchInput from 'components/atoms/SearchInput'
import styled from 'styled-components'
import {
  changeQuery,
  getCurrentParsedQuery,
  isAuthorized,
  stringifyQuery
} from 'utils/navigation'
import { dissoc, propOr } from 'ramda'
import LocationsTable from 'modules/locations/components/LocationsTable'
import ImportLocationsFromFileModal from 'modules/locations/components/ImportLocationsFromFileModal'
import FiltersButton from 'components/atoms/FiltersButton'
import LocationsFilters from 'modules/locations/components/LocationsFilters'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { fetchWarehousesRoutine } from 'modules/warehouses/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchZonesRoutine } from 'modules/zones/ducks/actions'
import AddLocationModal from 'modules/locations/components/AddLocationModal'
import ExportLocationsFromFile from 'modules/locations/components/ExportLocationsFromFile'
import { HeaderWrapper } from 'theme/wrappers'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'

const Locations = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRolesCRUD = ['admin']
  const priviledgedRolesImportExport = ['admin', 'bok']

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const filters = dissoc('search', propOr({}, 'filter', query))
    setFiltersOpen(isNotNilOrEmpty(filters))
  }, [])

  const toggleFilters = () => setFiltersOpen(prev => !prev)

  const handleSubmit = searchQuery => {
    const parsedQuery = getCurrentParsedQuery()
    const isEmpty = isNilOrEmpty(searchQuery)

    changeQuery({
      ...parsedQuery,
      page: 1,
      filter: isEmpty
        ? dissoc('search', propOr({}, 'filter', parsedQuery))
        : {
            ...propOr({}, 'filter', parsedQuery),
            search: searchQuery
          }
    })
  }

  const handleClear = () => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      filter: dissoc('search', propOr({}, 'filter', parsedQuery))
    })
  }

  useEffect(() => {
    dispatch(
      fetchWarehousesRoutine({
        query: stringifyQuery({
          page: 1,
          limit: 999
        })
      })
    )
    dispatch(
      fetchZonesRoutine({
        query: stringifyQuery({
          page: 1,
          limit: 999
        })
      })
    )
  }, [])

  return (
    <>
      <HeaderWrapper>
        <PageHeader>Lista lokalizacji</PageHeader>
        <ActionsWrapper>
          {isAuthorized(priviledgedRolesImportExport, userRoles) && (
            <ImportLocationsFromFileModal />
          )}
          {isAuthorized(priviledgedRolesImportExport, userRoles) && (
            <ExportLocationsFromFile />
          )}
          <FiltersButton active={filtersOpen} onClick={toggleFilters} />
          <InputWrapper>
            <SearchInput
              onSubmit={handleSubmit}
              onClear={handleClear}
              placeholder='Znajdź lokalizację...'
            />
          </InputWrapper>
          {isAuthorized(priviledgedRolesCRUD, userRoles) && <AddLocationModal />}
        </ActionsWrapper>
      </HeaderWrapper>
      <LocationsFilters open={filtersOpen} />
      <ContentPanel>
        <LocationsTable />
      </ContentPanel>
    </>
  )
}

export default Locations

const InputWrapper = styled.div`
  max-width: 250px;
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
