import React from 'react'
import Table from 'components/Table'
import { pathOr, propOr } from 'ramda'

const MultipickListTasksTable = ({ tasks }) => {
  const headers = [
    { children: 'Produkt' },
    { children: 'Ilość' },
    { children: 'Z' },
    { children: 'Status' }
  ]

  const rows = tasks.map(row => ({
    cells: [
      { children: pathOr('', ['product', 'ean'], row) },
      { children: propOr('', 'requestedQuantity', row) },
      { children: pathOr('', ['sourceLocation', 'name'], row) },
      { children: pathOr('', ['status', 'label'], row) }
    ]
  }))

  return (
    <Table
      rows={rows}
      headers={headers}
    />
  )
}

export default MultipickListTasksTable
