import React from 'react'
import DatePicker from "react-datepicker"
import styled from 'styled-components'

const DateInput = ({
  value,
  showTimeSelect,
  onChange,
  label,
  name,
  timeOnly
}) => {
  const handleValueChange = date => {
    onChange(name, date)
  }

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <StyledDatePicker
        onChangeRaw={e => e.preventDefault()}
        timeCaption='Godzina'
        selected={value}
        showTimeSelect={timeOnly || showTimeSelect}
        showTimeSelectOnly={timeOnly}
        timeIntervals={15}
        dateFormat={timeOnly ? 'HH:mm' : 'Pp'}
        onChange={handleValueChange}
      />
    </Wrapper>
  )
}

export default DateInput

const Wrapper = styled.div`
  position: relative;
  .react-datepicker-wrapper > .react-datepicker__input-container > input {
    font-size: 14px !important;

    &:focus {
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary.main}; !important;
    }
  }
`

const StyledDatePicker = styled(DatePicker)`
  height: 50.25px;
  padding: 0 10px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.40);
  cursor: pointer;
  margin-bottom: 15px;
  min-width: unset;
  max-width: unset;
  width: 100%;
`

const Label = styled.div`
  position: absolute;
  top: -5px;
  left: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  z-index: 3;
`
