import moment from 'moment'

export const DATE_FORMATS = {
  dashed: 'DD-MM-YYYY',
  slash: 'DD/MM/YYYY',
  input: 'YYYY-MM-DD',
  inputWithTime: 'YYYY-MM-DDTHH:mm',
  dashedWithTime: 'DD-MM-YYYY HH:mm:ss'
}

export const formatDate = (date, format) => date ? moment(date).format(format) : '---'
