import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import { useDispatch } from 'react-redux'
import Button from 'components/atoms/Button'
import RemoveIcon from 'components/icons/RemoveIcon'
import styled from 'styled-components'
import { removeDeliveryItemRoutine } from 'modules/delivery/ducks/actions'

const RemoveDeliveryItemModal = ({ item }) => {
  const { id, deliveryId } = item
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(
      removeDeliveryItemRoutine({
        id,
        deliveryId,
        callback: handleModalClose
      })
    )
  }

  return (
    <>
      <RemoveIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Usunięcie produktu z dostawy'
        onClose={handleModalClose}
      >
        Czy na pewno chcesz usunąć produkt?
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Anuluj
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Usuń
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default RemoveDeliveryItemModal

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
`
