import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import { validateWarehouseField } from 'modules/warehouses/ducks/schema'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import { editWarehouseRoutine } from 'modules/warehouses/ducks/actions'
import EditIcon from 'components/icons/EditIcon'
import { isEmpty, propOr } from 'ramda'

const emptyValues = {
  name: '',
  subiektId: ''
}

const EditWarehouseModal = ({ warehouse }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    setValues(emptyValues)
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    handleValueChange('name', warehouse.name)
  }, [warehouse])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(editWarehouseRoutine({
      values: {
        name: values.name,
        subiektId: propOr(null, 'subiektId', values),
        id: warehouse.id
      },
      callback: handleModalClose
    }))
  }

  return (
    <>
      <EditIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Edycja magazynu'
        onClose={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Input
            name='name'
            label='Nazwa magazynu'
            value={values.name}
            onChange={handleValueChange}
            validate={validateWarehouseField(values)}
          />
          <Input
            name='subiektId'
            label='Subiekt ID'
            value={values.subiektId}
            onChange={handleValueChange}
            validate={validateWarehouseField(values)}
          />
          <Button
            type='submit'
            color='primary'
            disabled={isEmpty(values.name)}
          >
            Zapisz
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default EditWarehouseModal
