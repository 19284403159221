import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import {
  MultipickFiltersTitle
} from 'modules/multipick/components/filters/MultipickFiltersCommonComponents'
import Input from 'components/atoms/Input'
import { useSelector } from 'react-redux'
import { selectMultipickDictionary } from 'modules/multipick/ducks/selectors'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

const MultipickListFilters = ({ values, handleValueChange }) => {
  const multipickDictionary = useSelector(selectMultipickDictionary)
  const stockUnitKinds = propOr([], 'stockUnitKinds', multipickDictionary)

  const canLimitByFilling = useMemo(() => {
    const kindsFromValues = propOr([], 'stockUnitKinds', values)
    const allowedTypesIds = stockUnitKinds
      .filter(kind => kind.limitVolume)
      .map(kind => kind.id)
    const allowedFromValues = kindsFromValues.filter(kindId => allowedTypesIds.includes(kindId))
    return isNotNilOrEmpty(allowedFromValues) && kindsFromValues.length === 1
  }, [values, stockUnitKinds])

  useEffect(() => {
    !canLimitByFilling && handleValueChange('limitBy', 'items_count')
  }, [canLimitByFilling])

  const limitationForm = useMemo(() => {
    switch (values.limitBy) {
      case 'stock_unit_filling':
        return (
          <Row>
            <Input
              name='minStockUnitFilling'
              type='number'
              inputProps={{
                min: 0,
                max: 100
              }}
              label='% wypełnienia OD'
              value={values.minStockUnitFilling}
              onChange={handleValueChange}
            />
            <Input
              name='maxStockUnitFilling'
              type='number'
              inputProps={{
                min: 0,
                max: 100
              }}
              label='% wypełnienia DO'
              value={values.maxStockUnitFilling}
              onChange={handleValueChange}
            />
          </Row>
        )
      case 'items_count':
        return (
          <>
            <Row>
              <Input
                name='minItems'
                type='number'
                inputProps={{
                  min: 1
                }}
                label='Ilość sztuk OD'
                value={values.minItems}
                onChange={handleValueChange}
              />
              <Input
                name='maxItems'
                type='number'
                inputProps={{
                  min: 1
                }}
                label='Ilość sztuk DO'
                value={values.maxItems}
                onChange={handleValueChange}
              />
            </Row>
            <Row>
              <Input
                name='minUniqueProducts'
                type='number'
                inputProps={{
                  min: 1
                }}
                label='Ilość różnych SKU OD'
                value={values.minUniqueProducts}
                onChange={handleValueChange}
              />
              <Input
                name='maxUniqueProducts'
                type='number'
                inputProps={{
                  min: 1
                }}
                label='Ilość różnych SKU DO'
                value={values.maxUniqueProducts}
                onChange={handleValueChange}
              />
            </Row>
          </>
        )
      default:
        return null
    }
  }, [values])

  return (
    <Wrapper>
      <MultipickFiltersTitle>Parametry listy</MultipickFiltersTitle>
      <Row>
        <Input
          name='priority'
          type='number'
          inputProps={{
            min: 0,
            max: 10
          }}
          label='Priorytet'
          value={values.priority}
          onChange={handleValueChange}
        />
        <SelectWrapper>
          <Input
            select
            options={[
              {
                label: 'Ilość na wózku',
                value: 'items_count'
              },
              {
                label: '% wypełnienia pojemnika',
                value: 'stock_unit_filling',
                disabled: !canLimitByFilling
              }
            ]}
            name='limitBy'
            label='Rodzaj ograniczenia'
            value={values.limitBy}
            onChange={handleValueChange}
          />
        </SelectWrapper>
      </Row>
      {limitationForm}
    </Wrapper>
  )
}

export default MultipickListFilters

const Wrapper = styled.div`
  min-width: 300px;
`

const Row = styled.div`
  display: flex;
  gap: 10px;
`

const SelectWrapper = styled.div`
  min-width: 75%;
`
