import React from 'react'
import PickerStatsTable from 'modules/flightControl/components/PickerStatsTable'
import PackerStatsTable from 'modules/flightControl/components/PackerStatsTable'
import styled from 'styled-components'
import ConnectorStatsTable from 'modules/flightControl/components/ConnectorStatsTable'

const FlightControlRoleStats = () => {
  return (
    <div>
      <Row>
        <PackerStatsTable />
        <PickerStatsTable />
      </Row>
      <ConnectorStatsTable />
    </div>
  )
}

export default FlightControlRoleStats

const Row = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
`
