import React from 'react'
import ContentPanel from 'components/atoms/ContentPanel'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectCurrentProduct } from 'modules/products/ducks/selectors'
import { head, pathOr, propOr } from 'ramda'
import { INPOST_SIZE } from 'utils/product'
import ProductBasicDetailsThumbnail from 'modules/productDetails/components/ProductBasicDetailsThumbnail'

const ProductBasicDetails = () => {
  const product = useSelector(selectCurrentProduct)
  const thumbnail = head(
    propOr([], 'files', product).filter(file => file.type === 'photo')
  )

  return (
    <ContentPanel withMargin>
      <Wrapper>
        <ProductImageWrapper>
          <ProductBasicDetailsThumbnail file={thumbnail} />
        </ProductImageWrapper>
        <div>
          <ProductEan>EAN: {product.ean}</ProductEan>
          <ProductName>{product.name}</ProductName>
          <TotalQuantity>Dostępne: {product.totalQuantity}</TotalQuantity>
          <DetailedInfo>
            <DetailItem>
              Wysokość:{' '}
              <span>
                {pathOr('---', ['height'], product)}
                {pathOr('', ['dimensionsUnit'], product)}
              </span>
            </DetailItem>
            <DetailItem>
              Szerokość:{' '}
              <span>
                {pathOr('---', ['width'], product)}
                {pathOr('', ['dimensionsUnit'], product)}
              </span>
            </DetailItem>
            <DetailItem>
              Głębokość:{' '}
              <span>
                {pathOr('---', ['depth'], product)}
                {pathOr('', ['dimensionsUnit'], product)}
              </span>
            </DetailItem>
            <DetailItem>
              Waga:{' '}
              <span>
                {pathOr(0, ['weight'], product)}
                {pathOr('', ['weightUnit'], product)}
              </span>
            </DetailItem>
            <DetailItem>
              Rodzaj opakowania:{' '}
              <span>{pathOr('---', ['packagingType', 'name'], product)}</span>
            </DetailItem>
            <DetailItem>
              Wymiar Inpost:{' '}
              <span>
                {propOr('', 'inpostSize', product) ? INPOST_SIZE[product.inpostSize] : '---'}
              </span>
            </DetailItem>
            <DetailItem>
              Nieporęczny: <span>{product.isCumbersome ? 'TAK' : 'NIE'}</span>
            </DetailItem>
            <DetailItem>
              Wstępnie spakowany:{' '}
              <span>{product.isPrepacked ? 'TAK' : 'NIE'}</span>
            </DetailItem>
          </DetailedInfo>
          <DetailedInfo>
            <DetailItem>
              Komentarz: <span>{pathOr('---', ['comment'], product)}</span>
            </DetailItem>
            <DetailItem>
              Rodzaj jednostki magazynowej:{' '}
              <span>{pathOr('---', ['stockUnitKind', 'name'], product)}</span>
            </DetailItem>
          </DetailedInfo>
        </div>
      </Wrapper>
    </ContentPanel>
  )
}

export default ProductBasicDetails

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`
const ProductImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ProductEan = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
`

const ProductName = styled.div`
  font-size: 24px;
`

const TotalQuantity = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGrey};
`

const DetailItem = styled.div`
  font-size: 12px;
  text-transform: uppercase;

  span {
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
  }
`

const DetailedInfo = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`
