import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import DeactivateIcon from 'components/icons/DeactivateIcon'
import { useDispatch } from 'react-redux'
import { fetchSaleOrdersRoutine, fetchSaleOrdersStatisticsRoutine } from '../ducks/actions'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import { cancelSaleOrder } from 'services/SaleOrdersService'
import ButtonWrapper from 'theme/wrappers'

const CancelSaleOrderModal = ({ saleOrder }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleModalClose = e => {
    e && e.stopPropagation()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = () => {
      dispatch(
        fetchSaleOrdersRoutine({
          query: stringifyQuery(getCurrentParsedQuery())
        })
      )
      dispatch(fetchSaleOrdersStatisticsRoutine())
      toast.success('Anulowano zamówienie')
      handleModalClose()
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    cancelSaleOrder({ id: saleOrder.id })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <DeactivateIcon onClick={handleModalOpen} title='Anuluj' />
      <Modal
        open={open}
        title='Anulowanie zamówienia'
        onClose={handleModalClose}
      >
        Czy na pewno chcesz anulować zamówienie?
        <ButtonWrapper>
          <Button onClick={handleModalClose} color='primary-outline'>
            Wróć
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Anuluj zamówienie
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default CancelSaleOrderModal
