import React from 'react'
import styled from 'styled-components'

const PageHeader = ({ children, withMargin }) =>
  <Header withMargin={withMargin}>
    {children}
  </Header>

export default PageHeader

const Header = styled.div`
  font-size: 24px;
  letter-spacing: 1px;
  margin-bottom: ${({ withMargin }) => withMargin ? '20px' : 0};
`
