import {
  fetchDefaultFilteredDeliveriesListRoutine,
  fetchDeliveriesListRoutine,
  fetchSingleDeliveryRoutine,
  fetchDeliveryItemsRoutine
} from 'modules/delivery/ducks/actions'

const defaultState = {
  list: [],
  pagination: null,
  defaultFilteredList: [],
  defaultFilteredListPagination: null,
  currentDelivery: {},
  deliveryItems: [],
  deliveryItemsPagination: null,
  isLoading: false,
  isLoadingFilteredList: false,
  isLoadingItems: false,
  isLoadingCurrentDelivery: false
}

const deliveriesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchDeliveriesListRoutine.TRIGGER:
      return {
        ...state,
        isLoading: true
      }
    case fetchDeliveriesListRoutine.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case fetchDeliveriesListRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoading: false
      }
    case fetchDefaultFilteredDeliveriesListRoutine.TRIGGER:
      return {
        ...state,
        isLoadingFilteredList: true
      }
    case fetchDefaultFilteredDeliveriesListRoutine.FAILURE:
      return {
        ...state,
        isLoadingFilteredList: false
      }
    case fetchDefaultFilteredDeliveriesListRoutine.SUCCESS:
      return {
        ...state,
        defaultFilteredList: action.payload.data,
        defaultFilteredListPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoadingFilteredList: false
      }
    case fetchSingleDeliveryRoutine.TRIGGER:
      return {
        ...state,
        isLoadingCurrentDelivery: true
      }
    case fetchSingleDeliveryRoutine.FAILURE:
      return {
        ...state,
        isLoadingCurrentDelivery: false
      }
    case fetchSingleDeliveryRoutine.SUCCESS:
      return {
        ...state,
        currentDelivery: action.payload,
        isLoadingCurrentDelivery: false
      }
    case fetchDeliveryItemsRoutine.TRIGGER:
      return {
        ...state,
        isLoadingItems: true
      }
    case fetchDeliveryItemsRoutine.FAILURE:
      return {
        ...state,
        isLoadingItems: false
      }
    case fetchDeliveryItemsRoutine.SUCCESS:
      return {
        ...state,
        deliveryItems: action.payload.data,
        deliveryItemsPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        },
        isLoadingItems: false
      }
    default:
      return state
  }
}

export default deliveriesReducer
