import React from 'react'
import PageHeader from 'components/atoms/PageHeader'
import ContentPanel from 'components/atoms/ContentPanel'
import WarehousesTable from 'modules/warehouses/components/WarehousesTable'
import AddWarehouseModal from 'modules/warehouses/components/AddWarehouseModal'
import { HeaderWrapper } from 'theme/wrappers'

const Warehouses = () => {
  return (
    <>
      <HeaderWrapper>
        <PageHeader>Lista magazynów</PageHeader>
        <AddWarehouseModal />
      </HeaderWrapper>
      <ContentPanel>
        <WarehousesTable />
      </ContentPanel>
    </>
  )
}

export default Warehouses
