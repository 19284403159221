import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import imagePlaceholder from 'assets/image-placeholder.png'
import ImageViewer from 'react-simple-image-viewer'
import { selectPackingTypesList } from 'modules/packingTypes/ducks/selectors'
import { useSelector } from 'react-redux'
import { getPackingProductColor } from 'utils/packing'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { selectCurrentPackingOrder } from 'modules/packing/ducks/selectors'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'

const PackingProductBox = ({ packingItem }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const packagingTypes = useSelector(selectPackingTypesList)
  const currentOrder = useSelector(selectCurrentPackingOrder)
  const isMultiSku = propOr([], 'items', currentOrder).length > 1

  const productStockUnits = useMemo(() => {
    return propOr([], 'stockUnits', packingItem)
  }, [])

  const color = useMemo(() => {
    return getPackingProductColor(packingItem)
  }, [packingItem])

  const getPackingTypeName = id => {
    return packagingTypes
      ? packagingTypes.find(type => type.id === id)?.name
      : ''
  }

  const openImageViewer = () => {
    setIsViewerOpen(true)
  }

  const closeImageViewer = () => {
    setIsViewerOpen(false)
  }

  const product = propOr({}, 'product', packingItem)
  const barcodes = propOr([], 'barcodes', product)
  const mixCodes = propOr([], 'mixCodes', product)
  const thumbnailUrl = propOr(null, 'iconLargeUrl', product)
  const photoUrl = isNotNilOrEmpty(thumbnailUrl)
    ? thumbnailUrl
    : imagePlaceholder

  return (
    <Box color={color}>
      <BoxTitle color={color}>
        Produkt: <span>{product.ean}</span>
      </BoxTitle>
      <BoxContent>
        <Thumbnail
          onClick={isNotNilOrEmpty(thumbnailUrl) ? openImageViewer : () => {}}
          url={photoUrl}
        />
        <ProductDetails>
          <DetailItem>
            <ProductDetailsTitle>Nazwa:</ProductDetailsTitle>
            <div>{product.name}</div>
          </DetailItem>
          <DetailItem>
            <ProductDetailsTitle>Kody produktu:</ProductDetailsTitle>
            {barcodes.map(code => (
              <div key={code}>{code}</div>
            ))}
            {isNotNilOrEmpty(mixCodes) && (
              <>
                <ProductDetailsTitle>Kody mixu:</ProductDetailsTitle>
                {mixCodes.map(code => (
                  <div key={code}>{code}</div>
                ))}
              </>
            )}
          </DetailItem>
        </ProductDetails>
        {!isMultiSku && (
          <PackagingTypeBox>
            <div>Opakowanie:</div>
            <PackagingName>
              {getPackingTypeName(product.packagingTypeId)}
            </PackagingName>
          </PackagingTypeBox>
        )}
        <PackagingBox>
          <>
            <div>Pojemnik:</div>
            {productStockUnits.map(unit => (
              <MultiSkuStockUnitCode key={unit.code}>
                {unit.code}
                <Tooltip
                  title={
                    <>
                      <MultiSkuStockUnitCodeInfo>
                        <span>Zbierający:</span>
                        {unit.picker}
                      </MultiSkuStockUnitCodeInfo>
                      <MultiSkuStockUnitCodeInfo>
                        <span>Z lokalizacji:</span>
                        {unit.sourceLocation}
                      </MultiSkuStockUnitCodeInfo>
                    </>
                  }
                >
                  <StyledInfoIcon />
                </Tooltip>
              </MultiSkuStockUnitCode>
            ))}
          </>
        </PackagingBox>
        <ItemBox color={color}>
          <ShoppingBasketOutlinedIcon />
          <ItemBoxValue>{packingItem.orderedQuantity}</ItemBoxValue>
        </ItemBox>
        <ItemBox color={color}>
          <CheckCircleIcon />
          <ItemBoxValue>{packingItem.packedQuantity}</ItemBoxValue>
        </ItemBox>
      </BoxContent>
      {isViewerOpen && (
        <ViewerWrapper>
          <ImageViewer
            src={[thumbnailUrl]}
            backgroundStyle={{
              background: 'rgba(0, 0, 0, .7)'
            }}
            currentIndex={0}
            disableScroll
            closeOnClickOutside
            onClose={closeImageViewer}
          />
        </ViewerWrapper>
      )}
    </Box>
  )
}

export default PackingProductBox

const Box = styled.div`
  border: 1px solid ${({ color }) => color};
  margin-bottom: 10px;
`

const BoxTitle = styled.div`
  background-color: ${({ color }) => color};
  color: #fff;
  height: 30px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  span {
    font-weight: bold;
    margin-left: 5px;
  }
`

const BoxContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;
  gap: 8px;
  height: 125px;
  font-size: 12px;
`

const ProductDetails = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`

const ProductDetailsTitle = styled.div`
  font-weight: bold;
`

const DetailItem = styled.div`
  margin-bottom: 10px;
`

const PackagingTypeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  max-width: 260px;
  padding: 0 20px;
  height: 95%;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
`

const PackagingName = styled.div`
  font-size: 40px;
`

const PackagingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-width: 80px;
  max-width: 80px;
  height: 95%;
  flex: 1;
  background-color: ${({ color }) => (color ? color : '#fff')};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
`

const Thumbnail = styled.div`
  min-width: 120px;
  height: 120px;
  position: relative;
  background: ${({ url, theme }) =>
    `${theme.colors.lightGrey} url(${url}) no-repeat center/cover`};
  cursor: pointer;
`

const ViewerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
`

const ItemBoxValue = styled.div`
  font-size: 24px;
`
const ItemBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-width: 40px;
  max-width: 40px;
  height: 95%;
  border-radius: 4px;
  flex: 1;
  background-color: ${({ color }) => (color ? color : '#fff')};
  color: #fff;
`

const MultiSkuStockUnitCode = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const MultiSkuStockUnitCodeInfo = styled.div`
  font-size: 14px;
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
  span {
    display: inline-block;
    font-weight: bold;
    margin-right: 5px;
  }
`

const StyledInfoIcon = styled(InfoOutlinedIcon)`
  font-size: 14px !important;
  cursor: help;
`
