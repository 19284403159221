import ContentPanel from 'components/atoms/ContentPanel'
import PageHeader from 'components/atoms/PageHeader'
import ExportWarehouseStateToFileModal from 'modules/warehouses/components/ExportWarehouseStateToFileModal'
import WarehouseStateTable from 'modules/warehouses/components/WarehouseStateTable'
import React from 'react'
import { HeaderWrapper } from 'theme/wrappers'

const WarehouseState = () => {
  return (
    <>
      <HeaderWrapper>
        <PageHeader>Stan magazynu</PageHeader>
        <ExportWarehouseStateToFileModal />
      </HeaderWrapper>
      <ContentPanel>
        <WarehouseStateTable />
      </ContentPanel>
    </>
  )
}

export default WarehouseState
