import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as importJobsService from 'services/ImportJobsService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'

export const fetchImportJobsRoutine = createRoutine('FETCH_IMPORT_JOBS')
export const fetchPendingImportJobsRoutine = createRoutine(
  'FETCH_PENDING_IMPORT_JOBS'
)

// ACTIONS
function * fetchImportJobs ({ payload }) {
  yield put(fetchImportJobsRoutine.request())
  try {
    const { data } = yield call(importJobsService.fetchImportJobs, payload)
    yield put(fetchImportJobsRoutine.success(data))
  } catch (e) {
    yield put(fetchImportJobsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

function * fetchPendingImportJobs () {
  yield put(fetchPendingImportJobsRoutine.request())
  try {
    const { data } = yield call(importJobsService.fetchPendingImportJobs)
    yield put(fetchPendingImportJobsRoutine.success(data.data))
  } catch (e) {
    yield put(fetchPendingImportJobsRoutine.failure(e))
    toast.error(getApiErrors(e))
    console.error(e)
  }
}

// WATCHERS
export function * fetchImportJobsWatcher () {
  yield takeLatest(fetchImportJobsRoutine.TRIGGER, fetchImportJobs)
}

export function * fetchPendingImportJobsWatcher () {
  yield takeLatest(
    fetchPendingImportJobsRoutine.TRIGGER,
    fetchPendingImportJobs
  )
}

// SAGAS
export const importJobsSagas = [
  fork(fetchImportJobsWatcher),
  fork(fetchPendingImportJobsWatcher)
]
