import React, { useMemo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import PATHS from 'utils/paths'
import { isNotNilOrEmpty } from 'utils/ramda'

const PrivateRoute = ({ component: Component, allowedRoles, userRoles = null, ...rest }) => {
  const hasValidRole = useMemo(() => {
    return userRoles && allowedRoles
      ? allowedRoles.some(role => userRoles.includes(role))
      : false
  }, [userRoles])

  if (isNotNilOrEmpty(userRoles)) {
    return hasValidRole
      ? <Route {...rest} render={props => <Component {...props} />} />
      : <Redirect to={PATHS.home} />
  } else {
    return <div />
  }
}

export default PrivateRoute
