import React from 'react'
import AllOrdersTable from 'modules/flightControl/components/AllOrdersTable'
import AllocatedOrdersTable from 'modules/flightControl/components/AllocatedOrdersTable'
import CollectedOrdersTable from 'modules/flightControl/components/CollectedOrdersTable'
import PackedOrdersTable from 'modules/flightControl/components/PackedOrdersTable'
import styled from 'styled-components'

const FlightControlSaleOrders = () => {
  return (
    <Wrapper>
      <Row>
        <AllOrdersTable />
        <AllocatedOrdersTable />
      </Row>
      <Row>
        <CollectedOrdersTable />
        <PackedOrdersTable />
      </Row>
    </Wrapper>
  )
}

export default FlightControlSaleOrders

const Wrapper = styled.div`
`

const Row = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
`
