import React, { useEffect, useMemo, useState } from 'react'
import Input from 'components/atoms/Input'
import {
  validateUpdateDeliveryField,
  validateUpdateDeliveryValues
} from 'modules/delivery/ducks/schema'
import Button from 'components/atoms/Button'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { useDispatch, useSelector } from 'react-redux'
import { selectWarehousesList } from 'modules/warehouses/ducks/selectors'
import styled from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import { selectCurrentDelivery } from 'modules/delivery/ducks/selectors'
import { pick } from 'ramda'
import { updateDelivery } from 'services/DeliveryService'
import { toast } from 'react-hot-toast'
import { fetchSingleDeliveryRoutine } from 'modules/delivery/ducks/actions'
import { getApiErrors } from 'utils/errors'
import moment from 'moment'
import { DELIVERY_STATUSES } from 'utils/delivery'
import { stringifyQuery } from 'utils/navigation'
import { fetchWarehousesRoutine } from 'modules/warehouses/ducks/actions'
import SuppliersAutocomplete from './SuppliersAutocomplete'

const emptyValues = {
  scheduledFor: formatDate(new Date(), DATE_FORMATS.input),
  supplierId: '',
  priority: '',
  comment: '',
  warehouseId: '',
  ordinalNo: null,
  documentNo: null,
  status: '',
  supplier: {}
}

const UpdateDeliveryForm = () => {
  const delivery = useSelector(selectCurrentDelivery)
  const [values, setValues] = useState(emptyValues)
  const [valid, setValid] = useState(false)
  const warehouses = useSelector(selectWarehousesList)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    isNotNilOrEmpty(delivery) &&
      setValues({
        ...pick(
          [
            'supplierId',
            'priority',
            'comment',
            'warehouseId',
            'ordinalNo',
            'documentNo',
            'status',
            'supplier'
          ],
          delivery
        ),
        scheduledFor: formatDate(delivery.scheduledFor, DATE_FORMATS.input)
      })
  }, [delivery])

  useEffect(() => {
    dispatch(
      fetchWarehousesRoutine({
        query: stringifyQuery({
          page: 1,
          limit: 1000
        })
      })
    )
  }, [])

  const warehousesOptions = useMemo(() => {
    return warehouses.map(warehouse => ({
      label: warehouse.name,
      value: warehouse.id
    }))
  }, [warehouses])

  const statusOptions = useMemo(() => {
    let items = []
    for (let key in DELIVERY_STATUSES) {
      items = [...items, { label: DELIVERY_STATUSES[key], value: key }]
    }
    return items
  }, [])

  useEffect(() => {
    validateUpdateDeliveryValues(values, setValid)
  }, [values])

  const handleSubmit = e => {
    e.preventDefault()

    updateDelivery({
      id: delivery.id,
      ...values
    })
      .then(() => {
        dispatch(fetchSingleDeliveryRoutine({ id: delivery.id }))
        toast.success('Zapisano zmiany')
      })
      .catch(err => {
        toast.error(getApiErrors(err))
      })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Wrapper>
        <div>
          <Input
            name='documentNo'
            label='Numer dokumentu'
            value={values.documentNo}
            onChange={handleValueChange}
            validate={validateUpdateDeliveryField(values)}
          />
          <Input
            name='ordinalNo'
            label='Numer porządkowy'
            value={values.ordinalNo}
            onChange={handleValueChange}
            validate={validateUpdateDeliveryField(values)}
          />
          <Input
            type='date'
            inputProps={{ min: formatDate(moment(), DATE_FORMATS.input) }}
            name='scheduledFor'
            label='Data planowana'
            value={values.scheduledFor}
            onChange={handleValueChange}
            validate={validateUpdateDeliveryField(values)}
          />
          <SuppliersAutocomplete
            supplier={values.supplier}
            onHandleValuesChange={handleValueChange}
            onValidate={validateUpdateDeliveryField(values)}
          />
          <Input
            select
            options={warehousesOptions}
            name='warehouseId'
            label='Magazyn'
            value={values.warehouseId}
            onChange={handleValueChange}
            validate={validateUpdateDeliveryField(values)}
          />
        </div>
        <div>
          <Input
            select
            options={[
              { label: 'Niski', value: 'low' },
              { label: 'Średni', value: 'medium' },
              { label: 'Wysoki', value: 'high' }
            ]}
            name='priority'
            label='Priorytet'
            value={values.priority}
            onChange={handleValueChange}
            validate={validateUpdateDeliveryField(values)}
          />
          <Input
            select
            disabled={delivery.status === 'confirmed'}
            options={statusOptions}
            name='status'
            label='Status'
            value={values.status}
            onChange={handleValueChange}
            validate={validateUpdateDeliveryField(values)}
          />
          <Input
            name='comment'
            multiline
            rows={3}
            label='Komentarz'
            value={values.comment}
            onChange={handleValueChange}
            validate={validateUpdateDeliveryField(values)}
          />
        </div>
      </Wrapper>
      <ButtonWrapper>
        <Button type='submit' color='primary' disabled={!valid}>
          Zapisz zmiany
        </Button>
      </ButtonWrapper>
    </Form>
  )
}

export default UpdateDeliveryForm

const Wrapper = styled.div`
  display: flex;
  gap: 30px;

  & > div {
    flex: 1;
  }
`

const Form = styled.form``

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  & > button {
    max-width: 200px;
  }
`
