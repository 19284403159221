import APIService from './APIService'

const api = APIService()

export const fetchStocktakingOrders = payload =>
  api.get(`/stocktaking-orders${payload.query}`)
export const createStocktakingOrderForLocation = payload =>
  api.post(`/stocktaking-orders/locations/${payload.id}`, payload)
export const createStocktakingOrderForProduct = payload =>
  api.post(`/stocktaking-orders/products/${payload.id}`, payload)
export const exportStocktakingOrdersToCsv = payload =>
  api.get(`stocktaking-orders/export/csv${payload.query}`)
export const resetStocktakingOrder = payload =>
  api.patch(`stocktaking-orders/${payload.id}/reset`)
export const cancelStocktakingOrder = payload =>
  api.delete(`stocktaking-orders/${payload.id}`)
