import React, { useEffect, useState } from 'react'
import Input from 'components/atoms/Input'
import {
  validateStockUnitAmountField,
  validateStockUnitAmountValues
} from 'modules/delivery/ducks/schema'

const StockUnitAmountForm = ({ item, onChange, onValidate }) => {
  const [stockUnit, setStockUnit] = useState({})

  useEffect(() => {
    if (stockUnit !== item) {
      setStockUnit(item)
    }
  }, [item])

  useEffect(() => {
    validateStockUnitAmountValues(stockUnit, onValidate)
  }, [stockUnit])

  const handleAmount = (name, value) => {
    setStockUnit(prev => ({ ...prev, [name]: value }))
    onChange(name, value)
  }

  return (
    <Input
      key={item.stockUnitId}
      name='quantity'
      label={'Ilość na JM ' + item.name}
      value={item.quantity}
      onChange={handleAmount}
      validate={validateStockUnitAmountField(stockUnit)}
    />
  )
}

export default StockUnitAmountForm
