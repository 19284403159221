import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import { useDispatch } from 'react-redux'
import { fetchCouriersGroupsListRoutine, } from 'modules/couriers/ducks/actions'
import { deleteCourierGroup } from 'services/CouriersService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import { getCurrentParsedQuery, stringifyQuery } from 'utils/navigation'
import RemoveIcon from 'components/icons/RemoveIcon'
import ButtonWrapper from 'theme/wrappers'

const DeleteCourierGroupModal = ({ group }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => {
    setOpen(false)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const handleSuccess = () => {
      dispatch(fetchCouriersGroupsListRoutine({
        query: stringifyQuery(getCurrentParsedQuery())
      }))
      handleModalClose()
      toast.success('Usunięto grupę kurierów')
    }

    const handleError = e => {
      toast.error(getApiErrors(e))
    }

    deleteCourierGroup({ id: group.id })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <RemoveIcon onClick={handleModalOpen} />
      <Modal
        open={open}
        title='Usuń grupę kurierów'
        onClose={handleModalClose}
      >
        Czy na pewno chcesz usunąć grupę &apos;{group.name}&apos;?
        <ButtonWrapper>
          <Button
            onClick={handleModalClose}
            color='primary-outline'
          >
            Anuluj
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
          >
            Usuń
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default DeleteCourierGroupModal
