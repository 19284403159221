import React from 'react'
import PageHeader from 'components/atoms/PageHeader'
import ImportJobsTabs from 'modules/importJobsReport/components/ImportJobsTabs'

const ImportJobsReport = () => {
  return (
    <>
      <PageHeader withMargin>Zadania importu</PageHeader>
      <ImportJobsTabs />
    </>
  )
}

export default ImportJobsReport
