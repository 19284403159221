import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import {
  selectCurrentPackingOrder,
  selectCurrentUnitCompletionList,
  selectCurrentUnitNumber, selectCurrentUnitParentNumber
} from 'modules/packing/ducks/selectors'
import { pathOr, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

const PackingOrderDetails = () => {
  const currentUnitNumber = useSelector(selectCurrentUnitNumber)
  const currentUnitParentNumber = useSelector(selectCurrentUnitParentNumber)
  const currentOrder = useSelector(selectCurrentPackingOrder)
  const completionList = useSelector(selectCurrentUnitCompletionList)

  const deliveryAddress = propOr({}, 'deliveryAddress', currentOrder)
  const street = propOr('', 'street', deliveryAddress)
  const city = propOr('', 'city', deliveryAddress)
  const zipCode = propOr('', 'zipCode', deliveryAddress)
  const address = isNotNilOrEmpty(deliveryAddress)
    ? `${street}, ${zipCode} ${city}`
    : '---'

  return (
    <Wrapper>
      <Box>
        <BoxTitle>Numer wózka</BoxTitle>
        <BoxContent>{isNotNilOrEmpty(currentUnitParentNumber) ? currentUnitParentNumber : currentUnitNumber}</BoxContent>
      </Box>
      <Box>
        <BoxTitle>ID Listy</BoxTitle>
        <BoxContent>{pathOr('---', ['listNumber'], completionList)}</BoxContent>
      </Box>
      <Box>
        <BoxTitle>Kurier</BoxTitle>
        <BoxContent>{pathOr('---', ['courier', 'name'], currentOrder)}</BoxContent>
      </Box>
      <Box>
        <BoxTitle>Adres zamówienia</BoxTitle>
        <BoxContent>{address}</BoxContent>
      </Box>
      <Box>
        <BoxTitle>Źródło zamówienia</BoxTitle>
        <BoxContent>{pathOr('---', ['source', 'name'], currentOrder)}</BoxContent>
      </Box>
    </Wrapper>
  )
}

export default PackingOrderDetails

const Wrapper = styled.div`
  display: flex;
  gap: 15px;
`

const Box = styled.div`
  flex: 1;
  border: 1px solid ${({ theme }) => theme.colors.border};
`

const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary.main};
  color: #fff;
  font-weight: bold;
`

const BoxContent = styled.div`
  padding: 10px;
  background: #fff;
  min-height: 70px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
