import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import LoginForm from 'modules/auth/components/LoginForm'
import loginBg from 'assets/login-bg.svg'
import { useSelector } from 'react-redux'
import { selectCurrentUser, selectIsLoggedIn } from 'modules/auth/ducks/selectors'
import { isAuthorizedToPath, redirect } from 'utils/navigation'
import PATHS from 'utils/paths'
import { propOr } from 'ramda'

const Login = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const user = useSelector(selectCurrentUser)
  const roles = propOr([], 'roles', user)
  const isAllowedControl = isAuthorizedToPath(PATHS.control, roles)

  useEffect(() => {
    if (isLoggedIn) {
     isAllowedControl
        ? redirect(PATHS.control)
        : redirect(PATHS.unauthorized)
    }
  }, [isLoggedIn])

  return (
    <>
      <Background src={loginBg} />
      <Helmet>
        Logowanie | Avapack
      </Helmet>
      <PageWrapper>
        <LoginForm />
      </PageWrapper>
    </>
  )
}

export default Login

const PageWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 30px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Background = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
