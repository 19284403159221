import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { dissoc, has, omit, pathOr, propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectGeneratedMultipicks,
  selectIsLoadingGeneratedOrders
} from 'modules/multipick/ducks/selectors'
import { fetchGeneratedMultipickListsRoutine } from 'modules/multipick/ducks/actions'
import { useHistory } from 'react-router-dom'
import {
  changeQuery,
  getCurrentParsedQuery,
  stringifyQuery
} from 'utils/navigation'
import {
  changeMultipickListsPriority,
  getMultipickListDetails
} from 'services/MultipickService'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-hot-toast'
import MultipickListOrdersTable from 'modules/multipick/components/MultipickListOrdersTable'
import MultipickListTasksTable from 'modules/multipick/components/filters/MultipickListTasksTable'
import SearchInput from 'components/atoms/SearchInput'
import BulkRemoveListsModal from 'modules/multipick/components/BulkRemoveListsModal'
import MultipickListsListItem from 'modules/multipick/components/MultipickListsListItem'
import LoadingSpinner from 'components/atoms/LoadingSpinner'

const defaultFilters = {
  page: 1,
  sort: 'created_at'
}

const MultipickListTab = () => {
  const [selectedLists, setSelectedLists] = useState([])
  const [priorityInput, setPriorityInput] = useState('')
  const list = useSelector(selectGeneratedMultipicks)
  const isLoading = useSelector(selectIsLoadingGeneratedOrders)
  const dispatch = useDispatch()
  const {
    location: { search, state }
  } = useHistory()
  const [currentList, setCurrentList] = useState({})
  const [isSublistOpen, setIsSublistOpen] = useState(false)
  const [isDetailsLoading, setIsDetailsLoading] = useState(false)

  const handleRowClick = list => () => {
    setIsDetailsLoading(true)
    getMultipickListDetails({ id: list.id })
      .then(resp => {
        setCurrentList(resp.data.data)
        setIsDetailsLoading(false)
      })
      .catch(err => {
        setCurrentList({})
        setIsDetailsLoading(false)
        toast.error(getApiErrors(err))
      })
  }

  useEffect(() => {
    setCurrentList({})
  }, [list])

  const clearCurrentList = () => {
    setCurrentList({})
  }

  const orders = useMemo(() => {
    return propOr([], 'orders', currentList)
  }, [currentList])

  const tasks = useMemo(() => {
    return propOr([], 'items', currentList)
  }, [currentList])

  useEffect(() => {
    const query = getCurrentParsedQuery()
    const payloadQuery = omit(
      ['activeTab', 'ordered_after', 'ordered_before'],
      query
    )
    if (isNotNilOrEmpty(search)) {
      if (has('isOpen', state)) {
        pathOr(null, ['currentMultipick', 'listNumber'], state) &&
          changeQuery({
            ...defaultFilters,
            filter: {
              name: pathOr('', ['currentMultipick', 'listNumber'], state)
            }
          })
        setIsSublistOpen(true)
      } else {
        dispatch(
          fetchGeneratedMultipickListsRoutine({
            query: stringifyQuery(payloadQuery)
          })
        )
      }
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const toggleCheckbox = order => (_, value) => {
    value
      ? setSelectedLists(prev => [...prev, order])
      : setSelectedLists(prev => prev.filter(o => o.id !== order.id))
  }

  const handleSelectAll = () => {
    list.forEach(list => {
      if (isNilOrEmpty(selectedLists.find(item => item.id === list.id))) {
        setSelectedLists(prev => [...prev, list])
      }
    })
  }

  const handleDeselectAll = () => {
    setSelectedLists([])
  }

  useEffect(() => {
    if (selectedLists.length === 0) {
      setPriorityInput('')
    } else if (selectedLists.length === 1) {
      const orderPriority = pathOr(1, [0, 'priority'], selectedLists)
      setPriorityInput(orderPriority)
    } else {
      const firstItemPriority = pathOr(1, [0, 'priority'], selectedLists)
      const areEqual = selectedLists.every(
        order => order.priority === firstItemPriority
      )
      setPriorityInput(areEqual ? firstItemPriority : '')
    }
  }, [selectedLists])

  const handlePriorityUpdate = e => {
    e.preventDefault()
    changeMultipickListsPriority({
      listIds: selectedLists.map(list => list.id),
      priority: Number(priorityInput)
    })
      .then(() => {
        toast.success('Pomyślnie zaktualizowano priorytety')
        const query = getCurrentParsedQuery()
        const payloadQuery = omit(
          ['activeTab', 'ordered_after', 'ordered_before'],
          query
        )
        dispatch(
          fetchGeneratedMultipickListsRoutine({
            query: stringifyQuery(payloadQuery)
          })
        )
        setSelectedLists([])
      })
      .catch(err => {
        toast.error(getApiErrors(err))
      })
  }

  const handleSearch = searchQuery => {
    const parsedQuery = getCurrentParsedQuery()
    const isEmpty = isNilOrEmpty(searchQuery)

    changeQuery({
      ...parsedQuery,
      page: 1,
      filter: isEmpty
        ? dissoc('name', propOr({}, 'filter', parsedQuery))
        : {
            ...propOr({}, 'filter', parsedQuery),
            name: searchQuery
          }
    })
  }

  const handleClear = () => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      filter: dissoc('name', propOr({}, 'filter', parsedQuery))
    })
  }

  const handleClearSelected = () => {
    setSelectedLists([])
  }

  return (
    <>
      {isNotNilOrEmpty(selectedLists) && (
        <HeaderWrapper>
          <div>Wybrane listy: {selectedLists.length}</div>
          <ActionButtons>
            <Form onSubmit={handlePriorityUpdate}>
              <Input
                type='number'
                inputProps={{
                  min: 1,
                  max: 10
                }}
                name='priority'
                label='Priorytet'
                value={priorityInput}
                onChange={(_, value) => setPriorityInput(value)}
              />
              <Button type='submit'>Zapisz</Button>
            </Form>
            <BulkRemoveListsModal
              clearSelected={handleClearSelected}
              lists={selectedLists}
            />
          </ActionButtons>
        </HeaderWrapper>
      )}
      <ListsWrapper>
        <List>
          <ListTitle>Listy kompletacji</ListTitle>
          <ListContent>
            <SearchWrapper>
              <SearchInput
                customFilterName='name'
                onSubmit={handleSearch}
                onClear={handleClear}
                placeholder='Znajdź listę...'
              />
            </SearchWrapper>
            <SelectAllWrapper>
              <SelectButton onClick={handleSelectAll}>
                Zaznacz wszystkie
              </SelectButton>
              <SelectButton onClick={handleDeselectAll}>
                Odznacz wszystkie
              </SelectButton>
            </SelectAllWrapper>
            <ItemsList>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                list.map(listItem => (
                  <MultipickListsListItem
                    clearCurrentList={clearCurrentList}
                    listItem={listItem}
                    key={listItem.id}
                    handleRowClick={handleRowClick}
                    currentList={currentList}
                    toggleCheckbox={toggleCheckbox}
                    selectedLists={selectedLists}
                    isOpen={isSublistOpen}
                  />
                ))
              )}
            </ItemsList>
          </ListContent>
        </List>
        <List>
          <ListTitle>Zamówienia</ListTitle>
          <ListContent>
            {isDetailsLoading ? <LoadingSpinner /> : null}
            {isNotNilOrEmpty(orders) && !isDetailsLoading ? (
              <MultipickListOrdersTable orders={orders} />
            ) : null}
          </ListContent>
        </List>
        <List>
          <ListTitle>Zadania operatorów</ListTitle>
          <ListContent>
            {isNotNilOrEmpty(tasks) && (
              <MultipickListTasksTable tasks={tasks} />
            )}
          </ListContent>
        </List>
      </ListsWrapper>
    </>
  )
}

export default MultipickListTab

const ListsWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
`

const List = styled.div`
  min-height: 300px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.border};
  flex: 1;
`

const ListTitle = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: #fff;
`

const ListContent = styled.div`
  padding: 5px;
  max-height: 500px;
  overflow-y: auto;
`

const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 300px;
`

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    max-width: 200px;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

const SearchWrapper = styled.div`
  margin-bottom: 5px;
`

const SelectAllWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
`

const SelectButton = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`

const ItemsList = styled.div``
