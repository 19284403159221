import React from 'react'
import styled from 'styled-components'

const AppLogo = () => {
  return (
    <LogoWrapper>
      <LogoIcon>Avapack</LogoIcon>
    </LogoWrapper>
  )
}

export default AppLogo

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 215px;
  font-size: 30px;
`

const LogoIcon = styled.div`
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: #fff;
  transform: skew(-10deg);
  text-transform: uppercase;
  margin-right: 5px;
  letter-spacing: 1px;
`
